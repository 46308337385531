import React, { useContext } from 'react'
import { Auth } from '../../../../contexts/allContext'
import classes from './Popup.module.css'

export default function Popup({ index, lab, setIsOpen }) {
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token
    const api = process.env.REACT_APP_API_URL

    const refreshPage = () => {
        window.location.reload()
    }

    const deleted = async () => {
        const response = await fetch(`${api}/lab-test/${lab.LabTest?.id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })

        if (response.ok) {
            refreshPage()
        }
    }

    return (
        <div key={() => index} className={classes.container}>
            <div className={classes.overlay} onClick={() => setIsOpen(false)}></div>
            <div className={classes.formWrapper}>
                <div className={classes.close} onClick={() => setIsOpen(false)}>
                    &times;
                </div>

                <p>{lab.LabTest?.name}</p>

                <div className={classes.buttonContainer}>
                    <button
                        className={classes.button}
                        // onClick={deleted}
                    >
                        Yes
                    </button>

                    <button className={classes.button} onClick={() => setIsOpen(false)}>
                        No
                    </button>
                </div>
            </div>
        </div>
    )
}
