import { useContext, useEffect, useState } from 'react'
import { Auth } from '../../../contexts/allContext'
import { api } from '../../../utils/apiToken'
import { Table } from '../../Resource'
import { Pagination } from '../../index'
import classes from './Eprescriptions.module.css'

export default function Eprescriptions({ id }) {
    const epV1 = process.env.REACT_APP_EP
    let serial = 0

    const [prescriptions, setPrescriptions] = useState([])
    const [pagePresSkip, setPagePresSkip] = useState(0)

    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    useEffect(() => {
        let fetchData = async () => {
            try {
                const response = await fetch(`${api}/ep/patient/${id}?skip=${pagePresSkip}&limit=10`, {
                    method: 'GET',
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()

                if (response.ok) {
                    setPrescriptions(data)
                }
            } catch (e) {
                setPrescriptions([])
            }
        }
        fetchData()
    }, [token, pagePresSkip, id])

    return (
        <div className={classes.collabList}>
            <Table>
                <thead>
                    <tr>
                        <th>Sl</th>
                        <th>Doctor Name</th>
                        <th>Cause of Consultation</th>
                        <th>Date</th>
                        <th>Prescription</th>
                    </tr>
                </thead>
                <tbody>
                    {prescriptions[1] &&
                        prescriptions[1].map((ep, index) => (
                            <tr className={classes.erow} key={index}>
                                <td>{(serial = serial + 1) + pagePresSkip}</td>
                                <td>{ep?.doctor[0]?.name}</td>
                                <td>{ep.cause_of_consultation}</td>
                                <td>{ep.created_at && ep.created_at.slice(0, 10)}</td>
                                <td>
                                    <a href={`${epV1}/ep/hxep${ep.id + 100000}`} target="__blank">
                                        <button>View</button>
                                    </a>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>

            <Pagination
                pageSkip={pagePresSkip}
                setPageSkip={setPagePresSkip}
                listItem={prescriptions[1]}
                pageAll={prescriptions[0]?.results}
            />
        </div>
    )
}
