import Pic from '../../../assets/notice.jpg'
import classes from './NoticePortal.module.css'

export default function NoticePortal({ notices }) {
    return (
        <div className={classes.allContainer}>
            <div className={classes.wrapperNotice}>
                <div className={classes.container}>
                    {notices &&
                        notices.map((notice, index) =>
                            notice.status === true ? (
                                <div className={classes.containerFlex} key={index}>
                                    <div>
                                        <img src={Pic} alt="" />
                                        <span>{notice.created_at.slice(0, 10)}</span>
                                    </div>
                                    <div>
                                        <h6>
                                            <button>{notice.title}</button>
                                        </h6>
                                        <p>{notice.body}</p>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )
                        )}
                </div>
            </div>
        </div>
    )
}
