import { useState, useEffect, useContext } from 'react'
import { Auth } from '../../../contexts/allContext'
import { api } from '../../../utils/apiToken'
import { toMonthNameShort } from '../../../utils/date'
import { LineChart } from '../../Chart'
import { DoubleNumber } from './index'

const Bp = ({ id }) => {
    const [dataBp, setDataBp] = useState([])
    const [high, setHigh] = useState(null)
    const [low, setLow] = useState(null)

    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    const submit = async (e) => {
        e.preventDefault()

        if (high !== null && low !== null && high !== 0 && low !== 0) {
            let bpFetch = await fetch(`${api}/admin/patient/indicator?user_id=${id}`, {
                method: 'POST',
                headers: {
                    Accept: 'appllication/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    key: 'bp',
                    unit: 'mmHg',
                    slot_int1: high,
                    slot_int2: low,
                }),
            })
            if (bpFetch.ok) {
                setHigh(0)
                setLow(0)
            }
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/admin/patient/indicator/bp/${id}`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                setDataBp(data)
            } catch {
                setDataBp([])
            }
        }
        return fetchData()
    }, [token, high, low, id])

    let data = {
        labels: [
            ...dataBp
                .map(
                    (elm) =>
                        `${elm.created_at.slice(8, 10)}-${toMonthNameShort(
                            elm.created_at.slice(5, 7)
                        )}${elm.created_at.slice(2, 4)}`
                )
                .reverse(),
        ],
        datasets: [
            {
                label: 'High',
                data: [...dataBp.map((elm) => elm.slot_int1).reverse()],
                fill: true,
                backgroundColor: 'rgba(245, 66, 66,0.1)',
                borderColor: 'rgba(245, 66, 65,1)',
                lineTension: 0.4,
            },
            {
                label: 'Low',
                data: [...dataBp.map((elm) => elm.slot_int2).reverse()],
                fill: true,
                backgroundColor: 'rgba(45, 114, 178,0.2)',
                borderColor: 'rgba(45, 114, 178,1)',
                lineTension: 0.4,
            },
        ],
    }

    return (
        <div>
            <DoubleNumber
                title="Blood Presure"
                place1="Input High BP"
                place2="Input Low BP"
                unit="mmHg"
                st1={high}
                st2={low}
                setSt1={setHigh}
                setSt2={setLow}
                smbt={submit}
                min1={0}
                min2={0}>
                <br />
                <LineChart data={data} />
                <br />
            </DoubleNumber>
        </div>
    )
}

export default Bp
