import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ListCount } from '../../../Resource'
import { Pagination, Loading } from '../../../index'
import classes from './ActiveList.module.css'

export default function ActiveList({
    search,
    setSearch,
    setIsOpenForm,
    setOpenActive,
    setOpenPending,
    medicines,
    pageSkip,
    setPageSkip,
    loading,
}) {
    let sl = 0

    const openClose = () => {
        setOpenActive(false)
        setOpenPending(true)
    }

    return (
        <div className={classes.tableContainer}>
            <div className={classes.wrapper}>
                <form action="">
                    <input
                        className={classes.searchField}
                        type="text"
                        value={search}
                        placeholder="Search Medicine"
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <button className={classes.searchButton}>
                        <FontAwesomeIcon icon={faSearch} />
                    </button>
                </form>

                <div className={classes.allButton}>
                    <button className={classes.button} onClick={() => openClose()}>
                        Pending List
                    </button>
                    <span></span>
                    <button className={`${classes.button} ${classes.buttonMargin}`} onClick={() => setIsOpenForm(true)}>
                        + Add Medicine
                    </button>
                </div>
            </div>

            <ListCount title="Medicine List" results={medicines} />

            <table className={classes.tableMain}>
                <thead>
                    <tr className={classes.tableRow}>
                        <th>Sl</th>
                        <th>Medicine Name</th>
                        <th>Generic</th>
                        <th>Strength</th>
                        <th>Form</th>
                        <th>Unit Price (৳)</th>
                        {/* <th>Depo Price (৳)</th> */}
                        <th>Type</th>
                        <th>Pharmaceuticals</th>
                    </tr>
                </thead>
                <tbody>
                    {medicines[1] &&
                        medicines[1].map((item, index) => (
                            <tr className={classes.tableRow} key={index}>
                                <td>{(sl = sl + 1) + pageSkip}</td>
                                <td className={classes.cap}>{item.name}</td>
                                <td className={classes.cap}>{item.generic}</td>
                                <td>{item.strength}</td>
                                <td>{item.form}</td>
                                <td>{item.unit_price.toFixed(2)}</td>
                                {/* <td>{item.unit_price !== null ? (item.unit_price / 1.334).toFixed(2) : '-'}</td> */}
                                <td>{item.unit_type}</td>
                                <td>{item.pharmaceuticals}</td>
                            </tr>
                        ))}
                </tbody>
            </table>
            <Pagination
                pageSkip={pageSkip}
                setPageSkip={setPageSkip}
                listItem={medicines[1]}
                pageAll={medicines[0]?.results}
            />
            {loading && <Loading />}
        </div>
    )
}
