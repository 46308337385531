import { faLeaf, faThumbtack } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import { Auth } from '../../../contexts/allContext'
import classes from './Form.module.css'

export default function Form({ setIsOpen }) {
    const [providerList, setProviderList] = useState([])
    const [providerSearch, setProviderSearch] = useState('')
    const [hide, setHide] = useState(false)

    const [providerInfo, setProviderInfo] = useState([])
    const [labName, setLabName] = useState('')
    const [mrp, setMrp] = useState('')
    const [discount, setDiscount] = useState('')
    const [price, setPrice] = useState('')

    const api = process.env.REACT_APP_API_URL
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    useEffect(() => {
        if (discount !== null) {
            const dis = mrp - (mrp * discount) / 100
            setPrice(dis)
        }
    }, [discount, mrp])

    const refreshPage = () => {
        window.location.reload()
    }

    //post lab test
    const handleSubmit = async (e) => {
        e.preventDefault()

        const details = {
            name: labName,
            mrp: mrp,
            discount: discount,
            price: price,
            provider_id: providerInfo.id,
        }
        let postFetch = await fetch(`${api}/lab-test/`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(details),
        })

        if (postFetch.ok) {
            refreshPage()
        }
    }

    //get all provider list
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/test-provider/`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()

                if (response.ok) {
                    setProviderList(data)
                }
            } catch {
                setProviderList([])
            }
        }
        fetchData()
    }, [token, api])

    return (
        <div>
            <div className={classes.wrapper}>
                <button className={classes.button} onClick={(e) => setIsOpen(false)}>
                    Lab Test List
                </button>
            </div>
            <div className={classes.formWrapper}>
                <FontAwesomeIcon className={classes.icon} icon={faThumbtack} />
                <div className={classes.logo}>ADD LAB TEST</div>

                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className={classes.section}>
                        <FontAwesomeIcon className={classes.faLeaf} icon={faLeaf} />
                        Details Info
                    </div>
                    <div className={classes.innerWrap}>
                        <label>
                            Provider Name <span>*</span>
                            <input
                                type="text"
                                value={providerSearch}
                                onChange={(e) => setProviderSearch(e.target.value)}
                                onFocus={(e) => setHide(true)}
                            />
                        </label>

                        {hide && (
                            <div className={classes.optAll}>
                                {providerList &&
                                    providerList.map((info, i) => (
                                        <div className={classes.optSelect} key={i}>
                                            <div
                                                onClick={(e) => {
                                                    setProviderSearch(info.name)
                                                    setHide(false)
                                                    setProviderInfo(info)
                                                }}>
                                                <option value={info.id}>{info.name}</option>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        )}

                        <div className={classes.formGrid}>
                            <label>
                                Lab Test Name <span>*</span>
                                <input
                                    type="text"
                                    value={labName}
                                    onChange={(e) => setLabName(e.target.value)}
                                    required
                                />
                            </label>
                            <label>
                                MRP <span>*</span>
                                <input type="text" value={mrp} onChange={(e) => setMrp(e.target.value)} required />
                            </label>
                        </div>

                        <div className={classes.formGrid}>
                            <label>
                                Discount <span>*</span>
                                <input
                                    type="text"
                                    value={discount}
                                    onChange={(e) => setDiscount(e.target.value)}
                                    required
                                />
                            </label>
                            <label>
                                Price <span>*</span>
                                <input type="text" value={price} onChange={(e) => setPrice(e.target.value)} required />
                            </label>
                        </div>
                    </div>

                    {/* {loading ? (
                    <Loading />
                ) : (
                    <button className={classes.button} type="submit">
                        SUBMIT
                    </button>
                )} */}
                    <button className={classes.button} type="submit">
                        SUBMIT
                    </button>
                </form>
            </div>
        </div>
    )
}
