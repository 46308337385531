import { useState } from 'react'
import Medication from './Medication/Medication'
import Prescription from './Prescription/Prescription'
import Report from './Report/Report'
import classes from './Reports.module.css'
import Surgery from './Surgery/Surgery'
import Vaccination from './Vaccination/Vaccination'

export default function Reports({ id }) {
    const [select, setSelect] = useState(1)

    return (
        <div className={classes.reports}>
            <div className={classes.selects}>
                <select onChange={(e) => setSelect(parseInt(e.target.value))}>
                    <option value="1">Prescriptions</option>
                    <option value="2">Medical Reports</option>
                    <option value="3">Surgery Reports</option>
                    <option value="4">Medication Records</option>
                    <option value="5">Vaccination Records</option>
                </select>
            </div>

            <div className={classes.container}>
                {select === 1 && <Prescription id={id} />}
                {select === 2 && <Report id={id} />}
                {select === 3 && <Surgery id={id} />}
                {select === 4 && <Medication id={id} />}
                {select === 5 && <Vaccination id={id} />}
            </div>
        </div>
    )
}
