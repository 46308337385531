import { useState, useEffect, useContext } from 'react'
import { Auth, UserInfo } from '../../contexts/allContext'
import { apiV2 } from '../../utils/apiToken'
import List from './List/List'
import classes from './Partners.module.css'

export default function Partners() {
    const [pharmacies, setPharmacies] = useState([])
    const [pendingPharmacies, setPendingPharmacies] = useState([])
    const [pageSkip, setPageSkip] = useState(0)
    const [loading, setLoading] = useState(true)

    const [loader, setLoader] = useState(false)

    const { stateAuth } = useContext(Auth)
    const { stateUser } = useContext(UserInfo)
    const userInfo = stateUser.info
    const token = stateAuth.token

    useEffect(() => {
        let fetchData = async () => {
            try {
                const response = await fetch(`${apiV2}/partner-pharmacy/all?skip=${pageSkip}&limit=10`, {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    method: 'GET',
                })
                const data = await response.json()

                if (response.ok) {
                    setPharmacies(data)
                    setLoading(false)
                    setLoader(false)
                }
            } catch (e) {
                setPharmacies([])
            }
        }
        fetchData()
    }, [token, pageSkip, loader])

    useEffect(() => {
        let fetchData = async () => {
            try {
                const response = await fetch(`${apiV2}/admin/partner-pharmacy/inactive?skip=${pageSkip}&limit=10`, {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    method: 'GET',
                })
                const data = await response.json()

                if (response.ok) {
                    setPendingPharmacies(data)
                    setLoading(false)
                }
            } catch (e) {
                setPendingPharmacies([])
            }
        }
        fetchData()
    }, [token, pageSkip])

    return (
        <div>
            {userInfo.role_name === 'admin' || userInfo.role_name === 'moderator' || userInfo.role_name === 'crm' ? (
                <List
                    pharmacies={pharmacies}
                    pendingPharmacies={pendingPharmacies}
                    pageSkip={pageSkip}
                    setPageSkip={setPageSkip}
                    loading={loading}
                    loader={loader}
                    setLoader={setLoader}
                />
            ) : (
                <div className={classes.modWrapper}>
                    <div className={classes.mod}>Employee Can't Access This Tab!</div>
                </div>
            )}
        </div>
    )
}
