import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useState } from 'react'
import { UserInfo } from '../../../../contexts/allContext'
import { Pagination, Loading } from '../../../index'
import classes from './PendingList.module.css'
import Update from './Update/Update'

export default function PendingList({
    setIsOpenForm,
    setOpenActive,
    setOpenPending,
    pendingMedicines,
    pageSkip,
    setPageSkip,
    loading,
    setSuccess,
}) {
    const [isOpen, setIsOpen] = useState(false)
    let sl = 0

    const openClose = () => {
        setOpenActive(true)
        setOpenPending(false)
    }

    const { stateUser } = useContext(UserInfo)
    const userInfo = stateUser.info

    return (
        <div className={classes.tableContainer}>
            <div className={classes.wrapper}>
                <button className={classes.button} onClick={() => openClose()}>
                    Medicine List
                </button>
                <button className={classes.button} onClick={() => setIsOpenForm(true)}>
                    + Add Medicine
                </button>
            </div>
            <table className={classes.tableMain}>
                <thead>
                    <tr className={classes.tableRow}>
                        <th>Sl</th>
                        <th>Medicine Name</th>
                        <th>Generic</th>
                        <th>Strength</th>
                        <th>Form</th>
                        <th>Unit Price</th>
                        <th>Type</th>
                        <th>Pharmaceuticals</th>
                        <th>Added By</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {pendingMedicines[1] &&
                        pendingMedicines[1].map((item, index) => (
                            <tr className={classes.tableRow} key={index}>
                                <td>{(sl = sl + 1)}</td>
                                <td className={classes.cap}>{item.name}</td>
                                <td className={classes.cap}>{item.generic}</td>
                                <td>{item.strength}</td>
                                <td>{item.form}</td>
                                <td>{item.unit_price}</td>
                                <td>{item.unit_type}</td>
                                <td>{item.pharmaceuticals}</td>
                                <td>{item.added_by_id}</td>
                                {userInfo.role_name === 'admin' || userInfo.role_name === 'moderator' ? (
                                    <td>
                                        <button className={classes.icon}>
                                            <FontAwesomeIcon icon={faEdit} onClick={() => setIsOpen(index)} />
                                        </button>
                                    </td>
                                ) : (
                                    <td>-</td>
                                )}

                                {isOpen === index && (
                                    <Update index={index} item={item} setIsOpen={setIsOpen} setSuccess={setSuccess} />
                                )}
                            </tr>
                        ))}
                </tbody>
            </table>
            <Pagination
                pageSkip={pageSkip}
                setPageSkip={setPageSkip}
                listItem={pendingMedicines[1]}
                pageAll={pendingMedicines[0]?.results}
            />
            {loading && <Loading />}
        </div>
    )
}
