import MedicineList from './MedicineList/MedicineList'

export default function MedicineShow({ index, setSuccess, medicines, forceUpdate }) {
    return (
        <div>
            {medicines[1] &&
                medicines[1].map((medicine) => (
                    <MedicineList index={index} setSuccess={setSuccess} medicine={medicine} forceUpdate={forceUpdate} />
                ))}
        </div>
    )
}
