import '@fortawesome/free-solid-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useState } from 'react'
import { UserInfo } from '../../../contexts/allContext'
import { toMonthNameShort } from '../../../utils/date'
import classes from './PlanList.module.css'
import PlanUpdate from './PlanUpdate/PlanUpdate'

export default function PlanList({ plans, setFormOpen, setListOpen }) {
    const [isOpen, setIsOpen] = useState(false)

    const { stateUser } = useContext(UserInfo)
    const userInfo = stateUser.info

    const openClose = () => {
        setListOpen(false)
        setFormOpen(true)
    }

    // plans.sort(function (nameA, nameB) {
    //     const a = nameA?.name.toLowerCase()
    //     const b = nameB?.name.toLowerCase()

    //     if (a > b) {
    //         return 1
    //     }
    //     if (b > a) {
    //         return -1
    //     }
    //     return 0
    // })

    return (
        <div className={classes.allContainer}>
            <div className={classes.wrapper}>
                <button className={classes.button} onClick={() => openClose()}>
                    + Add Service
                </button>
            </div>
            <div className={classes.wrapperPlan}>
                <p>
                    <FontAwesomeIcon icon={faBars} /> Health Plan
                </p>
                <div className={classes.container}>
                    {plans &&
                        plans
                            .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
                            .map((plan, index) =>
                                plan.plan_type === 'health_plan' ? (
                                    <div className={classes.containerFlex} key={index}>
                                        <div>
                                            {/* <img src={Plan} alt="" /> */}
                                            {plan?.expire_date !== null ? (
                                                <span>
                                                    Expire:{' '}
                                                    {`${plan.expire_date.slice(8, 10)}-${toMonthNameShort(
                                                        plan.expire_date.slice(6, 7)
                                                    )}-${plan.expire_date.slice(0, 4)}`}
                                                </span>
                                            ) : (
                                                <span></span>
                                            )}
                                            <span>{plan.voucher_code !== '' ? `Code: ${plan.voucher_code}` : ''}</span>
                                        </div>
                                        <div className={classes.box}>
                                            <h6>{plan.name}</h6>
                                            {/* <p>{plan.details}</p> */}
                                            <p>
                                                Number of Patient: <span>{plan.total_patients} </span>
                                            </p>
                                            <p>
                                                Duration:{' '}
                                                <span>
                                                    {Math.floor(plan.days / 30) === 0 ? 1 : Math.floor(plan.days / 30)}{' '}
                                                    {plan.days / 30 > 1
                                                        ? 'Months'
                                                        : Math.floor(plan.days / 30) === 0
                                                        ? 'Day'
                                                        : 'Month'}
                                                </span>
                                            </p>
                                            <p>
                                                Price: <span>৳{plan.fee}</span>
                                            </p>
                                        </div>
                                        {userInfo.role_name === 'admin' || userInfo.role_name === 'moderator' ? (
                                            <button onClick={() => setIsOpen(index)}>Edit</button>
                                        ) : (
                                            ''
                                        )}
                                        {isOpen === index && (
                                            <PlanUpdate setIsOpen={setIsOpen} index={index} plan={plan} />
                                        )}
                                    </div>
                                ) : (
                                    ''
                                )
                            )}
                </div>
            </div>

            <div className={classes.wrapperPackage}>
                <p>
                    <FontAwesomeIcon icon={faBars} /> Health Package
                </p>
                <div className={classes.container}>
                    {plans &&
                        plans.map((plan, index) =>
                            plan.plan_type === 'health_package' ? (
                                <div className={classes.containerFlex} key={index}>
                                    <div>
                                        {/* <img src={Package} alt="" /> */}
                                        {plan?.expire_date !== null ? (
                                            <span>
                                                Expire:{' '}
                                                {`${plan.expire_date.slice(8, 10)}-${toMonthNameShort(
                                                    plan.expire_date.slice(6, 7)
                                                )}-${plan.expire_date.slice(0, 4)}`}
                                            </span>
                                        ) : (
                                            <span></span>
                                        )}
                                        <span>{plan.voucher_code !== '' ? `Code: ${plan.voucher_code}` : ''}</span>
                                    </div>
                                    <div className={classes.box}>
                                        <h6>{plan.name}</h6>
                                        {/* <p>{plan.details}</p> */}
                                        <p>
                                            Number of Patient: <span>{plan.total_patients}</span>
                                        </p>
                                        <p>
                                            Duration:{' '}
                                            <span>
                                                {Math.floor(plan.days / 30) === 0 ? 1 : Math.floor(plan.days / 30)}{' '}
                                                {plan.days / 30 > 1
                                                    ? 'Months'
                                                    : Math.floor(plan.days / 30) === 0
                                                    ? 'Day'
                                                    : 'Month'}
                                            </span>
                                        </p>
                                        <p>
                                            Price: <span>৳{plan.fee}</span>
                                        </p>
                                    </div>
                                    {userInfo.role_name === 'admin' || userInfo.role_name === 'moderator' ? (
                                        <button onClick={() => setIsOpen(index)}>Edit</button>
                                    ) : (
                                        ''
                                    )}
                                    {isOpen === index && <PlanUpdate setIsOpen={setIsOpen} index={index} plan={plan} />}
                                </div>
                            ) : (
                                ''
                            )
                        )}
                </div>
            </div>
        </div>
    )
}
