import { faCalendarCheck, faClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Lab from '../../../../assets/notification/lab.png'
import Med from '../../../../assets/notification/med.png'
import Plan from '../../../../assets/notification/plan.png'
import Tele from '../../../../assets/notification/tele.png'
import { ServiceId } from '../../../../contexts/allContext'
import { formatAmPmGMT6, toDateMonthNameGMT6 } from '../../../../utils/dateGMT6'
import classes from './Inbox.module.css'

export default function Inbox({ close, notifications }) {
    // const { dispatchServiceid } = useContext(ServiceId)
    // const navigate = useNavigate()

    // const handleServiceid = (id) => {
    //     dispatchServiceid({ type: 'set', payload: id })
    //     if (window.location.pathname !== '/services') {
    //         navigate('/services')
    //     }
    // }
    return (
        <div className={classes.container}>
            <div className={classes.overlay} onClick={() => close(false)}></div>
            <div className={classes.form}>
                <div className={classes.close} onClick={() => close(false)}>
                    &times;
                </div>
                <h2>Notifications</h2>

                <div className={classes.wrap}>
                    {notifications &&
                        notifications.map((item, i) => (
                            <div
                                className={classes.detail}
                                key={i}
                                // onClick={() => {
                                //     handleServiceid(10000000 + item.service_id)
                                //     setTimeout(() => {
                                //     close(false)
                                //     }, 0)
                                // }}
                            >
                                <div className={classes.head}>
                                    <img
                                        src={
                                            item.service_name === 'health_plan'
                                                ? Plan
                                                : item.service_name === 'telemedicine'
                                                ? Tele
                                                : item.service_name === 'medicine_order'
                                                ? Med
                                                : item.service_name === 'lab_test'
                                                ? Lab
                                                : ''
                                        }
                                        alt=""
                                    />
                                    <div className={classes.info}>
                                        <h2>
                                            {item.service_name.replace(/_/g, ' ')}
                                            <span>HX-S{item.service_id + 10000000}</span>
                                        </h2>
                                        <span>*</span>
                                    </div>
                                </div>

                                <p>
                                    Patient: <b> {item.service_receiver_name}</b>
                                </p>
                                <p>By: {item.service_issuer_name}</p>
                                <div className={classes.date}>
                                    <p>
                                        <FontAwesomeIcon icon={faCalendarCheck} />{' '}
                                        {toDateMonthNameGMT6(item.created_at, 'short')}
                                    </p>
                                    <span>
                                        <FontAwesomeIcon icon={faClock} /> {formatAmPmGMT6(item.created_at)}
                                    </span>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    )
}
