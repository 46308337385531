import { faThumbtack } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classes from './Summary.module.css'

export default function Summary({ telemed, plan, patient }) {
    return (
        <div className={classes.summery}>
            <div className={classes.box}>
                <FontAwesomeIcon icon={faThumbtack} />
                <p>Telemedicine</p>
                <h4>{telemed.total || 0}</h4>
                <span className={classes.numbers}>
                    Today: <b>{telemed.current_day}</b>
                </span>
                <span className={classes.numbers}>
                    This Month: <b>{telemed.this_month}</b>
                </span>
                <span className={classes.numbers}>Last Month: {telemed.last_month}</span>
            </div>
            <div className={classes.box}>
                <FontAwesomeIcon icon={faThumbtack} />
                <p>Health Plan</p>
                <h4>{plan.total || 0}</h4>
                <span className={classes.numbers}>
                    Today: <b>{plan.current_day}</b>
                </span>
                <span className={classes.numbers}>
                    This Month: <b>{plan.this_month}</b>
                </span>
                <span className={classes.numbers}>Last Month: {plan.last_month}</span>
            </div>
            <div className={classes.box}>
                <FontAwesomeIcon icon={faThumbtack} />
                <p>Patient Register</p>
                <h4>{patient.total || 0}</h4>
                <span className={classes.numbers}>
                    Today: <b>{patient.current_day}</b>
                </span>
                <span className={classes.numbers}>
                    This Month: <b>{patient.this_month}</b>
                </span>
                <span className={classes.numbers}>Last Month: {patient.last_month}</span>
            </div>
            {/* <div className={classes.box}>
                    <FontAwesomeIcon icon={faThumbtack} />
                    <p>Medicine Order</p>
                    <span className={classes.numbers}>
                        Today: <b>12</b>
                    </span>
                    <span className={classes.numbers}>
                        This Month: <b>12</b>
                    </span>
                    <span className={classes.numbers}>Last Month: 12</span>
            </div> */}
        </div>
    )
}
