import { useContext } from 'react'
import { Auth } from '../../../../../contexts/allContext'
import { api } from '../../../../../utils/apiToken'
import classes from './Update.module.css'

export default function Update({ index, setIsOpen, item, setSuccess }) {
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    const activeMedicine = async () => {
        const details = {
            ...item,
            add_status: 'done',
        }
        let putFetch = await fetch(`${api}/medicines/${item.id}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(details),
        })
        if (putFetch.ok) {
            setIsOpen(false)
            setSuccess(true)

            setTimeout(() => {
                setSuccess(false)
            }, 3000)
        }
    }

    const deleteMedicine = async () => {
        let deleteFetch = await fetch(`${api}/medicines/${item.id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
        if (deleteFetch.ok) {
            setIsOpen(false)
            setSuccess(true)

            setTimeout(() => {
                setSuccess(false)
            }, 3000)
        }
    }

    return (
        <div key={() => index} className={classes.container}>
            <div className={classes.formWrapper}>
                <div className={classes.close} onClick={() => setIsOpen(false)}>
                    &times;
                </div>

                <div className={classes.buttonContainer}>
                    <button
                        className={classes.button}
                        onClick={() => {
                            activeMedicine()
                        }}>
                        Active (Yes)
                    </button>

                    <button className={classes.button} onClick={() => deleteMedicine()}>
                        Delete
                    </button>
                </div>
            </div>
        </div>
    )
}
