import { faLeaf } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Auth } from '../../../contexts/allContext'
import classes from './Password.module.css'

export default function Password() {
    const [password, setPassword] = useState('')
    const [rePassword, setRePassword] = useState('')
    const [alert, setAlert] = useState(false)

    const api = process.env.REACT_APP_API_URL
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    const navigate = useNavigate()

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (password !== rePassword) {
            setAlert(true)
            setTimeout(function () {
                setAlert(false)
            }, 3000)
        } else {
            const details = {
                password,
            }
            let putFetch = await fetch(`${api}/password/new`, {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(details),
            })

            if (putFetch.ok) {
                navigate('/')
            }
        }
    }

    return (
        <div className={classes.formWrapper}>
            <form onSubmit={(e) => handleSubmit(e)}>
                <div className={classes.section}>
                    <FontAwesomeIcon className={classes.leaf} icon={faLeaf} />
                    Password Change
                </div>
                <div className={classes.innerWrap}>
                    <div className={classes.formGrid}>
                        <label>
                            Password
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                minLength={4}
                                required
                            />
                        </label>
                        <label>
                            Confirm Password
                            <input
                                type="password"
                                value={rePassword}
                                onChange={(e) => setRePassword(e.target.value)}
                                minLength={4}
                                required
                            />
                        </label>
                    </div>
                </div>
                <button className={classes.button} type="submit">
                    Change
                </button>
                <div> {alert && <p className={classes.alertMessage}>Password not matched!</p>}</div>
            </form>
        </div>
    )
}
