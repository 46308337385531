import { useContext, useEffect, useState } from 'react'
import { Auth, UserInfo } from '../../contexts/allContext'
import { api } from '../../utils/apiToken'
import { currentDate } from '../../utils/date'
import classes from './Doctor.module.css'
import DoctorForm from './DoctorForm/DoctorForm'
import DoctorList from './DoctorList/DoctorList'

export default function Doctor() {
    const [pendingDoctors, setPendingDoctors] = useState([])
    const [activeDoctors, setActiveDoctors] = useState([])

    const [partner, setPartner] = useState()

    const [listOpen, setListOpen] = useState(true)
    const [formOpen, setFormOpen] = useState(false)
    const [pageSkip, setPageSkip] = useState(0)
    const [countLimit, setCountLimit] = useState(10)

    const [loading, setLoading] = useState(true)
    const [success, setSuccess] = useState(false)

    const { stateAuth } = useContext(Auth)
    const { stateUser } = useContext(UserInfo)
    const userInfo = stateUser.info
    const token = stateAuth.token

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${api}/admin/doctors/inactive?end_date=${currentDate}T23%3A59%3A59&skip=${pageSkip}&limit=10`,
                    {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                const data = await response.json()

                if (response.ok) {
                    setPendingDoctors(data)
                    setLoading(false)
                }
            } catch {
                setPendingDoctors([])
            }
        }
        return fetchData()
    }, [token, pageSkip])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    partner
                        ? `${api}/admin/doctors/active?end_date=${currentDate}T23%3A59%3A59&is_shown_partner=${partner}&skip=${pageSkip}&limit=${countLimit}`
                        : `${api}/admin/doctors/active?end_date=${currentDate}T23%3A59%3A59&skip=${pageSkip}&limit=${countLimit}`,
                    {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                const data = await response.json()

                if (response.ok) {
                    setActiveDoctors(data)
                    setLoading(false)
                }
            } catch {
                setActiveDoctors([])
            }
        }
        return fetchData()
    }, [token, pageSkip, partner, countLimit, success])

    return (
        <div>
            {userInfo.role_name === 'admin' || userInfo.role_name === 'moderator' ? (
                <div>
                    {formOpen && (
                        <DoctorForm setListOpen={setListOpen} setFormOpen={setFormOpen} api={api} token={token} />
                    )}
                    {listOpen && (
                        <DoctorList
                            activeDoctors={activeDoctors}
                            pendingDoctors={pendingDoctors}
                            pageSkip={pageSkip}
                            setPageSkip={setPageSkip}
                            setListOpen={setListOpen}
                            setFormOpen={setFormOpen}
                            loading={loading}
                            success={success}
                            setSuccess={setSuccess}
                            setCountLimit={setCountLimit}
                            setPartner={setPartner}
                        />
                    )}
                </div>
            ) : (
                <div className={classes.modWrapper}>
                    <div className={classes.mod}>Employee Can't Access This Page!</div>{' '}
                </div>
            )}
        </div>
    )
}
