import { useContext } from 'react'
import { Auth } from '../../../../../../contexts/allContext'
import Loading from '../../../../../Loading/Loading'
import { Table } from '../../../../../Resource'
import classes from './List.module.css'

export default function List({ chambers, isloading, setStatus }) {
    const api = process.env.REACT_APP_API_URL
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    const handleupdate = async (id, userid) => {
        let putFetch = await fetch(`${api}/doctors/chamber/activate/switch/${id}?user_id=${userid}`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
        if (putFetch.ok) {
            setStatus(true)
        }
    }

    return (
        <div>
            {isloading ? (
                <Loading />
            ) : (
                <Table>
                    <thead>
                        <tr>
                            <th>Sl.</th>
                            <th>Chamber</th>
                            <th>Details</th>
                            <th>District</th>
                            <th>Fee</th>
                            <th>Address</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {chambers &&
                            chambers.map((chamber, i) => (
                                <tr className={classes.row} key={i}>
                                    <td>{i + 1}</td>
                                    <td>{chamber.name || '-'}</td>
                                    <td>{chamber.detail || '-'}</td>
                                    <td>{chamber.district || '-'}</td>
                                    <td>{chamber.chamber_fee || '-'}</td>
                                    <td>{chamber.detail_address || '-'}</td>
                                    {/* <td className={classes.status}>
                                        {chamber.is_active === true ? (
                                            <span className={classes.active}>Active</span>
                                        ) : (
                                            <span className={classes.delete}>Off</span>
                                        )}
                                    </td> */}
                                    <td>
                                        <div className={classes.subNav}>
                                            <button
                                                className={chamber.is_active === true ? classes.selectedTrue : ''}
                                                onClick={(e) => handleupdate(chamber.id, chamber.user_id)}>
                                                On
                                            </button>
                                            <button
                                                className={chamber.is_active === false ? classes.selectedfalse : ''}
                                                onClick={(e) => handleupdate(chamber.id, chamber.user_id)}>
                                                Off
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            )}
        </div>
    )
}
