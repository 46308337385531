import { useContext, useEffect, useState } from 'react'
import { Auth } from '../../contexts/allContext'
import { api } from '../../utils/apiToken'
import { currentDate } from '../../utils/date'
import PatientForm from './PatientForm/PatientForm'
import PatientList from './PatientList/PatientList'

export default function Patient() {
    const [listOpen, setListOpen] = useState(true)
    const [formOpen, setFormOpen] = useState(false)

    const [patients, setPatients] = useState([])
    const [corporates, setCorporates] = useState([])

    const [pageSkip, setPageSkip] = useState(0)
    const [pageSkipPopup, setPageSkipPopup] = useState(0)

    const [indicatorType, setIndicatorType] = useState('bp')
    const [indicators, setIndicators] = useState([])
    const [getId, setGetId] = useState(0)

    const [searchId, setSearchId] = useState('')
    const [searchName, setSearchName] = useState('')
    const [searchPhone, setSearchPhone] = useState('')
    const [searchGender, setSearchGender] = useState('')

    const [loading, setLoading] = useState(true)

    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    searchId
                        ? `${api}/admin/user/filter?hx_user_id=${parseInt(searchId) - 1000000}`
                        : `${api}/admin/user/filter?name=${searchName}&phone=${searchPhone}&gender=${searchGender}&end_date=${currentDate}T23%3A59%3A59&skip=${pageSkip}&limit=10`,
                    {
                        method: 'GET',
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )

                const data = await response.json()
                if (response.ok) {
                    setPatients(data)
                    setLoading(false)
                }
            } catch {
                setPatients([])
            }
        }
        fetchData()
    }, [token, searchId, searchName, searchPhone, searchGender, pageSkip])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${api}/admin/patient/indicator/${indicatorType}/${getId}?skip=${pageSkipPopup}&limit=10`,
                    {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                const data = await response.json()
                setIndicators(data)
            } catch {
                setIndicators([])
            }
        }
        return fetchData()
    }, [token, getId, indicatorType, pageSkipPopup])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/corporate/`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                setCorporates(data)
            } catch {
                setCorporates([])
            }
        }
        fetchData()
    }, [token])

    return (
        <div>
            <div>
                {formOpen && <PatientForm setFormOpen={setFormOpen} setListOpen={setListOpen} />}
                {listOpen && (
                    <PatientList
                        setFormOpen={setFormOpen}
                        setListOpen={setListOpen}
                        patients={patients}
                        corporates={corporates}
                        setGetId={setGetId}
                        indicators={indicators}
                        setIndicatorType={setIndicatorType}
                        // page
                        pageSkip={pageSkip}
                        setPageSkip={setPageSkip}
                        pageSkipPopup={pageSkipPopup}
                        setPageSkipPopup={setPageSkipPopup}
                        // search
                        searchId={searchId}
                        setSearchId={setSearchId}
                        searchName={searchName}
                        setSearchName={setSearchName}
                        searchPhone={searchPhone}
                        setSearchPhone={setSearchPhone}
                        searchGender={searchGender}
                        setSearchGender={setSearchGender}
                        loading={loading}
                    />
                )}
            </div>
        </div>
    )
}
