import { useContext, useState } from 'react'
import { Auth } from '../../../contexts/allContext'
import { api } from '../../../utils/apiToken'
import classes from './MedicineForm.module.css'

export default function MedicineForm({ setIsOpenForm, manufacturers, setSuccess }) {
    const [name, setName] = useState('')
    const [generic, setGeneric] = useState('')
    const [form, setForm] = useState('')
    const [strength, setStrength] = useState('')
    const [type, setType] = useState('')
    const [unitPrice, setUnitPrice] = useState('')
    const [manufacturer, setManufacturer] = useState()

    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    const handleSubmit = async (e) => {
        e.preventDefault()

        const details = {
            name: name,
            generic: generic,
            form: form,
            strength: strength,
            pharmaceuticals: manufacturer,
            unit_type: type,
            unit_price: unitPrice,
        }
        let submit = await fetch(`${api}/medicines/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(details),
        })
        // let info = await submit.json()

        if (submit.ok) {
            setIsOpenForm(false)
            setSuccess(true)

            setTimeout(() => {
                setSuccess(false)
            }, 1000)
        }
    }

    return (
        <div className={classes.wrapper}>
            <div className={classes.formWrapper}>
                <h2>Add New Medicine</h2>
                <div className={classes.close} onClick={() => setIsOpenForm(false)}>
                    &times;
                </div>

                <form onSubmit={handleSubmit} className={classes.item}>
                    <div className={classes.selectBox}>
                        <select
                            className={classes.option}
                            onChange={(e) => setManufacturer(e.target.value)}
                            id="vendors">
                            <option value="">Select Manufacturer</option>
                            {manufacturers.map((manufacturer, i) => (
                                <option key={i} value={manufacturer.pharmaceuticals}>
                                    {manufacturer.pharmaceuticals}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className={classes.formGrid}>
                        <div className={classes.inputbox}>
                            <input onChange={(e) => setName(e.target.value)} value={name} type="text" required />
                            <label htmlFor="name">Medicine Name</label>
                        </div>
                        <div className={classes.inputbox}>
                            <input onChange={(e) => setGeneric(e.target.value)} value={generic} type="text" required />
                            <label htmlFor="generic">Generic Name</label>
                        </div>

                        <div className={classes.inputbox}>
                            <input onChange={(e) => setForm(e.target.value)} value={form} type="text" required />
                            <label htmlFor="form">Form</label>
                        </div>
                        <div className={classes.inputbox}>
                            <input
                                onChange={(e) => setStrength(e.target.value)}
                                value={strength}
                                type="text"
                                required
                            />
                            <label htmlFor="strength">Strength</label>
                        </div>
                    </div>

                    <div className={classes.inputbox}>
                        <input onChange={(e) => setType(e.target.value)} value={type} type="text" required />
                        <label htmlFor="type">Type</label>
                    </div>

                    <div className={classes.inputbox}>
                        <input
                            onChange={(e) => setUnitPrice(parseFloat(e.target.value))}
                            value={unitPrice}
                            type="number"
                            step="any"
                            required
                        />
                        <label htmlFor="price">Unit Price</label>
                    </div>

                    <button type="submit" className={classes.button}>
                        Submit
                    </button>
                </form>
            </div>
        </div>
    )
}
