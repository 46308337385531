import { faBars, faHome, faServer } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import classes from './MobileNav.module.css'

export default function MobileNav() {
    const [state, setState] = useState(2)

    return (
        <div className={classes.mobileNav}>
            <div className={classes.navigation}>
                <ul>
                    <li
                        className={state === 1 ? `${classes.list} ${classes.active}` : classes.list}
                        onClick={() => setState(1)}>
                        <Link to="/test">
                            <span className={classes.icon}>
                                <FontAwesomeIcon icon={faServer} />
                            </span>
                            <span className={classes.text}>Service</span>
                        </Link>
                    </li>
                    <li
                        className={state === 2 ? `${classes.list} ${classes.active}` : classes.list}
                        onClick={() => setState(2)}>
                        <Link to="/test">
                            <span className={classes.icon}>
                                <FontAwesomeIcon icon={faHome} />
                            </span>
                            <span className={classes.text}>Home</span>
                        </Link>
                    </li>
                    <li
                        className={state === 3 ? `${classes.list} ${classes.active}` : classes.list}
                        onClick={() => setState(3)}>
                        <Link to="/test">
                            <span className={classes.icon}>
                                <FontAwesomeIcon icon={faBars} />
                            </span>
                            <span className={classes.text}>Menu</span>
                        </Link>
                    </li>
                    <div className={classes.indicator}></div>
                </ul>
            </div>
        </div>
    )
}
