import React, { useContext, useEffect, useState } from 'react'
import { Auth } from '../../contexts/allContext'
import Form from './Form/Form'
import List from './List/List'

export default function LabTest() {
    const [isOpen, setIsOpen] = useState(false)
    const [labTests, setLabTests] = useState([])
    const [labName, setLabName] = useState('')
    const [providerName, setProviderName] = useState('')
    const [pageSkip, setPageSkip] = useState(0)

    const api = process.env.REACT_APP_API_URL
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${api}/lab-test/all?name=${labName}&provider=${providerName}&skip=${pageSkip}&limit=30`,
                    {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                const data = await response.json()

                if (response.ok) {
                    setLabTests(data)
                }
            } catch {
                setLabTests([])
            }
        }
        fetchData()
    }, [token, api, pageSkip, labName, providerName])

    return (
        <div>
            {!isOpen ? (
                <List
                    labTests={labTests}
                    setPageSkip={setPageSkip}
                    pageSkip={pageSkip}
                    labName={labName}
                    setLabName={setLabName}
                    providerName={providerName}
                    setProviderName={setProviderName}
                    setIsOpen={setIsOpen}
                />
            ) : (
                <Form setIsOpen={setIsOpen} />
            )}
        </div>
    )
}
