import HealthPlanList from './HealthPlanList/HealthPlanList'

export default function HealthPlanShow({ healthPlans }) {
    let plan = healthPlans[0]
    return (
        <div>
            <HealthPlanList plan={plan} />
        </div>
    )
}
