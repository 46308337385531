// context with localstorage
export const GetStorage = (key) => {
    let data = JSON.parse(localStorage.getItem(key))
    return data || null
}

export const SetStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value))
}

export const DefaultStorage = (key, def) => {
    const storedData = GetStorage(key)
    if (storedData === null) {
        SetStorage(key, def)
        return def
    }
    return storedData
}

// context with cookies
export const GetCookie = (key) => {
    const name = key + '='
    const cookieArray = document.cookie.split(';')

    for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i].trim()
        if (cookie.indexOf(name) === 0) {
            return JSON.parse(cookie.substring(name.length, cookie.length))
        }
    }
    return null
}

export const SetCookie = (key, value, day = 2) => {
    const date = new Date()
    date.setTime(date.getTime() + day * 24 * 60 * 60 * 1000)

    const expires = 'expires=' + date.toUTCString()
    const serializedValue = JSON.stringify(value)
    document.cookie = key + '=' + serializedValue + '; ' + expires
}

export const DefaultCookie = (key, def) => {
    const existingCookie = GetCookie(key)

    if (existingCookie === null) {
        SetCookie(key, def)
        return def
    }
    return existingCookie
}
