import { useState } from 'react'
import ActiveDoctor from './ActiveDoctor/ActiveDoctor'
import PendingDoctor from './PendingDoctor/PendingDoctor'

export default function DoctorList({
    activeDoctors,
    chambers,
    pendingDoctors,
    setId,
    pageSkip,
    setPageSkip,
    setListOpen,
    setFormOpen,
    loading,
    success,
    setSuccess,
    setCountLimit,
    setPartner,
}) {
    const [openActive, setOpenActive] = useState(true)
    const [openPending, setOpenPending] = useState(false)

    return (
        <div>
            {openActive && (
                <ActiveDoctor
                    activeDoctors={activeDoctors}
                    setOpenActive={setOpenActive}
                    setOpenPending={setOpenPending}
                    chambers={chambers}
                    setId={setId}
                    pageSkip={pageSkip}
                    setPageSkip={setPageSkip}
                    setListOpen={setListOpen}
                    setFormOpen={setFormOpen}
                    loading={loading}
                    success={success}
                    setSuccess={setSuccess}
                    setCountLimit={setCountLimit}
                    setPartner={setPartner}
                />
            )}
            {openPending && (
                <PendingDoctor
                    pendingDoctors={pendingDoctors}
                    setOpenActive={setOpenActive}
                    setOpenPending={setOpenPending}
                    pageSkip={pageSkip}
                    setPageSkip={setPageSkip}
                    setListOpen={setListOpen}
                    setFormOpen={setFormOpen}
                    loading={loading}
                />
            )}
        </div>
    )
}
