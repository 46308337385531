import { faEdit, faEye, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { ListCount } from '../../Resource'
import { Pagination } from '../../index'
import { Loading } from '../../index'
import ChangeRole from './ChangeRole/ChangeRole'
import ListDetail from './ListDetail/ListDetail'
import classes from './ModeratorList.module.css'
import SwitchStatus from './SwitchStatus/SwitchStatus'

export default function ModeratorList({
    setListOpen,
    setFormOpen,
    moderators,
    activities,
    results,
    setEmployeeId,
    users,
    pageSkip,
    setPageSkip,
    pageSkipPopup,
    setPageSkipPopup,
    roles,
    token,
    api,
    setStatusApi,
    loading,
    patientToday,
    patientCurrent,
    patientPrevious,
    telemedicineToday,
    telemedicineCurrent,
    telemedicinePrevious,
    medicineToday,
    medicineCurrent,
    medicinePrevious,
    planToday,
    planCurrent,
    planPrevious,
}) {
    const [search, setSearch] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const [popup, setPopup] = useState(false)
    const [status, setStatus] = useState(false)
    let serial = 0

    const [select, setSelect] = useState('active')

    useEffect(() => {
        if (select === 'deactive') {
            setStatusApi('deactive')
        } else {
            setStatusApi('all')
        }
    }, [select, setStatusApi])

    const openClose = () => {
        setListOpen(false)
        setFormOpen(true)
    }

    const nullFunction = () => {}

    return (
        <div className={classes.tableContainer}>
            <div className={classes.wrapper}>
                <div>
                    <select className={classes.select} onChange={(e) => setSelect(e.target.value)}>
                        <option value="active">Active Employee</option>
                        <option value="deactive">Deactivated Employee</option>
                    </select>

                    <input
                        className={classes.searchField}
                        type="text"
                        value={search}
                        placeholder="Search Employee"
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <button className={classes.searchButton}>
                        <FontAwesomeIcon icon={faSearch} />
                    </button>
                </div>
                <div>
                    <button className={classes.button} onClick={() => openClose()}>
                        + Add Employee
                    </button>
                </div>
            </div>

            <ListCount
                title={select === 'deactive' ? 'Deactive Employee List' : 'Active Employee List'}
                results={moderators}
            />

            <table className={classes.tableMain}>
                <thead>
                    <tr className={select !== 'active' ? classes.tableRowOff : classes.tableRow}>
                        <th>Sl</th>
                        <th>ID</th>
                        <th>Employee Name</th>
                        <th>Mobile</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {moderators[1] &&
                        moderators[1]
                            .filter(
                                (item) =>
                                    item.name.toLowerCase().includes(search) ||
                                    item.phone.toLowerCase().includes(search) ||
                                    item.email.toLowerCase().includes(search) ||
                                    item.role_name.toLowerCase().includes(search)
                            )
                            .map((item, index) => (
                                <tr className={classes.tableRow} key={index}>
                                    <td>{(serial = serial + 1) + pageSkip}</td>
                                    <td>
                                        HX-E
                                        {item.id + 1000}
                                    </td>
                                    <td className={classes.cap}>{item.name}</td>
                                    <td>{item.phone}</td>
                                    <td>{item.email}</td>
                                    <td className={classes.cap}>
                                        <button
                                            onClick={
                                                item.role_name !== 'admin' ? () => setPopup(index) : nullFunction()
                                            }>
                                            {item.role_name}
                                        </button>
                                    </td>
                                    <td>
                                        <button className={classes.icon}>
                                            <FontAwesomeIcon
                                                icon={faEye}
                                                onClick={() => {
                                                    setEmployeeId(item.id)
                                                    setIsOpen(index)
                                                }}
                                            />
                                        </button>
                                        {select === 'active' ? (
                                            <button className={classes.icon}>
                                                <FontAwesomeIcon
                                                    icon={faTrash}
                                                    onClick={() => {
                                                        setEmployeeId(item.id)
                                                        setStatus(index)
                                                    }}
                                                />
                                            </button>
                                        ) : (
                                            <button className={classes.iconOff}>
                                                <FontAwesomeIcon
                                                    icon={faEdit}
                                                    onClick={() => {
                                                        setEmployeeId(item.id)
                                                        setStatus(index)
                                                    }}
                                                />
                                            </button>
                                        )}
                                    </td>
                                    {popup === index && (
                                        <ChangeRole
                                            setPopup={setPopup}
                                            index={index}
                                            roles={roles}
                                            item={item}
                                            token={token}
                                            api={api}
                                        />
                                    )}
                                    {isOpen === index && (
                                        <ListDetail
                                            index={index}
                                            setIsOpen={setIsOpen}
                                            users={users}
                                            activities={activities}
                                            results={results}
                                            pageSkipPopup={pageSkipPopup}
                                            setPageSkipPopup={setPageSkipPopup}
                                            patientToday={patientToday}
                                            patientCurrent={patientCurrent}
                                            patientPrevious={patientPrevious}
                                            telemedicineToday={telemedicineToday}
                                            telemedicineCurrent={telemedicineCurrent}
                                            telemedicinePrevious={telemedicinePrevious}
                                            medicineToday={medicineToday}
                                            medicineCurrent={medicineCurrent}
                                            medicinePrevious={medicinePrevious}
                                            planToday={planToday}
                                            planCurrent={planCurrent}
                                            planPrevious={planPrevious}
                                        />
                                    )}
                                    {status === index && (
                                        <SwitchStatus index={index} setStatus={setStatus} item={item} />
                                    )}
                                </tr>
                            ))}
                </tbody>
            </table>

            <Pagination
                pageSkip={pageSkip}
                setPageSkip={setPageSkip}
                listItem={moderators[1]}
                pageAll={moderators[0]?.results}
            />

            {loading && <Loading />}
        </div>
    )
}
