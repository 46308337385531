import { useState } from 'react'
import Category from './Category/Category'
import Device from './Device/Device'

export default function Product() {
    const [deviceOpen, setDeviceOpen] = useState(true)
    const [categoryOpen, setCategoryOpen] = useState(false)

    return (
        <div>
            {categoryOpen && <Category setDeviceOpen={setDeviceOpen} setCategoryOpen={setCategoryOpen} />}
            {deviceOpen && <Device setDeviceOpen={setDeviceOpen} setCategoryOpen={setCategoryOpen} />}
        </div>
    )
}
