import { useState } from 'react'
import classes from './Pagination.module.css'

export default function Pagination({ pageSkip, setPageSkip, listItem, pageAll, item, limit }) {
    const [pageCount, setPageCount] = useState(1)
    const [buttonPrev, setButtonPrev] = useState(false)
    const [buttonNext, setButtonNext] = useState(true)

    const pageLimit = limit === '30' ? 29 : 9
    const pageItem = item === '30' ? 30 : 10

    const pageIncrease = () => {
        if (listItem.length > pageLimit) {
            setPageSkip((prev) => prev + pageItem)
            setPageCount((prev) => prev + 1)
            setButtonNext(true)
            setButtonPrev(true)
        } else {
            setButtonNext(false)
        }
    }

    const pageDecrease = () => {
        if (pageSkip > 0) {
            setPageSkip((prev) => prev - pageItem)
            setPageCount((prev) => prev - 1)
            setButtonNext(true)
            setButtonPrev(true)
        } else {
            setButtonPrev(false)
        }
    }
    return (
        <div className={classes.page}>
            <div className={classes.pageShow}>
                Page: {pageCount} of {Math.ceil(pageAll / pageItem) !== 0 ? Math.ceil(pageAll / pageItem) || '1' : 1}
            </div>
            <div className={classes.pageWrapper}>
                <ul className={classes.pageContainer}>
                    <div className={buttonPrev ? classes.btn : classes.btnOff} onClick={pageDecrease}>
                        &laquo; Previous
                    </div>
                    <div className={buttonNext ? classes.btn : classes.btnOff} onClick={pageIncrease}>
                        Next &raquo;
                    </div>
                </ul>
            </div>
        </div>
    )
}
