import '@fortawesome/free-solid-svg-icons'
import { faList } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ProfileMale from '../../../assets/profile1.png'
import ProfileFemale from '../../../assets/profile2.png'
import { Pagination } from '../../index'
import classes from './ProfileCard.module.css'

export default function ProfileCard({ activities, results, userInfo, pageSkip, setPageSkip }) {
    let serial = 0
    return (
        <div className={classes.cardContainer}>
            <div className={classes.card}>
                <img src={userInfo.sex === 'female' ? ProfileFemale : ProfileMale} alt="" />
                <p className={classes.title}>{userInfo.name}</p>
                <span className={classes.subTitle}>
                    {userInfo.role_name !== 'admin' ? `${userInfo.role_name} Team` : userInfo.role_name}
                </span>

                <div className={classes.flexRow}>
                    <div className={classes.flexColumn}>
                        <p>Patient Register</p>
                        <span className={classes.numbers}>{results[0]?.results}</span>
                    </div>
                    <div className={classes.flexColumn}>
                        <p>Total Activity</p>
                        <span className={classes.numbers}>{activities[0]?.results}</span>
                    </div>
                </div>

                <div className={classes.social}>
                    <p>{userInfo.email}</p>
                    <p>{userInfo.phone}</p>
                </div>

                <div className={classes.profile}>
                    <button className={classes.profileButton}>Message</button>
                </div>
            </div>
            <div className={classes.tableContainer}>
                <div className={classes.textWrapper}>
                    <FontAwesomeIcon icon={faList} />
                    <p>Activity List</p>
                </div>
                <table className={classes.tableMain}>
                    <thead>
                        <tr className={classes.tableRow}>
                            <th>Serial</th>
                            <th>Activity Info</th>
                            <th>Time</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {activities[1] &&
                            activities[1].map((activity, index) => (
                                <tr className={classes.tableRow} key={index}>
                                    <td>{(serial = serial + 1) + pageSkip} </td>
                                    <td className={classes.cap}>{activity.service_name.replace(/_/g, ' ')}</td>
                                    <td>{activity.created_at.slice(11, 16)}</td>
                                    <td>{activity.created_at.slice(0, 10)}</td>
                                </tr>
                            ))}
                    </tbody>
                </table>
                <Pagination
                    pageSkip={pageSkip}
                    setPageSkip={setPageSkip}
                    listItem={activities[1]}
                    pageAll={activities[0]?.results}
                />
            </div>
        </div>
    )
}
