import classes from './TelemedicineList.module.css'

export default function TelemedicineList({ telemedicine, serviceInfo }) {
    return (
        <div className={classes.telemedicineWrapper}>
            <div className={classes.formGrid}>
                <label>
                    Book Date
                    <span className={classes.fetchLabel}>
                        {telemedicine?.booked_date} <p>.</p>
                    </span>
                </label>
                {/* <label>
                    Schedule
                    <span className={classes.fetchLabel}>
                        <p>.</p>
                    </span>
                </label> */}

                <a href={`https://healthxbd.com/${serviceInfo.service_provider_id}`} target="__black">
                    View Doctor Profile
                </a>
            </div>
        </div>
    )
}
