import { Layout, PharmaOffers } from '../components'

export default function PharmaOffersPage() {
    return (
        <div>
            <Layout>
                <PharmaOffers />
            </Layout>
        </div>
    )
}
