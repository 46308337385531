import { faEdit, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import Pagination from '../../../Pagination/Pagination'
import { ListCount, Table } from '../../../Resource'
import { Loading } from '../../../index'
import classes from './Active.module.css'
import Profile from './Profile/Profile'
import Update from './Profile/Update/Update'

export default function Active({
    pharmacies,
    pageSkip,
    setPageSkip,
    loading,
    setOpenActive,
    setOpenPending,
    loader,
    setLoader,
}) {
    let serial = 0
    const [isProfile, setProfile] = useState(false)
    const [edit, setEdit] = useState(false)

    const openClose = () => {
        setOpenActive(false)
        setOpenPending(true)
    }

    return (
        <div className={classes.list}>
            <div className={classes.wrapper}>
                <div>
                    <input
                        className={classes.searchField}
                        type="text"
                        // value={search}
                        placeholder="Search Pharmacy"
                        // onChange={(e) => setSearch(e.target.value)}
                    />
                    <button className={classes.searchButton}>
                        <FontAwesomeIcon icon={faSearch} />
                    </button>
                </div>

                <div className={classes.allButton}>
                    <button className={classes.button} onClick={() => openClose()}>
                        View Pending Pharmacies
                    </button>
                    {/* <span></span> */}
                    {/* <button className={`${classes.button} ${classes.buttonMargin}`} onClick={() => openCloseListForm()}>
                        + Add Doctors
                    </button> */}
                </div>
            </div>

            <ListCount title="Active Pharmacy List" results={pharmacies} />
            <Table>
                <thead>
                    <tr>
                        <th>Sl</th>
                        <th>Pharmacy ID</th>
                        <th>Pharmacy Name</th>
                        <th>Pharmacy Phone</th>
                        <th>District</th>
                        <th>Sub District</th>
                        <th>Address</th>
                        <th>Trade License</th>
                        <th>Druc License</th>
                        <th>Reg. Date</th>
                        <th>Owner</th>
                        <th>Phone</th>
                        <th>Status</th>
                        <th>Other Details</th>
                        {/* <th>Action</th> */}
                        {/* <th>Edit</th> */}
                    </tr>
                </thead>
                <tbody>
                    {pharmacies[1] &&
                        pharmacies[1].map((list, index) => (
                            <>
                                <tr className={classes.row} key={index}>
                                    <td>{(serial = serial + 1) + pageSkip}</td>
                                    <td>hxpharmacy{list.Pharmacy?.id}</td>
                                    <td>
                                        <button className={classes.btn} onClick={() => setProfile(index)}>
                                            {list.Pharmacy?.name}
                                        </button>
                                    </td>
                                    <td>{list.Pharmacy?.contact_phone || '-'}</td>
                                    <td>{list.Pharmacy?.district || '-'}</td>
                                    <td>{list.Pharmacy?.sub_district || '-'}</td>
                                    <td>{list.Pharmacy?.detail_address || '-'}</td>
                                    <td>{list.Pharmacy?.trade_license || '-'}</td>
                                    <td>{list.Pharmacy?.drug_license || '-'}</td>
                                    <td>{list.Pharmacy?.created_at && list.Pharmacy?.created_at.slice(0, 10)}</td>
                                    <td>{list.User?.name || '-'}</td>
                                    <td>{list.User?.phone}</td>
                                    <td className={classes.status}>
                                        <span className={classes.active}>Active</span>
                                    </td>
                                    <td>
                                        <div className={classes.icons}>
                                            <FontAwesomeIcon
                                                icon={faEdit}
                                                title="edit"
                                                onClick={() => setEdit(index)}
                                            />
                                        </div>
                                    </td>
                                    {/* <td>
                                    <button className={classes.icon}>
                                        <FontAwesomeIcon title="Edit" icon={faEdit} onClick={() => {}} />
                                    </button>
                                </td> */}
                                    {edit === index && (
                                        <Update setIsOpen={setEdit} list={list} loader={loader} setLoader={setLoader} />
                                    )}
                                </tr>
                                {isProfile === index && (
                                    <Profile
                                        index={index}
                                        setIsOpen={setProfile}
                                        pharmacyUserId={list.User?.id}
                                        pharmacyId={list.Pharmacy?.id}
                                    />
                                )}
                            </>
                        ))}
                </tbody>
            </Table>
            <Pagination
                pageSkip={pageSkip}
                setPageSkip={setPageSkip}
                listItem={pharmacies[1]}
                pageAll={pharmacies[0]?.results}
            />
            {loading && <Loading />}
        </div>
    )
}
