import { faBars, faBell, faCapsules, faCog, faSignOut, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import M from '../../../assets/profile1.png'
import F from '../../../assets/profile2.png'
import { Auth, Count, UserInfo, Notification } from '../../../contexts/allContext'
import { SidebarContext } from '../../../contexts/sidebarContext'
import { api } from '../../../utils/apiToken'
import Inbox from './Inbox/Inbox'
import classes from './Navbar.module.css'

export default function Navbar() {
    const { isOpenSidebar, setIsOpenSidebar } = useContext(SidebarContext)
    const { stateAuth, dispatchAuth } = useContext(Auth)
    const { stateUser, dispatchUser } = useContext(UserInfo)
    const { stateCount, dispatchCount } = useContext(Count)
    const { notification, setNotification } = useContext(Notification)

    const [wsNotifications, setWsNotificatons] = useState([])
    const [popup, setPopup] = useState(false)

    const token = stateAuth.token
    const user = stateUser.info

    const logout = (e) => {
        e.preventDefault()
        dispatchUser({ type: 'remove' })
        dispatchAuth({ type: 'remove' })
        document.title = `HEALTHx Admin`
    }

    useEffect(() => {
        let dataFetch = async () => {
            try {
                const response = await fetch(`${api}/notifications/?skip=0&limit=20`, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    method: 'GET',
                })
                let data = await response.json()

                if (response.ok) {
                    setNotification(false)
                    setWsNotificatons(data)
                    dispatchCount({ type: 'bell', payload: stateCount.bell + data[0]?.results - stateCount.total })
                    dispatchCount({ type: 'total', payload: data[0]?.results })
                }
            } catch (e) {
                setWsNotificatons([])
            }
        }
        dataFetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, notification])

    useEffect(() => {
        document.title = `${stateCount.bell ? `(${stateCount.bell})` : ''} HEALTHx Admin`
    }, [stateCount.bell])

    return (
        <div className={classes.nav}>
            <div className={classes.topNavbar}>
                <div className={classes.hamburger} onClick={() => setIsOpenSidebar(!isOpenSidebar)}>
                    <FontAwesomeIcon icon={faBars} title="Toggle" />
                </div>
                <div className={classes.navList}>
                    <div
                        className={classes.notification}
                        onClick={() => {
                            setPopup(!popup)
                            dispatchCount({ type: 'remove' })
                        }}>
                        <span className={stateCount.bell ? classes.count : ''}>
                            {stateCount.bell ? stateCount.bell : ''}
                        </span>
                        <FontAwesomeIcon icon={faBell} className={popup ? classes.checked : ''} title="Notifications" />
                    </div>
                    <Link to="/medicines" onClick={() => setPopup(false)}>
                        <FontAwesomeIcon icon={faCapsules} title="Medicine" />
                    </Link>

                    <div className={classes.profile}>
                        <img src={user.sex === 'female' ? F : M} alt="" onClick={() => setPopup(false)} />
                        <div className={classes.user}>
                            <Link to="/profile">
                                <span>{user.name}</span>
                            </Link>
                            <Link to="/settings">
                                <p>
                                    <FontAwesomeIcon icon={faCog} /> Settings
                                </p>
                            </Link>
                            <Link to="/profile">
                                <p className={classes.pro}>
                                    <FontAwesomeIcon icon={faUserCircle} title="Profiles" /> Profile
                                </p>
                            </Link>
                            <p onClick={(e) => logout(e)}>
                                <FontAwesomeIcon icon={faSignOut} />
                                Logout
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {popup && <Inbox close={setPopup} notifications={wsNotifications[1]} />}
        </div>
    )
}
