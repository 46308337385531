import { useReducer } from 'react'
import { Routes, Route } from 'react-router-dom'
import MobileNav from './components/Layout/MobileNav/MobileNav'
import { Auth, UserInfo, Count, ServiceId } from './contexts/allContext'
import { NotificationProvider } from './contexts/provider/notification'
import { authReducer, authState } from './contexts/reducer/authReducer'
import { countReducer, countState } from './contexts/reducer/countReducer'
import { serviceidReducer, serviceidState } from './contexts/reducer/serviceidReducer'
import { userReducer, userState } from './contexts/reducer/userReducer'
import { SidebarProvider } from './contexts/sidebarContext'
import {
    Doctor,
    Home,
    Login,
    Moderator,
    Patient,
    Profile,
    Service,
    Setting,
    Activity,
    Notice,
    Corporate,
    HealthPlan,
    Error,
    Medicine,
    Product,
    Prescription,
    Clinic,
    Manual,
    LinkGenerator,
    Partner,
    PharmaOffers,
    LabProvider,
    LabTest,
} from './pages/'
import ProtectedRoute from './routes/ProtectedRoute'

export default function App() {
    const [stateAuth, dispatchAuth] = useReducer(authReducer, authState)
    const [stateUser, dispatchUser] = useReducer(userReducer, userState)
    const [stateCount, dispatchCount] = useReducer(countReducer, countState)
    const [stateServiceid, dispatchServiceid] = useReducer(serviceidReducer, serviceidState)

    return (
        <NotificationProvider>
            <SidebarProvider>
                <Auth.Provider value={{ stateAuth, dispatchAuth }}>
                    <UserInfo.Provider value={{ stateUser, dispatchUser }}>
                        <Count.Provider value={{ stateCount, dispatchCount }}>
                            <ServiceId.Provider value={{ stateServiceid, dispatchServiceid }}>
                                <Routes>
                                    <Route path="/login" element={<Login />} />
                                    <Route path="/test" element={<MobileNav />} />
                                    <Route path="*" element={<Error />} />

                                    <Route path="/*" element={<ProtectedRoute preventUser={['doctor', 'patient']} />}>
                                        <Route path="" element={<Home />} />
                                        <Route path="doctors" element={<Doctor />} />
                                        <Route path="patients" element={<Patient />} />
                                        <Route path="clinics" element={<Clinic />} />
                                        <Route path="corporates" element={<Corporate />} />
                                        <Route path="employees" element={<Moderator />} />
                                        <Route path="activities" element={<Activity />} />
                                        <Route path="services" element={<Service />} />
                                        <Route path="settings" element={<Setting />} />
                                        <Route path="notices" element={<Notice />} />
                                        <Route path="profile" element={<Profile />} />
                                        <Route path="service-list" element={<HealthPlan />} />
                                        <Route path="medicines" element={<Medicine />} />
                                        <Route path="device-list" element={<Product />} />
                                        <Route path="prescriptions" element={<Prescription />} />
                                        <Route path="manuals" element={<Manual />} />
                                        <Route path="partner-pharmacies" element={<Partner />} />
                                        <Route path="partner-pharma-offers" element={<PharmaOffers />} />
                                        <Route path="meeting/:code/:doc/:pat" element={<LinkGenerator />} />
                                        <Route path="lab-provider" element={<LabProvider />} />
                                        <Route path="lab-test" element={<LabTest />} />
                                    </Route>
                                </Routes>
                            </ServiceId.Provider>
                        </Count.Provider>
                    </UserInfo.Provider>
                </Auth.Provider>
            </SidebarProvider>
        </NotificationProvider>
    )
}
