import { useContext, useEffect, useState } from 'react'
import ProfileMale from '../../assets//profile1.png'
import ProfileFemale from '../../assets/profile2.png'
import { Auth } from '../../contexts/allContext'
import { api } from '../../utils/apiToken'
import Eprescriptions from './Eprescriptions/Eprescriptions'
import { Indicators } from './Indicators'
import classes from './PatientProfile.module.css'
import Reports from './Reports/Reports'
import Services from './Services/Services'

export default function PatientProfile({ index, patient, setIsOpen }) {
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    const [bp, setBp] = useState()
    const [rbs, setRbs] = useState()
    const [weight, setWeight] = useState()
    const [pulse, setPulse] = useState()

    // patient lastest indicator list
    useEffect(() => {
        const fetchBp = async () => {
            try {
                const response = await fetch(`${api}/admin/patient/indicator/bp/${patient.id}`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                setBp(data)
            } catch {
                setBp([])
            }
        }

        const fetchRbs = async () => {
            try {
                const response = await fetch(`${api}/admin/patient/indicator/rbs/${patient.id}`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                setRbs(data)
            } catch {
                setRbs([])
            }
        }

        const fetchPulse = async () => {
            try {
                const response = await fetch(`${api}/admin/patient/indicator/pulse/${patient.id}`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                setPulse(data)
            } catch {
                setPulse([])
            }
        }

        const fetchWeight = async () => {
            try {
                const response = await fetch(`${api}/admin/patient/indicator/weight/${patient.id}`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                setWeight(data)
            } catch {
                setWeight([])
            }
        }

        fetchBp()
        fetchRbs()
        fetchPulse()
        fetchWeight()
    }, [token, patient.id])

    return (
        <div key={() => index} className={classes.containerForm}>
            <div className={classes.overlay} onClick={() => setIsOpen(false)}></div>
            <div className={classes.formWrapper}>
                <div className={classes.close} onClick={() => setIsOpen(false)}>
                    &times;
                </div>

                <div className={classes.divScroll}>
                    <div className={classes.cardContainer}>
                        <div className={classes.cardWrapper}>
                            <div className={classes.card}>
                                <img src={patient.sex === 'female' ? ProfileFemale : ProfileMale} alt="" />
                                <p className={classes.title}>{patient.name}</p>
                                <p className={classes.subTitle}>{patient.phone}</p>
                                <div className={classes.flexRow}>
                                    <div className={classes.flexColumn}>
                                        <p className={classes.pFlex}>BP</p>
                                        <span className={classes.numbers}>
                                            {(bp && bp[0]?.slot_int1) || '-'}/{(bp && bp[0]?.slot_int2) || '-'}
                                        </span>
                                    </div>
                                    <div className={classes.flexColumn}>
                                        <p>DIABETES</p>
                                        <span className={classes.numbers}>{(rbs && rbs[0]?.slot_flt4) || '-'}</span>
                                    </div>
                                    <div className={classes.flexColumn}>
                                        <p>WEIGHT</p>
                                        <span className={classes.numbers}>
                                            {(weight && weight[0]?.slot_flt4) || '-'}
                                        </span>
                                    </div>
                                    <div className={classes.flexColumn}>
                                        <p className={classes.pFlex}>PULSE</p>
                                        <span className={classes.numbers}>{(pulse && pulse[0]?.slot_int1) || '-'}</span>
                                    </div>
                                </div>

                                <div className={classes.social}>
                                    <p>-</p>
                                    <p>--</p>
                                </div>

                                <div className={classes.profile}>
                                    <button className={classes.profileButton}>Message</button>
                                </div>
                            </div>
                        </div>

                        <div className={classes.innerWrap}>
                            <div className={classes.container}>
                                <ul>
                                    <li>
                                        <input type="checkbox" id="listItem1" />
                                        <label htmlFor="listItem1" className={classes.labelMain}>
                                            Indicators
                                        </label>
                                        <ul>
                                            <div className={classes.innerContainer}>
                                                <div className={classes.collab}>
                                                    <Indicators id={patient.id} />
                                                </div>
                                            </div>
                                        </ul>
                                    </li>

                                    <li>
                                        <input type="checkbox" id="listItem2" />
                                        <label htmlFor="listItem2" className={classes.labelMain}>
                                            Service
                                        </label>
                                        <ul>
                                            <div className={classes.innerContainer}>
                                                <div className={classes.collab}>
                                                    <Services id={patient.id} />
                                                </div>
                                            </div>
                                        </ul>
                                    </li>

                                    <li>
                                        <input type="checkbox" id="listItem3" />
                                        <label htmlFor="listItem3" className={classes.labelMain}>
                                            Reports
                                        </label>
                                        <ul>
                                            <div className={classes.innerContainer}>
                                                <div className={classes.collab}>
                                                    <Reports id={patient.id} />
                                                </div>
                                            </div>
                                        </ul>
                                    </li>

                                    <li>
                                        <input type="checkbox" id="listItem4" />
                                        <label htmlFor="listItem4" className={classes.labelMain}>
                                            Eprescriptions
                                        </label>
                                        <ul>
                                            <div className={classes.innerContainer}>
                                                <div className={classes.collab}>
                                                    <Eprescriptions id={patient.id} />
                                                </div>
                                            </div>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classes.buttonContainer}>
                    <button className={classes.button} onClick={() => setIsOpen(false)}>
                        Close
                    </button>
                </div>
            </div>
        </div>
    )
}
