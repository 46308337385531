import { useContext, useState } from 'react'
import { Auth } from '../../../../../../../contexts/allContext'
import classes from './Update.module.css'

export default function Update({ item, setHide }) {
    const [followUp, setFollowUp] = useState(item)

    const api = process.env.REACT_APP_API_URL
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    const updateFollowUp = async (e) => {
        e.preventDefault()

        const details = {
            ...followUp,
        }

        let patchFetch = await fetch(`${api}/follow-up/${item?.id}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(details),
        })

        if (patchFetch.ok) {
            alert('Successful')
        }
    }

    return (
        <div className={classes.collab}>
            <div className={classes.close} onClick={() => setHide(false)}>
                &times;
            </div>

            <p className={classes.title}>Update Follow-Up</p>
            <div className={classes.formGrid}>
                <label>
                    Title
                    <select
                        value={followUp?.title}
                        onChange={(e) =>
                            setFollowUp({
                                ...followUp,
                                title: e.target.value,
                            })
                        }
                        required
                        className={classes.select}>
                        <option value="All OK now, will call if any service needed">
                            All OK now, will call if any service needed
                        </option>
                        <option value="Busy, can't talk ">Busy, can't talk</option>
                        <option value="Didn't recognize ">Didn't recognize</option>
                        <option value="Foul / Pervert">Foul / Pervert</option>
                        <option value="Not interested ">Not interested </option>
                        <option value="Not received">Not received</option>
                        <option value="Service needed, number sent">Service needed, number sent</option>
                        <option value="Service needed, will inform later">Service needed, will inform later</option>
                        <option value="Unreachable / Switched off">Unreachable / Switched off</option>
                    </select>
                </label>
                <div className={classes.formGrid}>
                    <label>
                        Follow-Up Date
                        <input
                            type="date"
                            value={followUp?.followup_date}
                            onChange={(e) =>
                                setFollowUp({
                                    ...followUp,
                                    followup_date: e.target.value,
                                })
                            }
                        />
                    </label>
                    <label>
                        Status
                        <select
                            value={followUp?.status}
                            onChange={(e) =>
                                setFollowUp({
                                    ...followUp,
                                    status: e.target.value,
                                })
                            }
                            required
                            className={classes.select}>
                            <option value="">Select</option>
                            <option value="pending">Pending</option>
                            <option value="done">Done</option>
                        </select>
                    </label>
                </div>
            </div>
            <label>
                Remarks
                <textarea
                    value={followUp?.remarks}
                    onChange={(e) =>
                        setFollowUp({
                            ...followUp,
                            remarks: e.target.value,
                        })
                    }
                />
            </label>
            <button className={classes.submitBtn} onClick={(e) => updateFollowUp(e)}>
                Update
            </button>
        </div>
    )
}
