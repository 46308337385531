import { useContext, useState } from 'react'
import { Auth } from '../../../../../../contexts/allContext'
import classes from './MedicineList.module.css'

export default function MedicineList({ index, setSuccess, medicine, forceUpdate }) {
    let mrp = medicine?.total_mrp
    let [discount, setDiscount] = useState(medicine?.unit_discount_percent)
    let [total, setTotal] = useState(medicine?.total)

    const api = process.env.REACT_APP_API_URL
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    const handleMedicine = () => {
        total = mrp - mrp * (discount / 100)
        setTotal(Math.ceil(total))
    }

    const medicineSubmit = async (e) => {
        e.preventDefault()

        const details = {
            ...medicine,
            unit_discount_percent: discount,
            total: total,
        }

        let patchFetch = await fetch(`${api}/service/medicine/update/${medicine?.id}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(details),
        })

        if (patchFetch.ok) {
            alert('Medicine Update Successful')
            setSuccess(true)
            setTimeout(() => {
                setSuccess(false)
            }, 3000)
        }
    }

    return (
        <div className={classes.medWrapper} key={index}>
            <div className={`${classes.formGrid} ${classes.formMargin}`}>
                <label>
                    <span>Medicine</span>
                    <span className={classes.fetchLabel}>
                        {medicine.name} <p>.</p>
                    </span>
                </label>

                <label>
                    <span>Strength</span>
                    <span className={classes.fetchLabel}>
                        {medicine.strength} <p>.</p>
                    </span>
                </label>
                <label>
                    <span>Form</span>
                    <span className={classes.fetchLabel}>
                        {medicine.form} <p>.</p>
                    </span>
                </label>
                <label>
                    <span>Unit Price</span>
                    <span className={classes.fetchLabel}>
                        {medicine.unit_price_mrp} <p>.</p>
                    </span>
                </label>
                <label>
                    <span>Quantity</span>
                    <span className={classes.fetchLabel}>
                        {medicine.quantity} <p>.</p>
                    </span>
                </label>
                <label>
                    <span>MRP</span>
                    <span className={classes.fetchLabel}>
                        {medicine.total_mrp} <p>.</p>
                    </span>
                </label>
                <label>
                    <span>Discount %</span>
                    <input
                        type="number"
                        value={discount}
                        onChange={(e) => setDiscount(parseInt(e.target.value))}
                        onBlur={(e) => handleMedicine(e)}
                        min={0}
                        required
                    />
                </label>

                <label>
                    <span>Total</span>
                    <span className={classes.fetchLabel}>
                        {total} <p>.</p>
                    </span>
                </label>
                <label>
                    <span>Update</span>
                    <button
                        onClick={(e) => {
                            medicineSubmit(e)
                            forceUpdate()
                        }}>
                        ok
                    </button>
                </label>
            </div>
        </div>
    )
}
