import { useContext, useEffect, useState } from 'react'
import { Auth, Notification, ServiceId, UserInfo } from '../../contexts/allContext'
import { api } from '../../utils/apiToken'
import { currentDate, dateConvert } from '../../utils/date'
import classes from './Service.module.css'
import ServiceOrder from './ServiceOrderForm/ServiceOrder'
import ServiceOrderList from './ServiceOrderList/ServiceOrderList'

export default function Service() {
    const { stateServiceid } = useContext(ServiceId)

    const [patients, setPatients] = useState([])
    const [services, setServices] = useState([])
    const [telemedicines, setTelemedicines] = useState([])
    const [medicines, setMedicines] = useState([])
    const [healthPlans, setHealthPlans] = useState([])
    const [plans, setPlans] = useState([])
    const [serviceId, setServiceId] = useState(0)
    const [search, setSearch] = useState('')
    const [searchResults, setSearchResults] = useState('')

    const [listOpen, setListOpen] = useState(true)
    const [formOpen, setFormOpen] = useState(false)
    const [pageSkip, setPageSkip] = useState(0)

    const [patientBp, setPatientBp] = useState()
    const [patientRbs, setPatientRbs] = useState()
    const [patientWeight, setPatientWeight] = useState()
    const [patientPulse, setPatientPulse] = useState()

    const [patientService, setPatientService] = useState([])
    const [getPatientId, setGetPatientId] = useState(0)
    const [pageSkipService, setPageSkipService] = useState(0)

    // search state
    const [searchServiceId, setSearchServiceId] = useState('')
    const [searchPatientId, setSearchPatientId] = useState('')
    const [searchPatientName, setSearchPatientName] = useState('')
    const [searchPhone, setSearchPhone] = useState('')
    const [searchAddress, setSearchAddress] = useState('')
    const [searchStartDate, setSearchStartDate] = useState('')
    const [searchServiceName, setSearchServiceName] = useState('')
    const [searchOrderStatus, setSearchOrderStatus] = useState('')

    const [loading, setLoading] = useState(true)
    const [success, setSuccess] = useState(false)

    const { notification } = useContext(Notification)
    const { stateAuth } = useContext(Auth)
    const { stateUser } = useContext(UserInfo)
    const userInfo = stateUser.info
    const token = stateAuth.token
    let totalAll = 0

    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    // useEffect(() => {
    //     if (stateServiceid.id !== '') {
    //         setSearchServiceId(stateServiceid.id)
    //     }
    // }, [stateServiceid.id, searchServiceId])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    searchServiceId
                        ? `${api}/service/filter?service_id=${parseInt(searchServiceId) - 10000000}&skip=0&limit=10`
                        : searchPatientId
                        ? `${api}/service/filter?customer_id=${
                              parseInt(searchPatientId) - 1000000
                          }&service_name=${searchServiceName}&order_status=${searchOrderStatus}&skip=${pageSkip}&limit=30`
                        : startDate && endDate
                        ? `${api}/service/filter?customer_name=${searchPatientName}&customer_phone=${searchPhone}&address=${searchAddress}&order_date=${searchStartDate}&service_name=${searchServiceName}&order_status=${searchOrderStatus}&start_date=${dateConvert(
                              startDate
                          )}T00%3A00%3A01&end_date=${dateConvert(endDate)}T23%3A59%3A59&skip=${pageSkip}&limit=30`
                        : `${api}/service/filter?customer_name=${searchPatientName}&customer_phone=${searchPhone}&address=${searchAddress}&order_date=${searchStartDate}&service_name=${searchServiceName}&order_status=${searchOrderStatus}&end_date=${currentDate}T23%3A59%3A59&skip=${pageSkip}&limit=30`,
                    {
                        method: 'GET',
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                const data = await response.json()
                if (response.ok) {
                    setServices(data)
                    setLoading(false)
                }
            } catch {
                setServices([])
            }
        }
        fetchData()
    }, [
        token,
        pageSkip,
        success,
        notification,

        searchServiceId,
        searchPatientId,
        searchPatientName,
        searchPhone,
        searchAddress,
        searchStartDate,
        searchServiceName,
        searchOrderStatus,
        startDate,
        endDate,
    ])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${api}/admin/patient/all?phone_number=${search}&end_date=${currentDate}T23%3A59%3A59&skip=0&limit=10`,
                    {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                const data = await response.json()
                setPatients(data)
            } catch {
                setPatients([])
            }
        }
        return fetchData()
    }, [token, success, search])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/service/telemedicine/${serviceId}?skip=0&limit=100`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                setTelemedicines(data)
            } catch {
                setTelemedicines([])
            }
        }
        return fetchData()
    }, [token, success, serviceId, totalAll])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/service/healthplan/subscribe/${serviceId}?skip=0&limit=10`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                setHealthPlans(data)
            } catch {
                setHealthPlans([])
            }
        }
        return fetchData()
    }, [token, success, serviceId])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/service/medicine/${serviceId}?skip=0&limit=100`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                setMedicines(data)
            } catch {
                setMedicines([])
            }
        }
        return fetchData()
    }, [token, success, serviceId, totalAll])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/health-plan/`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                setPlans(data)
            } catch {
                setPlans([])
            }
        }
        return fetchData()
    }, [token, success])

    // indicator fetch //
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/admin/patient/indicator/bp/${getPatientId}`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                setPatientBp(data)
            } catch {
                setPatientBp([])
            }
        }
        return fetchData()
    }, [token, success, getPatientId])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/admin/patient/indicator/rbs/${getPatientId}`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                setPatientRbs(data)
            } catch {
                setPatientRbs([])
            }
        }
        return fetchData()
    }, [token, success, getPatientId])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/admin/patient/indicator/weight/${getPatientId}`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                setPatientWeight(data)
            } catch {
                setPatientWeight([])
            }
        }
        return fetchData()
    }, [token, getPatientId])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/admin/patient/indicator/pulse/${getPatientId}`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                setPatientPulse(data)
            } catch {
                setPatientPulse([])
            }
        }
        return fetchData()
    }, [token, success, getPatientId])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${api}/service/patient/${getPatientId}?skip=${pageSkipService}&limit=10`,
                    {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                const data = await response.json()
                setPatientService(data)
            } catch {
                setPatientService([])
            }
        }
        return fetchData()
    }, [token, success, getPatientId, pageSkipService])

    return (
        <div>
            {userInfo.role_name === 'admin' ||
            userInfo.role_name === 'moderator' ||
            userInfo.role_name === 'crm' ||
            userInfo.role_name === 'sales' ? (
                <div>
                    {formOpen && (
                        <ServiceOrder
                            patients={patients}
                            plans={plans}
                            search={search}
                            setSearch={setSearch}
                            searchResults={searchResults}
                            setSearchResults={setSearchResults}
                            setListOpen={setListOpen}
                            setFormOpen={setFormOpen}
                        />
                    )}
                    {listOpen && (
                        <ServiceOrderList
                            api={api}
                            token={token}
                            services={services}
                            setListOpen={setListOpen}
                            setFormOpen={setFormOpen}
                            pageSkip={pageSkip}
                            setPageSkip={setPageSkip}
                            telemedicines={telemedicines}
                            medicines={medicines}
                            healthPlans={healthPlans}
                            setServiceId={setServiceId}
                            totalAll={totalAll}
                            setGetPatientId={setGetPatientId}
                            pageSkipService={pageSkipService}
                            setPageSkipService={setPageSkipService}
                            patientService={patientService}
                            patientBp={patientBp}
                            patientRbs={patientRbs}
                            patientWeight={patientWeight}
                            patientPulse={patientPulse}
                            //search
                            searchServiceId={searchServiceId}
                            setSearchServiceId={setSearchServiceId}
                            searchPatientId={searchPatientId}
                            setSearchPatientId={setSearchPatientId}
                            searchPatientName={searchPatientName}
                            setSearchPatientName={setSearchPatientName}
                            searchPhone={searchPhone}
                            setSearchPhone={setSearchPhone}
                            searchAddress={searchAddress}
                            setSearchAddress={setSearchAddress}
                            searchStartDate={searchStartDate}
                            setSearchStartDate={setSearchStartDate}
                            searchOrderStatus={searchOrderStatus}
                            setSearchOrderStatus={setSearchOrderStatus}
                            searchServiceName={searchServiceName}
                            setSearchServiceName={setSearchServiceName}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            loading={loading}
                            setSuccess={setSuccess}
                        />
                    )}
                </div>
            ) : (
                <div className={classes.modWrapper}>
                    <div className={classes.mod}>Employee Can't Access This Page!</div>{' '}
                </div>
            )}
        </div>
    )
}
