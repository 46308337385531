import { useContext, useState } from 'react'
import { Auth, UserInfo } from '../../../../contexts/allContext'
import { api } from '../../../../utils/apiToken'
import { refreshPage } from '../../../../utils/refreshPage'
import classes from './Edit.module.css'

export default function Edit({ index, patient, closeButton }) {
    const [name, setName] = useState(patient.name)
    const [phone, setPhone] = useState(patient.phone)
    const [email, setEmail] = useState(patient.email)

    const { stateAuth } = useContext(Auth)
    const { stateUser } = useContext(UserInfo)
    const user = stateUser.info
    const token = stateAuth.token

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            const details = {
                name,
                phone,
                email,
            }
            let response = await fetch(`${api}/admin/doctor/user/update/${patient.id}`, {
                method: 'PATCH',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(details),
            })

            if (response.ok) {
                alert('Update successful!')
                refreshPage()
            }
        } catch {
            alert('Problem with update!')
        }
    }

    return (
        <div className={classes.edit}>
            <div key={() => index} className={classes.container}>
                <div className={classes.overlay} onClick={(e) => closeButton(e)}></div>
                <div className={classes.formWrapper}>
                    <div className={classes.close} onClick={(e) => closeButton(e)}>
                        &times;
                    </div>
                    <p>Update Patient Info</p>

                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className={classes.innerWrap}>
                            <label>
                                Patient Full Name
                                <input
                                    type="text"
                                    placeholder="full name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                            </label>
                            <div className={classes.formGrid}>
                                <label>
                                    Phone
                                    <input
                                        type="number"
                                        placeholder="phone"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        required
                                    />
                                </label>
                                <label>
                                    Email
                                    <input
                                        type="email"
                                        placeholder="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </label>
                            </div>
                        </div>
                        {user.role_name === 'admin' || user.role_name === 'moderator' ? (
                            <button className={classes.button} type="submit">
                                Update
                            </button>
                        ) : (
                            ''
                        )}
                    </form>
                </div>
            </div>
        </div>
    )
}
