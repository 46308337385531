import { useState, useEffect, useContext } from 'react'
import { Auth } from '../../../contexts/allContext'
import { api } from '../../../utils/apiToken'
import { toMonthNameShort } from '../../../utils/date'
import { LineChart } from '../../Chart'
import { Number } from './index'

const Pulse = ({ id }) => {
    const [rbs, setRbs] = useState()
    const [dataRbs, setDataRbs] = useState([])

    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    const submit = async (e) => {
        e.preventDefault()

        if (rbs !== null && rbs !== 0) {
            let rbsFetch = await fetch(`${api}/admin/patient/indicator?user_id=${id}`, {
                method: 'POST',
                headers: {
                    Accept: 'appllication/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    key: 'rbs',
                    unit: 'mmol/L',
                    slot_flt4: rbs,
                }),
            })

            if (rbsFetch.ok) {
                setRbs(0)
            }
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/admin/patient/indicator/rbs/${id}`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                setDataRbs(data)
            } catch {
                setDataRbs([])
            }
        }
        return fetchData()
    }, [token, rbs, id])

    let data = {
        labels: [
            ...dataRbs
                .map(
                    (elm) =>
                        `${elm.created_at.slice(8, 10)}-${toMonthNameShort(
                            elm.created_at.slice(5, 7)
                        )}${elm.created_at.slice(2, 4)}`
                )
                .reverse(),
        ],
        datasets: [
            {
                label: 'RBS',
                data: [...dataRbs.map((elm) => elm.slot_flt4).reverse()],
                fill: true,
                backgroundColor: 'rgba(119, 221, 119,0.2)',
                borderColor: 'rgba(119, 221, 119,1)',
                lineTension: 0.4,
            },
        ],
    }

    return (
        <div>
            <Number
                title="Diabetes"
                place="Input RBS"
                unit="mmol/L"
                st={rbs}
                setSt={setRbs}
                smbt={submit}
                min={0}
                max={40}>
                <br />
                <LineChart data={data} />
                <br />
            </Number>
        </div>
    )
}

export default Pulse
