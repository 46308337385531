import DeletePopup from '../DeletePopup/DeletePopup'
import classes from './ScheduleList.module.css'

export default function ScheduleList({ todaySchedule, deleteSchedule, setScheduleId, sOpen, setSOpen }) {
    const date = new Date()
    const today = date.toString()

    return (
        <div className={classes.wrapper}>
            <div className={classes.slot}>
                <div className={classes.slotHead}>Today's ({today.slice(4, 10)}) Online Schedule</div>
                <div className={classes.slotBody}>
                    <div className={classes.slotButtons}>
                        {todaySchedule.map((slot, i) =>
                            slot.online === true ? (
                                <>
                                    <div className={classes.slotButton} key={i}>
                                        <button
                                            onClick={() => {
                                                setScheduleId(slot.id)
                                                setSOpen(i)
                                            }}>
                                            {slot.time.split(':')[1].length === 2 ? slot.time : `${slot.time}0`}{' '}
                                            {slot.am_pm}
                                        </button>
                                        {sOpen === i && (
                                            <DeletePopup close={setSOpen} del={deleteSchedule} state={1}></DeletePopup>
                                        )}
                                    </div>
                                </>
                            ) : (
                                ''
                            )
                        )}
                    </div>
                </div>
            </div>

            <div className={classes.slot}>
                <div className={classes.slotHead}>Today's ({today.slice(4, 10)}) Chamber Schedule</div>
                <div className={classes.slotBody}>
                    <div className={classes.slotButtons}>
                        {todaySchedule.map((slot, i) =>
                            slot.online === false ? (
                                <div className={classes.slotButton} key={i}>
                                    <button
                                        onClick={() => {
                                            setScheduleId(slot.id)
                                            setSOpen(i)
                                        }}>
                                        {slot.time.split(':')[1].length === 2 ? slot.time : `${slot.time}0`}{' '}
                                        {slot.am_pm}
                                    </button>
                                    {sOpen === i && <DeletePopup close={setSOpen} del={deleteSchedule}></DeletePopup>}
                                </div>
                            ) : (
                                ''
                            )
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
