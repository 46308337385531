import React, { useContext, useState } from 'react'
import districtJson from '../../../../config/locations/bd-districts.json'
import divisionJson from '../../../../config/locations/bd-divisions.json'
import { Auth } from '../../../../contexts/allContext'
import { api } from '../../../../utils/apiToken'
import classes from './Edit.module.css'

export default function Edit({ provider, setIsOpen, index }) {
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    const [name, setName] = useState('')
    const [category, setCategory] = useState('')
    const [division, setDivision] = useState('')
    const [district, setDistrict] = useState('')
    const [city, setCity] = useState('')
    const [address, setAddress] = useState('')
    const [pathology, setPathology] = useState('')
    const [imaging, setImaging] = useState('')
    const [ctmri, setCtmri] = useState('')
    const [surgery, setSurgery] = useState('')
    const [opd, setOpd] = useState('')
    const [icu, setIcu] = useState('')
    const [medicine, setMedicine] = useState('')
    const [emergency, setEmergency] = useState('')
    const [bedipd, setBedipd] = useState('')
    const [ambulance, setAmbulance] = useState('')

    const [file, setFile] = useState('')

    const refreshPage = () => {
        window.location.reload()
    }

    const submitImage = async (Id) => {
        const imgData = new FormData()
        imgData.append('file', file)

        let response = await fetch(`${api}/test-provider/picture/${Id}`, {
            headers: {
                Accept: 'appllication/json',
                type: 'image/jpeg',
                Authorization: `Bearer ${token}`,
            },
            method: 'PATCH',
            body: imgData,
        })

        if (response.ok) {
            console.log('Image upload successful!')
            refreshPage()
        } else {
            alert('Something went wrong!')
        }
    }

    const updatePlan = async (e) => {
        e.preventDefault()

        const details = {
            name: name || provider.name,
            category: category || provider.category,
            division: division || provider.division,
            district: district || provider.district,
            city: city || provider.city,
            address: address || provider.address,
            pathology_discount: pathology || provider.pathology_discount,
            imaging_discount: imaging || provider.imaging_discount,
            ct_mri_discount: ctmri || provider.ct_mri_discount,
            surgery_discount: surgery || provider.surgery_discount,
            bed_ipd_discount: bedipd || provider.bed_ipd_discount,
            opd_discount: opd || provider.opd_discount,
            icu_discount: icu || provider.icu_discount,
            medicine_discount: medicine || provider.medicine_discount,
            emergency_discount: emergency || provider.emergency_discount,
            ambulance_discount: ambulance || provider.ambulance_discount,
        }

        const response = await fetch(`${api}/test-provider/${provider.id}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(details),
        })

        let log = await response.json()

        if (response.ok) {
            submitImage(log.id)
        }
    }

    return (
        <div key={() => index} className={classes.containerPopup}>
            <div className={classes.overlay} onClick={() => setIsOpen(false)}></div>
            <div className={classes.formWrapper}>
                <div className={classes.close} onClick={() => setIsOpen(false)}>
                    &times;
                </div>

                <div>
                    <div className={classes.formGrid}>
                        <label>
                            Name
                            <input
                                type="text"
                                defaultValue={provider?.name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </label>
                        <label>
                            {' '}
                            Provider Profile Picture <span>*</span>
                            <input
                                type="file"
                                accept="image"
                                onChange={(e) => setFile(e.target.files[0])}
                                title="Select Profile Picture"
                            />
                        </label>
                    </div>

                    <div className={classes.formGrid}>
                        <label>
                            Category
                            <select type="text" value={category} onChange={(e) => setCategory(e.target.value)}>
                                <option value={provider?.category} selected>
                                    {provider?.category}
                                </option>
                                <option value="Hospital">Hospital</option>
                                <option value="Lab">Lab</option>
                                <option value="Lab + Clinic">Lab + Clinic</option>
                                <option value="Derma Clinic">Derma Clinic</option>
                                <option value="Physio Clinic">Physio Clinic</option>
                                <option value="Clinic">Clinic</option>
                                <option value="Eye Clinic">Eye Clinic</option>
                                <option value="Psychiatric Hospital">Psychiatric Hospital</option>
                                <option value="Addiction Treatment Center">Addiction Treatment Center</option>
                                <option value="Fertility Clinic">Fertility Clinic</option>
                                <option value="Hospice Care Facility">Hospice Care Facility</option>
                                <option value="Dialysis Facility">Dialysis Facility</option>
                                <option value="Blood Bank">Blood Bank</option>
                            </select>
                        </label>
                        <label>
                            Divison
                            <select type="text" value={division} onChange={(e) => setDivision(e.target.value)}>
                                <option value={provider?.division}>{provider?.division}</option>
                                {divisionJson.divisions.map((div) => (
                                    <option key={div.id} value={div.name}>
                                        {div.name}
                                    </option>
                                ))}
                            </select>
                        </label>
                        <label>
                            District
                            <select type="text" value={district} onChange={(e) => setDistrict(e.target.value)}>
                                <option value={provider?.district}>{provider?.district}</option>
                                {districtJson.districts
                                    // .filter((item) => item.division_id === String(division))
                                    .map((dis) => (
                                        <option key={dis.id} value={dis.name}>
                                            {dis.name}
                                        </option>
                                    ))}
                            </select>
                        </label>

                        <label>
                            City
                            <input
                                type="text"
                                defaultValue={provider?.city}
                                onChange={(e) => setCity(e.target.value)}
                            />
                        </label>
                    </div>

                    <div className={classes.formGrid}>
                        <label>
                            Address
                            <textarea
                                type="text"
                                defaultValue={provider?.address}
                                onChange={(e) => setAddress(e.target.value)}
                            />
                        </label>
                    </div>

                    <div className={classes.formGrid}>
                        <label>
                            Pathology Discount (%)
                            <input
                                type="text"
                                defaultValue={provider?.pathology_discount}
                                onChange={(e) => setPathology(e.target.value)}
                            />
                        </label>
                        <label>
                            Imaging Discount (%)
                            <input
                                type="text"
                                defaultValue={provider?.imaging_discount}
                                onChange={(e) => setImaging(e.target.value)}
                            />
                        </label>

                        <label>
                            CT MRI Discount (%)
                            <input
                                type="text"
                                defaultValue={provider?.ct_mri_discount}
                                onChange={(e) => setCtmri(e.target.value)}
                            />
                        </label>
                        <label>
                            Surgery Discount (%)
                            <input
                                type="text"
                                defaultValue={provider?.surgery_discount}
                                onChange={(e) => setSurgery(e.target.value)}
                            />
                        </label>
                    </div>

                    <div className={classes.formGrid}>
                        <label>
                            OPD Discount (%)
                            <input
                                type="text"
                                defaultValue={provider?.opd_discount}
                                onChange={(e) => setOpd(e.target.value)}
                            />
                        </label>
                        <label>
                            ICU Discount (%)
                            <input
                                type="text"
                                defaultValue={provider?.icu_discount}
                                onChange={(e) => setIcu(e.target.value)}
                            />
                        </label>
                        <label>
                            Medicine Discount (%)
                            <input
                                type="text"
                                defaultValue={provider?.medicine_discount}
                                onChange={(e) => setMedicine(e.target.value)}
                            />
                        </label>
                        <label>
                            Emergency Discount (%)
                            <input
                                type="text"
                                defaultValue={provider?.emergency_discount}
                                onChange={(e) => setEmergency(e.target.value)}
                            />
                        </label>
                    </div>

                    <div className={classes.formGrid}>
                        <label>
                            Bed IPD Discount (%)
                            <input
                                type="text"
                                defaultValue={provider?.bed_ipd_discount}
                                onChange={(e) => setBedipd(e.target.value)}
                            />
                        </label>
                        <label>
                            Ambulance Discount (%)
                            <input
                                type="text"
                                defaultValue={provider?.ambulance_discount}
                                onChange={(e) => setAmbulance(e.target.value)}
                            />
                        </label>
                    </div>
                </div>
                <button className={classes.btn} onClick={(e) => updatePlan(e)}>
                    Update
                </button>
            </div>
        </div>
    )
}
