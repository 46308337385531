import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import classes from './List.module.css'
import Update from './Update/Update'

export default function List({ followUp, service, setFormHide, setListHide }) {
    const [hide, setHide] = useState(false)
    let sl = 0

    const openClose = () => {
        setFormHide(true)
        setListHide(false)
    }

    return (
        <div className={classes.collabList}>
            <div className={classes.tableContainer}>
                <div className={classes.wrapper}>
                    <button className={classes.button} onClick={() => openClose()}>
                        + Add Follow-Up
                    </button>
                </div>
                <table className={classes.tableFollow}>
                    <thead>
                        <tr className={classes.tableRow}>
                            <th>Sl</th>
                            <th>ID</th>
                            <th>Title</th>
                            <th>Remarks</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Update</th>
                        </tr>
                    </thead>
                    <tbody>
                        {followUp[1] &&
                            followUp[1].map((item, index) => (
                                <>
                                    <tr className={classes.tableRow}>
                                        <td>{(sl = sl + 1)}</td>
                                        <td>HX-S{service?.ServiceOrder?.id + 1000}</td>
                                        <td className={classes.cap}>{item.title}</td>
                                        <td>{item.remarks}</td>
                                        <td>{item.followup_date}</td>
                                        <td className={`${classes.status} ${classes.cap}`}>
                                            <span
                                                className={
                                                    item.status === 'done'
                                                        ? classes.active
                                                        : item.status === 'in progress'
                                                        ? classes.progress
                                                        : item.status === 'emergency'
                                                        ? classes.delete
                                                        : item.status === 'cancel'
                                                        ? classes.cancel
                                                        : classes.waiting
                                                }>
                                                {item.status}
                                            </span>
                                        </td>
                                        <td>
                                            <button className={classes.icon}>
                                                <FontAwesomeIcon
                                                    icon={faEye}
                                                    onClick={() => {
                                                        setHide(index)
                                                    }}
                                                />
                                            </button>
                                        </td>
                                    </tr>
                                    <div>
                                        {hide === index && <Update index={index} item={item} setHide={setHide} />}
                                    </div>
                                </>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
