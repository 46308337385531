import React from 'react'
import Table from '../../../Resource/Table/Table'
import classes from './Detail.module.css'

export default function Detail({ provider, index, setIsOpen }) {
    let sl = 0
    return (
        <div key={() => index} className={classes.containerPopup}>
            <div className={classes.overlay} onClick={() => setIsOpen(false)}></div>
            <div className={classes.formWrapper}>
                <div className={classes.close} onClick={() => setIsOpen(false)}>
                    &times;
                </div>
                <Table>
                    <thead>
                        <tr>
                            <th>Sl</th>
                            <th>Pathology</th>
                            <th>Imaging</th>
                            <th>CT MRI</th>
                            <th>Surgery</th>
                            <th>Bed IPD</th>
                            <th>OPD</th>
                            <th>ICU</th>
                            <th>Medicine</th>
                            <th>Emergency</th>
                            <th>Ambulance</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className={classes.row}>
                            <td>{(sl = sl + 1)}</td>
                            <td>{provider.pathology_discount || '--'}</td>
                            <td>{provider.imaging_discount || '--'}</td>
                            <td>{provider.ct_mri_discount || '--'}</td>
                            <td>{provider.surgery_discount || '--'}</td>
                            <td>{provider.bed_ipd_discount || '--'}</td>
                            <td>{provider.opd_discount || '--'}</td>
                            <td>{provider.icu_discount || '--'}</td>
                            <td>{provider.medicine_discount || '--'}</td>
                            <td>{provider.emergency_discount || '--'}</td>
                            <td>{provider.ambulance_discount || '--'}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </div>
    )
}
