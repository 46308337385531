import { DefaultStorage, GetStorage, SetStorage } from '../storage/storage'

export const serviceidState = DefaultStorage('serviceid', { id: {} })

export const serviceidReducer = (state, action) => {
    switch (action.type) {
        case 'get':
            SetStorage('serviceid', { id: state.id })
            return GetStorage('serviceid')
        case 'set':
            SetStorage('serviceid', { id: action.payload })
            return GetStorage('serviceid')
        case 'remove':
            SetStorage('serviceid', { id: {} })
            return GetStorage('serviceid')
        default:
            return state
    }
}
