import { useContext, useEffect, useState } from 'react'
import { Auth } from '../../contexts/allContext'
import CorporateForm from './CorporateForm/CorporateForm'
import CorporateList from './CorporateList/CorporateList'

export default function Corporate() {
    const [corporates, setCorporates] = useState([])
    const [listOpen, setListOpen] = useState(true)
    const [formOpen, setFormOpen] = useState(false)

    const api = process.env.REACT_APP_API_URL
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/corporate/`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                setCorporates(data)
            } catch {
                setCorporates([])
            }
        }
        return fetchData()
    }, [token, api])

    return (
        <div>
            {formOpen && <CorporateForm api={api} token={token} setListOpen={setListOpen} setFormOpen={setFormOpen} />}
            {listOpen && <CorporateList setListOpen={setListOpen} setFormOpen={setFormOpen} corporates={corporates} />}
        </div>
    )
}
