import React, { useContext, useEffect, useState } from 'react'
import { Auth } from '../../contexts/allContext'
import Form from './Form/Form'
import List from './List/List'

export default function LabProvider() {
    const [isOpen, setIsOpen] = useState(false)
    const [search, setSearch] = useState('')

    const [providers, setProviders] = useState([])
    const [pageSkip, setPageSkip] = useState(0)

    const api = process.env.REACT_APP_API_URL
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/test-provider/all?name=${search}&skip=0&limit=30`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()

                if (response.ok) {
                    setProviders(data)
                }
            } catch {
                setProviders([])
            }
        }
        fetchData()
    }, [token, api, search])

    return (
        <div>
            {isOpen ? (
                <Form setIsOpen={setIsOpen} />
            ) : (
                <List
                    setIsOpen={setIsOpen}
                    providers={providers}
                    setSearch={setSearch}
                    search={search}
                    setPageSkip={setPageSkip}
                    pageSkip={pageSkip}
                />
            )}
        </div>
    )
}
