import { useContext, useEffect, useState } from 'react'
import { Auth } from '../../../../../contexts/allContext'
import Form from './Form/Form'
import List from './List/List'

export default function FollowUp({ service }) {
    const [followUp, setFollowUp] = useState([])

    const [listHide, setListHide] = useState(true)
    const [formHide, setFormHide] = useState(false)

    const api = process.env.REACT_APP_API_URL
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/follow-up/service/${service?.ServiceOrder?.id}?skip=0&limit=15`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                setFollowUp(data)
            } catch {
                setFollowUp([])
            }
        }
        return fetchData()
    }, [token, api, service?.ServiceOrder?.id])

    return (
        <div>
            {formHide && (
                <Form api={api} token={token} service={service} setListHide={setListHide} setFormHide={setFormHide} />
            )}
            {listHide && (
                <List followUp={followUp} service={service} setListHide={setListHide} setFormHide={setFormHide} />
            )}
        </div>
    )
}
