import React, { useContext, useEffect, useState } from 'react'
import { Auth } from '../../../../contexts/allContext'
import classes from './Edit.module.css'

export default function Edit({ index, lab, setIsOpen }) {
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token
    const api = process.env.REACT_APP_API_URL

    const [labName, setLabName] = useState(lab.LabTest?.name)
    const [mrp, setMrp] = useState(lab.LabTest?.mrp)
    const [discount, setDiscount] = useState(lab.LabTest?.discount)
    const [price, setPrice] = useState(lab.LabTest?.price)

    useEffect(() => {
        const dis = mrp - (mrp * discount) / 100
        setPrice(dis)
    }, [discount, mrp])

    const refreshPage = () => {
        window.location.reload()
    }

    const updatePlan = async (e) => {
        e.preventDefault()

        const details = {
            name: labName,
            mrp: mrp,
            discount: discount || 0,
            price: price,
        }

        const response = await fetch(`${api}/lab-test/${lab.LabTest?.id}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(details),
        })

        if (response.ok) {
            refreshPage()
        }
    }

    return (
        <div key={() => index} className={classes.containerPopup}>
            <div className={classes.overlay} onClick={() => setIsOpen(false)}></div>
            <div className={classes.formWrapper}>
                <div className={classes.close} onClick={() => setIsOpen(false)}>
                    &times;
                </div>
                <div className={classes.formGrid}>
                    <label>
                        Lab Test Name
                        <input type="text" value={labName} onChange={(e) => setLabName(e.target.value)} />
                    </label>

                    <label>
                        Mrp
                        <input type="text" value={mrp} onChange={(e) => setMrp(e.target.value)} />
                    </label>
                </div>
                <div className={classes.formGrid}>
                    <label>
                        Discount
                        <input type="text" value={discount} onChange={(e) => setDiscount(e.target.value)} />
                    </label>

                    <label>
                        price
                        <input type="text" value={price} onChange={(e) => setPrice(e.target.value)} />
                    </label>
                </div>
                <button className={classes.btn} onClick={(e) => updatePlan(e)}>
                    Update
                </button>
            </div>
        </div>
    )
}
