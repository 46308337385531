import { faLeaf } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Auth } from '../../../contexts/allContext'
import classes from './UserPassword.module.css'

export default function UserPassword({ users, search, setSearch, searchResults, setSearchResults }) {
    const [userId, setUserId] = useState()
    const [password, setPassword] = useState('')
    const [rePassword, setRePassword] = useState('')
    const [alert, setAlert] = useState(false)
    const [change, setChange] = useState(false)
    const [changeSelect, setChangeSelect] = useState('')
    const [hide, setHide] = useState(false)

    const api = process.env.REACT_APP_API_URL
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    const navigate = useNavigate()

    useEffect(() => {
        if (changeSelect === 'yes') {
            setChange(true)
        } else {
            setChange(false)
        }
    }, [changeSelect])

    const handler = (search) => {
        let matches = []
        if (search.length > 1) {
            matches = users.filter((user) => user.phone.toLowerCase().includes(search))
            setHide(true)
        }
        if (search.length < 1) {
            setHide(false)
        }
        setSearch(search)
        setSearchResults(matches)
    }

    const setHandle = (search) => {
        setSearch(search)
        setUserId(search?.id)
        setSearchResults([])
        setHide(false)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (password !== rePassword) {
            setAlert(true)
            setTimeout(function () {
                setAlert(false)
            }, 3000)
        } else {
            const details = {
                password,
            }
            let postFetch = await fetch(`${api}/admin/password?user_id=${userId}`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(details),
            })

            if (postFetch.ok) {
                navigate('/')
            }
        }
    }

    return (
        <div className={classes.wrapper}>
            <div className={classes.formWrapper}>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className={classes.section}>
                        <FontAwesomeIcon className={classes.leaf} icon={faLeaf} />
                        Update User Password!
                    </div>
                    <div className={classes.innerWrap}>
                        <label>
                            Select For Update
                            <select className={classes.select} onChange={(e) => setChangeSelect(e.target.value)}>
                                <option value="">Select</option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                        </label>
                        {change && (
                            <>
                                <div className={classes.formGrid}>
                                    <label>
                                        Input Mobile
                                        <input
                                            type="text"
                                            placeholder="search user"
                                            value={search.phone}
                                            onChange={(e) => handler(e.target.value)}
                                            required
                                        />
                                    </label>
                                    <label>
                                        User Name
                                        <span className={classes.fetchLabel}>
                                            {search.name} <p>.</p>
                                        </span>
                                    </label>
                                </div>
                                {hide && (
                                    <div className={classes.optAll}>
                                        {searchResults &&
                                            searchResults.map((info, i) => (
                                                <div className={classes.optSelect} key={i}>
                                                    <div onClick={() => setHandle(info)}>
                                                        <option value={info.id}>
                                                            {info.phone} - {info.name}
                                                        </option>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                )}
                                <div className={classes.formGrid}>
                                    <label>
                                        Password
                                        <input
                                            type="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            minLength={4}
                                            required
                                        />
                                    </label>
                                    <label>
                                        Confirm Password
                                        <input
                                            type="password"
                                            value={rePassword}
                                            onChange={(e) => setRePassword(e.target.value)}
                                            minLength={4}
                                            required
                                        />
                                    </label>
                                </div>
                                <button className={classes.button} type="submit">
                                    Update
                                </button>
                            </>
                        )}
                    </div>
                    <div> {alert && <p className={classes.alertMessage}>Password not matched!</p>}</div>
                </form>
            </div>
        </div>
    )
}
