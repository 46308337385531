export const randomId = (len) => {
    let chars = 'qwertyuiopasdfghjklmnbvcxz'
    let maxPos = chars.length
    let result = ''

    let i
    for (i = 0; i < len; i++) {
        result += chars.charAt(Math.floor(Math.random() * maxPos))
    }
    return result
}

export const randomNumber = (len) => {
    let chars = 'qwertyuiopasdfghjklmnbvcxz'
    let maxPos = chars.length
    let result = ''

    let i
    for (i = 0; i < len; i++) {
        result += chars.charAt(Math.floor(Math.random() * maxPos))
        if ((i + 1) % 3 === 0 && i + 1 !== len) {
            result += '-'
        }
    }
    return result
}
