import { faThumbtack } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import classes from './Snapshot.module.css'

export default function Snapshot({
    totalActiveDoctors,
    totalActiveDoctorsThisMonth,
    totalActiveDoctorsPreviousMonth,

    totalPendingDoctors,
    totalPendingDoctorsThisMonth,
    totalPendingDoctorsPreviousMonth,

    totalUsers,
    totalUsersThisMonth,
    totalUsersPreviousMonth,

    totalPatients,
    totalPatientsThisMonth,
    totalPatientsPreviousMonth,

    totalServices,
    totalServicesThisMonth,
    totalServicesPreviousMonth,

    repeatServices,
    repeatServicesThisMonth,
    repeatServicesPreviousMonth,

    totalPrescriptions,
    totalPrescriptionsThisMonth,
    totalPrescriptionsPreviousMonth,
}) {
    return (
        <div className={classes.container}>
            <div className={classes.cardBox}>
                <FontAwesomeIcon className={classes.icon} icon={faThumbtack} />
                <div className={classes.wrap}>
                    <p className={classes.title}>
                        <Link to="doctors">Total Doctors</Link>
                    </p>
                    <span className={classes.count}>
                        <Link to="doctors">
                            {isNaN(totalActiveDoctors[0]?.results + 0) !== true ? totalActiveDoctors[0]?.results : 0}
                        </Link>
                    </span>
                    <div className={classes.subInfo}>
                        <span>
                            {`Last Month: ${
                                isNaN(totalActiveDoctorsPreviousMonth[0]?.results + 0) !== true
                                    ? totalActiveDoctorsPreviousMonth[0]?.results
                                    : 0
                            }`}
                        </span>
                        <span>
                            {`This Month: ${
                                isNaN(totalActiveDoctorsThisMonth[0]?.results + 0) !== true
                                    ? totalActiveDoctorsThisMonth[0]?.results
                                    : 0
                            }`}
                        </span>
                    </div>
                </div>
            </div>
            <div className={classes.cardBox}>
                <FontAwesomeIcon className={classes.icon} icon={faThumbtack} />
                <div className={classes.wrap}>
                    <p className={classes.title}>
                        <Link to="doctors">Pending Doctors</Link>
                    </p>
                    <span className={classes.count}>
                        <Link to="doctors">
                            {isNaN(totalPendingDoctors[0]?.results + 0) !== true ? totalPendingDoctors[0]?.results : 0}
                        </Link>
                    </span>
                    <div className={classes.subInfo}>
                        <span>
                            {`Last Month: ${
                                isNaN(totalPendingDoctorsPreviousMonth[0]?.results + 0) !== true
                                    ? totalPendingDoctorsPreviousMonth[0]?.results
                                    : 0
                            }`}
                        </span>
                        <span>
                            {`This Month: ${
                                isNaN(totalPendingDoctorsThisMonth[0]?.results + 0) !== true
                                    ? totalPendingDoctorsThisMonth[0]?.results
                                    : 0
                            }`}
                        </span>
                    </div>
                </div>
            </div>
            <div className={classes.cardBox}>
                <FontAwesomeIcon className={classes.icon} icon={faThumbtack} />
                <div className={classes.wrap}>
                    <p className={classes.title}>
                        <Link to="patients">Total Users</Link>
                    </p>
                    <span className={classes.count}>
                        <Link to="patients">
                            {isNaN(totalUsers[0]?.results + 0) !== true ? totalUsers[0]?.results : 0}
                        </Link>
                    </span>
                    <div className={classes.subInfo}>
                        <span>
                            {`Last Month: ${
                                isNaN(totalUsersPreviousMonth[0]?.results + 0) !== true
                                    ? totalUsersPreviousMonth[0]?.results
                                    : 0
                            }`}
                        </span>
                        <span>
                            {`This Month: ${
                                isNaN(totalUsersThisMonth[0]?.results + 0) !== true
                                    ? totalUsersThisMonth[0]?.results
                                    : 0
                            }`}
                        </span>
                    </div>
                </div>
            </div>
            <div className={classes.cardBox}>
                <FontAwesomeIcon className={classes.icon} icon={faThumbtack} />
                <div className={classes.wrap}>
                    <p className={classes.title}>
                        <Link to="services">Total Patients</Link>
                    </p>
                    <span className={classes.count}>
                        <Link to="services">
                            {isNaN(totalPatients[0]?.results + 0) !== true ? totalPatients[0]?.results : 0}
                        </Link>
                    </span>
                    <div className={classes.subInfo}>
                        <span>
                            {`Last Month: ${
                                isNaN(totalPatientsPreviousMonth[0]?.results + 0) !== true
                                    ? totalPatientsPreviousMonth[0]?.results
                                    : 0
                            }`}
                        </span>
                        <span>
                            {`This Month: ${
                                isNaN(totalPatientsThisMonth[0]?.results + 0) !== true
                                    ? totalPatientsThisMonth[0]?.results
                                    : 0
                            }`}
                        </span>
                    </div>
                </div>
            </div>
            <div className={classes.cardBox}>
                <FontAwesomeIcon className={classes.icon} icon={faThumbtack} />
                <div className={classes.wrap}>
                    <p className={classes.title}>
                        <Link to="services">Total Services</Link>
                    </p>
                    <span className={classes.count}>
                        <Link to="services">
                            {isNaN(totalServices[0]?.results + 0) !== true ? totalServices[0]?.results : 0}
                        </Link>
                    </span>
                    <div className={classes.subInfo}>
                        <span>
                            {`Last Month: ${
                                isNaN(totalServicesPreviousMonth[0]?.results + 0) !== true
                                    ? totalServicesPreviousMonth[0]?.results
                                    : 0
                            }`}
                        </span>
                        <span>
                            {`This Month: ${
                                isNaN(totalServicesThisMonth[0]?.results + 0) !== true
                                    ? totalServicesThisMonth[0]?.results
                                    : 0
                            }`}
                        </span>
                    </div>
                </div>
            </div>
            <div className={classes.cardBox}>
                <FontAwesomeIcon className={classes.icon} icon={faThumbtack} />
                <div className={classes.wrap}>
                    <p className={classes.title}>
                        <Link to="services">Repeat Services</Link>
                    </p>
                    <span className={classes.count}>
                        <Link to="services">{isNaN(repeatServices + 0) !== true ? repeatServices : 0}</Link>
                    </span>
                    <div className={classes.subInfo}>
                        <span>
                            {`Last Month: ${
                                isNaN(repeatServicesPreviousMonth + 0) !== true ? repeatServicesPreviousMonth : 0
                            }`}
                        </span>
                        <span>
                            {`This Month: ${isNaN(repeatServicesThisMonth + 0) !== true ? repeatServicesThisMonth : 0}`}
                        </span>
                    </div>
                </div>
            </div>
            <div className={classes.cardBox}>
                <FontAwesomeIcon className={classes.icon} icon={faThumbtack} />
                <div className={classes.wrap}>
                    <p className={classes.title}>
                        <Link to="prescriptions">Total Prescriptions</Link>
                    </p>
                    <span className={classes.count}>
                        <Link to="prescriptions">
                            {isNaN(totalPrescriptions[0]?.results + 0) !== true ? totalPrescriptions[0]?.results : 0}
                        </Link>
                    </span>
                    <div className={classes.subInfo}>
                        <span>
                            {`Last Month: ${
                                isNaN(totalPrescriptionsPreviousMonth[0]?.results + 0) !== true
                                    ? totalPrescriptionsPreviousMonth[0]?.results
                                    : 0
                            }`}
                        </span>
                        <span>
                            {`This Month: ${
                                isNaN(totalPrescriptionsThisMonth[0]?.results + 0) !== true
                                    ? totalPrescriptionsThisMonth[0]?.results
                                    : 0
                            }`}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
