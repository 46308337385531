import { faList } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classes from './ListCount.module.css'

export default function ListCount({ title, results }) {
    return (
        <div className={classes.count}>
            <p>
                <FontAwesomeIcon icon={faList} />
                {title}
            </p>
            <p>
                Total: <span>{results[0]?.results}</span>
            </p>
        </div>
    )
}
