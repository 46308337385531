import { faCheck, faCopy, faVideo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import classes from './LinkGenerator.module.css'

export default function LinkGenerator() {
    const { code, doc, pat } = useParams()
    const [stateDoc, setStateDoc] = useState(false)
    const [statePat, setStatePat] = useState(false)

    const docName = doc.replace('-', ' ')
    const patName = pat.replace('-', ' ')

    const linkRefDoc = useRef(null)
    const linkRefPat = useRef(null)

    const handleClickDoc = () => {
        linkRefDoc.current.select()
        document.execCommand('copy')
        window.getSelection().removeAllRanges()
        setStateDoc(true)
    }

    const handleClickPat = () => {
        linkRefPat.current.select()
        document.execCommand('copy')
        window.getSelection().removeAllRanges()
        setStatePat(true)
    }

    const docLink = `https://healthxbd.com/meeting/${code}/${doc}`
    const patLink = `https://healthxbd.com/meeting/${code}/${pat}`

    return (
        <div className={classes.link}>
            <h1>
                <span>
                    <FontAwesomeIcon icon={faVideo} />
                </span>
                Meeting Link
            </h1>

            <div className={classes.box}>
                <div>
                    <h3>Doctor Link:</h3> <span>"{docName}"</span>
                </div>
                {docLink}
                <input
                    type="text"
                    value={docLink}
                    ref={linkRefDoc}
                    readOnly
                    style={{ position: 'absolute', left: '-9999px' }}
                />
                {stateDoc ? (
                    <FontAwesomeIcon icon={faCheck} className={classes.tic} />
                ) : (
                    <span onClick={handleClickDoc} className={classes.copy}>
                        <FontAwesomeIcon icon={faCopy} />
                        Copy
                    </span>
                )}
            </div>
            <div className={classes.box}>
                <div>
                    <h3>Patient Link:</h3> <span>"{patName}"</span>
                </div>
                {patLink}
                <input
                    type="text"
                    value={patLink}
                    ref={linkRefPat}
                    readOnly
                    style={{ position: 'absolute', left: '-9999px' }}
                />
                {statePat ? (
                    <FontAwesomeIcon icon={faCheck} className={classes.tic} />
                ) : (
                    <span onClick={handleClickPat} className={classes.copy}>
                        <FontAwesomeIcon icon={faCopy} />
                        Copy
                    </span>
                )}
            </div>
        </div>
    )
}
