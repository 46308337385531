import { useState } from 'react'
import Active from './Active/Active'
import Pending from './Pending/Pending'

export default function List({ pharmacies, pendingPharmacies, pageSkip, setPageSkip, loading, loader, setLoader }) {
    const [openActive, setOpenActive] = useState(true)
    const [openPending, setOpenPending] = useState(false)

    return (
        <div>
            {openActive && (
                <Active
                    pharmacies={pharmacies}
                    pageSkip={pageSkip}
                    setPageSkip={setPageSkip}
                    loading={loading}
                    setOpenActive={setOpenActive}
                    setOpenPending={setOpenPending}
                    loader={loader}
                    setLoader={setLoader}
                />
            )}
            {openPending && (
                <Pending
                    pendingPharmacies={pendingPharmacies}
                    pageSkip={pageSkip}
                    setPageSkip={setPageSkip}
                    loading={loading}
                    setOpenActive={setOpenActive}
                    setOpenPending={setOpenPending}
                />
            )}
        </div>
    )
}
