import { useContext, useEffect, useState } from 'react'
import { UserInfo } from '../../../../contexts/allContext'
import Loader from '../Loader/Loader'
import classes from './Update.module.css'

export default function Update({ index, setIsOpen, offers, setImgup, setLoader, loader }) {
    const api = process.env.REACT_APP_API_URL_V2
    const { stateUser } = useContext(UserInfo)
    const userInfo = stateUser.info

    const [pharma, setPharma] = useState([])
    const [file, setFile] = useState('')

    const [productName, setProductName] = useState('')
    const [genericName, setGenericName] = useState('')
    const [pharmaName, setPharmaName] = useState('')
    const [strength, setStrength] = useState('')
    const [pack, setPack] = useState('')
    const [regular, setRegular] = useState()
    const [discount, setDiscount] = useState()
    const [offer, setOffer] = useState('')
    const [invoice, setInvoice] = useState()
    const [bonus, setBonus] = useState('')
    const [details, setDetails] = useState('')
    const [date, setDate] = useState('')

    const refreshPage = () => {
        window.location.reload()
    }

    //offers details update
    const updatePlan = async (e) => {
        e.preventDefault()

        const info = {
            product_name: productName || offers.PharmaOffer?.product_name,
            generic_name: genericName || offers.PharmaOffer?.generic_name,
            pharmaceutical_name: pharmaName || offers.PharmaOffer?.pharmaceutical_name,
            strength: strength || offers.PharmaOffer?.strength,
            pack_size: pack || offers.PharmaOffer?.pack_size,
            regular_price: regular || offers.PharmaOffer?.regular_price,
            discount_offer: discount || offers.PharmaOffer?.discount_offer,
            offer_price: offer || offers.PharmaOffer?.offer_price,
            invoice_quantity: invoice || offers.PharmaOffer?.invoice_quantity,
            bonus_quantity: bonus || offers.PharmaOffer?.bonus_quantity,
            other_details: details || offers.PharmaOffer?.other_details,
            expiry_date: date || offers.PharmaOffer?.expiry_date,
        }

        const response = await fetch(`${api}/pharmaceuticals-offer/${offers.PharmaOffer?.id}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(info),
        })

        if (response.ok) {
            refreshPage()
        }
    }

    //image upload
    const submitImage = async (docId) => {
        const imgData = new FormData()
        imgData.append('file', file)

        let response = await fetch(`${api}/pharmaceuticals-offer/picture?issuer_id=${userInfo.id}&offer_id=${docId}`, {
            headers: {
                Accept: 'appllication/json',
                type: 'image/jpeg',
            },
            method: 'PATCH',
            body: imgData,
        })

        if (response.ok) {
            console.log('Image upload successful!')
            // refreshPage()
            setImgup(true)
            setLoader(true)
        } else {
            alert('Something went wrong!')
        }
    }

    //get pharma name lists
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/pharmaceuticals-offer/pharmaceuticals`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                const data = await response.json()

                if (response.ok) {
                    setPharma(data)
                }
            } catch {
                setPharma([])
            }
        }
        fetchData()
    }, [api])

    return (
        <div key={() => index} className={classes.containerPopup}>
            <div className={classes.overlay} onClick={() => setIsOpen(false)}></div>
            <div className={classes.formWrapper}>
                <div className={classes.close} onClick={() => setIsOpen(false)}>
                    &times;
                </div>

                <div className={classes.viewall}>
                    <div className={classes.cardWrapper}>
                        <div className={classes.card}>
                            {loader ? (
                                <div className={classes.loader}>
                                    <Loader />
                                </div>
                            ) : (
                                <img alt="" src={offers.ImagesLog?.image_url} />
                            )}

                            <label>
                                Product Image
                                <input type="file" accept="image" onChange={(e) => setFile(e.target.files[0])} />
                            </label>
                            {file ? (
                                <div>
                                    <button
                                        className={classes.imgbtn}
                                        onClick={(e) => {
                                            submitImage(offers.PharmaOffer?.id)
                                        }}>
                                        Update
                                    </button>
                                </div>
                            ) : (
                                <div>
                                    <button className={classes.imgbtndis} disabled>
                                        Update
                                    </button>
                                </div>
                            )}
                        </div>
                        {/* <Loader /> */}
                    </div>
                    <form className={classes.innerWrap} onSubmit={(e) => updatePlan(e)}>
                        <div className={classes.formGrid}>
                            <label>
                                Product Name
                                <input
                                    type="text"
                                    name="product_name"
                                    defaultValue={offers.PharmaOffer?.product_name}
                                    onChange={(e) => setProductName(e.target.value)}
                                />
                            </label>
                            <label>
                                Generic Name
                                <input
                                    type="text"
                                    name="generic_name"
                                    defaultValue={offers.PharmaOffer?.generic_name}
                                    onChange={(e) => setGenericName(e.target.value)}
                                />
                            </label>
                            <label>
                                Pharmaceutical name
                                <select
                                    id="type"
                                    name="pharmaceutical_name"
                                    onChange={(e) => setPharmaName(e.target.value)}>
                                    <option defaultValue={offers.PharmaOffer?.pharmaceutical_name}>
                                        {offers.PharmaOffer?.pharmaceutical_name}
                                    </option>
                                    {pharma[1]?.map((par, index) => (
                                        <option value={par.name} key={index}>
                                            {par.name}
                                        </option>
                                    ))}
                                </select>
                            </label>
                            <label>
                                Strength
                                <input
                                    type="text"
                                    name="strength"
                                    defaultValue={offers.PharmaOffer?.strength}
                                    onChange={(e) => setStrength(e.target.value)}
                                />
                            </label>
                            <label>
                                Pack Size
                                <input
                                    type="text"
                                    name="pack_size"
                                    defaultValue={offers.PharmaOffer?.pack_size}
                                    onChange={(e) => setPack(e.target.value)}
                                />
                            </label>
                            <label>
                                Regular Price
                                <input
                                    type="number"
                                    name="regular_price"
                                    defaultValue={offers.PharmaOffer?.regular_price}
                                    onChange={(e) => setRegular(e.target.value)}
                                />
                            </label>
                            <label>
                                Discount Offer
                                <input
                                    type="number"
                                    name="discount_offer"
                                    defaultValue={offers.PharmaOffer?.discount_offer}
                                    onChange={(e) => setDiscount(e.target.value)}
                                />
                            </label>
                            <label>
                                Offer Price
                                <input
                                    type="number"
                                    name="offer_price"
                                    defaultValue={offers.PharmaOffer?.offer_price}
                                    onChange={(e) => setOffer(e.target.value)}
                                />
                            </label>
                            <label>
                                Invoice Quantity
                                <input
                                    type="text"
                                    name="invoice_quantity"
                                    defaultValue={offers.PharmaOffer?.invoice_quantity}
                                    onChange={(e) => setInvoice(e.target.value)}
                                />
                            </label>
                            <label>
                                Bonus Quantity
                                <input
                                    type="text"
                                    name="bonus_quantity"
                                    defaultValue={offers.PharmaOffer?.bonus_quantity}
                                    onChange={(e) => setBonus(e.target.value)}
                                />
                            </label>
                            <label>
                                Other Details
                                <textarea
                                    type="text"
                                    name="other_details"
                                    defaultValue={offers.PharmaOffer?.other_details}
                                    onChange={(e) => setDetails(e.target.value)}
                                />
                            </label>
                            <label>
                                Expiry Date
                                <input
                                    type="date"
                                    name="expiry_date"
                                    defaultValue={offers.PharmaOffer?.expiry_date}
                                    onChange={(e) => setDate(e.target.value)}
                                />
                            </label>
                        </div>
                        <button className={classes.btn} type="submit">
                            Update
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}
