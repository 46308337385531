import { faEdit, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import Pagination from '../../../Pagination/Pagination'
import { ListCount, Table } from '../../../Resource'
import { Loading } from '../../../index'
import classes from './Pending.module.css'
import Popup from './Popup/Popup'

export default function Pending({ pendingClinics, pageSkip, setPageSkip, loading, setOpenActive, setOpenPending }) {
    const [isOpen, setIsOpen] = useState(false)
    let serial = 0

    const openClose = () => {
        setOpenActive(true)
        setOpenPending(false)
    }

    return (
        <div className={classes.list}>
            <div className={classes.wrapper}>
                <div>
                    <input
                        className={classes.searchField}
                        type="text"
                        // value={search}
                        placeholder="Search Clinic"
                        // onChange={(e) => setSearch(e.target.value)}
                    />
                    <button className={classes.searchButton}>
                        <FontAwesomeIcon icon={faSearch} />
                    </button>
                </div>

                <div className={classes.allButton}>
                    <button className={classes.button} onClick={() => openClose()}>
                        View Active Clinics
                    </button>
                    {/* <span></span> */}
                    {/* <button className={`${classes.button} ${classes.buttonMargin}`} onClick={() => openCloseListForm()}>
                        + Add Doctors
                    </button> */}
                </div>
            </div>

            <ListCount title="Pending Clinic List" results={pendingClinics} />
            <Table>
                <thead>
                    <tr>
                        <th>Sl</th>
                        <th>Clinic Name</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>District</th>
                        <th>Sub District</th>
                        <th>Address</th>
                        <th>License</th>
                        <th>Reg. Date</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {pendingClinics[1] &&
                        pendingClinics[1].map((clinic, index) => (
                            <tr className={classes.row} key={index}>
                                <td>{(serial = serial + 1) + pageSkip}</td>
                                <td>{clinic?.name}</td>
                                <td>{clinic?.contact_phone}</td>
                                <td>{clinic.contact_email || '-'}</td>
                                <td>{clinic.district || '-'}</td>
                                <td>{clinic.sub_district || '-'}</td>
                                <td>{clinic.detail_address}</td>
                                <td>{clinic.clinic_license}</td>
                                <td>{clinic.created_at && clinic.created_at.slice(0, 10)}</td>
                                <td className={classes.status}>
                                    <span className={classes.waiting}>Pending</span>
                                </td>
                                <td>
                                    <button className={classes.icon}>
                                        <FontAwesomeIcon
                                            title="Action"
                                            icon={faEdit}
                                            onClick={() => setIsOpen(index)}
                                        />
                                    </button>
                                </td>
                                {isOpen === index && <Popup clinic={clinic} setIsOpen={setIsOpen} index={index} />}
                            </tr>
                        ))}
                </tbody>
            </Table>
            <Pagination
                pageSkip={pageSkip}
                setPageSkip={setPageSkip}
                listItem={pendingClinics[1]}
                pageAll={pendingClinics[0]?.results}
            />
            {loading && <Loading />}
        </div>
    )
}
