import { useContext } from 'react'
import { Auth } from '../../../../contexts/allContext'
import classes from './SwitchStatus.module.css'

export default function SwitchStatus({ index, setStatus, item }) {
    // const [statusChange, setStatusChange] = useState('')

    const api = process.env.REACT_APP_API_URL
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    const refreshPage = () => {
        window.location.reload()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        let patchFetch = await fetch(`${api}/admin/switch/active/${item.id}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })

        if (patchFetch.ok) {
            refreshPage()
        }
    }

    return (
        <div key={() => index} className={classes.container}>
            <div className={classes.formWrapper}>
                <div className={classes.close} onClick={() => setStatus(false)}>
                    &times;
                </div>
                <form>
                    <div className={classes.innerWrap}>
                        <label className={classes.gap}>
                            Change Status of - <span>{item.name}</span>
                        </label>
                    </div>
                    {item.is_active !== true ? (
                        <button className={classes.buttonOn} type="submit" onClick={(e) => handleSubmit(e)}>
                            Active
                        </button>
                    ) : (
                        <button className={classes.button} type="submit" onClick={(e) => handleSubmit(e)}>
                            Deactive
                        </button>
                    )}
                </form>
            </div>
        </div>
    )
}
