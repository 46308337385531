import { refreshPage } from '../../../../utils/refreshPage'
import classes from './Popup.module.css'

export default function Popup({ index, setIsOpen, offers }) {
    // const { stateAuth } = useContext(Auth)
    // const token = stateAuth.token

    const api = process.env.REACT_APP_API_URL_V2
    const deleted = async () => {
        const response = await fetch(`${api}/pharmaceuticals-offer/${offers.PharmaOffer?.id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                // Authorization: `Bearer ${token}`,
            },
        })

        if (response.ok) {
            refreshPage()
        }
    }

    return (
        <div key={() => index} className={classes.container}>
            <div className={classes.overlay} onClick={() => setIsOpen(false)}></div>
            <div className={classes.formWrapper}>
                <div className={classes.close} onClick={() => setIsOpen(false)}>
                    &times;
                </div>

                <p>{offers.name}</p>

                <div className={classes.buttonContainer}>
                    <button className={classes.button} onClick={deleted}>
                        Yes
                    </button>

                    <button className={classes.button} onClick={() => setIsOpen(false)}>
                        No
                    </button>
                </div>
            </div>
        </div>
    )
}
