import { faLeaf } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useState } from 'react'
import { Auth } from '../../../contexts/allContext'
import classes from './UserRole.module.css'

export default function UserPassword({ users, search, setSearch, searchResults, setSearchResults, roles }) {
    const [hide, setHide] = useState(false)
    const [roleChange, setRoleChange] = useState('')
    const [userId, setUserId] = useState()

    const api = process.env.REACT_APP_API_URL
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    const handler = (search) => {
        let matches = []
        if (search.length > 1) {
            matches = users.filter((user) => user.phone.toLowerCase().includes(search))
            setHide(true)
        }
        if (search.length < 1) {
            setHide(false)
        }
        setSearch(search)
        setSearchResults(matches)
    }

    const setHandle = (search) => {
        setSearch(search)
        setUserId(search?.id)
        setSearchResults([])
        setHide(false)
    }

    const refreshPage = () => {
        window.location.reload()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        let patchFetch = await fetch(`${api}/admin/role-change?id=${userId}&role_name=${roleChange}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })

        if (patchFetch.ok) {
            refreshPage()
        }
    }

    return (
        <div className={classes.formWrapper}>
            <form onSubmit={(e) => handleSubmit(e)}>
                <div className={classes.section}>
                    <FontAwesomeIcon className={classes.leaf} icon={faLeaf} />
                    Update User Role!
                </div>
                <div className={classes.innerWrap}>
                    <div className={classes.formGrid}>
                        <label>
                            Input Mobile
                            <input
                                type="text"
                                placeholder="search user"
                                value={search.phone}
                                onChange={(e) => handler(e.target.value)}
                                required
                            />
                        </label>
                        <div className={classes.formGrid}>
                            <label>
                                User Name
                                <span className={classes.fetchLabel}>
                                    {search.name} <p>.</p>
                                </span>
                            </label>
                            <label>
                                User Role
                                <span className={classes.fetchLabel}>
                                    {search.role_name} <p>.</p>
                                </span>
                            </label>
                        </div>
                    </div>
                    {hide && (
                        <div className={classes.optAll}>
                            {searchResults &&
                                searchResults.map((info, i) => (
                                    <div className={classes.optSelect} key={i}>
                                        <div onClick={() => setHandle(info)}>
                                            <option value={info.id}>
                                                {info.phone} - {info.name}
                                            </option>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    )}

                    <label>
                        Select New Role
                        <select className={classes.select} onChange={(e) => setRoleChange(e.target.value)}>
                            <option value="">Select Role</option>
                            {roles &&
                                roles.map((role) =>
                                    role.name !== 'admin' ? <option value={role.name}>{role.name}</option> : ''
                                )}
                        </select>
                    </label>

                    <button className={classes.button} type="submit" onClick={(e) => handleSubmit(e)}>
                        Update
                    </button>
                </div>
                {/* <div> {alert && <p className={classes.alertMessage}>Password not matched!</p>}</div> */}
            </form>
        </div>
    )
}
