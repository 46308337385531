import { useState } from 'react'
import classes from './Form.module.css'

export default function Form({ api, token, service, setListHide, setFormHide }) {
    const [title, setTitle] = useState('')
    const [remarks, setRemarks] = useState('')
    const [date, setDate] = useState('')

    const openClose = () => {
        setFormHide(false)
        setListHide(true)
    }

    const refreshPage = () => {
        window.location.reload()
    }

    const handleFollowUpForm = async (e) => {
        e.preventDefault()

        const details = {
            status: 'pending',
            title: title,
            remarks: remarks,
            followup_date: date,
        }

        let postFetch = await fetch(`${api}/follow-up/?service_id=${service?.ServiceOrder?.id}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(details),
        })

        if (postFetch.ok) {
            refreshPage()
        }
    }

    return (
        <div className={classes.collabForm}>
            <button className={classes.formBtn} onClick={() => openClose()}>
                Follow-Up List
            </button>
            <div className={classes.refMargin}></div>
            <label className={classes.labelM}>
                Patient Info
                <span className={`${classes.fetchLabel} ${classes.spanMargin} `}>
                    {service?.User?.name} - {service?.User?.phone} <br /> {service?.ServiceOrder?.current_address}
                    <p>.</p>
                </span>
            </label>
            <div className={classes.formGrid}>
                <label>
                    Follow-Up Title <span className={classes.starSign}>*</span>
                    {/* <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required /> */}
                    <select onChange={(e) => setTitle(e.target.value)} required>
                        <option value="">Select</option>
                        <option value="All OK now, will call if any service needed">
                            All OK now, will call if any service needed
                        </option>
                        <option value="Busy, can't talk ">Busy, can't talk</option>
                        <option value="Didn't recognize ">Didn't recognize</option>
                        <option value="Foul / Pervert">Foul / Pervert</option>
                        <option value="Not interested ">Not interested </option>
                        <option value="Not received">Not received</option>
                        <option value="Service needed, number sent">Service needed, number sent</option>
                        <option value="Service needed, will inform later">Service needed, will inform later</option>
                        <option value="Unreachable / Switched off">Unreachable / Switched off</option>
                    </select>
                </label>
                <label>
                    Follow-Up Date <span className={classes.starSign}>*</span>
                    <input type="date" value={date} onChange={(e) => setDate(e.target.value)} required />
                </label>
            </div>
            <label>
                Remarks
                <textarea row={4} type="text" value={remarks} onChange={(e) => setRemarks(e.target.value)} required />
            </label>
            <button className={classes.submitBtn} onClick={(e) => handleFollowUpForm(e)}>
                Add
            </button>
        </div>
    )
}
