import { useContext, useState } from 'react'
import { Auth } from '../../../contexts/allContext'
import ActiveList from './ActiveList/ActiveList'
import InactiveList from './InactiveList/InactiveList'

export default function NoticeList({ setListOpen, setFormOpen, notices, userInfo }) {
    const [isOpen, setIsOpen] = useState(false)
    const [activeList, setActiveList] = useState(true)
    const [inactiveList, setInactiveList] = useState(false)

    const api = process.env.REACT_APP_API_URL
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    const openClose = () => {
        setListOpen(false)
        setFormOpen(true)
    }

    const refreshPage = () => {
        window.location.reload()
    }

    const noticeId = async (value) => {
        let idFetch = await fetch(`${api}/notice/active-switch?id=${value}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
        if (idFetch.ok) {
            refreshPage()
        } else {
            alert(`Unauthorized!`)
        }
    }

    return (
        <div>
            {activeList && (
                <ActiveList
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    setListOpen={setListOpen}
                    setFormOpen={setFormOpen}
                    notices={notices}
                    setActiveList={setActiveList}
                    setInactiveList={setInactiveList}
                    noticeId={noticeId}
                    openClose={openClose}
                    userInfo={userInfo}
                />
            )}
            {inactiveList && (
                <InactiveList
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    setListOpen={setListOpen}
                    setFormOpen={setFormOpen}
                    notices={notices}
                    setActiveList={setActiveList}
                    setInactiveList={setInactiveList}
                    noticeId={noticeId}
                    openClose={openClose}
                    userInfo={userInfo}
                />
            )}
        </div>
    )
}
