import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useEffect, useState } from 'react'
import { Auth } from '../../contexts/allContext'
import From from './From/From'
import List from './List/List'
import classes from './PharmaOffers.module.css'

export default function PharmaOffers() {
    const [offers, setOffers] = useState([])
    const [show, setShow] = useState(true)
    const [pageSkip, setPageSkip] = useState(0)
    const [search, setSearch] = useState('')
    const [generic, setGeneric] = useState('')
    const [pharmaName, setPharmaName] = useState('')

    const [imgup, setImgup] = useState(false)
    const [loader, setLoader] = useState(false)

    const [pharma, setPharma] = useState([])

    const api = process.env.REACT_APP_API_URL_V2
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${api}/pharmaceuticals-offer/?name=${search}&generic=${generic}&pharmaceutical=${pharmaName}&sort_order=desc&skip=${pageSkip}&limit=10`,
                    {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                const data = await response.json()

                if (response.ok) {
                    setOffers(data)
                    setLoader(false)
                }
            } catch {
                setOffers([])
            }
        }
        fetchData()
    }, [token, api, search, pageSkip, generic, pharmaName, imgup, loader])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/pharmaceuticals-offer/pharmaceuticals`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                setPharma(data)
            } catch {
                setPharma([])
            }
        }
        return fetchData()
    }, [])

    console.log(pharma)

    return (
        <div className={classes.list}>
            <div className={classes.wrapper}>
                <form action="">
                    {show && (
                        <div className={classes.searchBar}>
                            <div>
                                <input
                                    className={classes.searchField}
                                    type="text"
                                    placeholder="search medicine name"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                                <button className={classes.searchButton}>
                                    <FontAwesomeIcon icon={faSearch} />
                                </button>
                            </div>
                            <div>
                                <input
                                    className={classes.searchField}
                                    type="text"
                                    placeholder="search generic name"
                                    value={generic}
                                    onChange={(e) => setGeneric(e.target.value)}
                                />
                                <button className={classes.searchButton}>
                                    <FontAwesomeIcon icon={faSearch} />
                                </button>
                            </div>

                            {/* --------pharma name search--------- */}
                            <div>
                                <input
                                    className={classes.searchField}
                                    type="text"
                                    placeholder="search pharma name"
                                    value={pharmaName}
                                    onChange={(e) => setPharmaName(e.target.value)}
                                />
                                <button className={classes.searchButton}>
                                    <FontAwesomeIcon icon={faSearch} />
                                </button>
                            </div>

                            {/* ------------------------------------------- */}
                            {/* <div className={classes.lists}>
                                <input
                                    className={classes.searchField}
                                    autoComplete="on"
                                    list="suggestions"
                                    type="text"
                                    placeholder="search pharma name"
                                    value={pharmaName}
                                    onChange={(e) => setPharmaName(e.target.value)}
                                />
                                <datalist id="suggestions">
                                    {pharma[1]?.map((par, index) => (
                                        <option key={index}>{par.name}</option>
                                    ))}
                                </datalist>
                            </div> */}
                            {/* ------------------------------------------- */}
                        </div>
                    )}
                </form>
                <div className={classes.btngrp}>
                    {show ? (
                        <button className={classes.button} onClick={(e) => setShow(!show)}>
                            + Add Offers
                        </button>
                    ) : (
                        <button className={classes.button} onClick={(e) => setShow(!show)}>
                            Pharma Offer Lists
                        </button>
                    )}
                </div>
            </div>
            {show ? (
                <List
                    offers={offers}
                    pageSkip={pageSkip}
                    setPageSkip={setPageSkip}
                    setImgup={setImgup}
                    loader={loader}
                    setLoader={setLoader}
                />
            ) : (
                <From />
            )}
        </div>
    )
}
