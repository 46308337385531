import { useState, useEffect, useContext } from 'react'
import { Auth, UserInfo } from '../../contexts/allContext'
import { api } from '../../utils/apiToken'
import ClinicList from './ClinicList/ClinicList'
import classes from './Clinics.module.css'

export default function Clinics() {
    const [clinics, setClinics] = useState([])
    const [pendingClinics, setPendingClinics] = useState([])
    const [pageSkip, setPageSkip] = useState(0)
    const [loading, setLoading] = useState(true)

    const { stateAuth } = useContext(Auth)
    const { stateUser } = useContext(UserInfo)
    const userInfo = stateUser.info
    const token = stateAuth.token

    useEffect(() => {
        let fetchData = async () => {
            try {
                const response = await fetch(`${api}/admin/active-clinic?skip=${pageSkip}&limit=10`, {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    method: 'GET',
                })
                const data = await response.json()

                if (response.ok) {
                    setClinics(data)
                    setLoading(false)
                }
            } catch (e) {
                setClinics([])
            }
        }
        fetchData()
    }, [token, pageSkip])

    useEffect(() => {
        let fetchData = async () => {
            try {
                const response = await fetch(`${api}/admin/inactive-clinic?skip=${pageSkip}&limit=10`, {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    method: 'GET',
                })
                const data = await response.json()

                if (response.ok) {
                    setPendingClinics(data)
                    setLoading(false)
                }
            } catch (e) {
                setPendingClinics([])
            }
        }
        fetchData()
    }, [token, pageSkip])

    return (
        <div>
            {userInfo.role_name === 'admin' || userInfo.role_name === 'moderator' || userInfo.role_name === 'crm' ? (
                <ClinicList
                    clinics={clinics}
                    pendingClinics={pendingClinics}
                    pageSkip={pageSkip}
                    setPageSkip={setPageSkip}
                    loading={loading}
                />
            ) : (
                <div className={classes.modWrapper}>
                    <div className={classes.mod}>Employee Can't Access This Tab!</div>
                </div>
            )}
        </div>
    )
}
