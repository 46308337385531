import React from 'react'
import { LabTest, Layout } from '../components'

export default function LabTestPage() {
    return (
        <div>
            <Layout>
                <LabTest />
            </Layout>
        </div>
    )
}
