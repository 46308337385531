import { useContext, useEffect, useState } from 'react'
import { Auth } from '../../contexts/allContext'
import { api } from '../../utils/apiToken'
import PlanForm from './PlanForm/PlanForm'
import PlanList from './PlanList/PlanList'

export default function HealthPlan() {
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    const [plans, setPlans] = useState([])
    const [listOpen, setListOpen] = useState(true)
    const [formOpen, setFormOpen] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/health-plan/`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()

                if (response.ok) {
                    setPlans(data)
                }
            } catch {
                setPlans([])
            }
        }
        return fetchData()
    }, [token])

    return (
        <div>
            {formOpen && <PlanForm setFormOpen={setFormOpen} setListOpen={setListOpen} />}
            {listOpen && <PlanList setFormOpen={setFormOpen} setListOpen={setListOpen} plans={plans} />}
        </div>
    )
}
