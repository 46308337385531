import { useContext, useEffect, useState } from 'react'
import { Auth } from '../../../contexts/allContext'
import { api } from '../../../utils/apiToken'
import DeviceForm from './DeviceForm/DeviceForm'
import DeviceList from './DeviceList/DeviceList'

export default function Device({ setCategoryOpen, setDeviceOpen }) {
    const [categories, setCategories] = useState([])
    const [devices, setDevices] = useState([])
    const [listOpen, setListOpen] = useState(true)
    const [formOpen, setFormOpen] = useState(false)
    const [pageSkip, setPageSkip] = useState(0)

    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/mediva/device/catagories/`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                setCategories(data)
            } catch {
                setCategories([])
            }
        }
        return fetchData()
    }, [token])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/mediva/devices/?skip=${pageSkip}&limit=10`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                setDevices(data)
            } catch {
                setDevices([])
            }
        }
        return fetchData()
    }, [token, pageSkip])

    return (
        <div>
            {formOpen && <DeviceForm setFormOpen={setFormOpen} setListOpen={setListOpen} categories={categories} />}
            {listOpen && (
                <DeviceList
                    setFormOpen={setFormOpen}
                    setListOpen={setListOpen}
                    devices={devices}
                    setCategoryOpen={setCategoryOpen}
                    setDeviceOpen={setDeviceOpen}
                    pageSkip={pageSkip}
                    setPageSkip={setPageSkip}
                />
            )}
        </div>
    )
}
