import { useState, useContext, useEffect } from 'react'
import PDF from '../../../../assets/pdf.png'
import { Auth } from '../../../../contexts/allContext'
import { api } from '../../../../utils/apiToken'
import { toMonthNameShort } from '../../../../utils/date'
import ReportUpload from '../Upload/Upload'
import classes from './Fetch.module.css'

export default function Fetch({ title, address, id }) {
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token
    const [msg, setMsg] = useState([])

    const [img, setImg] = useState([])
    const [pdf, setPdf] = useState([])
    const [imageViewer, setImageViewer] = useState(false)
    const [number, setNumber] = useState()

    const popup = () => {
        setImageViewer(!imageViewer)
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/patient/reports/admin/img/${address}/${id}?skip=0&limit=6`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()

                if (response.ok) {
                    setImg(data)
                }
            } catch (e) {
                setImg([])
            }
        }
        fetchData()
    }, [token, msg, address, id])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/patient/reports/admin/pdf/${address}/${id}?skip=0&limit=6`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()

                if (response.ok) {
                    setPdf(data)
                }
            } catch {
                setPdf([])
            }
        }
        fetchData()
    }, [token, msg, address, id])

    const reportImgUrl = `${api}/images/${address}/`
    const reportPdfUrl = `${api}/pdf/${address}/`

    return (
        <div className={classes.fetch}>
            <div className={classes.container}>
                <div className={classes.Header}>
                    <p>{title}</p>
                    <ReportUpload msg={msg} setMsg={setMsg} address={address} id={id} />
                </div>

                <div className={classes.files}>
                    {img[1]?.map((report, index) => (
                        <div key={index}>
                            <div
                                onClick={() => {
                                    setNumber(index)
                                    popup()
                                }}>
                                <img
                                    src={report.bucket ? report.image_url : reportImgUrl + report.image_string}
                                    alt="file"
                                />
                                <p>
                                    <span>Prescription</span>.png
                                </p>
                                <p>{`${report?.created_at?.slice(8, 10)}-${toMonthNameShort(
                                    report?.created_at.slice(6, 7)
                                )}-${report?.created_at?.slice(0, 4)}`}</p>
                            </div>
                        </div>
                    ))}
                </div>

                {imageViewer && (
                    <div className={classes.previewContainer}>
                        <div className={classes.overlay} onClick={() => setImageViewer(false)}></div>
                        <div className={classes.Preview}>
                            <img
                                src={
                                    img[1][number].bucket
                                        ? img[1][number].image_url
                                        : reportImgUrl + img[1][number]?.image_string
                                }
                                alt="viewer"
                            />
                            <button onClick={popup} className={classes.closeBtn}>
                                x
                            </button>
                        </div>
                    </div>
                )}

                <div className={classes.files}>
                    {pdf[1]?.map((report, index) => (
                        <div className={classes.pdf} key={index}>
                            <a
                                href={report.bucket ? report.pdf_url : reportPdfUrl + report.pdf_string}
                                target="__blank">
                                <img src={PDF} alt="file" />
                                <p>
                                    <span>Prescription</span>.pdf
                                </p>
                                <p>{`${report?.created_at?.slice(8, 10)}-${toMonthNameShort(
                                    report?.created_at?.slice(6, 7)
                                )}-${report?.created_at?.slice(0, 4)}`}</p>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
