import Pic from '../../../../assets/notice.jpg'
import classes from './ActiveList.module.css'

export default function ActiveList({
    openClose,
    isOpen,
    noticeId,
    setIsOpen,
    notices,
    setActiveList,
    setInactiveList,
    userInfo,
}) {
    const activeInactive = () => {
        setActiveList(false)
        setInactiveList(true)
    }

    return (
        <div className={classes.allContainer}>
            <div className={classes.wrapper}>
                <button className={classes.button} onClick={() => activeInactive()}>
                    Inactive Notice
                </button>
                <button className={classes.button} onClick={() => openClose()}>
                    + Add Notice
                </button>
            </div>
            <div className={classes.wrapperNotice}>
                <div className={classes.container}>
                    {notices &&
                        notices.map((notice, index) =>
                            notice.status === true ? (
                                <div className={classes.containerFlex} key={index}>
                                    <div>
                                        <img src={Pic} alt="" />
                                        <span>{notice.created_at.slice(0, 10)}</span>
                                    </div>
                                    <div>
                                        <h6>
                                            <button
                                                onClick={userInfo.role_name === 'admin' ? () => setIsOpen(index) : ''}>
                                                {notice.title}
                                            </button>
                                        </h6>
                                        <p>{notice.body}</p>

                                        {isOpen === index && (
                                            <div key={() => index} className={classes.containerPopup}>
                                                <div className={classes.formWrapper}>
                                                    <div className={classes.close} onClick={() => setIsOpen(false)}>
                                                        &times;
                                                    </div>

                                                    <div className={classes.buttonContainer}>
                                                        <button
                                                            className={classes.button}
                                                            onClick={() => noticeId(notice.id)}>
                                                            Inactive Notice
                                                        </button>

                                                        <button
                                                            className={classes.button}
                                                            onClick={() => setIsOpen(false)}>
                                                            No
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                ''
                            )
                        )}
                </div>
            </div>
        </div>
    )
}
