import { faTrashArrowUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import classes from './MedicineAdd.module.css'

export default function MedicineAdd({ lines, setLines, index }) {
    const [searchMedicine, setSearchMedicine] = useState('')
    const [allMedicines, setAllMedicines] = useState([])
    const [info, setInfo] = useState({})
    const [discount, setDiscount] = useState(0)
    const [total, setTotal] = useState()
    const [price, setPrice] = useState()
    const [hide, setHide] = useState(false)

    const api = process.env.REACT_APP_API_URL

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/medicines/?search_medicine=${searchMedicine}&skip=0&limit=90`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                const data = await response.json()
                if (response.ok) {
                    setAllMedicines(data)
                }
            } catch {
                setAllMedicines([])
            }
        }
        return fetchData()
    }, [api, searchMedicine])

    const medicineHandle = (searchMedicine) => {
        if (searchMedicine.length > 1) {
            setHide(true)
        }
        if (searchMedicine.length < 1) {
            setHide(false)
        }
        setSearchMedicine(searchMedicine)
    }

    const changeQuantity = (value) => {
        let mainData = lines
        mainData[index].quantity = value
        mainData[index].unit_price_tp = 0
        mainData[index].unit_price_mrp = price
        mainData[index].total_mrp = Math.ceil(mainData[index].quantity * price)

        mainData[index].name = info?.name
        mainData[index].generic = info?.generic
        mainData[index].strength = info?.strength
        mainData[index].form = info?.form
        mainData[index].pharmaceuticals = info?.pharmaceuticals

        setLines([...mainData])
    }

    useEffect(() => {
        let mainData = lines
        mainData[index].unit_price_mrp = price
        mainData[index].total_mrp = Math.ceil(mainData[index].quantity * price)
        mainData[index].unit_discount_percent = discount
        mainData[index].total = Math.ceil(mainData[index].total_mrp - mainData[index].total_mrp * (discount / 100))

        setTotal(mainData[index].total)
    }, [discount, lines, index, price, total])

    const removeItem = (index) => {
        setLines([...lines.slice(0, index), ...lines.slice(index + 1, lines.length)])
    }

    // const handleRemoveItem = (index) => {
    //     setLines(...lines.filter((item, i) => i !== index))
    // }

    return (
        <div className={classes.medWrapper}>
            <div className={`${classes.formGrid} ${classes.formMargin}`}>
                <label>
                    <span>
                        Medicine <span className={classes.starSign}>*</span>
                    </span>
                    <input
                        type="text"
                        value={searchMedicine}
                        onChange={(e) => medicineHandle(e.target.value)}
                        placeholder="search medicine"
                    />
                </label>
                {hide && (
                    <div className={classes.optAll}>
                        {info.name !== searchMedicine
                            ? allMedicines &&
                              allMedicines.map((info, i) => (
                                  <div className={classes.optSelect} key={i}>
                                      <div
                                          onClick={() => {
                                              setInfo(info)
                                              setSearchMedicine(info.name)
                                              setPrice(info.unit_price)
                                              setAllMedicines([])
                                              setHide(false)
                                          }}>
                                          <div value={info.id}>
                                              <span style={{ fontSize: '10px' }}>{i + 1}.</span>{' '}
                                              <span style={{ fontSize: '14px' }}>
                                                  {info.name} - {info.unit_price}৳
                                              </span>
                                              <br />
                                              <span style={{ fontSize: '12px' }}>
                                                  {info.generic} | {info.form} | {info.strength} <br />
                                              </span>
                                              <span style={{ fontSize: '10px' }}>{info.pharmaceuticals}</span>
                                          </div>
                                      </div>
                                  </div>
                              ))
                            : null}
                    </div>
                )}
                <label>
                    <span>Strength</span>
                    <span className={classes.fetchLabel}>
                        {info?.strength} <p>.</p>
                    </span>
                </label>
                <label>
                    <span>Form</span>
                    <span className={classes.fetchLabel}>
                        {info?.form} <p>.</p>
                    </span>
                </label>
                <label>
                    <span>Unit Price</span>
                    <input
                        type="number"
                        value={price}
                        onChange={(e) => setPrice(parseFloat(e.target.value))}
                        onBlur={(e) => setLines([...lines])}
                        min={0}
                        required
                        step="any"
                    />
                </label>
                <label>
                    <span>
                        Quantity <span className={classes.starSign}>*</span>
                    </span>
                    <input
                        type="number"
                        value={lines[index].quantity}
                        onChange={(e) => changeQuantity(parseInt(e.target.value))}
                        onBlur={(e) => setLines([...lines])}
                        min={1}
                        required
                    />
                </label>
                <label>
                    <span>MRP</span>
                    <span className={classes.fetchLabel}>
                        {isNaN(lines[index].total_mrp) !== true ? lines[index].total_mrp : ''} <p>.</p>
                    </span>
                </label>
                <label>
                    <span>
                        Discount % <span className={classes.starSign}>*</span>
                    </span>
                    <input
                        type="number"
                        value={discount}
                        placeholder="%"
                        onChange={(e) => setDiscount(parseInt(e.target.value))}
                        onBlur={(e) => setLines([...lines])}
                        min={0}
                        max={100}
                    />
                </label>
                <label>
                    <span>Total</span>
                    <span className={classes.fetchLabel}>
                        {isNaN(total) !== true ? Math.ceil(total) : ''} <p>.</p>
                    </span>
                </label>

                <button className={classes.cross} onClick={() => removeItem(index)}>
                    <FontAwesomeIcon icon={faTrashArrowUp} />
                </button>
            </div>
        </div>
    )
}
