import ReactLoading from 'react-loading'
import classes from './Loading.module.css'

export default function Loading() {
    return (
        <div className={classes.loading}>
            <ReactLoading type="spinningBubbles" color="var(--secondary)" height={44} width={44} />
        </div>
    )
}
