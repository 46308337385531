// full date with month name
export const toDateMonthName = (dateIn, type) => {
    const updateDate = dateIn.slice(0, 10)
    const [year, month, day] = updateDate.split('-')

    const date = new Date()
    date.setMonth(month - 1)
    const monthName = date.toLocaleString('en-US', {
        // type = 'short' or 'long'
        month: type,
    })

    return day + '-' + monthName + '-' + year
}

// month name from number
export const toMonthNameShort = (monthNumber) => {
    const date = new Date()
    date.setMonth(monthNumber - 1)

    return date.toLocaleString('en-US', {
        month: 'short',
    })
}
export const toMonthNameLong = (monthNumber) => {
    const date = new Date()
    date.setMonth(monthNumber - 1)

    return date.toLocaleString('en-US', {
        month: 'long',
    })
}

// global to 12 hours timing
export const formatAmPm = (timeIn) => {
    const updateTime = timeIn.slice(11, 16)
    const [hours, minutes] = updateTime.split(':')

    const period = hours >= 12 ? 'PM' : 'AM'
    const hours12 = hours % 12 || 12
    const full12 = hours12.toString().padStart(2, '0')

    return full12 + ':' + minutes + ' ' + period
}

// current date time
function twoDigits(num) {
    return num.toString().padStart(2, '0')
}
function formatDate(date) {
    return (
        [date.getFullYear(), twoDigits(date.getMonth() + 1), twoDigits(date.getDate())].join('-') +
        'T' +
        [twoDigits(date.getHours()), twoDigits(date.getMinutes())].join(':')
    )
}
export const currentDate = formatDate(new Date()).slice(0, 10)
export const currentDateTime = formatDate(new Date())

export const calenderDate = (monthNumber) => {
    return formatDate(monthNumber)
}

// date convert
export const dateConvert = (inputDate) => {
    const date = new Date(inputDate)
    if (!inputDate || isNaN(date)) {
        return null
    }

    return formatDate(date).slice(0, 10)
}

// firstday and lastday of current month
const date = new Date()

const fDay = new Date(date.getFullYear(), date.getMonth(), 2).toISOString()
const lDay = new Date(date.getFullYear(), date.getMonth() + 1, 1).toISOString()
export const firstDay = fDay.slice(0, 10)
export const lastDay = lDay.slice(0, 10)

// firstday and lastday of previous month
const fDayPrev = new Date(date.getFullYear(), date.getMonth() - 1, 2).toISOString()
const lDayPrev = new Date(date.getFullYear(), date.getMonth() + 0, 1).toISOString()
export const firstDayPrevious = fDayPrev.slice(0, 10)
export const lastDayPrevious = lDayPrev.slice(0, 10)
