import { useContext, useState } from 'react'
import { Auth } from '../../../../contexts/allContext'
import { api } from '../../../../utils/apiToken'
import classes from './PatientType.module.css'

export default function PatientType({ index, setPopupType, patient, corporates }) {
    const [companyId, setCompanyId] = useState()

    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    const handleSubmit = async (e) => {
        e.preventDefault()

        const details = {
            corporate_id: companyId,
            users_id: patient.id,
            department: null,
        }
        let patchFetch = await fetch(`${api}/corporate/user`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(details),
        })

        if (patchFetch.ok) {
            setPopupType(false)
            alert(`Success`)
        }
    }

    return (
        <div key={() => index} className={classes.container}>
            <div className={classes.formWrapper}>
                <div className={classes.close} onClick={() => setPopupType(false)}>
                    &times;
                </div>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className={classes.innerWrap}>
                        <label className={classes.gap}>
                            Change Type of - <span>{patient.name}</span>
                            <select
                                required
                                className={classes.select}
                                onChange={(e) => setCompanyId(parseInt(e.target.value))}>
                                <option value="">Select Company</option>
                                {corporates &&
                                    corporates.map((name, i) => (
                                        <option key={i} value={name.id}>
                                            {name.name}
                                        </option>
                                    ))}
                            </select>
                        </label>
                    </div>
                    <button className={classes.button} type="submit">
                        SUBMIT
                    </button>
                </form>
            </div>
        </div>
    )
}
