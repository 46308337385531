import classes from './HealthPlan.module.css'

export default function HealthPlan({ plans, setPlanId, setTotalAmount, setPayableAmount }) {
    const handlePlanChange = (e) => {
        const selectedPlanId = parseInt(e.target.value)
        const selectedPlan = plans.find((plan) => plan.id === selectedPlanId)
        if (selectedPlan) {
            setPlanId(selectedPlanId)
            setTotalAmount(parseInt(selectedPlan.fee))
            setPayableAmount(parseInt(selectedPlan.fee))
        }
    }

    return (
        <div className={classes.planWrapper}>
            <label>
                Select Plan <span className={classes.starSign}>*</span>
                <select className={classes.select} onChange={handlePlanChange} required>
                    <option value="">Select</option>
                    {plans &&
                        plans
                            .filter((plan) => plan.plan_type === 'health_plan')
                            .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
                            .map((plan, i) => (
                                <option key={i} value={plan.id}>
                                    {plan.name} - [{Math.floor(plan.days / 30) === 0 ? 1 : Math.floor(plan.days / 30)}{' '}
                                    {plan.days / 30 > 1 ? 'Months' : Math.floor(plan.days / 30) === 0 ? 'Day' : 'Month'}
                                    ] [{plan.fee}TK]
                                </option>
                            ))}
                </select>
            </label>
        </div>
    )
}
