import { faLeaf, faThumbtack } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import classes from './CorporateForm.module.css'

export default function CorporateForm({ api, token, setListOpen, setFormOpen }) {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [type, setType] = useState('')
    const [district, setDistrict] = useState('')
    const [address, setAddress] = useState('')
    const [detail, setDetail] = useState('')

    const [personName, setPersonName] = useState('')
    const [personPhone, setPersonPhone] = useState('')
    const [personEmail, setPersonEmail] = useState('')

    const [alertFetch, setAlertFetch] = useState(false)
    const [fetchInfo, setFetchInfo] = useState({})

    const [hidePerson, setHidePerson] = useState(false)
    const [selectPerson, setSelectPerson] = useState('')

    const openClose = () => {
        setListOpen(true)
        setFormOpen(false)
    }

    const refreshPage = () => {
        window.location.reload()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const details = {
            name,
            type,
            phone,
            email,
            district,
            detail,
            detail_address: address,
            contact_person: personName,
            contact_person_phone: personPhone,
            contact_person_email: personEmail,
        }
        let postFetch = await fetch(`${api}/corporate/`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(details),
        })

        let contextFetch = await postFetch.json()
        if (postFetch.ok) {
            refreshPage()
        } else {
            setAlertFetch(true)
            setFetchInfo(contextFetch)
            setTimeout(function () {
                setAlertFetch(false)
            }, 3000)
        }
    }

    useEffect(() => {
        if (selectPerson === 'yes') {
            setHidePerson(true)
        } else {
            setHidePerson(false)
        }
    }, [selectPerson])

    return (
        <div>
            <div className={classes.wrapper}>
                <button className={classes.button} onClick={() => openClose()}>
                    Company List
                </button>
            </div>
            <div className={classes.formWrapper}>
                <FontAwesomeIcon className={classes.icon} icon={faThumbtack} />
                <div className={classes.logo}>ADD COMPANY</div>

                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className={classes.section}>
                        <FontAwesomeIcon className={classes.leaf} icon={faLeaf} />
                        Details Info
                    </div>
                    <div className={classes.innerWrap}>
                        <div className={classes.formGrid}>
                            <label>
                                Full Name
                                <input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    minLength={3}
                                    required
                                />
                            </label>
                            <div className={`${classes.formGrid} ${classes.formMargin}`}>
                                <label>
                                    Phone
                                    <input
                                        type="tel"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        minLength={11}
                                        maxLength={11}
                                        pattern="[0][1][0-9]{9}"
                                        required
                                    />
                                </label>
                                <label>
                                    Email
                                    <input
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </label>
                            </div>
                        </div>
                        <div className={classes.formGrid}>
                            <label>
                                Address
                                <input
                                    type="text"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    minLength={4}
                                    required
                                />
                            </label>
                            <div className={`${classes.formGrid} ${classes.formMargin}`}>
                                <label>
                                    Type
                                    <select
                                        className={classes.select}
                                        onChange={(e) => setType(e.target.value)}
                                        required>
                                        <option value="">Select</option>
                                        <option value="corporate">Corporate</option>
                                    </select>
                                </label>
                                <label>
                                    District
                                    <select
                                        className={classes.select}
                                        onChange={(e) => setDistrict(e.target.value)}
                                        required>
                                        <option value="">Select</option>
                                        <option value="dhaka">Dhaka</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                        <label>
                            Details
                            <textarea
                                type="text"
                                rows={4}
                                placeholder="Details if any"
                                value={detail}
                                onChange={(e) => setDetail(e.target.value)}
                            />
                        </label>
                        <label>
                            Contact Person If Any
                            <select className={classes.select} onChange={(e) => setSelectPerson(e.target.value)}>
                                <option value="">Select</option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                        </label>
                        {hidePerson && (
                            <div className={classes.formGrid}>
                                <label>
                                    Contact Person Name
                                    <input
                                        type="text"
                                        value={personName}
                                        onChange={(e) => setPersonName(e.target.value)}
                                        minLength={3}
                                        required
                                    />
                                </label>
                                <div className={`${classes.formGrid} ${classes.formMargin}`}>
                                    <label>
                                        Mobile
                                        <input
                                            type="tel"
                                            value={personPhone}
                                            onChange={(e) => setPersonPhone(e.target.value)}
                                            minLength={11}
                                            maxLength={11}
                                            pattern="[0][1][0-9]{9}"
                                            required
                                        />
                                    </label>
                                    <label>
                                        Email
                                        <input
                                            type="email"
                                            value={personEmail}
                                            onChange={(e) => setPersonEmail(e.target.value)}
                                        />
                                    </label>
                                </div>
                            </div>
                        )}
                    </div>

                    <button className={classes.button} type="submit">
                        SUBMIT
                    </button>
                    <div> {alertFetch && <p className={classes.alertMessage}>{fetchInfo.context}</p>}</div>
                </form>
            </div>
        </div>
    )
}
