import { faEdit, faSearch, faTrashArrowUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import Pagination from '../../Pagination/Pagination'
import ListCount from '../../Resource/ListCount/ListCount'
import Table from '../../Resource/Table/Table'
import Edit from './Edit/Edit'
import classes from './List.module.css'
import Popup from './Popup/Popup'

export default function List({
    labTests,
    setPageSkip,
    pageSkip,
    labName,
    setLabName,
    providerName,
    setProviderName,
    setIsOpen,
}) {
    let sl = 0

    const [popup, setPopup] = useState(false)
    const [edit, setEdit] = useState(false)

    return (
        <div className={classes.list}>
            <div className={classes.wrapper}>
                <div className={classes.searchbox}>
                    <form action="">
                        <input
                            className={classes.searchField}
                            type="text"
                            placeholder="Search Lab Test"
                            value={labName}
                            onChange={(e) => setLabName(e.target.value)}
                        />
                        <button className={classes.searchButton}>
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                    </form>
                    <form action="">
                        <input
                            className={classes.searchField}
                            type="text"
                            placeholder="Search Lab Provider"
                            value={providerName}
                            onChange={(e) => setProviderName(e.target.value)}
                        />
                        <button className={classes.searchButton}>
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                    </form>
                </div>
                <button className={classes.button} onClick={(e) => setIsOpen(true)}>
                    + Add Lab Test
                </button>
            </div>
            <ListCount title="Lab Test List" results={labTests} />
            <Table>
                <thead>
                    <tr>
                        <th>Sl</th>
                        <th>Lab Test Name</th>
                        <th>Provider Name</th>
                        <th>Mrp</th>
                        <th>Discount</th>
                        <th>Price</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {labTests &&
                        labTests[1]?.map((item, index) => (
                            <tr className={classes.row} key={index}>
                                <td>{(sl = sl + 1) + pageSkip}</td>
                                <td>{item.LabTest?.name}</td>
                                <td>{item.TestProvider?.name}</td>
                                <td>{item.LabTest?.mrp}</td>
                                <td>{item.LabTest?.discount}%</td>
                                <td>{item.LabTest?.price}</td>
                                <td className={classes.icons}>
                                    <FontAwesomeIcon icon={faEdit} onClick={() => setEdit(index)} />
                                    <FontAwesomeIcon icon={faTrashArrowUp} onClick={() => setPopup(index)} />
                                </td>
                                {edit === index && <Edit lab={item} setIsOpen={setEdit} />}
                                {popup === index && <Popup lab={item} setIsOpen={setPopup} />}
                            </tr>
                        ))}
                </tbody>
            </Table>

            <Pagination
                pageSkip={pageSkip}
                setPageSkip={setPageSkip}
                listItem={labTests[1]}
                pageAll={labTests[0]?.results}
                item="30"
                limit="30"
            />
        </div>
    )
}
