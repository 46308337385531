import { useContext, useEffect } from 'react'
import { Navigate, Outlet, useNavigate } from 'react-router-dom'
import { Auth, UserInfo } from '../contexts/allContext'

export default function ProtectedRoute({ preventUser = [] }) {
    const { stateAuth, dispatchAuth } = useContext(Auth)
    const { dispatchUser } = useContext(UserInfo)

    const api = process.env.REACT_APP_API_URL
    let token = stateAuth.token
    const navigate = useNavigate()

    useEffect(() => {
        let userFetch = async () => {
            const response = await fetch(`${api}/auth`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                method: 'GET',
            })

            const data = await response.json()

            if (response.ok) {
                dispatchAuth({ type: 'token', payload: token })
                dispatchUser({ type: 'set', payload: data })
            } else {
                dispatchAuth({ type: 'remove' })
                dispatchUser({ type: 'remove' })
            }

            //role check
            if (preventUser.indexOf(data.role_name) !== -1) {
                dispatchAuth({ type: 'remove' })
                dispatchUser({ type: 'remove' })
                navigate('/login')
            }
        }

        try {
            userFetch()
        } catch (e) {
            dispatchAuth({ type: 'remove' })
            dispatchUser({ type: 'remove' })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, navigate])
    //dispatchUser, api, token, dispatchAuth, preventUser

    return stateAuth.auth === true ? <Outlet /> : <Navigate to="/login" />
}
