// full date with month name with GMT 6+
export const toDateMonthNameGMT6 = (dateIn, type) => {
    const userDate = new Date(dateIn)
    const newDate = new Date(userDate.getTime() + 6 * 60 * 60 * 1000)

    const options = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        timeZone: 'Etc/GMT-6',
    }

    const formattedDate = newDate.toLocaleString('en-US', options)
    const [month, day, year] = formattedDate?.split('/')

    const date = new Date()
    date.setMonth(month - 1)
    const monthName = date.toLocaleString('en-US', {
        // type = 'short' or 'long'
        month: type,
    })

    return day + '-' + monthName + '-' + year
}

// global to 12 hours timing with GMT 6+
export const formatAmPmGMT6 = (timeIn) => {
    const userTime = new Date(timeIn)
    const newTime = new Date(userTime.getTime() + 6 * 60 * 60 * 1000)

    const options = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: 'Etc/GMT-6',
    }

    const formattedTime = newTime.toLocaleString('en-US', options)
    return formattedTime
}
