import { useContext, useEffect, useState } from 'react'
import { Auth } from '../../contexts/allContext'
import Form from './Form/Form'
import List from './List/List'

export default function Manual() {
    const [manuals, setManuals] = useState([])
    const [listOpen, setListOpen] = useState(true)
    const [formOpen, setFormOpen] = useState(false)

    const api = process.env.REACT_APP_API_URL
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/admin/s3/manuals`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()

                if (response.ok) {
                    setManuals(data)
                }
            } catch {
                setManuals([])
            }
        }
        fetchData()
    }, [token, api])

    return (
        <div>
            {formOpen && <Form api={api} token={token} setListOpen={setListOpen} setFormOpen={setFormOpen} />}
            {listOpen && <List setListOpen={setListOpen} setFormOpen={setFormOpen} manuals={manuals} />}
        </div>
    )
}
