import { faFlask, faLaptopMedical, faTruck, faUserNurse } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../../..../../../../../assets/logo.png'
import { numToWords } from '../../../../../../utils/numToWord'
import classes from './Pdf.module.css'

export const Pdf = React.forwardRef((props, ref) => {
    const { pharmacy, list } = props.props
    const payment = list?.PaymentPayLog
    const issuer = list?.Issuer

    return (
        <div ref={ref} className={classes.bodyPdf}>
            <div className={classes.page}>
                <div className={classes.arrow}>
                    <p>Invoice</p>
                </div>
                <header className={classes.clear}>
                    <div className={classes.logo}>
                        <img src={Logo} alt="" />
                    </div>
                    <div className={classes.company}>
                        {/* <div className={classes.name}>HEALTHx Pharmacy</div> */}
                        <div>House 06, Road 2, Banani</div>
                        <div>+88 01322658481</div>
                        <div>
                            <Link to="#">contact@healthx.com.bd</Link>
                        </div>
                    </div>
                </header>

                <div className={classes.gridBox}>
                    <div className={classes.itemBox}>
                        <div className={`${classes.payment} ${classes.clear}`}>
                            <div className={classes.client}>
                                <div className={classes.to}>INVOICE TO:</div>
                                <div className={classes.name}>{pharmacy.name}</div>
                                <div className={classes.mobile}>{pharmacy.contact_phone}</div>
                                <div className={classes.address}>{pharmacy.detail_address}</div>
                            </div>
                            <div className={classes.invoice}>
                                <div className={classes.name}>INVOICE: {payment.trx_ref_id}</div>
                                <div className={classes.serviceName}>By: {issuer.name}</div>
                            </div>
                        </div>

                        <table className={classes.tablePdf}>
                            <tr>
                                <th className={classes.qty}>Payment Amount</th>
                                <th className={classes.qty}>Payment Method</th>
                                <th className={classes.unit}>Trx</th>
                                <th className={classes.qty}>Payment Date</th>
                                <th className={classes.qty}>Payment by</th>
                            </tr>
                            <tr>
                                <td className={classes.desc}>৳{payment.payment_amount}</td>
                                <td className={classes.desc}>{payment.payment_method}</td>
                                <td className={classes.desc}>{payment.trx_ref_id}</td>
                                <td className={classes.desc}>{payment.payment_date?.slice(0, 10)}</td>
                                <td className={classes.desc}>{issuer.name}</td>
                            </tr>
                        </table>

                        <div className={classes.amountFlex}>
                            <div>
                                <div className={classes.notices}>
                                    <div>Payment:</div>
                                    <div className={classes.notice}>{payment?.payment_method}</div>
                                </div>
                            </div>
                            <table className={classes.amount}>
                                <tr>
                                    <td>Subtotal: </td>
                                    <td>{payment.payment_amount} TK</td>
                                </tr>
                                <tr>
                                    <td>Total Paid Amount: </td>
                                    <td>
                                        <span>{payment.payment_amount} TK</span>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div className={classes.note}>Remarks : {payment?.ServiceOrder?.remarks}</div>
                        <div className={classes.amountWord}>
                            <div>
                                In Words: <span>{numToWords(payment.payment_amount)}Taka Only</span>
                            </div>
                        </div>

                        <div className={classes.bgLogo}>
                            <img src={Logo} alt="" />
                        </div>
                    </div>

                    <div className={classes.boxAll}>
                        <div className={classes.text}>
                            <p>For Any Queries or Support: Please Contact at 01322658481</p>
                            {/* <span>Thanks for choosing us! Stay healthy!</span> */}
                        </div>
                        <div className={classes.service}>
                            <div style={{ fontWeight: '600' }}>Our Services:</div>
                            <div>
                                <FontAwesomeIcon icon={faLaptopMedical} className={classes.icon} />
                                Tele Health
                            </div>
                            <div>|</div>
                            <div>
                                <FontAwesomeIcon icon={faTruck} className={classes.icon} />
                                Medicine Delivery
                            </div>
                            <div>|</div>
                            <div>
                                <FontAwesomeIcon icon={faFlask} className={classes.icon} />
                                Sample Collection (Blood/Urine)
                            </div>
                            <div>|</div>
                            <div>
                                <FontAwesomeIcon icon={faUserNurse} className={classes.icon} />
                                Nursing/Attendant
                            </div>
                        </div>
                        <div className={classes.devlop}>Developed by Healthx</div>
                    </div>
                </div>
            </div>
        </div>
    )
})
