import { useState, useEffect, useContext } from 'react'
import { Auth } from '../../../contexts/allContext'
import { api } from '../../../utils/apiToken'
import { toMonthNameShort } from '../../../utils/date'
import { LineChart } from '../../Chart'
import { Number } from './index'

const Weight = ({ id }) => {
    const [weight, setWeight] = useState()
    const [dataWeight, setDataWeight] = useState([])

    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    const submit = async (e) => {
        e.preventDefault()

        if (weight !== null && weight !== 0) {
            let rbsFetch = await fetch(`${api}/admin/patient/indicator?user_id=${id}`, {
                method: 'POST',
                headers: {
                    Accept: 'appllication/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    key: 'weight',
                    unit: 'kg',
                    slot_flt4: weight,
                }),
            })

            if (rbsFetch.ok) {
                setWeight(0)
            }
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/admin/patient/indicator/weight/${id}`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                setDataWeight(data)
            } catch {
                setDataWeight([])
            }
        }
        return fetchData()
    }, [token, weight, id])

    let data = {
        labels: [
            ...dataWeight
                .map(
                    (elm) =>
                        `${elm.created_at.slice(8, 10)}-${toMonthNameShort(
                            elm.created_at.slice(5, 7)
                        )}${elm.created_at.slice(2, 4)}`
                )
                .reverse(),
        ],
        datasets: [
            {
                label: 'Weight',
                data: [...dataWeight.map((elm) => elm.slot_flt4).reverse()],
                fill: true,
                backgroundColor: 'rgba(65, 173, 217, 0.2)',
                borderColor: 'rgba(65, 173, 217, 1)',
                lineTension: 0.4,
            },
        ],
    }

    return (
        <div>
            <Number
                title="Weight"
                place="Input Weight"
                unit="kg"
                st={weight}
                setSt={setWeight}
                smbt={submit}
                min={0}
                max={400}>
                <br />
                <LineChart data={data} />
                <br />
            </Number>
        </div>
    )
}

export default Weight
