import { faLeaf, faThumbTack } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classes from './Form.module.css'

export default function Form({ children, title, btnTitle = '', onSubmit, onClick }) {
    return (
        <div className={classes.container}>
            <FontAwesomeIcon className={classes.icon} icon={faThumbTack} />
            <div className={classes.title}>{title}</div>

            <div className={classes.section}>
                <FontAwesomeIcon className={classes.leaf} icon={faLeaf} />
                Details Info
            </div>

            {btnTitle ? (
                <>
                    <div className={classes.inner}>{children}</div>
                    <button onClick={onClick}>{btnTitle}</button>
                </>
            ) : (
                <form onSubmit={onSubmit}>
                    <div className={classes.inner}>{children}</div>
                    <button type="submit">SUBMIT</button>
                </form>
            )}
        </div>
    )
}
