import { useContext, useState } from 'react'
import districtJson from '../../../../../../config/locations/bd-districts.json'
import divisionJson from '../../../../../../config/locations/bd-divisions.json'
import upazilaJson from '../../../../../../config/locations/bd-upazilas.json'
import { Auth, UserInfo } from '../../../../../../contexts/allContext'
import { nameFromDistrictId, nameFromDivisionId } from '../../../../../../utils/location'
import Loader from '../../../../../PharmaOffers/List/Loader/Loader'
import classes from './Update.module.css'

export default function Update({ index, setIsOpen, list, loader, setLoader }) {
    const api = process.env.REACT_APP_API_URL_V2

    const { stateUser } = useContext(UserInfo)
    const userInfo = stateUser.info
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    const [picture, setPicture] = useState()
    const [pharmacyName, setPharmacyName] = useState('')
    const [pharmacyEmail, serPharmacyEmail] = useState('')
    const [pharmacyPhone, setPharmacyPhone] = useState('')
    const [division, setDivision] = useState('')
    const [district, setDistrict] = useState('')
    const [subDistrict, setSubDistrict] = useState('')
    const [pharmacyAddress, serPharmacyAddress] = useState('')
    const [drugLicense, setDrugLicense] = useState('')
    const [tradeLicense, setTradeLicense] = useState('')

    const refreshPage = () => {
        window.location.reload()
    }

    //Pharmacy details update
    const updatePlan = async (e) => {
        e.preventDefault()

        const info = {
            name: pharmacyName || list.Pharmacy?.name,
            contact_email: pharmacyEmail || list.Pharmacy?.contact_email,
            contact_phone: pharmacyPhone || list.Pharmacy?.contact_phone,
            division: nameFromDivisionId(String(division), divisionJson.divisions),
            district: nameFromDistrictId(String(district), districtJson.districts),
            sub_district: subDistrict || list.Pharmacy.sub_district,
            detail_address: pharmacyAddress || list.Pharmacy?.detail_address,
            trade_license: tradeLicense || list.Pharmacy?.trade_license,
            drug_license: drugLicense || list.Pharmacy?.drug_license,
        }

        const response = await fetch(`${api}/partner-pharmacy/${list.Pharmacy?.id}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(info),
        })

        if (response.ok) {
            refreshPage()
        }
    }

    //pharmacy image upload
    const handleOnSubmitImage = async (e) => {
        e.preventDefault()

        if (picture) {
            const imgData = new FormData()
            imgData.append('picture', picture)

            try {
                const response = await fetch(
                    `${api}/partner-pharmacy/picture/upload?user_id=${list.User?.id}&pharmacy_id=${list.Pharmacy?.id}`,
                    {
                        method: 'PATCH',
                        headers: {
                            Accept: 'application/json',
                            type: 'image/jpeg',
                        },
                        body: imgData,
                    }
                )

                if (response.ok) {
                    // alert('Image Update Successful')
                    setLoader(true)
                } else {
                    alert('Something went wrong!')
                }
            } catch (error) {
                console.error('Error:', error)
                alert('Error: Something went wrong!')
            }
        } else {
            alert('No image selected!')
        }
    }

    return (
        <div key={() => index} className={classes.containerPopup}>
            <div className={classes.overlay} onClick={() => setIsOpen(false)}></div>
            <div className={classes.formWrapper}>
                <div className={classes.close} onClick={() => setIsOpen(false)}>
                    &times;
                </div>

                <div className={classes.viewall}>
                    <div className={classes.cardWrapper}>
                        <div className={classes.card}>
                            {loader ? (
                                <div className={classes.loader}>
                                    <Loader />
                                </div>
                            ) : (
                                <img alt="" src={list.Picture?.image_url} />
                            )}
                            <label>
                                Product Image
                                <input type="file" accept="image" onChange={(e) => setPicture(e.target.files[0])} />
                            </label>
                            {picture ? (
                                <div>
                                    <button className={classes.imgbtn} onClick={handleOnSubmitImage}>
                                        Update
                                    </button>
                                </div>
                            ) : (
                                <div>
                                    <button className={classes.imgbtndis} disabled>
                                        Update
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    <form className={classes.innerWrap} onSubmit={updatePlan}>
                        <div className={classes.formGrid}>
                            <label>
                                Pharmacy Name
                                <input
                                    type="text"
                                    defaultValue={list.Pharmacy?.name}
                                    name="pharmacyName"
                                    onChange={(e) => setPharmacyName(e.target.value)}
                                />
                            </label>
                            <label>
                                Pharmacy Email
                                <input
                                    type="text"
                                    defaultValue={list.Pharmacy?.contact_email}
                                    name="contact_email"
                                    onChange={(e) => serPharmacyEmail(e.target.value)}
                                />
                            </label>
                            <label>
                                Pharmacy Phone
                                <input
                                    type="number"
                                    defaultValue={list.Pharmacy?.contact_phone}
                                    name="contact_phone"
                                    onChange={(e) => setPharmacyPhone(e.target.value)}
                                />
                            </label>
                            <label>
                                Division
                                <select name="division" onChange={(e) => setDivision(e.target.value)}>
                                    <option selected disabled>
                                        {list.Pharmacy?.division}
                                    </option>
                                    {divisionJson.divisions.map((div) => (
                                        <option key={div.id} value={div.id}>
                                            {div.name}
                                        </option>
                                    ))}
                                </select>
                            </label>
                            <label>
                                District
                                <select onChange={(e) => setDistrict(e.target.value)}>
                                    <option selected disabled>
                                        {list.Pharmacy?.district}
                                    </option>
                                    {districtJson.districts
                                        .filter((item) => item.division_id === String(division))
                                        .map((dis) => (
                                            <option key={dis.id} value={dis.id}>
                                                {dis.name}
                                            </option>
                                        ))}
                                </select>
                            </label>
                            <label>
                                Sub District
                                <select onChange={(e) => setSubDistrict(e.target.value)}>
                                    <option selected disabled>
                                        {list.Pharmacy?.sub_district}
                                    </option>
                                    {upazilaJson.upazilas
                                        .filter((item) => item.district_id === String(district))
                                        .map((upa) => (
                                            <option key={upa.id} value={upa.name}>
                                                {upa.name}
                                            </option>
                                        ))}
                                </select>
                            </label>
                            <label>
                                Trade License
                                <input
                                    type="text"
                                    name="trade_license"
                                    defaultValue={list.Pharmacy?.trade_license}
                                    onChange={(e) => setTradeLicense(e.target.value)}
                                />
                            </label>
                            <label>
                                Drug License
                                <input
                                    type="text"
                                    name="drug_license"
                                    defaultValue={list.Pharmacy?.drug_license}
                                    onChange={(e) => setDrugLicense(e.target.value)}
                                />
                            </label>
                        </div>
                        <label className={classes.textarea}>
                            Address
                            <textarea
                                defaultValue={list.Pharmacy?.detail_address}
                                name="pharmacyAddress"
                                onChange={(e) => serPharmacyAddress(e.target.value)}></textarea>
                        </label>
                        <button className={classes.btn} type="submit">
                            Update
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}
