import { faLeaf, faThumbtack } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import Loading from '../../Loading/Loading'
import classes from './Form.module.css'

export default function Form({ api, token, setListOpen, setFormOpen }) {
    const [title, setTitle] = useState('')
    const [date, setDate] = useState('')
    const [authority, setAuthority] = useState('')

    const [pdf, setPdf] = useState('')
    const [loading, setLoading] = useState(false)

    const openClose = () => {
        setListOpen(true)
        setFormOpen(false)
    }

    const refreshPage = () => {
        window.location.reload()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        const pdfData = new FormData()
        pdfData.append('file', pdf)

        const response = await fetch(`${api}/admin/s3/manual?title=${title}&date=${date}&authority=${authority}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                type: 'file/pdf',
                Authorization: `Bearer ${token}`,
            },
            body: pdfData,
        })

        const log = await response.json()

        if (response.ok) {
            refreshPage()
        } else {
            alert(log.context)
        }
    }

    return (
        <div>
            <div className={classes.wrapper}>
                <button className={classes.button} onClick={() => openClose()}>
                    Manual List
                </button>
            </div>
            <div className={classes.formWrapper}>
                <FontAwesomeIcon className={classes.icon} icon={faThumbtack} />
                <div className={classes.logo}>ADD MANUAL</div>

                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className={classes.section}>
                        <FontAwesomeIcon className={classes.leaf} icon={faLeaf} />
                        Details Info
                    </div>
                    <div className={classes.innerWrap}>
                        <label>
                            File (PDF) <span>*</span>
                            <input type="file" onChange={(e) => setPdf(e.target.files[0])} required />
                        </label>
                        <label>
                            Title <span>*</span>
                            <input
                                type="text"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                minLength={3}
                                required
                            />
                        </label>

                        <div className={classes.formGrid}>
                            <label>
                                Last Updated <span>*</span>
                                <input type="text" value={date} onChange={(e) => setDate(e.target.value)} required />
                            </label>
                            <label>
                                Issuing Authority <span>*</span>
                                <input
                                    type="text"
                                    value={authority}
                                    onChange={(e) => setAuthority(e.target.value)}
                                    required
                                />
                            </label>
                        </div>
                    </div>

                    {loading ? (
                        <Loading />
                    ) : (
                        <button className={classes.button} type="submit">
                            SUBMIT
                        </button>
                    )}
                </form>
            </div>
        </div>
    )
}
