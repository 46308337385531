import { faLeaf, faThumbtack } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useState } from 'react'
import { Auth } from '../../../contexts/allContext'
import { api } from '../../../utils/apiToken'
import classes from './PatientForm.module.css'

export default function PatientForm({ setListOpen, setFormOpen }) {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [gender, setGender] = useState('')
    const [password, setPassword] = useState('')
    const [rePassword, setRePassword] = useState('')
    const [alert, setAlert] = useState(false)
    const [alertFetch, setAlertFetch] = useState(false)
    const [fetchInfo, setFetchInfo] = useState({})

    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    const openClose = () => {
        setListOpen(true)
        setFormOpen(false)
    }

    const refreshPage = () => {
        window.location.reload()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (password !== rePassword) {
            setAlert(true)
            setTimeout(function () {
                setAlert(false)
            }, 3000)
        } else {
            if (email.length > 3) {
                const details = {
                    name,
                    email,
                    phone,
                    role_name: 'patient',
                    sex: gender,
                    is_active: true,
                    password,
                }
                let postFetch = await fetch(`${api}/admin/patient/create`, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(details),
                })

                let contextFetch = await postFetch.json()
                if (postFetch.ok) {
                    refreshPage()
                } else {
                    setAlertFetch(true)
                    setFetchInfo(contextFetch)
                    setTimeout(function () {
                        setAlertFetch(false)
                    }, 3000)
                }
            } else {
                setEmail(`hx${phone.slice(2, 11)}@healthxbd.com`)
            }
        }
    }

    return (
        <div>
            <div className={classes.wrapper}>
                <button className={classes.button} onClick={() => openClose()}>
                    Patient List
                </button>
            </div>
            <div className={classes.formWrapper}>
                <FontAwesomeIcon className={classes.icon} icon={faThumbtack} />
                <div className={classes.logo}>ADD PATIENT</div>

                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className={classes.section}>
                        <FontAwesomeIcon className={classes.leaf} icon={faLeaf} />
                        Details Info
                    </div>
                    <div className={classes.innerWrap}>
                        <div className={classes.formGrid}>
                            <label>
                                Full Name
                                <input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    minLength={3}
                                    required
                                />
                            </label>
                            <label>
                                Gender
                                <select className={classes.select} onChange={(e) => setGender(e.target.value)} required>
                                    <option value="">Select</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                </select>
                            </label>
                        </div>
                        <div className={classes.formGrid}>
                            <label>
                                Mobile
                                <input
                                    type="tel"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    minLength={9}
                                    maxLength={18}
                                    required
                                />
                            </label>
                            <label>
                                Email
                                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </label>
                        </div>
                        <div className={classes.formGrid}>
                            <label>
                                Password
                                <input
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    minLength={4}
                                    required
                                />
                            </label>
                            <label>
                                Confirm Password
                                <input
                                    type="password"
                                    value={rePassword}
                                    onChange={(e) => setRePassword(e.target.value)}
                                    minLength={4}
                                    required
                                />
                            </label>
                        </div>
                    </div>
                    <button className={classes.button} type="submit">
                        SUBMIT
                    </button>
                    <div> {alert && <p className={classes.alertMessage}>Password not matched!</p>}</div>
                    <div> {alertFetch && <p className={classes.alertMessage}>{fetchInfo.context}</p>}</div>
                </form>
            </div>
        </div>
    )
}
