import { useState } from 'react'
import ActiveList from './ActiveList/ActiveList'
import PendingList from './PendingList/PendingList'

export default function MedicineList({
    search,
    setSearch,
    setIsOpenForm,
    medicines,
    pendingMedicines,
    pageSkip,
    setPageSkip,
    loading,
    setSuccess,
}) {
    const [openActive, setOpenActive] = useState(true)
    const [openPending, setOpenPending] = useState(false)

    return (
        <div>
            {openActive && (
                <ActiveList
                    search={search}
                    setSearch={setSearch}
                    setIsOpenForm={setIsOpenForm}
                    setOpenActive={setOpenActive}
                    setOpenPending={setOpenPending}
                    medicines={medicines}
                    pageSkip={pageSkip}
                    setPageSkip={setPageSkip}
                    loading={loading}
                />
            )}
            {openPending && (
                <PendingList
                    setIsOpenForm={setIsOpenForm}
                    setOpenActive={setOpenActive}
                    setOpenPending={setOpenPending}
                    pendingMedicines={pendingMedicines}
                    pageSkip={pageSkip}
                    setPageSkip={setPageSkip}
                    loading={loading}
                    setSuccess={setSuccess}
                />
            )}
        </div>
    )
}
