import { faEye, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { Pagination } from '../../../index'
import classes from './CategoryList.module.css'

export default function CategoryList({ setFormOpen, setListOpen, setDeviceOpen, setCategoryOpen, categories }) {
    const [isOpen, setIsOpen] = useState(false)
    const [search, setSearch] = useState('')
    let serial = 0

    const openClose = () => {
        setFormOpen(true)
        setListOpen(false)
    }

    const openCloseListForm = () => {
        setDeviceOpen(true)
        setCategoryOpen(false)
    }

    return (
        <div>
            <div className={classes.tableContainer}>
                <div className={classes.wrapper}>
                    <form action="">
                        <input
                            className={classes.searchField}
                            type="text"
                            value={search}
                            placeholder="Search Category"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <button className={classes.searchButton}>
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                    </form>

                    <div className={classes.allButton}>
                        <button className={classes.button} onClick={() => openCloseListForm()}>
                            View Device
                        </button>
                        <span></span>
                        <button className={`${classes.button} ${classes.buttonMargin}`} onClick={() => openClose()}>
                            + Add Category
                        </button>
                    </div>
                </div>
                <table className={classes.tableMain}>
                    <thead>
                        <tr className={classes.tableRow}>
                            <th>Sl</th>
                            <th>Category</th>
                            <th>Details</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {categories &&
                            categories.map((category, index) => (
                                <tr className={classes.tableRow} key={index}>
                                    <td>{(serial = serial + 1)}</td>
                                    <td>{category.name}</td>
                                    <td>{category.details}</td>
                                    <td>
                                        <button className={classes.icon}>
                                            <FontAwesomeIcon icon={faEye} onClick={() => setIsOpen(index)} />
                                        </button>
                                    </td>
                                    {/* {isOpen === index && (
                                        <div key={() => index} className={classes.container}>
                                            <div className={classes.formWrapper}>
                                                <div className={classes.close} onClick={() => setIsOpen(false)}>
                                                    &times;
                                                </div>

                                                <div className={classes.buttonContainer}>
                                                    <button
                                                        className={classes.button}
                                                        onClick={() => {
                                                            activeId(doc?.User?.id)
                                                        }}>
                                                        Active (Yes)
                                                    </button>

                                                    <button className={classes.button} onClick={() => setIsOpen(false)}>
                                                        No
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )} */}
                                </tr>
                            ))}
                    </tbody>
                </table>
                {/* <Pagination
                    pageSkip={pageSkip}
                    setPageSkip={setPageSkip}
                    listItem={pendingDoctors[1]}
                    pageAll={pendingDoctors[0]?.results}
                /> */}
            </div>
        </div>
    )
}
