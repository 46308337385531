import { faEdit, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import Pagination from '../../../Pagination/Pagination'
import { ListCount, Table } from '../../../Resource'
import { Loading } from '../../../index'
import classes from './Pending.module.css'
import Popup from './Popup/Popup'

export default function Pending({ pendingPharmacies, pageSkip, setPageSkip, loading, setOpenActive, setOpenPending }) {
    const [isOpen, setIsOpen] = useState(false)
    let serial = 0

    const openClose = () => {
        setOpenActive(true)
        setOpenPending(false)
    }

    return (
        <div className={classes.list}>
            <div className={classes.wrapper}>
                <div>
                    <input
                        className={classes.searchField}
                        type="text"
                        // value={search}
                        placeholder="Search Pharmacy"
                        // onChange={(e) => setSearch(e.target.value)}
                    />
                    <button className={classes.searchButton}>
                        <FontAwesomeIcon icon={faSearch} />
                    </button>
                </div>

                <div className={classes.allButton}>
                    <button className={classes.button} onClick={() => openClose()}>
                        View Active Pharmacies
                    </button>
                    {/* <span></span> */}
                    {/* <button className={`${classes.button} ${classes.buttonMargin}`} onClick={() => openCloseListForm()}>
                        + Add Doctors
                    </button> */}
                </div>
            </div>

            <ListCount title="Pending Pharmacy List" results={pendingPharmacies} />
            <Table>
                <thead>
                    <tr>
                        <th>Sl</th>
                        <th>Pharmacy ID</th>
                        <th>Pharmacy Name</th>
                        <th>Pharmacy Phone</th>
                        <th>District</th>
                        <th>Sub District</th>
                        <th>Address</th>
                        <th>Trade License</th>
                        <th>Drug License</th>
                        <th>Reg. Date</th>
                        <th>Owner</th>
                        <th>Phone</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {pendingPharmacies[1] &&
                        pendingPharmacies[1].map((list, index) => (
                            <tr className={classes.row} key={index}>
                                <td>{(serial = serial + 1) + pageSkip}</td>
                                <td>hxpharmacy{list.Pharmacy?.id}</td>
                                <td>{list.Pharmacy?.name}</td>
                                <td>{list.Pharmacy?.contact_phone || '-'}</td>

                                <td>{list.Pharmacy?.district || '-'}</td>
                                <td>{list.Pharmacy?.sub_district || '-'}</td>
                                <td>{list.Pharmacy?.detail_address || '-'}</td>
                                <td>{list.Pharmacy?.trade_license || '-'}</td>
                                <td>{list.Pharmacy?.drug_license || '-'}</td>
                                <td>{list.Pharmacy?.created_at && list.Pharmacy?.created_at.slice(0, 10)}</td>
                                <td>{list.User?.name}</td>
                                <td>{list.User?.phone}</td>
                                <td className={classes.status}>
                                    <span className={classes.waiting}>Pending</span>
                                </td>
                                <td>
                                    <button className={classes.icon}>
                                        <FontAwesomeIcon
                                            title="Action"
                                            icon={faEdit}
                                            onClick={() => setIsOpen(index)}
                                        />
                                    </button>
                                </td>
                                {isOpen === index && (
                                    <Popup list={list?.Pharmacy} setIsOpen={setIsOpen} index={index} />
                                )}
                            </tr>
                        ))}
                </tbody>
            </Table>
            <Pagination
                pageSkip={pageSkip}
                setPageSkip={setPageSkip}
                listItem={pendingPharmacies[1]}
                pageAll={pendingPharmacies[0]?.results}
            />
            {loading && <Loading />}
        </div>
    )
}
