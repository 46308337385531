import { useContext, useEffect, useState } from 'react'
import { Auth, Notification, UserInfo } from '../../contexts/allContext'
import { api } from '../../utils/apiToken'
import { firstDay, lastDay, firstDayPrevious, lastDayPrevious, currentDate } from '../../utils/date'
import NoticePortal from './NoticePortal/NoticePortal'
import Snapshot from './Snapshot/Snapshot'

export default function Dashboard() {
    const [totalActiveDoctors, setTotalActiveDoctors] = useState([])
    const [totalActiveDoctorsPreviousMonth, setTotalActiveDoctorsPreviousMonth] = useState([])
    const [totalActiveDoctorsThisMonth, setTotalActiveDoctorsThisMonth] = useState([])

    const [totalPendingDoctors, setTotalPendingDoctors] = useState([])
    const [totalPendingDoctorsPreviousMonth, setTotalPendingDoctorsPreviousMonth] = useState([])
    const [totalPendingDoctorsThisMonth, setTotalPendingDoctorsThisMonth] = useState([])
    // PreviousMonth

    const [totalUsers, setTotalUsers] = useState([])
    const [totalUsersPreviousMonth, setTotalUsersPreviousMonth] = useState([])
    const [totalUsersThisMonth, setTotalUsersThisMonth] = useState([])

    const [totalPatients, setTotalPatients] = useState([])
    const [totalPatientsPreviousMonth, setTotalPatientsPreviousMonth] = useState([])
    const [totalPatientsThisMonth, setTotalPatientsThisMonth] = useState([])

    const [totalServices, setTotalServices] = useState([])
    const [totalServicesPreviousMonth, setTotalServicesPreviousMonth] = useState([])
    const [totalServicesThisMonth, setTotalServicesThisMonth] = useState([])

    const [repeatServices, setRepeatServices] = useState(0)
    const [repeatServicesPreviousMonth, setRepeatServicesPreviousMonth] = useState(0)
    const [repeatServicesThisMonth, setRepeatServicesThisMonth] = useState(0)

    const [totalPrescriptions, setTotalPrescriptions] = useState([])
    const [totalPrescriptionsPreviousMonth, setTotalPrescriptionsPreviousMonth] = useState([])
    const [totalPrescriptionsThisMonth, setTotalPrescriptionsThisMonth] = useState([])

    const [notices, setNotices] = useState([])

    const { notification } = useContext(Notification)
    const { stateAuth } = useContext(Auth)
    const { stateUser } = useContext(UserInfo)
    const userInfo = stateUser.info
    const token = stateAuth.token

    // **************** Active Doctor Counts **************** //
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${api}/admin/doctors/active?end_date=${currentDate}T23%3A59%3A59&skip=0&limit=0`,
                    {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )

                const data = await response.json()
                if (response.ok) {
                    setTotalActiveDoctors(data)
                }
            } catch {
                setTotalActiveDoctors([])
            }
        }
        fetchData()
    }, [token])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${api}/admin/doctors/active?start_date=${firstDayPrevious}&end_date=${lastDayPrevious}T23%3A59%3A59&skip=0&limit=0`,
                    {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )

                const data = await response.json()
                if (response.ok) {
                    setTotalActiveDoctorsPreviousMonth(data)
                }
            } catch {
                setTotalActiveDoctorsPreviousMonth([])
            }
        }
        fetchData()
    }, [token])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${api}/admin/doctors/active?start_date=${firstDay}&end_date=${lastDay}T23%3A59%3A59&skip=0&limit=0`,
                    {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )

                const data = await response.json()
                if (response.ok) {
                    setTotalActiveDoctorsThisMonth(data)
                }
            } catch {
                setTotalActiveDoctorsThisMonth([])
            }
        }
        fetchData()
    }, [token])

    // **************** Inactive Doctor Counts **************** //
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${api}/admin/doctors/inactive?end_date=${currentDate}T23%3A59%3A59&skip=0&limit=0`,
                    {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                const data = await response.json()
                if (response.ok) {
                    setTotalPendingDoctors(data)
                }
            } catch {
                setTotalPendingDoctors([])
            }
        }
        fetchData()
    }, [token])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${api}/admin/doctors/inactive?start_date=${firstDayPrevious}&end_date=${lastDayPrevious}T23%3A59%3A59&skip=0&limit=0`,
                    {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )

                const data = await response.json()
                if (response.ok) {
                    setTotalPendingDoctorsPreviousMonth(data)
                }
            } catch {
                setTotalPendingDoctorsPreviousMonth([])
            }
        }
        fetchData()
    }, [token])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${api}/admin/doctors/inactive?start_date=${firstDay}&end_date=${lastDay}T23%3A59%3A59&skip=0&limit=0`,
                    {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )

                const data = await response.json()
                if (response.ok) {
                    setTotalPendingDoctorsThisMonth(data)
                }
            } catch {
                setTotalPendingDoctorsThisMonth([])
            }
        }
        fetchData()
    }, [token])

    // **************** User Counts **************** //
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${api}/admin/user/filter?end_date=${currentDate}T23%3A59%3A59&skip=0&limit=0`,
                    {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )

                const data = await response.json()
                if (response.ok) {
                    setTotalUsers(data)
                }
            } catch {
                setTotalUsers([])
            }
        }
        fetchData()
    }, [token])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${api}/admin/user/filter?start_date=${firstDayPrevious}&end_date=${lastDayPrevious}T23%3A59%3A59&skip=0&limit=0`,
                    {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                const data = await response.json()
                if (response.ok) {
                    setTotalUsersPreviousMonth(data)
                }
            } catch {
                setTotalUsersPreviousMonth([])
            }
        }
        fetchData()
    }, [token])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${api}/admin/user/filter?start_date=${firstDay}&end_date=${lastDay}T23%3A59%3A59&skip=0&limit=0`,
                    {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )

                const data = await response.json()
                if (response.ok) {
                    setTotalUsersThisMonth(data)
                }
            } catch {
                setTotalUsersThisMonth([])
            }
        }
        fetchData()
    }, [token])

    // **************** Patient Counts **************** //
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${api}/admin/patient/all?end_date=${currentDate}T23%3A59%3A59&&skip=0&limit=0`,
                    {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                const data = await response.json()
                if (response.ok) {
                    setTotalPatients(data)
                }
            } catch {
                setTotalPatients([])
            }
        }
        fetchData()
    }, [token])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${api}/admin/patient/all?start_date=${firstDayPrevious}&end_date=${lastDayPrevious}T23%3A59%3A59&&skip=0&limit=0`,
                    {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )

                const data = await response.json()
                if (response.ok) {
                    setTotalPatientsPreviousMonth(data)
                }
            } catch {
                setTotalPatientsPreviousMonth([])
            }
        }
        fetchData()
    }, [token])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${api}/admin/patient/all?start_date=${firstDay}&end_date=${lastDay}T23%3A59%3A59&&skip=0&limit=0`,
                    {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )

                const data = await response.json()
                if (response.ok) {
                    setTotalPatientsThisMonth(data)
                }
            } catch {
                setTotalPatientsThisMonth([])
            }
        }
        fetchData()
    }, [token])

    // **************** Service Counts **************** //
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/service/?end_date=${currentDate}T23%3A59%3A59&skip=0&limit=0`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })

                const data = await response.json()
                if (response.ok) {
                    setTotalServices(data)
                }
            } catch {
                setTotalServices([])
            }
        }
        fetchData()
    }, [token, notification])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${api}/service/filter?start_date=${firstDayPrevious}&end_date=${lastDayPrevious}T23%3A59%3A59&skip=0&limit=0`,
                    {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )

                const data = await response.json()
                if (response.ok) {
                    setTotalServicesPreviousMonth(data)
                }
            } catch {
                setTotalServicesPreviousMonth([])
            }
        }
        fetchData()
    }, [token])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${api}/service/filter?start_date=${firstDay}&end_date=${lastDay}T23%3A59%3A59&skip=0&limit=0`,
                    {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )

                const data = await response.json()
                if (response.ok) {
                    setTotalServicesThisMonth(data)
                }
            } catch {
                setTotalServicesThisMonth([])
            }
        }
        fetchData()
    }, [token, notification])

    // **************** Multi Service Counts **************** //
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/service/patient/multi/service-order`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })

                const data = await response.json()
                if (response.ok) {
                    setRepeatServices(data)
                }
            } catch {
                setRepeatServices(0)
            }
        }
        fetchData()
    }, [token, notification])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${api}/service/patient/multi/service-order/range/${firstDayPrevious}/${lastDayPrevious}T23%3A59%3A59`,
                    {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )

                const data = await response.json()
                if (response.ok) {
                    setRepeatServicesPreviousMonth(data)
                }
            } catch {
                setRepeatServicesPreviousMonth(0)
            }
        }
        fetchData()
    }, [token])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${api}/service/patient/multi/service-order/range/${firstDay}/${lastDay}T23%3A59%3A59`,
                    {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )

                const data = await response.json()
                if (response.ok) {
                    setRepeatServicesThisMonth(data)
                }
            } catch {
                setRepeatServicesThisMonth(0)
            }
        }
        fetchData()
    }, [token, notification])

    // **************** Prescription Counts **************** //
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/ep/count/?end_date=${currentDate}T23%3A59%3A59&skip=0&limit=0`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })

                const data = await response.json()
                if (response.ok) {
                    setTotalPrescriptions(data)
                }
            } catch {
                setTotalPrescriptions([])
            }
        }
        fetchData()
    }, [token])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${api}/ep/count/?start_date=${firstDayPrevious}&end_date=${lastDayPrevious}T23%3A59%3A59&skip=0&limit=0`,
                    {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )

                const data = await response.json()
                if (response.ok) {
                    setTotalPrescriptionsPreviousMonth(data)
                }
            } catch {
                setTotalPrescriptionsPreviousMonth([])
            }
        }
        fetchData()
    }, [token])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${api}/ep/count/?start_date=${firstDay}&end_date=${lastDay}T23%3A59%3A59&skip=0&limit=0`,
                    {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )

                const data = await response.json()
                if (response.ok) {
                    setTotalPrescriptionsThisMonth(data)
                }
            } catch {
                setTotalPrescriptionsThisMonth([])
            }
        }
        fetchData()
    }, [token])

    // **************** Notice Counts **************** //
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/notice/portal?portal=${userInfo.role_name}&skip=0&limit=20`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()

                if (response.ok) {
                    setNotices(data)
                }
            } catch {
                setNotices([])
            }
        }
        fetchData()
    }, [token, userInfo.role_name])

    return (
        <div>
            <Snapshot
                totalActiveDoctors={totalActiveDoctors}
                totalActiveDoctorsThisMonth={totalActiveDoctorsThisMonth}
                totalActiveDoctorsPreviousMonth={totalActiveDoctorsPreviousMonth}
                //
                totalPendingDoctors={totalPendingDoctors}
                totalPendingDoctorsThisMonth={totalPendingDoctorsThisMonth}
                totalPendingDoctorsPreviousMonth={totalPendingDoctorsPreviousMonth}
                //
                totalUsers={totalUsers}
                totalUsersThisMonth={totalUsersThisMonth}
                totalUsersPreviousMonth={totalUsersPreviousMonth}
                //
                totalPatients={totalPatients}
                totalPatientsThisMonth={totalPatientsThisMonth}
                totalPatientsPreviousMonth={totalPatientsPreviousMonth}
                //
                totalServices={totalServices}
                totalServicesThisMonth={totalServicesThisMonth}
                totalServicesPreviousMonth={totalServicesPreviousMonth}
                //
                repeatServices={repeatServices}
                repeatServicesThisMonth={repeatServicesThisMonth}
                repeatServicesPreviousMonth={repeatServicesPreviousMonth}
                //
                totalPrescriptions={totalPrescriptions}
                totalPrescriptionsThisMonth={totalPrescriptionsThisMonth}
                totalPrescriptionsPreviousMonth={totalPrescriptionsPreviousMonth}
            />
            <NoticePortal notices={notices} />
        </div>
    )
}
