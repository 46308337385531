import { faTrashArrowUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import classes from './Labtest.module.css'

export default function Labtest({ lablines, setLablines, index }) {
    const [searchLabtest, setSearchLabtest] = useState('')
    const [allLabtest, setAllLabtest] = useState([])
    const [discount, setDiscount] = useState(0)
    const [total, setTotal] = useState()
    const [price, setPrice] = useState()
    const [info, setInfo] = useState({})
    const [hide, setHide] = useState(false)

    const api = process.env.REACT_APP_API_URL

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/lab-test/?name=${searchLabtest}`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                const data = await response.json()
                if (response.ok) {
                    setAllLabtest(data)
                }
            } catch {
                setAllLabtest([])
            }
        }
        return fetchData()
    }, [api, searchLabtest])

    const medicineHandle = (searchLabtest) => {
        if (searchLabtest.length > 1) {
            setHide(true)
        }
        if (searchLabtest.length < 1) {
            setHide(false)
        }
        setSearchLabtest(searchLabtest)
    }

    const addLabtest = (value) => {
        let mainData = lablines
        // mainData[index].name = value.LabTest?.name
        // mainData[index].mrp = value.LabTest?.mrp
        // mainData[index].price = value.LabTest?.price
        // mainData[index].id = value.LabTest?.id
        // mainData[index].provider = value.TestProvider?.name

        setLablines([...mainData])
    }

    useEffect(() => {
        let mainData = lablines
        mainData[index].test_name = info.LabTest?.name
        mainData[index].test_id = info.LabTest?.id
        mainData[index].mrp = info.LabTest?.mrp
        mainData[index].price = info.LabTest?.price
        // mainData[index].id = info.LabTest?.id
        mainData[index].provider_name = info.TestProvider?.name
        mainData[index].provider_id = info.TestProvider?.id

        mainData[index].discount = info.LabTest?.discount || discount
        mainData[index].total = Math.ceil(mainData[index].price - mainData[index].price * (discount / 100))
        mainData[index].price = mainData[index].total

        setTotal(mainData[index].total)
    }, [discount, lablines, index, price, total])

    const removeItem = (index) => {
        setLablines([...lablines.slice(0, index), ...lablines.slice(index + 1, lablines.length)])
    }

    // const handleDelete = (value) => {
    //     const newLabtest = lablines.filter((item) => item.id == value)
    //     setLablines([...newLabtest])
    //     console.log(newLabtest)
    // }

    return (
        <div className={classes.medWrapper}>
            <div className={`${classes.formGrid} ${classes.formMargin}`}>
                <label>
                    <span>
                        Lab Test <span className={classes.starSign}>*</span>
                    </span>
                    <input
                        type="text"
                        value={searchLabtest}
                        onChange={(e) => medicineHandle(e.target.value)}
                        placeholder="search labtest"
                    />
                </label>
                {hide && (
                    <div className={classes.optAll}>
                        {info.LabTest?.name !== searchLabtest
                            ? allLabtest &&
                              allLabtest.map((info, i) => (
                                  <div className={classes.optSelect} key={i}>
                                      <div
                                          onClick={() => {
                                              setInfo(info)
                                              setSearchLabtest(info.LabTest?.name)
                                              setPrice(info.LabTest?.price)
                                              setAllLabtest([])
                                              setHide(false)
                                              addLabtest(info)
                                          }}>
                                          <div value={info.id}>
                                              <span style={{ fontSize: '10px' }}>{i + 1}.</span>{' '}
                                              <span style={{ fontSize: '14px' }}>
                                                  {info.LabTest?.name} - {info.LabTest?.price}৳
                                              </span>
                                              <br />
                                              <span style={{ fontSize: '12px' }}>
                                                  {info.TestProvider?.name} <br />
                                              </span>
                                          </div>
                                      </div>
                                  </div>
                              ))
                            : null}
                    </div>
                )}
                <label>
                    <span>Test Provider</span>
                    <span className={classes.fetchLabel}>
                        {info.TestProvider?.name} <p>.</p>
                    </span>
                </label>

                <label>
                    <span>MRP</span>
                    <span className={classes.fetchLabel}>
                        {info.LabTest?.mrp} <p>.</p>
                    </span>
                </label>
                <label>
                    <span>
                        Discount % <span className={classes.starSign}>*</span>
                    </span>
                    <input
                        type="number"
                        // value={discount}
                        defaultValue={info.LabTest?.discount}
                        placeholder="%"
                        onChange={(e) => setDiscount(parseInt(e.target.value))}
                        onBlur={(e) => setLablines([...lablines])}
                        min={0}
                        max={100}
                    />
                </label>
                <label>
                    <span>Total</span>
                    <span className={classes.fetchLabel}>
                        {isNaN(total) !== true ? Math.ceil(total) : ''} <p>.</p>
                    </span>
                </label>

                <button className={classes.cross} onClick={() => removeItem(index)}>
                    <FontAwesomeIcon icon={faTrashArrowUp} />
                </button>
            </div>
        </div>
    )
}
