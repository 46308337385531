import { faLeaf, faThumbtack } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useState } from 'react'
import { Auth, UserInfo } from '../../../contexts/allContext'
import classes from './NoticeForm.module.css'

export default function NoticeForm({ setListOpen, setFormOpen, roles }) {
    const [audience, setAudience] = useState('')
    const [priority, setPriority] = useState()
    const [title, setTitle] = useState('')
    const [body, setBody] = useState('')

    const api = process.env.REACT_APP_API_URL
    const { stateAuth } = useContext(Auth)
    const { stateUser } = useContext(UserInfo)
    const userInfo = stateUser.info
    const token = stateAuth.token

    const openClose = () => {
        setListOpen(true)
        setFormOpen(false)
    }

    const refreshPage = () => {
        window.location.reload()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const details = {
            cover_img_id: 0,
            title: title,
            body: body,
            portal: audience,
            priority: priority,
            status: true,
        }
        let postFetch = await fetch(`${api}/notice`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(details),
        })

        if (postFetch.ok) {
            refreshPage()
        }
    }
    return (
        <div>
            <div className={classes.wrapper}>
                <button className={classes.button} onClick={() => openClose()}>
                    Notice List
                </button>
            </div>
            <div className={classes.formWrapper}>
                <FontAwesomeIcon className={classes.icon} icon={faThumbtack} />
                <div className={classes.logo}>ADD NOTICE</div>

                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className={classes.section}>
                        <FontAwesomeIcon className={classes.leaf} icon={faLeaf} />
                        Details Info
                    </div>
                    <div className={classes.innerWrap}>
                        <div className={classes.formGrid}>
                            <label>
                                Select Image
                                <input type="file" />
                            </label>
                            <div className={`${classes.formGrid} ${classes.formMargin}`}>
                                <label>
                                    Select Audience
                                    <select
                                        required
                                        className={classes.select}
                                        onChange={(e) => setAudience(e.target.value)}>
                                        <option value="">Select</option>
                                        {roles &&
                                            roles.map((role, i) =>
                                                role.name !== 'admin' ? (
                                                    userInfo.role_name !== role.name ? (
                                                        <option key={i} value={role.name}>
                                                            {role.name}
                                                        </option>
                                                    ) : null
                                                ) : null
                                            )}
                                    </select>
                                </label>
                                <label>
                                    Select Priority
                                    <select
                                        required
                                        className={classes.select}
                                        onChange={(e) => setPriority(e.target.value)}>
                                        <option value="">Select</option>
                                        <option value="1">Urgent</option>
                                        <option value="2">High</option>
                                        <option value="3">Low</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                        <label>
                            Notice Title
                            <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
                        </label>
                        <label>
                            Type Notice
                            <textarea
                                type="text"
                                rows={8}
                                placeholder="Details"
                                value={body}
                                onChange={(e) => setBody(e.target.value)}
                                required
                            />
                        </label>
                    </div>
                    <button className={classes.button} type="submit">
                        SUBMIT
                    </button>
                </form>
            </div>
        </div>
    )
}
