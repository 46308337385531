import { faCircleH, faFlask, faLaptopMedical, faTruck, faUserNurse } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../../../assets/logo.png'
import { Auth } from '../../../../contexts/allContext'
import { api } from '../../../../utils/apiToken'
import { numToWords } from '../../../../utils/numToWord'
import classes from './ServicePdf.module.css'

export const Pdf = React.forwardRef((props, ref) => {
    const details = props.props[0]
    const medicines = props.props[1][1]
    const healthPlan = props.props[2][0]
    const labTest = props.props[0]
    const telemedicine = props.props[0]
    const [planDetail, setPlanDetail] = useState({})
    const [labtestDetail, setLabtestDetail] = useState({})

    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    //healthplan service lists
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/health-plan/${healthPlan?.health_plan_id}`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                setPlanDetail(data)
            } catch {
                setPlanDetail({})
            }
        }
        return fetchData()
    }, [token, healthPlan?.health_plan_id])

    //labtest service lists
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/service/labtest/${labTest.ServiceOrder?.id}?skip=0&limit=30`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                setLabtestDetail(data)
            } catch {
                setLabtestDetail({})
            }
        }
        return fetchData()
    }, [token, labTest.ServiceOrder?.id])

    let sl = 0

    console.log('props:', telemedicine)

    return (
        <div ref={ref} className={classes.bodyPdf}>
            <div className={classes.page}>
                {/* <div className={classes.arrow}>
                    <p>Invoice</p>
                </div> */}
                <header className={classes.clear}>
                    <div className={classes.invoiceName}>
                        <p>
                            {' '}
                            <span>Invoice</span> <br />
                            {/* {details?.ServiceOrder?.service_name.replace(/_/g, ' ')} */}
                            {details?.ServiceOrder?.service_name === 'lab_test'
                                ? 'Sample Collection'
                                : details?.ServiceOrder?.service_name.replace(/_/g, ' ')}
                        </p>
                    </div>
                    <div className={classes.company}>
                        {/* <div className={classes.name}>HEALTHx Pharmacy</div> */}
                        <div className={classes.logo}>
                            <img src={Logo} alt="" />
                        </div>
                        <div>House 6, Road 2, Banani</div>
                        <div>+88 01969908181</div>
                        <div>
                            <Link to="#">contact@healthx.com.bd</Link>
                        </div>
                    </div>
                </header>

                <div className={classes.gridBox}>
                    <div className={classes.itemBox}>
                        <div className={`${classes.details}`}>
                            <div className={classes.client}>
                                <div className={classes.to}>INVOICE TO :</div>
                                <div className={classes.name}>{details?.User?.name}</div>
                                <div className={classes.mobile}>{details?.User?.phone}</div>
                                <div className={classes.address}>{details?.ServiceOrder?.current_address}</div>
                            </div>
                            <div className={classes.invoice}>
                                <div className={classes.name}>INVOICE : HX-S{details?.ServiceOrder?.id + 10000000}</div>
                                <div className={classes.serviceName}>
                                    Service: {details?.ServiceOrder?.service_name.replace(/_/g, ' ')}
                                </div>

                                <div className={classes.date}>
                                    Order Date:{' '}
                                    {details?.ServiceOrder?.order_placement !== null ? (
                                        <>{details?.ServiceOrder?.order_placement.split('T')[0]}</>
                                    ) : (
                                        '--'
                                    )}
                                </div>
                                <div className={classes.date}>
                                    Delivery Date:{' '}
                                    {details?.ServiceOrder?.order_completion !== null ? (
                                        <>{details?.ServiceOrder?.order_completion.split('T')[0]}</>
                                    ) : (
                                        '--'
                                    )}
                                </div>
                            </div>
                        </div>

                        {planDetail.body !== null ? (
                            <table className={classes.tablePdf}>
                                <tr>
                                    <th className={classes.no}>No.</th>
                                    <th className={classes.desc}>Plan Description</th>
                                    <th className={classes.qty}>Code</th>
                                    <th className={classes.unit}>Duration</th>
                                    <th className={classes.qty}>Patient</th>
                                    <th className={classes.desc}>MRP</th>
                                    <th className={classes.desc}>Total</th>
                                </tr>
                                <tr>
                                    <td className={classes.desc}>1</td>
                                    <td className={classes.desc}>{planDetail.name}</td>
                                    <td className={classes.desc}>{planDetail.voucher_code}</td>
                                    <td className={classes.desc}>
                                        {Math.floor(planDetail.days / 30) === 0 ? 1 : Math.floor(planDetail.days / 30)}{' '}
                                        {planDetail.days / 30 > 1
                                            ? 'Months'
                                            : Math.floor(planDetail.days / 30) === 0
                                            ? 'Day'
                                            : 'Month'}
                                    </td>
                                    <td className={classes.desc}>{planDetail.total_patients}</td>
                                    <td className={classes.desc}>{planDetail.fee}</td>
                                    <td className={classes.desc}>{planDetail.fee}</td>
                                </tr>
                            </table>
                        ) : (
                            ''
                        )}

                        {medicines.length !== 0 ? (
                            <table className={classes.tablePdf}>
                                <tr>
                                    <th className={classes.no}>No.</th>
                                    <th className={classes.desc}>Medicine Description</th>
                                    <th className={classes.unit}>Unit Price</th>
                                    <th className={classes.qty}>Quantity</th>
                                    <th className={classes.desc}>MRP</th>
                                    <th className={classes.desc}>Discount</th>
                                    <th className={classes.total}>Total</th>
                                </tr>
                                {medicines &&
                                    medicines.map((med) => (
                                        <tr key={med.id}>
                                            <td className={classes.desc}> {(sl = sl + 1)} </td>
                                            <td className={classes.desc}>
                                                {med.form !== null ? med.form.slice(0, 3) : ''}. {med.name} -{' '}
                                                {med.strength}
                                            </td>
                                            <td className={classes.desc}> {med.unit_price_mrp} </td>
                                            <td className={classes.desc}> {med.quantity} </td>
                                            <td className={classes.desc}> {med.total_mrp} </td>
                                            <td className={classes.desc}>
                                                {med.unit_discount_percent} <span>%</span>
                                            </td>
                                            <td className={classes.desc}> {med.total} </td>
                                        </tr>
                                    ))}
                            </table>
                        ) : (
                            ''
                        )}

                        {labtestDetail[0]?.results !== 0 ? (
                            <table className={classes.tablePdf}>
                                <tr>
                                    <th className={classes.no}>No.</th>
                                    <th className={classes.qty}>Test Name</th>
                                    <th className={classes.desc}>Provider Name</th>
                                    <th className={classes.desc}>MRP</th>
                                    <th className={classes.desc}>Discount</th>
                                    <th className={classes.desc}>Total</th>
                                </tr>
                                {labtestDetail &&
                                    labtestDetail[1]?.map((lab, id) => (
                                        <tr key={id}>
                                            <td className={classes.desc}> {(sl = sl + 1)} </td>
                                            <td className={classes.desc}> {lab.test_name || '--'} </td>
                                            <td className={classes.desc}> {lab.provider_name || '--'} </td>
                                            <td className={classes.desc}> {lab.mrp || '--'} </td>
                                            <td className={classes.desc}> {lab.discount || '0'} % </td>
                                            <td className={classes.desc}> {lab.price || '--'} </td>
                                        </tr>
                                    ))}
                            </table>
                        ) : (
                            ''
                        )}

                        {telemedicine.ServiceOrder?.service_name == 'telemedicine' ? (
                            <table className={classes.tablePdf}>
                                <tr>
                                    <th className={classes.no}>No.</th>
                                    <th className={classes.desc}>Doctor Name</th>
                                    <th className={classes.qty}>Patient Name</th>
                                    <th className={classes.qty}>Fee</th>
                                </tr>
                                <tr>
                                    <td className={classes.desc}>1</td>
                                    <td className={classes.desc}>{telemedicine.ServiceOrder?.doctor[0]?.name}</td>
                                    <td className={classes.desc}>{telemedicine.User?.name}</td>
                                    <td className={classes.desc}>{telemedicine.ServiceOrder?.payable_amount}</td>
                                </tr>
                            </table>
                        ) : (
                            ''
                        )}

                        <div className={classes.amountFlex}>
                            <div>
                                <div className={classes.notices}>
                                    <div>Payment:</div>
                                    <div className={classes.notice}>{details?.ServiceOrder?.payment_method}</div>
                                </div>
                            </div>
                            <table className={classes.amount}>
                                <tr>
                                    <td>Subtotal: </td>
                                    <td>{details?.ServiceOrder?.order_value} TK</td>
                                </tr>
                                <tr>
                                    <td>Discount:</td>
                                    <td>
                                        <span>{details?.ServiceOrder?.discount_percent} %</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Total Payable Amount: </td>
                                    <td>
                                        <span>{details?.ServiceOrder?.payable_amount} TK</span>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div className={classes.note}>Remarks : {details?.ServiceOrder?.remarks}</div>
                        <div className={classes.amountWord}>
                            <div>
                                In Words: <span>{numToWords(details?.ServiceOrder?.payable_amount)}Taka Only</span>
                            </div>
                            <div>
                                Payment Method: <span>COD or Bkash +8801322658481 (Merchant) </span>
                            </div>
                        </div>

                        <div className={classes.bgLogo}>
                            <img src={Logo} alt="" />
                        </div>
                    </div>

                    <div className={classes.boxAll}>
                        <div className={classes.text}>
                            <p>For Any Queries, Refund or Support: Please Contact at 01969908181</p>
                            <span>Thanks for choosing us! Stay healthy!</span>
                        </div>
                        <div className={classes.service}>
                            <div style={{ fontWeight: '600' }}>Our Services:</div>

                            <div>
                                <FontAwesomeIcon icon={faLaptopMedical} className={classes.icon} />
                                Online Doctor 24/7
                            </div>
                            <div>|</div>
                            <div>
                                <FontAwesomeIcon icon={faTruck} className={classes.icon} />
                                Medicine Delivery
                            </div>
                            <div>|</div>
                            <div>
                                <FontAwesomeIcon icon={faFlask} className={classes.icon} />
                                Sample Collection
                            </div>
                            <div>|</div>
                            <div>
                                <FontAwesomeIcon icon={faCircleH} className={classes.icon} />
                                Hospital Discount
                            </div>

                            <div>|</div>
                            <div>
                                <FontAwesomeIcon icon={faUserNurse} className={classes.icon} />
                                Health Insurance
                            </div>
                        </div>
                        <div className={classes.devlop}>Developed by Healthx BD</div>
                    </div>
                </div>
            </div>
        </div>
    )
})
