import classes from './Popup.module.css'

export default function Popup({ children, index, close, btn, bg, title, name, first, second }) {
    return (
        <div key={() => index} className={classes.container}>
            <div className={classes.overlay} onClick={() => close(false)}></div>
            <div className={classes.form}>
                <div className={classes.close} onClick={() => close(false)}>
                    &times;
                </div>
                <div className={classes.info}>
                    <p>{first}</p> <span>{name}</span> <p>{second}</p>
                </div>
                <div className={classes.wrap}>{children}</div>
                <div className={classes.buttons}>
                    <button className={classes.btn} onClick={() => btn(false)} style={{ backgroundColor: bg }}>
                        {title}
                    </button>
                </div>
            </div>
        </div>
    )
}
