import { faLeaf, faThumbtack } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useState } from 'react'
import { Auth } from '../../../../contexts/allContext'
import { api } from '../../../../utils/apiToken'
import classes from './CategoryForm.module.css'

export default function CategoryForm({ setListOpen, setFormOpen, categories }) {
    const [name, setName] = useState('')
    const [detail, setDetail] = useState('')

    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    const openClose = () => {
        setListOpen(true)
        setFormOpen(false)
    }

    const refreshPage = () => {
        window.location.reload()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const details = {
            name,
            details: detail,
        }
        let postFetch = await fetch(`${api}/mediva/device/catagories/`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(details),
        })

        let contextFetch = await postFetch.json()

        if (postFetch.ok) {
            refreshPage()
        }
    }

    return (
        <div>
            <div className={classes.wrapper}>
                <button className={classes.button} onClick={() => openClose()}>
                    Category List
                </button>
            </div>
            <div className={classes.formWrapper}>
                <FontAwesomeIcon className={classes.icon} icon={faThumbtack} />
                <div className={classes.logo}>ADD CATEGORY</div>

                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className={classes.section}>
                        <FontAwesomeIcon className={classes.leaf} icon={faLeaf} />
                        Details Info
                    </div>
                    <div className={classes.innerWrap}>
                        <label>
                            Name
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                minLength={3}
                                required
                            />
                        </label>
                        <label>
                            Details
                            <textarea rows={2} value={detail} onChange={(e) => setDetail(e.target.value)} />
                        </label>
                    </div>
                    <button className={classes.button} type="submit">
                        SUBMIT
                    </button>
                    {/* <div> {alert && <p className={classes.alertMessage}>Password not matched!</p>}</div> */}
                    {/* <div> {alertFetch && <p className={classes.alertMessage}>{fetchInfo.context}</p>}</div> */}
                </form>
            </div>
        </div>
    )
}
