import { useContext, useEffect, useState } from 'react'
import { Auth } from '../../../contexts/allContext'
import { api } from '../../../utils/apiToken'
import { Table } from '../../Resource'
import { Pagination } from '../../index'
import classes from './Services.module.css'

export default function Services({ id }) {
    const [patientService, setPatientService] = useState([])
    const [pageSkipService, setPageSkipService] = useState(0)

    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    // patient service list
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/service/patient/${id}?skip=${pageSkipService}&limit=10`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()

                if (response.ok) {
                    setPatientService(data)
                }
            } catch {
                setPatientService([])
            }
        }
        fetchData()
    }, [token, pageSkipService, id])

    let sl = 0
    return (
        <div className={classes.collabList}>
            <Table>
                <thead>
                    <tr>
                        <th>Sl</th>
                        <th>ID</th>
                        <th>Service Name</th>
                        <th>Provider</th>
                        <th>Order Date</th>
                        <th>Delivery Date</th>
                        <th>Total</th>
                        <th>Payable</th>
                        <th>Paid</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {patientService[1] &&
                        patientService[1].map((service, index) => (
                            <tr className={classes.row} key={index}>
                                <td>{(sl = sl + 1)}</td>
                                <td>HX-S{service?.id + 10000000}</td>
                                <td className={classes.cap}>{service.service_name.replace(/_/g, ' ')}</td>
                                <td>{service.service_name === 'medicine_order' ? 'HEALTHx Pharmacy' : '-'}</td>
                                <td>{service.order_placement.slice(0, 10)}</td>
                                <td>
                                    {service.order_completion !== null ? service.order_completion.slice(0, 10) : '-'}
                                </td>
                                <td>{service.order_value}</td>
                                <td>{service.payable_amount}</td>
                                <td>{service.payment_by_customer}</td>
                                <td className={`${classes.status} ${classes.cap}`}>
                                    <span
                                        className={
                                            service.order_status === 'done'
                                                ? classes.active
                                                : service.order_status === 'processing'
                                                ? classes.progress
                                                : service.order_status === 'running'
                                                ? classes.running
                                                : service.order_status === 'emergency'
                                                ? classes.delete
                                                : service.order_status === 'cancel'
                                                ? classes.cancel
                                                : classes.waiting
                                        }>
                                        {service.order_status}
                                    </span>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>
            <Pagination
                pageSkip={pageSkipService}
                setPageSkip={setPageSkipService}
                listItem={patientService[1]}
                pageAll={patientService[0]?.results}
            />
        </div>
    )
}
