import { faLeaf } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Auth, UserInfo } from '../../../contexts/allContext'
import classes from './UpdateInfo.module.css'

export default function UpdateInfo() {
    const api = process.env.REACT_APP_API_URL
    const navigate = useNavigate()

    const { stateAuth } = useContext(Auth)
    const { stateUser } = useContext(UserInfo)
    const userInfo = stateUser.info
    const token = stateAuth.token

    const [name, setName] = useState(userInfo.name)
    const [email, setEmail] = useState(userInfo.email)
    const [phone, setPhone] = useState(userInfo.phone)
    const [alert, setAlert] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const details = {
                name,
                email,
                phone,
                sex: '1',
            }
            let putFetch = await fetch(`${api}/user/update`, {
                method: 'PATCH',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(details),
            })

            if (putFetch.ok) {
                navigate('/')
            }
        } catch {
            setAlert(true)
            setTimeout(function () {
                setAlert(false)
            }, 3000)
        }
    }

    return (
        <div className={classes.formWrapper}>
            <form onSubmit={(e) => handleSubmit(e)}>
                <div className={classes.section}>
                    <FontAwesomeIcon className={classes.leaf} icon={faLeaf} />
                    Personal Info
                </div>
                <div className={classes.innerWrap}>
                    <label>
                        Full Name
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            minLength={3}
                            required
                        />
                    </label>
                    <div className={classes.formGrid}>
                        <label>
                            Mobile
                            <input
                                type="tel"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                minLength={11}
                                maxLength={11}
                                required
                            />
                        </label>
                        <label>
                            Email
                            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                        </label>
                    </div>
                </div>
                <button className={classes.button} type="submit">
                    Update
                </button>
                <div> {alert && <p className={classes.alertMessage}>Email or Phone already exists!</p>}</div>
            </form>
        </div>
    )
}
