import { useContext, useState } from 'react'
import { Auth } from '../../../../contexts/allContext'
import { api } from '../../../../utils/apiToken'
import classes from './Update.module.css'

export default function Update({ index, setIsOpen, manual }) {
    const [manualInfo, setManualInfo] = useState(manual)

    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    const refreshPage = () => {
        window.location.reload()
    }

    const updatePlan = async (e) => {
        e.preventDefault()

        const details = {
            ...manualInfo,
        }

        const response = await fetch(`${api}/admin/s3/manual/${manual.id}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(details),
        })

        if (response.ok) {
            refreshPage()
        }
    }

    return (
        <div key={() => index} className={classes.containerPopup}>
            <div className={classes.overlay} onClick={() => setIsOpen(false)}></div>
            <div className={classes.formWrapper}>
                <div className={classes.close} onClick={() => setIsOpen(false)}>
                    &times;
                </div>

                <div className={classes.innerWrap}>
                    <label>
                        Title
                        <input
                            type="text"
                            value={manualInfo?.name}
                            onChange={(e) =>
                                setManualInfo({
                                    ...manualInfo,
                                    name: e.target.value,
                                })
                            }
                            required
                        />
                    </label>
                    <div className={classes.formGrid}>
                        <label>
                            Last Updated
                            <input
                                type="text"
                                value={manualInfo?.last_updated}
                                onChange={(e) =>
                                    setManualInfo({
                                        ...manualInfo,
                                        voucher_code: e.target.value,
                                    })
                                }
                                required
                            />
                        </label>

                        <label>
                            Issuing Authority
                            <input
                                type="text"
                                value={manualInfo?.issuing_authority}
                                onChange={(e) =>
                                    setManualInfo({
                                        ...manualInfo,
                                        voucher_code: e.target.value,
                                    })
                                }
                                required
                            />
                        </label>
                    </div>
                </div>
                <button className={classes.btn} onClick={(e) => updatePlan(e)}>
                    Update
                </button>
            </div>
        </div>
    )
}
