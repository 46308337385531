import { useState } from 'react'
import classes from './ChangeRole.module.css'

export default function ChangeRole({ setPopup, index, roles, item, token, api }) {
    const [roleChange, setRoleChange] = useState('')

    const refreshPage = () => {
        window.location.reload()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        let patchFetch = await fetch(`${api}/admin/role-change?id=${item.id}&role_name=${roleChange}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })

        if (patchFetch.ok) {
            refreshPage()
        }
    }

    return (
        <div key={() => index} className={classes.container}>
            <div className={classes.formWrapper}>
                <div className={classes.close} onClick={() => setPopup(false)}>
                    &times;
                </div>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className={classes.innerWrap}>
                        <label className={classes.gap}>
                            Change Role of - <span>{item.name}</span>
                            <select
                                id="type"
                                className={classes.select}
                                onChange={(e) => setRoleChange(e.target.value)}
                                required>
                                <option value="">Select</option>
                                {roles &&
                                    roles.map((role, i) =>
                                        role.name !== 'admin' && role.name !== 'doctor' && role.name !== 'patient' ? (
                                            <option key={i} value={role.name}>
                                                {role.name}
                                            </option>
                                        ) : null
                                    )}
                            </select>
                        </label>
                    </div>
                    <button className={classes.button} type="submit">
                        SUBMIT
                    </button>
                </form>
            </div>
        </div>
    )
}
