import { useContext, useEffect, useState } from 'react'
import { Auth, UserInfo } from '../../contexts/allContext'
import Password from './ResetPassword/Password'
import UserPassword from './ResetUserPassword/UserPassword'
import UserRole from './ResetUserRole/UserRole'
import classes from './Setting.module.css'
import UpdateInfo from './Update/UpdateInfo'

export default function Setting() {
    const [users, setUsers] = useState([])
    const [roles, setRoles] = useState([])
    const [menu, setMenu] = useState(1)
    const [search, setSearch] = useState('')
    const [searchResults, setSearchResults] = useState('')

    const { stateUser } = useContext(UserInfo)
    const userInfo = stateUser.info

    const api = process.env.REACT_APP_API_URL
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/user-search/phone?number=${search}&skip=0&limit=5`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                setUsers(data)
            } catch {
                setUsers([])
            }
        }
        return fetchData()
    }, [token, api, search])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/roles/`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                const data = await response.json()
                setRoles(data)
            } catch {
                setRoles([])
            }
        }
        return fetchData()
    }, [api])

    return (
        <>
            <div className={classes.settings}>
                <div className={classes.wrapper}>
                    <div className={classes.nav}>
                        <span
                            className={menu === 1 ? `${classes.activeNav}` : `${classes.deactiveNav}`}
                            onClick={(e) => setMenu(1)}>
                            Personal Info
                        </span>

                        <span
                            className={menu === 2 ? `${classes.activeNav}` : `${classes.deactiveNav}`}
                            onClick={(e) => setMenu(2)}>
                            Change Password
                        </span>

                        {userInfo.role_name === 'admin' ? (
                            <span
                                className={menu === 3 ? `${classes.activeNav}` : `${classes.deactiveNav}`}
                                onClick={(e) => setMenu(3)}>
                                Change User Role
                            </span>
                        ) : null}
                    </div>
                    <div>
                        {userInfo.role_name === 'admin' || userInfo.role_name === 'moderator' ? (
                            <div>{menu === 1 ? <UpdateInfo /> : null}</div>
                        ) : (
                            <div>
                                {menu === 1 ? (
                                    <div className={classes.modWrapper}>
                                        <div className={classes.mod}>Employee Can't Access This Tab!</div>
                                    </div>
                                ) : null}
                            </div>
                        )}
                        {menu === 2 ? <Password /> : null}

                        {menu === 3 ? (
                            <UserRole
                                users={users}
                                search={search}
                                setSearch={setSearch}
                                searchResults={searchResults}
                                setSearchResults={setSearchResults}
                                roles={roles}
                            />
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
            {userInfo.role_name === 'admin' ? (
                <div>
                    {menu === 2 ? (
                        <UserPassword
                            users={users}
                            search={search}
                            setSearch={setSearch}
                            searchResults={searchResults}
                            setSearchResults={setSearchResults}
                        />
                    ) : null}
                </div>
            ) : (
                ''
            )}
        </>
    )
}
