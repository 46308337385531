import { useState } from 'react'
import Pagination from '../../Pagination/Pagination'
import { ListCount, Table } from '../../Resource'
import { Loading, PatientProfile } from '../../index'
import classes from './PrescriptionList.module.css'

export default function PrescriptionList({ prescriptions, pageSkip, setPageSkip, loading }) {
    const epV1 = process.env.REACT_APP_EP
    const demoId = parseInt(process.env.REACT_APP_DEMO_ID)
    let serial = 0

    const [isProfileOpen, setIsProfileOpen] = useState(false)

    return (
        <div className={classes.prescription}>
            <ListCount title="Prescription List" results={prescriptions} />
            <Table>
                <thead>
                    <tr>
                        <th>Sl</th>
                        <th>Doctor Name</th>
                        <th>Doctor Phone</th>
                        <th>Patient Name</th>
                        <th>Patient Phone</th>
                        <th>Patient Sex</th>
                        <th>Cause of Consultation</th>
                        <th>Date</th>
                        <th>Prescription</th>
                    </tr>
                </thead>
                <tbody>
                    {prescriptions[1] &&
                        prescriptions[1].map((ep, index) => (
                            <tr className={classes.row} key={index}>
                                <td>{(serial = serial + 1) + pageSkip}</td>
                                <td>{ep?.doctor[0]?.name}</td>
                                <td>{ep?.doctor[0]?.phone}</td>
                                <td>
                                    {ep.patient_id !== demoId ? (
                                        <button className={classes.btnActive} onClick={() => setIsProfileOpen(index)}>
                                            {ep.patient_name || '-'}
                                        </button>
                                    ) : (
                                        <button className={classes.btn}>{ep.patient_name || '-'} </button>
                                    )}
                                </td>
                                <td>{ep.patient_phone || '-'}</td>
                                <td>{ep.patient_sex || '-'}</td>
                                <td>{ep.cause_of_consultation}</td>
                                <td>{ep.created_at && ep.created_at.slice(0, 10)}</td>
                                <td>
                                    <a href={`${epV1}/ep/hxep${ep.id + 100000}`} target="__blank">
                                        <button>View</button>
                                    </a>
                                </td>

                                {isProfileOpen === index && ep.patient_id !== demoId && (
                                    <PatientProfile
                                        index={index}
                                        patient={ep?.patient[0]}
                                        setIsOpen={setIsProfileOpen}
                                    />
                                )}
                            </tr>
                        ))}
                </tbody>
            </Table>

            <Pagination
                pageSkip={pageSkip}
                setPageSkip={setPageSkip}
                listItem={prescriptions[1]}
                pageAll={prescriptions[0]?.results}
            />
            {loading && <Loading />}
        </div>
    )
}
