import { faList, faThumbtack } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ProfileMale from '../../../../assets/profile1.png'
import ProfileFemale from '../../../../assets/profile2.png'
import { Pagination } from '../../../index'
import classes from './ListDetail.module.css'

export default function ListDetail({
    index,
    setIsOpen,
    users,
    activities,
    results,
    patientToday,
    patientCurrent,
    patientPrevious,
    telemedicineToday,
    telemedicineCurrent,
    telemedicinePrevious,
    medicineToday,
    medicineCurrent,
    medicinePrevious,
    planToday,
    planCurrent,
    planPrevious,
    pageSkipPopup,
    setPageSkipPopup,
}) {
    let serialList = 0

    return (
        <div key={() => index} className={classes.container}>
            <div className={classes.overlay} onClick={() => setIsOpen(false)}></div>
            <div className={classes.formWrapper}>
                <div className={classes.close} onClick={() => setIsOpen(false)}>
                    &times;
                </div>

                <div className={classes.divScroll}>
                    <div className={classes.cardContainer}>
                        <div className={classes.cardWrapper}>
                            <div className={classes.card}>
                                <img src={users.sex === 'female' ? ProfileFemale : ProfileMale} alt="" />
                                <p className={classes.title}>{users.name}</p>
                                <span className={classes.subTitle}>
                                    {users.role_name !== 'admin' ? `${users.role_name} Team` : users.role_name}
                                </span>

                                <div className={classes.flexRow}>
                                    <div className={classes.flexColumn}>
                                        <p>Patient Register</p>
                                        <span className={classes.numbers}>{results[0]?.results}</span>
                                    </div>
                                    <div className={classes.flexColumn}>
                                        <p>Total Activity</p>
                                        <span className={classes.numbers}>{activities[0]?.results}</span>
                                    </div>
                                </div>

                                <div className={classes.social}>
                                    <p>{users.email}</p>
                                    <p>{users.phone}</p>
                                </div>

                                <div className={classes.profile}>
                                    <button className={classes.profileButton}>Message</button>
                                </div>
                            </div>
                        </div>
                        <div className={classes.tableContainerPopup}>
                            <div className={classes.summery}>
                                <div className={classes.box}>
                                    <FontAwesomeIcon icon={faThumbtack} />
                                    <p>Patient Register</p>
                                    <span className={classes.numbers}>
                                        Today: <b>{patientToday?.results}</b>
                                    </span>
                                    <span className={classes.numbers}>
                                        This Month: <b>{patientCurrent?.results}</b>
                                    </span>
                                    <span className={classes.numbers}>Last Month: {patientPrevious?.results}</span>
                                </div>
                                <div className={classes.box}>
                                    <FontAwesomeIcon icon={faThumbtack} />
                                    <p>Health Plan</p>
                                    <span className={classes.numbers}>
                                        Today: <b>{planToday?.results}</b>
                                    </span>
                                    <span className={classes.numbers}>
                                        This Month: <b>{planCurrent?.results}</b>
                                    </span>
                                    <span className={classes.numbers}>Last Month: {planPrevious?.results}</span>
                                </div>
                                <div className={classes.box}>
                                    <FontAwesomeIcon icon={faThumbtack} />
                                    <p>Telemedicine</p>
                                    <span className={classes.numbers}>
                                        Today: <b>{telemedicineToday?.results}</b>
                                    </span>
                                    <span className={classes.numbers}>
                                        This Month: <b>{telemedicineCurrent?.results}</b>
                                    </span>
                                    <span className={classes.numbers}>Last Month: {telemedicinePrevious?.results}</span>
                                </div>
                                <div className={classes.box}>
                                    <FontAwesomeIcon icon={faThumbtack} />
                                    <p>Medicine Order</p>
                                    <span className={classes.numbers}>
                                        Today: <b>{medicineToday?.results}</b>
                                    </span>
                                    <span className={classes.numbers}>
                                        This Month: <b>{medicineCurrent?.results}</b>
                                    </span>
                                    <span className={classes.numbers}>Last Month: {medicinePrevious?.results}</span>
                                </div>
                            </div>

                            <div className={classes.textWrapper}>
                                <FontAwesomeIcon icon={faList} />
                                <p>Activity List</p>
                            </div>
                            <table className={classes.tableMain}>
                                <thead>
                                    <tr className={classes.tableRow}>
                                        <th>Serial</th>
                                        <th>Activity Info</th>
                                        <th>Time</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {activities[1] &&
                                        activities[1].map((activity, index) => (
                                            <tr className={classes.tableRow} key={index}>
                                                <td>{(serialList = serialList + 1) + pageSkipPopup}</td>
                                                <td className={classes.cap}>
                                                    {activity.service_name.replace(/_/g, ' ')}
                                                </td>
                                                <td>{activity.created_at.slice(11, 16)}</td>
                                                <td>{activity.created_at.slice(0, 10)}</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                            <Pagination
                                pageSkip={pageSkipPopup}
                                setPageSkip={setPageSkipPopup}
                                listItem={activities[1]}
                                pageAll={activities[0]?.results}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
