import { Popup } from '../../../../Resource'
import classes from './Chamber.module.css'
import ChamberCreate from './ChamberCreate/ChamberCreate'
import List from './List/List'

export default function Chamber({
    index,
    setChamberOpen,
    chambers,
    id,
    show,
    setShow,
    isloading,
    setIsLoading,
    setStatus,
}) {
    return (
        <Popup index={index} close={setChamberOpen} btn={setChamberOpen} bg={'var(--red)'} title="Close">
            <div className={classes.addbtn}>
                {!show ? (
                    <button onClick={(e) => setShow(true)}>+ Add Chamber</button>
                ) : (
                    <button onClick={(e) => setShow(false)}>Chamber Lists</button>
                )}
            </div>

            {!show ? (
                <List chambers={chambers} isloading={isloading} setStatus={setStatus} />
            ) : (
                <ChamberCreate show={show} setShow={setShow} id={id} setIsLoading={setIsLoading} />
            )}
        </Popup>
    )
}
