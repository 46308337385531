import { faLeaf, faThumbtack } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useState } from 'react'
import { Auth } from '../../../../contexts/allContext'
import { api } from '../../../../utils/apiToken'
import classes from './DeviceForm.module.css'

export default function DeviceForm({ setListOpen, setFormOpen, categories }) {
    const [name, setName] = useState('')
    const [brand, setBrand] = useState('')
    const [model, setModel] = useState('')
    const [price, setPrice] = useState()
    const [quantity, setQuantity] = useState()
    const [category, setCategory] = useState('')
    const [detail, setDetail] = useState('')

    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    const openClose = () => {
        setListOpen(true)
        setFormOpen(false)
    }

    const refreshPage = () => {
        window.location.reload()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const details = {
            name,
            brand,
            model,
            details: detail,
            img_id: 0,
            catagory_id: category,
            quantity,
            trp: 0,
            old_mrp: 0,
            current_mrp: price,
        }
        let postFetch = await fetch(`${api}/mediva/devices/`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(details),
        })

        let contextFetch = await postFetch.json()

        if (postFetch.ok) {
            refreshPage()
        }
    }

    return (
        <div>
            <div className={classes.wrapper}>
                <button className={classes.button} onClick={() => openClose()}>
                    Device List
                </button>
            </div>
            <div className={classes.formWrapper}>
                <FontAwesomeIcon className={classes.icon} icon={faThumbtack} />
                <div className={classes.logo}>ADD DEVICE</div>

                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className={classes.section}>
                        <FontAwesomeIcon className={classes.leaf} icon={faLeaf} />
                        Details Info
                    </div>
                    <div className={classes.innerWrap}>
                        <label>
                            Add Image
                            <div>
                                <input type="file" />
                                <span>
                                    <button>Upload</button>
                                </span>
                            </div>
                        </label>
                        <div className={classes.formGrid}>
                            <label>
                                Name
                                <input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    minLength={3}
                                    required
                                />
                            </label>
                            <div className={`${classes.formGrid} ${classes.formMargin}`}>
                                <label>
                                    Brand
                                    <input
                                        type="text"
                                        value={brand}
                                        onChange={(e) => setBrand(e.target.value)}
                                        minLength={3}
                                        required
                                    />
                                </label>
                                <label>
                                    Model
                                    <input
                                        type="text"
                                        value={model}
                                        onChange={(e) => setModel(e.target.value)}
                                        minLength={3}
                                        required
                                    />
                                </label>
                            </div>
                        </div>
                        <div className={classes.formGrid}>
                            <div className={classes.formGrid}>
                                <label>
                                    Price
                                    <input
                                        type="number"
                                        value={price}
                                        onChange={(e) => setPrice(parseFloat(e.target.value))}
                                        step="any"
                                        required
                                    />
                                </label>
                                <label>
                                    Quantity
                                    <input
                                        type="number"
                                        value={quantity}
                                        onChange={(e) => setQuantity(parseInt(e.target.value))}
                                        minLength={3}
                                        required
                                    />
                                </label>
                            </div>
                            <label>
                                Category
                                <select
                                    required
                                    className={classes.select}
                                    onChange={(e) => setCategory(parseInt(e.target.value))}>
                                    <option value="">Select</option>
                                    {categories &&
                                        categories.map((category, i) => (
                                            <option key={i} value={category.id}>
                                                {category.name}
                                            </option>
                                        ))}
                                </select>
                            </label>
                        </div>
                        <label>
                            Details
                            <textarea rows={2} value={detail} onChange={(e) => setDetail(e.target.value)} />
                        </label>
                    </div>
                    <button className={classes.button} type="submit">
                        SUBMIT
                    </button>
                    {/* <div> {alert && <p className={classes.alertMessage}>Password not matched!</p>}</div> */}
                    {/* <div> {alertFetch && <p className={classes.alertMessage}>{fetchInfo.context}</p>}</div> */}
                </form>
            </div>
        </div>
    )
}
