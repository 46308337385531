import { faEdit, faSearch, faTrashArrowUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { Table } from '../../Resource'
import classes from './List.module.css'
import Popup from './Popup/Popup'
import Update from './Update/Update'

export default function List({ setListOpen, setFormOpen, manuals }) {
    const [search, setSearch] = useState('')
    const [popup, setPopup] = useState(false)
    const [edit, setEdit] = useState(false)
    let sl = 0

    const openClose = () => {
        setListOpen(false)
        setFormOpen(true)
    }

    return (
        <div className={classes.list}>
            <div className={classes.wrapper}>
                <form action="">
                    <input
                        className={classes.searchField}
                        type="text"
                        value={search}
                        placeholder="Search Manual"
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <button className={classes.searchButton}>
                        <FontAwesomeIcon icon={faSearch} />
                    </button>
                </form>
                <button className={classes.button} onClick={() => openClose()}>
                    + Add Manual
                </button>
            </div>
            <Table>
                <thead>
                    <tr>
                        <th>Sl</th>
                        <th>Title</th>
                        <th>Last Updated</th>
                        <th>Issuing Authority</th>
                        <th>File</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {manuals &&
                        manuals
                            .filter((item) => item.name.toLowerCase().includes(search))
                            .map((item, index) => (
                                <tr className={classes.row} key={index}>
                                    <td>{(sl = sl + 1)}</td>
                                    <td>{item.name}</td>
                                    <td>{item.last_updated}</td>
                                    <td>{item.issuing_authority}</td>
                                    <td>
                                        <a href={item.pdf_url}>Download</a>
                                    </td>
                                    <td className={classes.icons}>
                                        <FontAwesomeIcon icon={faEdit} onClick={() => setEdit(index)} />
                                        <FontAwesomeIcon icon={faTrashArrowUp} onClick={() => setPopup(index)} />
                                    </td>
                                    {edit === index && <Update manual={item} setIsOpen={setEdit} />}
                                    {popup === index && <Popup manual={item} setIsOpen={setPopup} />}
                                </tr>
                            ))}
                </tbody>
            </Table>
        </div>
    )
}
