import { faEdit, faEye, faNotesMedical, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useEffect, useState } from 'react'
import { Auth } from '../../../contexts/allContext'
import { api } from '../../../utils/apiToken'
import { ListCount } from '../../Resource'
import { Pagination } from '../../index'
import { Loading } from '../../index'
import { PatientProfile } from '../../index'
import Edit from './Edit/Edit'
import classes from './PatientList.module.css'
import PatientType from './PatientType/PatientType'

export default function PatientList({
    setListOpen,
    setFormOpen,
    patients,

    corporates,
    pageSkip,
    setPageSkip,
    setGetId,
    indicators,
    setIndicatorType,

    searchId,
    setSearchId,
    searchName,
    setSearchName,
    searchPhone,
    setSearchPhone,
    setSearchGender,
    loading,
}) {
    const [typeSelect, setTypeSelect] = useState(0)
    const [indicator, setIndicator] = useState('')
    const [unit, setUnit] = useState('')
    const [slotInt1, setSlotInt1] = useState(null)
    const [slotInt2, setSlotInt2] = useState(null)
    const [slotFlt4, setSlotFlt4] = useState(null)
    const [id, setId] = useState()

    const [isOpenPost, setIsOpenPost] = useState(false)
    const [isOpenGet, setIsOpenGet] = useState(false)
    const [popup, setPopup] = useState(true)

    const [hideHead, setHideHead] = useState(false)
    const [typeNumber, setTypeNumber] = useState(0)
    let serial = 0

    const [openProfile, setOpenProfile] = useState(false)
    const [popupType, setPopupType] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)

    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    const openClose = () => {
        setListOpen(false)
        setFormOpen(true)
    }

    useEffect(() => {
        if (typeSelect === 0) {
            setHideHead(false)
        }
        if (typeSelect === 1) {
            setIndicator('bp')
            setUnit('mmHg')
            setIndicatorType('bp')
            setHideHead(true)
            setTypeNumber(1)
        }
        if (typeSelect === 2) {
            setIndicator('rbs')
            setUnit('mmol/L')
            setIndicatorType('rbs')
            setHideHead(true)
            setTypeNumber(2)
        }
        if (typeSelect === 3) {
            setIndicator('weight')
            setUnit('kg')
            setIndicatorType('weight')
            setHideHead(true)
            setTypeNumber(3)
        }
        if (typeSelect === 4) {
            setIndicator('pulse')
            setUnit('rate/minute')
            setIndicatorType('pulse')
            setHideHead(true)
            setTypeNumber(4)
        }
    }, [typeSelect, setIndicatorType])

    const closeButton = () => {
        setSlotInt1(null)
        setSlotInt2(null)
        setSlotFlt4(null)
        setTypeSelect(0)
        setIsOpenPost(false)
        setIsOpenGet(false)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (typeSelect !== 0) {
            const details = {
                key: indicator,
                unit: unit,
                slot_int1: slotInt1,
                slot_int2: slotInt2,
                slot_flt4: slotFlt4,
            }
            let postFetch = await fetch(`${api}/admin/patient/indicator?user_id=${id}`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(details),
            })

            if (postFetch.ok) {
                alert(`Submission Successful`)
                setSlotInt1(null)
                setSlotInt2(null)
                setSlotFlt4(null)
                setTimeout(function () {
                    setPopup(false)
                    setPopup(true)
                    setTypeSelect(0)
                }, 200)
            }
        } else {
            alert(`Invalid Input!`)
        }
    }

    return (
        <div className={classes.tableContainer}>
            <div className={classes.wrapper}>
                <div>
                    <input
                        className={classes.searchField}
                        type="text"
                        value={searchId}
                        placeholder="Search Patient by ID#"
                        onChange={(e) => setSearchId(e.target.value)}
                    />
                    {searchId.length > 0 ? (
                        <FontAwesomeIcon icon={faXmark} className={classes.xmark} onClick={() => setSearchId('')} />
                    ) : (
                        <FontAwesomeIcon icon={faSearch} />
                    )}

                    <input
                        className={classes.searchField}
                        type="text"
                        value={searchName}
                        placeholder="Search Patient by Name"
                        onChange={(e) => setSearchName(e.target.value)}
                    />
                    {searchName.length > 0 ? (
                        <FontAwesomeIcon icon={faXmark} className={classes.xmark} onClick={() => setSearchName('')} />
                    ) : (
                        <FontAwesomeIcon icon={faSearch} />
                    )}

                    <input
                        className={classes.searchField}
                        type="text"
                        value={searchPhone}
                        placeholder="Search Patient by Phone"
                        onChange={(e) => setSearchPhone(e.target.value)}
                    />
                    {searchPhone.length > 0 ? (
                        <FontAwesomeIcon icon={faXmark} className={classes.xmark} onClick={() => setSearchPhone('')} />
                    ) : (
                        <FontAwesomeIcon icon={faSearch} />
                    )}

                    <select className={classes.select} onChange={(e) => setSearchGender(e.target.value)}>
                        <option value="">Select Gender</option>
                        <option value="">All</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                    </select>
                </div>
                <div>
                    <button className={classes.button} onClick={() => openClose()}>
                        + Add Patient
                    </button>
                </div>
            </div>

            <ListCount title="Patient List" results={patients} />

            <table className={classes.tableMain}>
                <thead>
                    <tr className={classes.tableRow}>
                        <th>Sl</th>
                        <th>ID</th>
                        <th>Patient Name</th>
                        <th>Mobile</th>
                        <th>Email</th>
                        <th>Gender</th>
                        <th>Corporate</th>
                        <th>Reg. Date</th>
                        <th>Reg. By</th>
                        <th>Role</th>
                        <th>Indicators</th>
                        <th>Edit</th>
                    </tr>
                </thead>
                <tbody>
                    {patients[1] &&
                        patients[1].map((patient, index) => (
                            <tr className={classes.tableRow} key={index}>
                                <td>{(serial = serial + 1) + pageSkip}</td>
                                <td className={classes.cap}>
                                    <button
                                        onClick={() => {
                                            setPopupType(index)
                                        }}>
                                        HX-P{1000000 + patient.id}
                                    </button>
                                </td>
                                <td className={classes.cap}>
                                    <button
                                        onClick={() => {
                                            setOpenProfile(index)
                                        }}>
                                        {patient.name}
                                    </button>
                                </td>
                                <td>{patient.phone}</td>
                                <td style={{ wordBreak: 'break-all' }}>
                                    {patient.email !== `hx${patient.phone.slice(2.11)}@gmail.com` &&
                                    patient.email !== `hx${patient.phone.slice(2.11)}@healthxbd.com` &&
                                    patient.email !== ''
                                        ? patient.email
                                        : '-'}
                                </td>
                                <td className={classes.cap}>{patient.sex}</td>
                                <td className={classes.cap}>
                                    {patient.company_name !== null ? patient.company_name : '-'}
                                </td>
                                <td>{patient.created_at.slice(0, 10)}</td>
                                <td>{patient.register_by_name !== null ? patient.register_by_name : '-'}</td>
                                <td className={classes.cap}>
                                    {patient.register_by_role !== null ? patient.register_by_role : '-'}
                                </td>
                                <td>
                                    <button className={classes.iconBtn}>
                                        <FontAwesomeIcon
                                            icon={faNotesMedical}
                                            className={classes.icon}
                                            onClick={() => setIsOpenPost(index)}
                                        />
                                        <FontAwesomeIcon
                                            icon={faEye}
                                            className={classes.icon}
                                            onClick={() => {
                                                setGetId(patient.id)
                                                setIsOpenGet(index)
                                            }}
                                        />
                                    </button>
                                </td>

                                <td>
                                    <button className={classes.iconBtn}>
                                        <FontAwesomeIcon
                                            icon={faEdit}
                                            className={classes.icon}
                                            onClick={() => {
                                                setGetId(patient.id)
                                                setOpenEdit(index)
                                            }}
                                        />
                                    </button>
                                </td>

                                {openEdit === index && (
                                    <Edit index={index} patient={patient} closeButton={setOpenEdit} />
                                )}

                                {popupType === index && (
                                    <PatientType
                                        setPopupType={setPopupType}
                                        index={index}
                                        patient={patient}
                                        corporates={corporates}
                                    />
                                )}

                                {openProfile === index && (
                                    <PatientProfile index={index} patient={patient} setIsOpen={setOpenProfile} />
                                )}

                                {popup && isOpenPost === index && (
                                    <div key={() => index} className={classes.container}>
                                        <div className={classes.formWrapper}>
                                            <div className={classes.close} onClick={(e) => closeButton(e)}>
                                                &times;
                                            </div>
                                            <form onSubmit={(e) => handleSubmit(e)}>
                                                <div className={classes.innerWrap}>
                                                    <label className={classes.gap}>
                                                        Select Indicator
                                                        <select
                                                            id="type"
                                                            className={classes.select}
                                                            required
                                                            onClick={(e) => setTypeSelect(parseInt(e.target.value))}>
                                                            <option value="0">Select</option>
                                                            <option value="1">Blood Pressure</option>
                                                            <option value="2">Diabetes</option>
                                                            <option value="3">Weight</option>
                                                            <option value="4">Pulse</option>
                                                        </select>
                                                    </label>

                                                    {typeSelect === 0 ? (
                                                        <div className={classes.typeMargin}></div>
                                                    ) : null}

                                                    {typeSelect === 1 ? (
                                                        <div className={classes.formGrid}>
                                                            <label>
                                                                Input High BP
                                                                <input
                                                                    type="number"
                                                                    placeholder="high"
                                                                    value={slotInt1}
                                                                    required
                                                                    min={0}
                                                                    max={300}
                                                                    onChange={(e) =>
                                                                        setSlotInt1(parseInt(e.target.value))
                                                                    }
                                                                />
                                                            </label>
                                                            <label>
                                                                Input Low BP
                                                                <input
                                                                    type="number"
                                                                    placeholder="low"
                                                                    value={slotInt2}
                                                                    required
                                                                    min={0}
                                                                    max={200}
                                                                    onChange={(e) =>
                                                                        setSlotInt2(parseInt(e.target.value))
                                                                    }
                                                                />
                                                            </label>
                                                        </div>
                                                    ) : null}

                                                    {typeSelect === 2 ? (
                                                        <label>
                                                            Input Diabetes
                                                            <input
                                                                type="number"
                                                                step="any"
                                                                placeholder="rbs"
                                                                value={slotFlt4}
                                                                required
                                                                min={0}
                                                                max={100}
                                                                onChange={(e) =>
                                                                    setSlotFlt4(parseFloat(e.target.value))
                                                                }
                                                            />
                                                        </label>
                                                    ) : null}

                                                    {typeSelect === 3 ? (
                                                        <label>
                                                            Input Weight
                                                            <input
                                                                type="number"
                                                                step="any"
                                                                placeholder="kg"
                                                                value={slotFlt4}
                                                                required
                                                                min={0}
                                                                max={500}
                                                                onChange={(e) =>
                                                                    setSlotFlt4(parseFloat(e.target.value))
                                                                }
                                                            />
                                                        </label>
                                                    ) : null}
                                                    {typeSelect === 4 ? (
                                                        <label>
                                                            Input Pulse
                                                            <input
                                                                type="number"
                                                                placeholder="pulse per min"
                                                                value={slotInt1}
                                                                required
                                                                min={0}
                                                                max={300}
                                                                onChange={(e) => setSlotInt1(parseInt(e.target.value))}
                                                            />
                                                        </label>
                                                    ) : null}
                                                </div>
                                                <button
                                                    className={classes.button}
                                                    type="submit"
                                                    onClick={() => setId(patient.id)}>
                                                    SUBMIT
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                )}

                                {popup && isOpenGet === index && (
                                    <div key={() => index} className={classes.container}>
                                        <div className={`${classes.formWrapper} ${classes.formWrapperWidth}`}>
                                            <div className={classes.close} onClick={() => closeButton(false)}>
                                                &times;
                                            </div>

                                            <div className={classes.innerWrap}>
                                                <label className={classes.gap}>
                                                    Select Indicator
                                                    <select
                                                        id="type"
                                                        className={classes.select}
                                                        onClick={(e) => setTypeSelect(parseInt(e.target.value))}>
                                                        <option value="0">Select</option>
                                                        <option value="1">Blood Pressure</option>
                                                        <option value="2">Diabetes</option>
                                                        <option value="3">Weight</option>
                                                        <option value="4">Pulse</option>
                                                    </select>
                                                </label>

                                                <div className={classes.tableScroll}>
                                                    {typeSelect === 0 ? (
                                                        <div className={classes.typeMargin}></div>
                                                    ) : null}
                                                    {hideHead && (
                                                        <>
                                                            <table className={classes.tableMain}>
                                                                <thead className={classes.formHead}>
                                                                    <tr>
                                                                        <th>Indicators</th>
                                                                        <th>Unit</th>
                                                                        <th>Value</th>
                                                                        <th>Value</th>
                                                                        <th>Last Update</th>
                                                                        <th>Time</th>
                                                                    </tr>
                                                                </thead>

                                                                {typeSelect === typeNumber ? (
                                                                    <tbody>
                                                                        {indicators &&
                                                                            indicators.map((value, i) => (
                                                                                <tr
                                                                                    className={`${classes.alert} ${classes.popupTable}`}
                                                                                    key={i}>
                                                                                    <td className={classes.cap}>
                                                                                        {value.key}
                                                                                    </td>
                                                                                    <td>{value.unit}</td>
                                                                                    <td>
                                                                                        {typeSelect !== 1 &&
                                                                                        typeSelect !== 4
                                                                                            ? value.slot_flt4 !== null
                                                                                                ? value.slot_flt4
                                                                                                : '-'
                                                                                            : value.slot_int1 !== null
                                                                                            ? value.slot_int1
                                                                                            : '-'}
                                                                                    </td>
                                                                                    <td>
                                                                                        {typeSelect !== 1
                                                                                            ? '-'
                                                                                            : value.slot_int2 !== null
                                                                                            ? value.slot_int2
                                                                                            : '-'}
                                                                                    </td>
                                                                                    <td>
                                                                                        {value.created_at.slice(0, 10)}
                                                                                    </td>
                                                                                    <td>
                                                                                        {value.created_at.slice(11, 16)}
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                    </tbody>
                                                                ) : null}
                                                            </table>
                                                        </>
                                                    )}
                                                </div>

                                                <div className={classes.buttonContainer}>
                                                    <button
                                                        className={classes.buttonGet}
                                                        onClick={() => closeButton(false)}>
                                                        Close
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </tr>
                        ))}
                </tbody>
            </table>
            <Pagination
                pageSkip={pageSkip}
                setPageSkip={setPageSkip}
                listItem={patients[1]}
                pageAll={patients[0]?.results}
            />
            {loading && <Loading />}
        </div>
    )
}
