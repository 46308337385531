import { useContext, useEffect, useState } from 'react'
import { Auth, UserInfo } from '../../contexts/allContext'
import ProfileCard from './ProfileCard/ProfileCard'

export default function Profile() {
    const [activities, setActivities] = useState([])
    const [results, setResults] = useState([])
    const [pageSkip, setPageSkip] = useState(0)

    const api = process.env.REACT_APP_API_URL
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    const { stateUser } = useContext(UserInfo)
    const userInfo = stateUser.info

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/admin/activity/log?skip=${pageSkip}&limit=10`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                setActivities(data)
            } catch {
                setActivities([])
            }
        }
        return fetchData()
    }, [token, api, pageSkip])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${api}/admin/activity/log/service/${userInfo?.id}/patient_register?skip=0&limit=1`,
                    {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                const data = await response.json()
                setResults(data)
            } catch {
                setResults([])
            }
        }
        return fetchData()
    }, [token, api, userInfo?.id])

    return (
        <div>
            <div>
                <ProfileCard
                    activities={activities}
                    results={results}
                    userInfo={userInfo}
                    pageSkip={pageSkip}
                    setPageSkip={setPageSkip}
                />
            </div>
        </div>
    )
}
