import { useContext, useEffect, useState } from 'react'
import { Auth } from '../../../../contexts/allContext'
import { api } from '../../../../utils/apiToken'
import classes from './PlanUpdate.module.css'

export default function PlanUpdate({ index, setIsOpen, plan }) {
    const [planInfo, setPlanInfo] = useState(plan)
    const [date, setDate] = useState(plan?.expire_date)
    const [hide, setHide] = useState(true)

    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    useEffect(() => {
        if (plan?.expire_status === false) {
            setDate(null)
            setHide(false)
        } else {
            setHide(true)
        }
    }, [plan?.expire_status])

    const refreshPage = () => {
        window.location.reload()
    }

    const updatePlan = async (e) => {
        e.preventDefault()

        const details = {
            ...planInfo,
            expire_date: date,
        }

        let patchFetch = await fetch(`${api}/health-plan/${plan?.id}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(details),
        })

        if (patchFetch.ok) {
            refreshPage()
        }
    }

    return (
        <div key={() => index} className={classes.containerPopup}>
            <div className={classes.formWrapper}>
                <div className={classes.close} onClick={() => setIsOpen(false)}>
                    &times;
                </div>

                <div className={classes.innerWrap}>
                    <label>
                        Service Type
                        <select
                            required
                            value={planInfo?.plan_type}
                            onChange={(e) =>
                                setPlanInfo({
                                    ...planInfo,
                                    plan_type: e.target.value,
                                })
                            }
                            className={classes.select}>
                            <option value="">Select</option>
                            <option value="health_plan">Health Plan</option>
                            <option value="health_package">Health Package</option>
                        </select>
                    </label>

                    <div className={classes.formGrid}>
                        <label>
                            Plan Name
                            <input
                                type="text"
                                value={planInfo?.name}
                                onChange={(e) =>
                                    setPlanInfo({
                                        ...planInfo,
                                        name: e.target.value,
                                    })
                                }
                                required
                            />
                        </label>
                        <div className={classes.formGrid}>
                            <label>
                                Voucher Code
                                <input
                                    type="text"
                                    value={planInfo?.voucher_code}
                                    onChange={(e) =>
                                        setPlanInfo({
                                            ...planInfo,
                                            voucher_code: e.target.value,
                                        })
                                    }
                                    required
                                />
                            </label>

                            <label>
                                Duration
                                <select
                                    required
                                    value={planInfo?.days}
                                    onChange={(e) =>
                                        setPlanInfo({
                                            ...planInfo,
                                            days: parseInt(e.target.value),
                                        })
                                    }
                                    className={classes.select}>
                                    <option value="">Select</option>
                                    <option value="1">1 Day</option>
                                    <option value="30">1 Month</option>
                                    <option value="90">3 Months</option>
                                    <option value="180">6 Months</option>
                                    <option value="365">12 Months</option>
                                </select>
                            </label>
                        </div>
                    </div>
                    <label>
                        Plan Details
                        <textarea
                            type="text"
                            value={planInfo?.details}
                            onChange={(e) =>
                                setPlanInfo({
                                    ...planInfo,
                                    details: e.target.value,
                                })
                            }
                            required
                        />
                    </label>
                    <div className={classes.formGrid}>
                        <div className={classes.formGrid}>
                            <label>
                                Number of Patient
                                <input
                                    type="number"
                                    value={planInfo?.total_patients}
                                    onChange={(e) =>
                                        setPlanInfo({
                                            ...planInfo,
                                            total_patients: parseInt(e.target.value),
                                        })
                                    }
                                    min={1}
                                    required
                                />
                            </label>
                            <label>
                                Plan Price
                                <input
                                    type="number"
                                    value={planInfo?.fee}
                                    onChange={(e) =>
                                        setPlanInfo({
                                            ...planInfo,
                                            fee: parseInt(e.target.value),
                                        })
                                    }
                                    min={0}
                                    required
                                />
                            </label>
                        </div>
                        <label>
                            Expire Status
                            <select
                                value={planInfo?.expire_status}
                                onChange={(e) =>
                                    setPlanInfo({
                                        ...planInfo,
                                        expire_status: e.target.value,
                                    })
                                }
                                required
                                className={classes.select}>
                                <option value="">Select</option>
                                <option value="true">True</option>
                                <option value="false">False</option>
                            </select>
                        </label>
                    </div>

                    {hide && (
                        <label>
                            Expire Date
                            <input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
                        </label>
                    )}
                </div>
                <button className={classes.btn} onClick={(e) => updatePlan(e)}>
                    Update
                </button>
            </div>
        </div>
    )
}
