import { faLeaf, faThumbtack } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState, useEffect } from 'react'
// import { doctorQualification, doctorSpeciality } from '../../../utils/Info'
import classes from './DoctorForm.module.css'

export default function DoctorForm({ setListOpen, setFormOpen, api, token }) {
    const [title, setTitle] = useState('')
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [gender, setGender] = useState('')
    const [qualification, setQualification] = useState('')
    const [speciality, setSpeciality] = useState('')
    const [bmdc, setBmdc] = useState('')

    const [fees, setFees] = useState()
    const [followup, setFollowup] = useState()
    const [institute, setInstitute] = useState('')

    const [password, setPassword] = useState('')
    const [rePassword, setRePassword] = useState('')
    const [picture, setPicture] = useState('')

    const [alert, setAlert] = useState(false)
    const [alertFetch, setAlertFetch] = useState(false)
    const [fetchInfo, setFetchInfo] = useState({})

    // const [qualificationSearch, setQualificationSearch] = useState('')
    // const [qualificationSearchResults, setQualificationSearchResults] = useState('')
    // const [specialitySearch, setSpecialitySearch] = useState('')
    // const [specialitySearchResults, setSpecialitySearchResults] = useState('')
    // const [hideQualification, setHideQualification] = useState(false)
    // const [hideSpeciality, setHideSpeciality] = useState(false)

    const openCloseListForm = () => {
        setListOpen(true)
        setFormOpen(false)
    }

    const refreshPage = () => {
        window.location.reload()
    }

    // const handlerQualification = (qualificationSearch) => {
    //     let matches = []
    //     if (qualificationSearch.length > 0) {
    //         matches = doctorQualification.filter((value) => value.toLowerCase().includes(qualificationSearch))
    //         setHideQualification(true)
    //     }
    //     if (qualificationSearch.length < 1) {
    //         setHideQualification(false)
    //     }
    //     setQualificationSearch(qualificationSearch)
    //     setQualificationSearchResults(matches)
    // }
    // const setHandleQualification = (qualificationSearch) => {
    //     setQualificationSearch(qualificationSearch)
    //     setQualification(qualificationSearch)
    //     setQualificationSearchResults([])
    //     setHideQualification(false)
    // }

    // const handlerSpeciality = (specialitySearch) => {
    //     let matches = []
    //     if (specialitySearch.length > 0) {
    //         matches = doctorSpeciality.filter((value) => value.toLowerCase().includes(specialitySearch))
    //         setHideSpeciality(true)
    //     }
    //     if (specialitySearch.length < 1) {
    //         setHideSpeciality(false)
    //     }
    //     setSpecialitySearch(specialitySearch)
    //     setSpecialitySearchResults(matches)
    // }
    // const setHandleSpeciality = (specialitySearch) => {
    //     setSpecialitySearch(specialitySearch)
    //     setSpeciality(specialitySearch)
    //     setSpecialitySearchResults([])
    //     setHideSpeciality(false)
    // }

    const submitImage = async (docId) => {
        const imgData = new FormData()
        imgData.append('file', picture)

        let response = await fetch(`${api}/admin/s3/profile-pic/${docId}`, {
            headers: {
                Accept: 'appllication/json',
                type: 'image/jpeg',
            },
            method: 'POST',
            body: imgData,
        })

        if (response.ok) {
            console.log('Image upload successful!')
            refreshPage()
        } else {
            alert('Something went wrong!')
        }
    }

    useEffect(() => {
        if (phone.length === 11) {
            setPassword(`hx${phone.slice(7, 11)}`)
            setRePassword(`hx${phone.slice(7, 11)}`)
        } else {
            setPassword('')
            setRePassword('')
        }
    }, [phone])

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (password !== rePassword) {
            setAlert(true)
            setTimeout(function () {
                setAlert(false)
            }, 3000)
        } else {
            if (email.length > 3) {
                const details = {
                    dr_title: title,
                    name,
                    email,
                    phone,
                    sex: gender,
                    password,
                    speciality,
                    qualification,
                    bmdc,
                    online_fees: fees,
                    followup_fees: followup,
                    institute,
                }
                const response = await fetch(`${api}/admin/doctor/register`, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(details),
                })

                let log = await response.json()

                if (response.ok) {
                    submitImage(log.id)
                } else {
                    setAlertFetch(true)
                    setFetchInfo(log)
                    setTimeout(function () {
                        setAlertFetch(false)
                    }, 3000)
                }
            } else {
                setEmail(`hx${phone.slice(2, 11)}@healthxbd.com`)
            }
        }
    }

    return (
        <div>
            <div className={classes.wrapper}>
                <button className={classes.button} onClick={() => openCloseListForm()}>
                    Doctor List
                </button>
            </div>
            <div className={classes.formWrapper}>
                <FontAwesomeIcon className={classes.icon} icon={faThumbtack} />
                <div className={classes.logo}>ADD DOCTOR</div>

                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className={classes.section}>
                        <FontAwesomeIcon className={classes.leaf} icon={faLeaf} />
                        Details Info
                    </div>
                    <div className={classes.innerWrap}>
                        <div className={classes.formGrid}>
                            <div className={classes.formGrid}>
                                <label>
                                    Designation Title <span className={classes.starSign}>*</span>
                                    <select
                                        required
                                        className={classes.select}
                                        onChange={(e) => setTitle(e.target.value)}>
                                        <option value="">Select</option>
                                        <option value="Dr.">Dr.</option>
                                        <option value="Prof. Dr.">Prof. Dr.</option>
                                        <option value="Assoc. Prof. Dr.">Assoc. Prof. Dr.</option>
                                        <option value="Asst. Prof. Dr.">Asst. Prof. Dr.</option>
                                    </select>
                                </label>
                                <label>
                                    Full Name <span className={classes.starSign}>*</span>
                                    <input
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        minLength={3}
                                        required
                                    />
                                </label>
                            </div>
                            <div className={`${classes.formGrid} ${classes.formMargin} `}>
                                <label>
                                    Mobile <span className={classes.starSign}>*</span>
                                    <input
                                        type="tel"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        minLength={11}
                                        maxLength={11}
                                        pattern="[0][1][0-9]{9}"
                                        required
                                    />
                                </label>
                                <label>
                                    Email
                                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                </label>
                            </div>
                        </div>

                        <div className={classes.formGrid}>
                            <div className={classes.formGrid}>
                                <label>
                                    BMDC <span className={classes.starSign}>*</span>
                                    <input
                                        type="number"
                                        value={bmdc}
                                        onChange={(e) => setBmdc(e.target.value)}
                                        required
                                    />
                                </label>
                                <label>
                                    Gender <span className={classes.starSign}>*</span>
                                    <select
                                        required
                                        className={classes.select}
                                        onChange={(e) => setGender(e.target.value)}>
                                        <option value="">Select</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </select>
                                </label>
                            </div>
                            <div className={`${classes.formGrid} ${classes.formMargin}`}>
                                <label>
                                    Online Fees <span className={classes.starSign}>*</span>
                                    <input
                                        type="number"
                                        value={fees}
                                        onChange={(e) => setFees(parseFloat(e.target.value))}
                                        required
                                    />
                                </label>
                                <label>
                                    Followup Fees <span className={classes.starSign}>*</span>
                                    <input
                                        type="number"
                                        value={followup}
                                        onChange={(e) => setFollowup(parseFloat(e.target.value))}
                                        required
                                    />
                                </label>
                            </div>
                        </div>

                        <div className={classes.formGrid}>
                            <label>
                                Qualification <span className={classes.starSign}>*</span>
                                <input
                                    type="text"
                                    value={qualification}
                                    onChange={(e) => setQualification(e.target.value)}
                                    required
                                />
                            </label>

                            <label>
                                Specialty <span className={classes.starSign}>*</span>
                                <input
                                    type="text"
                                    value={speciality}
                                    onChange={(e) => setSpeciality(e.target.value)}
                                    required
                                />
                            </label>
                            {/* {hideQualification && (
                                <div className={classes.optAllQualification}>
                                    {qualificationSearchResults.map((info, i) => (
                                        <div className={classes.optSelectQualification} key={i}>
                                            <div onClick={() => setHandleQualification(info)}>
                                                <option value={info}>{info}</option>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {hideSpeciality && (
                                <div className={classes.optAllSpeciality}>
                                    {specialitySearchResults.map((info, i) => (
                                        <div className={classes.optSelectSpeciality} key={i}>
                                            <div onClick={() => setHandleSpeciality(info)}>
                                                <option value={info}>{info}</option>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )} */}
                        </div>

                        <div className={classes.formGrid}>
                            <label>
                                Working Institute
                                <input type="text" value={institute} onChange={(e) => setInstitute(e.target.value)} />
                            </label>
                            <label>
                                Profile Picture <span className={classes.starSign}>*</span>
                                <input
                                    type="file"
                                    accept="image"
                                    onChange={(e) => setPicture(e.target.files[0])}
                                    title="Select Profile Picture"
                                    required
                                />
                            </label>
                        </div>

                        <div className={classes.formGrid}>
                            <label>
                                Password <span className={classes.starSign}>*</span>
                                <input
                                    type="text"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    minLength={4}
                                    required
                                />
                            </label>
                            <label>
                                Confirm Password <span className={classes.starSign}>*</span>
                                <input
                                    type="text"
                                    value={rePassword}
                                    onChange={(e) => setRePassword(e.target.value)}
                                    minLength={4}
                                    required
                                />
                            </label>
                        </div>
                    </div>
                    <button className={classes.button} type="submit">
                        SUBMIT
                    </button>
                    <div> {alert && <p className={classes.alertMessage}>Password not matched!</p>}</div>
                    <div> {alertFetch && <p className={classes.alertMessage}>{fetchInfo.context}</p>}</div>
                </form>
            </div>
        </div>
    )
}
