import { useContext, useEffect, useState } from 'react'
import { Auth } from '../../contexts/allContext'
import ActivityList from './ActivityList/ActivityList'

export default function Activity() {
    const [activities, setActivities] = useState([])
    const [pageSkip, setPageSkip] = useState(0)

    const api = process.env.REACT_APP_API_URL
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/admin/activity/log/all?skip=${pageSkip}&limit=10`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()

                if (response.ok) {
                    setActivities(data)
                    setLoading(false)
                }
            } catch {
                setActivities([])
            }
        }
        return fetchData()
    }, [token, api, pageSkip])

    return (
        <div>
            <div>
                <ActivityList activities={activities} pageSkip={pageSkip} setPageSkip={setPageSkip} loading={loading} />
            </div>
        </div>
    )
}
