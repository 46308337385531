import classes from './DeletePopup.module.css'

export default function DeletePopup({ close, del, state }) {
    return (
        <div className={state === 1 ? classes.deleteTop : state === 2 ? classes.deleteBottom : ''}>
            <div className={classes.div}>
                <span onClick={() => close(false)}>X</span>
                <button>Appointment</button>
                <button onClick={del}>Delete</button>
            </div>
        </div>
    )
}
