import { useContext, useEffect, useState } from 'react'
import { Auth } from '../../../contexts/allContext'
import { api } from '../../../utils/apiToken'
import CategoryForm from './CategoryForm/CategoryForm'
import CategoryList from './CategoryList/CategoryList'

export default function Category({ setDeviceOpen, setCategoryOpen }) {
    const [categories, setCategories] = useState([])
    const [listOpen, setListOpen] = useState(true)
    const [formOpen, setFormOpen] = useState(false)

    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/mediva/device/catagories/`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                setCategories(data)
            } catch {
                setCategories([])
            }
        }
        return fetchData()
    }, [token])

    return (
        <div>
            {formOpen && <CategoryForm setFormOpen={setFormOpen} setListOpen={setListOpen} />}
            {listOpen && (
                <CategoryList
                    setFormOpen={setFormOpen}
                    setListOpen={setListOpen}
                    setDeviceOpen={setDeviceOpen}
                    setCategoryOpen={setCategoryOpen}
                    categories={categories}
                />
            )}
        </div>
    )
}
