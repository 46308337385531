import { faEye, faLeaf } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Auth } from '../../../contexts/allContext'
import classes from './Login.module.css'

export default function Login() {
    const { stateAuth, dispatchAuth } = useContext(Auth)

    const [identifier, setIdentifier] = useState('')
    const [password, setPassword] = useState('')
    const [passShown, setPassShown] = useState(false)
    const [alert, setAlert] = useState(false)

    const navigate = useNavigate()
    const api = process.env.REACT_APP_API_URL

    const shownPassword = () => {
        setPassShown(!passShown)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        let logFetch = await fetch(`${api}/login`, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            dataType: 'json',
            method: 'POST',
            body: JSON.stringify({
                identifier,
                password,
            }),
        })

        let log = await logFetch.json()

        if (logFetch.ok) {
            dispatchAuth({ type: 'token', payload: log.access_token })
        } else {
            setAlert(true)
            setTimeout(function () {
                setAlert(false)
            }, 3000)
        }
    }

    useEffect(() => {
        if (stateAuth.auth) {
            navigate('/')
        } else {
            navigate('/login')
        }
    }, [navigate, stateAuth])

    return (
        <div className={classes.wrapper}>
            <Link to="/" className={classes.logo}>
                <FontAwesomeIcon className={classes.leaf} icon={faLeaf} title="Admin Panel" />
                Admin Panel
            </Link>
            <div className={classes.container} id="container">
                <div className={`${classes.formContainer} ${classes.signIn}`}>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <p>SIGN IN</p>
                        <input
                            placeholder="Phone or Email"
                            name="username"
                            type="text"
                            value={identifier}
                            onChange={(e) => setIdentifier(e.target.value)}
                            required
                        />
                        <FontAwesomeIcon
                            icon={faEye}
                            onClick={() => {
                                shownPassword()
                            }}
                            className={classes.iconEye}
                        />
                        <input
                            placeholder="Password"
                            name="password"
                            type={passShown ? 'text' : 'password'}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <Link to="#">Forgot your password?</Link>
                        <button type="submit">Sign In</button>
                    </form>
                </div>
                {alert && <p className={classes.alertMessage}>Invalid Username or Password!</p>}

                <div className={classes.overlayContainer}>
                    <div className={classes.overlay}>
                        <div className={`${classes.overlayPanel} ${classes.overlayRight}`}>
                            <h2>Echamber Admin Panel</h2>
                            <p>Click sign in & redircet to dashboard!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
