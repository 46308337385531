import { useContext } from 'react'
import { Auth } from '../../../../../contexts/allContext'
import { api } from '../../../../../utils/apiToken'
import { refreshPage } from '../../../../../utils/refreshPage'
import classes from './Popup.module.css'

export default function Popup({ index, setIsOpen, clinic }) {
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    const activate = async () => {
        let putFetch = await fetch(`${api}/admin/switch/active/clinic/${clinic.id}/${clinic.user_id}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })

        if (putFetch.ok) {
            refreshPage()
        }
    }

    return (
        <div key={() => index} className={classes.container}>
            <div className={classes.overlay} onClick={() => setIsOpen(false)}></div>
            <div className={classes.formWrapper}>
                <div className={classes.close} onClick={() => setIsOpen(false)}>
                    &times;
                </div>

                <p>{clinic.name}</p>

                <div className={classes.buttonContainer}>
                    <button className={classes.button} onClick={activate}>
                        Active (Yes)
                    </button>

                    <button className={classes.button} onClick={() => setIsOpen(false)}>
                        Close
                    </button>
                </div>
            </div>
        </div>
    )
}
