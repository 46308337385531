import { faEye, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import Oxy from '../../../../assets/oxymitter.png'
import { ListCount } from '../../../Resource'
import { Pagination } from '../../../index'
import classes from './DeviceList.module.css'

export default function DeviceList({
    devices,
    pageSkip,
    setPageSkip,
    setListOpen,
    setFormOpen,
    setDeviceOpen,
    setCategoryOpen,
}) {
    // const [isOpen, setIsOpen] = useState(false)
    const [search, setSearch] = useState('')
    let serial = 0

    const openClose = () => {
        setListOpen(false)
        setFormOpen(true)
    }

    const openCloseListForm = () => {
        setDeviceOpen(false)
        setCategoryOpen(true)
    }

    return (
        <div className={classes.tableContainer}>
            <div className={classes.wrapper}>
                <form action="">
                    <input
                        className={classes.searchField}
                        type="text"
                        value={search}
                        placeholder="Search Product"
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <button className={classes.searchButton}>
                        <FontAwesomeIcon icon={faSearch} />
                    </button>
                </form>

                <div className={classes.allButton}>
                    <button className={classes.button} onClick={() => openCloseListForm()}>
                        View Category
                    </button>
                    <span></span>
                    <button className={`${classes.button} ${classes.buttonMargin}`} onClick={() => openClose()}>
                        + Add Device
                    </button>
                </div>
            </div>

            <ListCount title="Device List" results={devices} />
            <table className={classes.tableMain}>
                <thead>
                    <tr className={classes.tableRow}>
                        <th>Sl</th>
                        <th>Name</th>
                        <th>Brand</th>
                        <th>Model</th>
                        <th>Category</th>
                        <th>Details</th>
                        {/* <th>Old Price</th> */}
                        <th>Current Price</th>
                        <th>Image</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {devices[1] &&
                        devices[1].map((device, index) => (
                            <tr className={classes.tableRow} key={index}>
                                <td>{(serial = serial + 1) + pageSkip}</td>
                                <td>{device.name}</td>
                                <td>{device.brand}</td>
                                <td>{device.model}</td>
                                <td>{device.catagory_id}</td>
                                <td>{device.details}</td>
                                <td>৳{device.current_mrp}</td>
                                <td>
                                    <img src={Oxy} alt="Pic" />
                                </td>
                                <td>
                                    <button className={classes.icon}>
                                        <FontAwesomeIcon icon={faEye} />
                                    </button>
                                </td>

                                {/* {isOpen === index && (
                                            <div key={() => index} className={classes.container}>
                                                <div className={classes.formWrapper}>
                                                    <div className={classes.close} onClick={() => setIsOpen(false)}>
                                                        &times;
                                                    </div>

                                                    <table className={classes.tableMain}>
                                                        <thead className={classes.formHead}>
                                                            <tr>
                                                                <th>Chamber</th>
                                                                <th>Details</th>
                                                                <th>Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {chambers &&
                                                                chambers.map((chamber, i) => (
                                                                    <tr className={classes.alert} key={i}>
                                                                        <td>{chamber.name}</td>
                                                                        <td>{chamber.detail}</td>
                                                                        <td className={classes.status}>
                                                                            {chamber.is_active === true ? (
                                                                                <span className={classes.active}>
                                                                                    Active
                                                                                </span>
                                                                            ) : (
                                                                                <span className={classes.delete}>
                                                                                    Off
                                                                                </span>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                        </tbody>
                                                    </table>
                                                    <div className={classes.buttonContainer}>
                                                        <button
                                                            className={classes.button}
                                                            onClick={() => setIsOpen(false)}>
                                                            Close
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )} */}
                            </tr>
                        ))}
                </tbody>
            </table>
            <Pagination
                pageSkip={pageSkip}
                setPageSkip={setPageSkip}
                listItem={devices[1]}
                pageAll={devices[0]?.results}
            />
        </div>
    )
}
