import { faLeaf, faThumbtack } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { useContext } from 'react'
import { Auth, UserInfo } from '../../../contexts/allContext'
import { api } from '../../../utils/apiToken'
import { currentDate } from '../../../utils/date'
import HealthPlan from './HealthPlan/HealthPlan'
import Labtest from './Labtest/Labtest'
import MedicineAdd from './Medicine/MedicineAdd'
import classes from './ServiceOrder.module.css'
import Telemedicine from './Telemedicine/Telemedicine'

export default function ServiceOrder({
    setListOpen,
    setFormOpen,
    patients,
    plans,
    search,
    setSearch,
    searchResults,
    setSearchResults,
}) {
    const [serviceName, setServiceName] = useState('')
    const [orderDate, setOrderDate] = useState(null)
    const [deliveryDate, setDeliveryDate] = useState(null)
    const [serviceInfo, setServiceInfo] = useState('')
    const [patientId, setPatientId] = useState()
    const [patientAddress, setPatientAddress] = useState('')

    const [totalAmount, setTotalAmount] = useState()
    const [discount, setDiscount] = useState(0)
    let [payableAmount, setPayableAmount] = useState()
    const [paidAmount, setPaidAmount] = useState()
    let [dueAmount, setDueAmount] = useState()
    const [paymentMethod, setPaymentMethod] = useState('')
    const [paymentDate, setPaymentDate] = useState(null)
    const [paymentStatus, setPaymentStatus] = useState('')
    const [paymentCharge, setPaymentCharge] = useState(0)

    const [providerType, setProviderType] = useState('')
    const [providerId, setProviderId] = useState(1)
    const [providerFee, setProviderFee] = useState()
    const [providerFeePaid, setProviderFeePaid] = useState()
    let [providerFeeDue, setProviderFeeDue] = useState()
    const [providerPaymentStatus, setProviderPaymentStatus] = useState('')
    const [providerUpdateDate, setProviderUpdateDate] = useState(null)

    const [referralSelect, setReferralSelect] = useState('')
    const [referralType, setReferralType] = useState(null)
    const [referralId, setReferralId] = useState(null)
    const [referralFee, setReferralFee] = useState(null)
    const [referralFeePaid, setReferralFeePaid] = useState(null)
    let [referralFeeDue, setReferralFeeDue] = useState(null)
    const [referralPaymentStatus, setReferralPaymentStatus] = useState(null)
    const [referralUpdateDate, setReferralUpdateDate] = useState(null)

    const [lines, setLines] = useState([{}])
    const [planId, setPlanId] = useState(1)
    const [healthPlan, setHealthPlan] = useState({})

    const [hide, setHide] = useState(false)
    const [paymentHide, setPaymentHide] = useState(false)
    const [telemedicineHide, setTelemedicineHide] = useState(false)
    const [healthPlanHide, setHealthPlanHide] = useState(false)
    const [medicineHide, setMedicineHide] = useState(false)
    const [referralHide, setReferralHide] = useState(false)

    const [apiServiceName, setApiServiceName] = useState('')

    const [searchDoctor, setSearchDoctor] = useState('')
    const [doctors, setDoctors] = useState([])
    const [hideDoc, setHideDoc] = useState(false)
    const [infoDoc, setInfoDoc] = useState({})

    const [labtestHide, setLabtestHide] = useState(false)
    const [lablines, setLablines] = useState([{}])

    const { stateAuth } = useContext(Auth)
    const { stateUser } = useContext(UserInfo)
    const user = stateUser.info
    const token = stateAuth.token

    // medicine price calculate
    const calculateTotalAmount = () => {
        let totalDisplay = 0
        lines.forEach((item) => {
            totalDisplay += item.total
        })
        setTotalAmount(parseInt(totalDisplay))
        setPayableAmount(parseInt(totalDisplay - totalDisplay * (discount / 100)))
    }

    // labtest price calculate
    const calculateTotalAmountlabtest = () => {
        let totalDisplay = 0
        lablines.forEach((item) => {
            totalDisplay += item.total
        })
        setTotalAmount(parseInt(totalDisplay))
        setPayableAmount(parseInt(totalDisplay - totalDisplay * (discount / 100)))
    }

    useEffect(() => {
        calculateTotalAmount()
        // calculateTotalAmountlabtest()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lines])

    useEffect(() => {
        calculateTotalAmountlabtest()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lablines])

    const openClose = () => {
        setListOpen(true)
        setFormOpen(false)
    }

    // service selection //
    useEffect(() => {
        if (serviceName === 'telemedicine') {
            setApiServiceName('telemedicine')
            setProviderType('doctor')
            setTelemedicineHide(true)
        } else {
            setTelemedicineHide(false)
        }
    }, [serviceName])

    useEffect(() => {
        if (serviceName === 'health_plan') {
            setApiServiceName(`healthplan/subscribe?voucher_code=${healthPlan?.voucher_code}`)
            setProviderType('doctor')
            setHealthPlanHide(true)
        } else {
            setHealthPlanHide(false)
        }
    }, [serviceName, healthPlan?.voucher_code])

    useEffect(() => {
        if (serviceName === 'medicine') {
            setApiServiceName('medicine')
            setProviderType('pharmacy')
            setMedicineHide(true)
        } else {
            setMedicineHide(false)
        }
    }, [serviceName])

    useEffect(() => {
        if (serviceName === 'lab_test') {
            setApiServiceName('labtest')
            setProviderType('lab')
            setLabtestHide(true)
        } else {
            setLabtestHide(false)
        }
    }, [serviceName])

    useEffect(() => {
        if (serviceName === '') {
            setProviderType('')
        }
    }, [serviceName])
    // end //

    useEffect(() => {
        if (paymentMethod === 'bkash') {
            setPaymentHide(true)
        } else {
            setPaymentHide(false)
        }
    }, [paymentMethod])

    useEffect(() => {
        if (referralSelect === 'yes') {
            setReferralHide(true)
        } else {
            setReferralHide(false)
        }
    }, [referralSelect])

    const handler = (search) => {
        let matches = []
        if (search.length > 1) {
            matches = patients[1].filter((patient) => patient.phone.toLowerCase().includes(search))
            setHide(true)
        }
        if (search.length < 1) {
            setHide(false)
        }
        setSearch(search)
        setSearchResults(matches)
    }

    const setHandle = (search) => {
        setSearch(search)
        setPatientId(search?.id)
        setSearchResults([])
        setHide(false)
    }

    // plan fetch//
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/health-plan/${planId}`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                setHealthPlan(data)
            } catch {
                setHealthPlan({})
            }
        }
        return fetchData()
    }, [token, planId])

    // doctor search //
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/doctors/search/?search=${searchDoctor}&skip=0&limit=20`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                setDoctors(data)
            } catch {
                setDoctors([])
            }
        }
        return fetchData()
    }, [token, searchDoctor])

    const doctorHandle = (searchDoctor) => {
        if (searchDoctor.length > 1) {
            setHideDoc(true)
        }
        if (searchDoctor.length < 1) {
            setHideDoc(false)
        }
        setSearchDoctor(searchDoctor)
    }

    const handlePayment = () => {
        payableAmount = totalAmount - totalAmount * (discount / 100)
        setPayableAmount(Math.ceil(payableAmount))

        payableAmount = payableAmount + payableAmount * (paymentCharge / 1000)
        setPayableAmount(Math.ceil(payableAmount))

        dueAmount = payableAmount - paidAmount
        setDueAmount(Math.ceil(dueAmount))
    }

    const providerPayment = () => {
        providerFeeDue = providerFee - providerFeePaid
        setProviderFeeDue(providerFeeDue)
    }

    const referralPayment = () => {
        referralFeeDue = referralFee - referralFeePaid
        setReferralFeeDue(referralFeeDue)
    }

    const refreshPage = () => {
        window.location.reload()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const details = {
            service_name: serviceName,
            service_issuer_id: user.id,
            service_issuer_type:
                user.role_name === 'admin' || user.role_name === 'moderator' || user.role_name === 'crm'
                    ? 'digital'
                    : user.role_name === 'sales'
                    ? 'field'
                    : '',
            patient_id: patientId,
            order_placement: orderDate,
            order_completion: deliveryDate,
            remarks: serviceInfo,
            current_address: patientAddress,

            order_value: totalAmount,
            order_status: 'pending',
            discount_percent: discount,
            payable_amount: payableAmount,
            payment_by_customer: paidAmount,
            payment_pending: dueAmount,
            last_payment_date: paymentDate,
            payment_method: paymentMethod,
            payment_status: paymentStatus,

            service_provider_type: providerType,
            service_provider_id: providerId,
            service_provider_fee: providerFee,
            service_provider_fee_paid: providerFeePaid,
            service_provider_fee_pending: providerFeeDue,
            service_provider_fee_last_update: providerUpdateDate,
            service_provider_fee_status: providerPaymentStatus,

            referral_type: referralType,
            referral_id: referralId,
            referral_provider_fee: referralFee,
            referral_provider_fee_paid: referralFeePaid,
            referral_provider_fee_pending: referralFeeDue,
            referral_provider_fee_last_update: referralUpdateDate,
            referral_provider_fee_status: referralPaymentStatus,
        }

        // service details for post //
        const detailsTelemedicine = {
            service: details,
            telemedicine: {
                health_plan_id: planId,
                patient_id: patientId,
                doctor_id: providerId,
                schedule_id: 0,
                booked_date: currentDate,
            },
        }

        const detailsHealthPlan = {
            service: details,
            health_plan_subscribe: {
                user_id: patientId,
                register_by_id: patientId,
                discount_percent: discount,
                days: healthPlan?.days,
                fixed_amount: true,
                amount: payableAmount,
            },
        }

        const detailsMedicine = [details, lines]

        const detailsLabtest = [details, lablines]
        // end //

        let postFetch = await fetch(`${api}/service/${apiServiceName}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(
                apiServiceName === 'telemedicine'
                    ? detailsTelemedicine
                    : apiServiceName === `healthplan/subscribe?voucher_code=${healthPlan?.voucher_code}`
                    ? detailsHealthPlan
                    : apiServiceName === 'medicine'
                    ? detailsMedicine
                    : apiServiceName === 'labtest'
                    ? detailsLabtest
                    : ''
            ),
        })

        if (postFetch.ok) {
            alert(`${apiServiceName} successful`)
            refreshPage()
        }
    }

    return (
        <div>
            <div className={classes.wrapper}>
                <button className={classes.button} onClick={() => openClose()}>
                    Service List
                </button>
            </div>
            <div className={classes.formWrapper}>
                <FontAwesomeIcon className={classes.icon} icon={faThumbtack} />
                <div className={classes.logo}>SERVICE ORDER DASHBOARD</div>
                <div className={classes.form}>
                    <div className={classes.section}>
                        <FontAwesomeIcon className={classes.leaf} icon={faLeaf} />
                        Service Info
                    </div>
                    <div className={classes.innerWrap}>
                        <div className={classes.container}>
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <ul>
                                    <li>
                                        <input type="checkbox" id="listItem1" />
                                        <label htmlFor="listItem1" className={classes.labelMain}>
                                            Service
                                        </label>
                                        <ul>
                                            <div className={classes.innerContainer}>
                                                <div className={classes.collab}>
                                                    <div className={classes.formGrid}>
                                                        <label>
                                                            Input Mobile <span className={classes.starSign}>*</span>
                                                            <input
                                                                type="tel"
                                                                placeholder="search patient"
                                                                value={search.phone}
                                                                onChange={(e) => handler(e.target.value)}
                                                                required
                                                            />
                                                        </label>
                                                        <label>
                                                            Patient Name
                                                            <span className={classes.fetchLabel}>
                                                                {search.name} <p>.</p>
                                                            </span>
                                                        </label>
                                                    </div>
                                                    {hide && (
                                                        <div className={classes.optAll}>
                                                            {searchResults &&
                                                                searchResults.map((info, i) => (
                                                                    <div className={classes.optSelect} key={i}>
                                                                        <div onClick={() => setHandle(info)}>
                                                                            <option value={info.id}>
                                                                                {info.phone} - {info.name}
                                                                            </option>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    )}
                                                    <div className={classes.formGrid}>
                                                        <label>
                                                            Service Name <span className={classes.starSign}>*</span>
                                                            <select
                                                                onChange={(e) => {
                                                                    setServiceName(e.target.value)
                                                                    setTotalAmount()
                                                                    setPayableAmount()
                                                                }}
                                                                required
                                                                className={classes.select}>
                                                                <option value="">Select</option>
                                                                {/* <option value="pathology">Pathology</option> */}
                                                                <option value="telemedicine">Telemedicine</option>
                                                                <option value="health_plan">Health Plan</option>
                                                                <option value="medicine">Medicine</option>
                                                                <option value="lab_test">Lab Test</option>
                                                            </select>
                                                        </label>
                                                        <div className={`${classes.formGrid} ${classes.formMargin}`}>
                                                            <label>
                                                                Order Date <span className={classes.starSign}>*</span>
                                                                <input
                                                                    type="datetime-local"
                                                                    value={orderDate}
                                                                    onChange={(e) => setOrderDate(e.target.value)}
                                                                    required
                                                                />
                                                            </label>
                                                            <label>
                                                                Delivery Date
                                                                <input
                                                                    type="datetime-local"
                                                                    value={deliveryDate}
                                                                    onChange={(e) => setDeliveryDate(e.target.value)}
                                                                />
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <label>
                                                        Service Remarks
                                                        <input
                                                            type="text"
                                                            value={serviceInfo}
                                                            onChange={(e) => setServiceInfo(e.target.value)}
                                                        />
                                                    </label>
                                                    <label>
                                                        Delivery Address <span className={classes.starSign}>*</span>
                                                        <textarea
                                                            type="text"
                                                            rows={2}
                                                            value={patientAddress}
                                                            onChange={(e) => setPatientAddress(e.target.value)}
                                                            required
                                                        />
                                                    </label>
                                                </div>

                                                {telemedicineHide && (
                                                    <div className={`${classes.innerContainer} ${classes.innerMargin}`}>
                                                        <p>Add Telemedicine</p>
                                                        <div className={classes.collab}>
                                                            <Telemedicine />
                                                        </div>
                                                    </div>
                                                )}

                                                {healthPlanHide && (
                                                    <div className={`${classes.innerContainer} ${classes.innerMargin}`}>
                                                        <p>Add Health Plan</p>
                                                        <div className={classes.collab}>
                                                            <HealthPlan
                                                                plans={plans}
                                                                setPlanId={setPlanId}
                                                                setTotalAmount={setTotalAmount}
                                                                setPayableAmount={setPayableAmount}
                                                            />
                                                        </div>
                                                    </div>
                                                )}

                                                {medicineHide && (
                                                    <div className={`${classes.innerContainer} ${classes.innerMargin}`}>
                                                        <p>Add Medicine</p>
                                                        <div className={classes.collab}>
                                                            {lines.map((line, i) => (
                                                                <MedicineAdd
                                                                    lines={lines}
                                                                    setLines={setLines}
                                                                    index={i}
                                                                    key={i}
                                                                />
                                                            ))}
                                                            <button
                                                                className={classes.buttonAdd}
                                                                onClick={() => setLines((prev) => prev.concat({}))}
                                                                type="button">
                                                                + Add Medicine
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}

                                                {labtestHide && (
                                                    <div className={`${classes.innerContainer} ${classes.innerMargin}`}>
                                                        <p>Add Lab Test</p>
                                                        <div className={classes.collab}>
                                                            {lablines.map((line, i) => (
                                                                <Labtest
                                                                    lablines={lablines}
                                                                    setLablines={setLablines}
                                                                    index={i}
                                                                    key={i}
                                                                />
                                                            ))}
                                                            <button
                                                                className={classes.buttonAdd}
                                                                onClick={() => setLablines((prev) => prev.concat({}))}
                                                                type="button">
                                                                + Add Lab Test
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </ul>
                                    </li>

                                    <li>
                                        <input type="checkbox" id="listItem3" />
                                        <label htmlFor="listItem3" className={classes.labelMain}>
                                            Provider
                                        </label>
                                        <ul>
                                            <div className={classes.innerContainer}>
                                                <div className={classes.collab}>
                                                    <label>
                                                        Provider Type <span className={classes.starSign}>*</span>
                                                        <select
                                                            value={providerType}
                                                            onChange={(e) => setProviderType(e.target.value)}
                                                            required
                                                            className={classes.select}>
                                                            <option value="">Select</option>
                                                            <option value="doctor">Doctor</option>
                                                            <option value="pharmacy">Pharmacy</option>
                                                            <option value="lab">Lab</option>
                                                        </select>
                                                    </label>
                                                    <div className={classes.formGrid}>
                                                        <label className={classes.relative}>
                                                            Input Name
                                                            <input
                                                                type="text"
                                                                value={searchDoctor}
                                                                onChange={(e) => doctorHandle(e.target.value)}
                                                                placeholder="search provider"
                                                            />
                                                            {searchDoctor.length > 0 ? (
                                                                <span
                                                                    className={classes.cross}
                                                                    onClick={() => {
                                                                        setSearchDoctor('')
                                                                        setInfoDoc({})
                                                                        setDoctors([])
                                                                        setHideDoc(false)
                                                                        setTotalAmount()
                                                                        setPayableAmount()
                                                                        setProviderFee()
                                                                    }}>
                                                                    x
                                                                </span>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </label>

                                                        {hideDoc && (
                                                            <div className={classes.optAllDoc}>
                                                                {doctors &&
                                                                    doctors.map((info, i) => (
                                                                        <div className={classes.optSelect} key={i}>
                                                                            <div
                                                                                onClick={() => {
                                                                                    setProviderId(info.id)
                                                                                    setTotalAmount(
                                                                                        parseInt(
                                                                                            info?.doctor[0]
                                                                                                ?.online_total_fees
                                                                                        )
                                                                                    )
                                                                                    setPayableAmount(
                                                                                        parseInt(
                                                                                            info?.doctor[0]
                                                                                                ?.online_total_fees
                                                                                        )
                                                                                    )
                                                                                    setProviderFee(
                                                                                        parseInt(
                                                                                            info?.doctor[0]?.online_fees
                                                                                        )
                                                                                    )
                                                                                    setInfoDoc(info)
                                                                                    setSearchDoctor(
                                                                                        `${
                                                                                            info?.doctor[0]?.dr_title ||
                                                                                            ''
                                                                                        } ${info?.name}`
                                                                                    )
                                                                                    setDoctors([])
                                                                                    setHideDoc(false)
                                                                                }}>
                                                                                <div value={info?.id}>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: '10px',
                                                                                            paddingRight: '4px',
                                                                                        }}>
                                                                                        {i + 1}.
                                                                                    </span>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: '14px',
                                                                                        }}>{`${
                                                                                        info?.doctor[0]?.dr_title || ''
                                                                                    } ${info?.name}`}</span>
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: '11px',
                                                                                            paddingLeft: '4px',
                                                                                        }}>
                                                                                        [{info?.doctor[0]?.bmdc}]
                                                                                    </span>
                                                                                    <br />
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: '12px',
                                                                                        }}>
                                                                                        {
                                                                                            info?.specialities[0]
                                                                                                ?.speciality
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                            </div>
                                                        )}

                                                        <label>
                                                            Provider Phone
                                                            <span className={classes.fetchLabel}>
                                                                {infoDoc?.phone} <p>.</p>
                                                            </span>
                                                        </label>
                                                    </div>

                                                    <div className={classes.formGrid}>
                                                        <label>
                                                            Provider Fee
                                                            <span className={classes.fetchLabel}>
                                                                {providerFee} <p>.</p>
                                                            </span>
                                                        </label>

                                                        <div className={`${classes.formGrid} ${classes.formMargin}`}>
                                                            <label>
                                                                Paid Amount
                                                                <input
                                                                    type="number"
                                                                    value={providerFeePaid}
                                                                    onChange={(e) =>
                                                                        setProviderFeePaid(parseInt(e.target.value))
                                                                    }
                                                                    onBlur={(e) => providerPayment(e)}
                                                                />
                                                            </label>
                                                            <label>
                                                                Pending Amount
                                                                <input
                                                                    type="number"
                                                                    value={providerFeeDue}
                                                                    onChange={(e) =>
                                                                        setProviderFeeDue(parseInt(e.target.value))
                                                                    }
                                                                    onBlur={(e) => providerPayment(e)}
                                                                />
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className={classes.formGrid}>
                                                        <label>
                                                            Payment Status
                                                            <select
                                                                onChange={(e) =>
                                                                    setProviderPaymentStatus(e.target.value)
                                                                }
                                                                className={classes.select}>
                                                                <option value="">Select</option>
                                                                <option value="done">Done</option>
                                                                <option value="pending">Pending</option>
                                                            </select>
                                                        </label>
                                                        <label>
                                                            Payment Date
                                                            <input
                                                                type="datetime-local"
                                                                value={providerUpdateDate}
                                                                onChange={(e) => setProviderUpdateDate(e.target.value)}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </ul>
                                    </li>

                                    <li>
                                        <input type="checkbox" id="listItem2" />
                                        <label htmlFor="listItem2" className={classes.labelMain}>
                                            Payment
                                        </label>
                                        <ul>
                                            <div className={classes.innerContainer}>
                                                <div className={classes.collab}>
                                                    <div className={classes.formGrid}>
                                                        <label>
                                                            Payment Method
                                                            <select
                                                                onChange={(e) => setPaymentMethod(e.target.value)}
                                                                className={classes.select}>
                                                                <option value="">Select</option>
                                                                <option value="cash on delivery">
                                                                    Cash On Delivery
                                                                </option>
                                                                <option value="bkash">Bkash</option>
                                                            </select>
                                                        </label>
                                                        <div className={`${classes.formGrid} ${classes.formMargin}`}>
                                                            <label>
                                                                Payment Date
                                                                <input
                                                                    type="datetime-local"
                                                                    value={paymentDate}
                                                                    onChange={(e) => setPaymentDate(e.target.value)}
                                                                />
                                                            </label>
                                                            <label>
                                                                Payment Status{' '}
                                                                <span className={classes.starSign}>*</span>
                                                                <select
                                                                    onChange={(e) => setPaymentStatus(e.target.value)}
                                                                    required
                                                                    className={classes.select}>
                                                                    <option value="">Select</option>
                                                                    <option value="done">Done</option>
                                                                    <option value="pending">Pending</option>
                                                                </select>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className={classes.formGrid}>
                                                        <label>
                                                            Total Amount <span className={classes.starSign}>*</span>
                                                            <span className={classes.fetchLabel}>
                                                                {totalAmount} <p>.</p>
                                                            </span>
                                                        </label>

                                                        <label>
                                                            Discount (%) <span className={classes.starSign}>*</span>
                                                            <input
                                                                type="number"
                                                                value={discount}
                                                                onChange={(e) => setDiscount(parseInt(e.target.value))}
                                                                onBlur={(e) => handlePayment(e)}
                                                                min={0}
                                                                required
                                                            />
                                                        </label>
                                                    </div>
                                                    {paymentHide && (
                                                        <div className={classes.formGrid}>
                                                            <label>
                                                                Charge Fee
                                                                <input
                                                                    type="number"
                                                                    value={paymentCharge}
                                                                    onChange={(e) =>
                                                                        setPaymentCharge(parseInt(e.target.value))
                                                                    }
                                                                    onBlur={(e) => handlePayment(e)}
                                                                    min={0}
                                                                />
                                                            </label>
                                                        </div>
                                                    )}
                                                    <div className={classes.formGrid}>
                                                        <label>
                                                            Payable Amount
                                                            <input
                                                                type="number"
                                                                value={payableAmount}
                                                                onChange={(e) =>
                                                                    setPayableAmount(parseInt(e.target.value))
                                                                }
                                                                onBlur={(e) => {
                                                                    handlePayment(e)
                                                                }}
                                                                min={0}
                                                                required
                                                            />
                                                        </label>

                                                        <div className={`${classes.formGrid} ${classes.formMargin}`}>
                                                            <label>
                                                                Paid Amount <span className={classes.starSign}>*</span>
                                                                <input
                                                                    type="number"
                                                                    value={paidAmount}
                                                                    onChange={(e) =>
                                                                        setPaidAmount(parseInt(e.target.value))
                                                                    }
                                                                    onBlur={(e) => handlePayment(e)}
                                                                    min={0}
                                                                    required
                                                                />
                                                            </label>
                                                            <label>
                                                                Due Amount
                                                                <input
                                                                    type="number"
                                                                    value={dueAmount}
                                                                    onChange={(e) =>
                                                                        setDueAmount(parseInt(e.target.value))
                                                                    }
                                                                    onBlur={(e) => handlePayment(e)}
                                                                    min={0}
                                                                    required
                                                                />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ul>
                                    </li>

                                    <li>
                                        <input type="checkbox" id="listItem4" />
                                        <label htmlFor="listItem4" className={classes.labelMain}>
                                            Referral
                                        </label>
                                        <ul>
                                            <div className={classes.innerContainer}>
                                                <div className={classes.collab}>
                                                    <label>
                                                        Select Referral If Any
                                                        <select
                                                            onChange={(e) => setReferralSelect(e.target.value)}
                                                            className={classes.select}>
                                                            <option value="">Select</option>
                                                            <option value="yes">Yes</option>
                                                            <option value="no">No</option>
                                                        </select>
                                                    </label>

                                                    <div className={classes.refMargin}></div>
                                                    {referralHide && (
                                                        <>
                                                            <label>
                                                                Referral Type
                                                                <select
                                                                    onChange={(e) => setReferralType(e.target.value)}
                                                                    required
                                                                    className={classes.select}>
                                                                    <option value="">Select</option>
                                                                    <option value="doctor">Doctor</option>
                                                                    <option value="partner">Partner</option>
                                                                    <option value="employee">Employee</option>
                                                                    <option value="pharmacy">Pharmacy</option>
                                                                </select>
                                                            </label>
                                                            <div className={classes.formGrid}>
                                                                <label>
                                                                    Input Mobile
                                                                    <input type="tel" placeholder="search referral" />
                                                                </label>
                                                                <label>
                                                                    Referral Name
                                                                    <span className={classes.fetchLabel}>
                                                                        <p>.</p>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className={classes.formGrid}>
                                                                <label>
                                                                    Referral Fee
                                                                    <input
                                                                        type="number"
                                                                        value={referralFee}
                                                                        onChange={(e) =>
                                                                            setReferralFee(parseInt(e.target.value))
                                                                        }
                                                                        onBlur={(e) => referralPayment(e)}
                                                                        required
                                                                    />
                                                                </label>
                                                                <div
                                                                    className={`${classes.formGrid} ${classes.formMargin}`}>
                                                                    <label>
                                                                        Paid Amount
                                                                        <input
                                                                            type="number"
                                                                            value={referralFeePaid}
                                                                            onChange={(e) =>
                                                                                setReferralFeePaid(
                                                                                    parseInt(e.target.value)
                                                                                )
                                                                            }
                                                                            onBlur={(e) => referralPayment(e)}
                                                                            required
                                                                        />
                                                                    </label>
                                                                    <label>
                                                                        Pending Amount
                                                                        <input
                                                                            type="number"
                                                                            value={referralFeeDue}
                                                                            onChange={(e) =>
                                                                                setReferralFeeDue(
                                                                                    parseInt(e.target.value)
                                                                                )
                                                                            }
                                                                            onBlur={(e) => referralPayment(e)}
                                                                            required
                                                                        />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className={classes.formGrid}>
                                                                <label>
                                                                    Payment Status
                                                                    <select
                                                                        onChange={(e) =>
                                                                            setReferralPaymentStatus(e.target.value)
                                                                        }
                                                                        required
                                                                        className={classes.select}>
                                                                        <option value="">Select</option>
                                                                        <option value="done">Done</option>
                                                                        <option value="pending">Pending</option>
                                                                    </select>
                                                                </label>
                                                                <label>
                                                                    Update Date
                                                                    <input
                                                                        type="datetime-local"
                                                                        value={referralUpdateDate}
                                                                        onChange={(e) =>
                                                                            setReferralUpdateDate(e.target.value)
                                                                        }
                                                                        required
                                                                    />
                                                                </label>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </ul>
                                    </li>
                                </ul>
                                <button type="submit" className={classes.button}>
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
