import { faEye, faFileLines, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Link } from 'react-router-dom'
import { ServiceId } from '../../../contexts/allContext'
import { randomNumber } from '../../../utils/random'
import { ListCount } from '../../Resource'
import { PatientProfile, Loading, Pagination } from '../../index'
import './DatePicker.css'
import ListDetail from './ListDetail/ListDetail'
import classes from './ServiceOrderList.module.css'
import { ServicePdfPrint } from './ServicePdf/ServicePdfPrint'

export default function ServiceOrderList(props) {
    const [isOpen, setIsOpen] = useState(false)
    const [pdfOpen, setPdfOpen] = useState(false)
    let serial = 0

    // const { dispatchServiceid } = useContext(ServiceId)

    const [oepnProfile, setOpenProfile] = useState(false)

    const openClose = () => {
        props.setListOpen(false)
        props.setFormOpen(true)
    }

    const handleStartDateChange = (date) => {
        props.setStartDate(date)
    }
    const handleEndDateChange = (date) => {
        props.setEndDate(date)
    }

    return (
        <div className={classes.tableContainer}>
            <div className={classes.wrapper}>
                <div className={classes.boxSearch}>
                    <div>
                        <input
                            className={classes.searchField}
                            type="number"
                            value={props.searchServiceId}
                            onChange={(e) => {
                                props.setSearchServiceId(e.target.value)
                                // dispatchServiceid({ type: 'set', payload: e.target.value })
                            }}
                            placeholder="Search Service by Service ID#"
                        />
                        {props.searchServiceId.length > 0 ? (
                            <FontAwesomeIcon
                                icon={faXmark}
                                className={classes.xmark}
                                onClick={() => {
                                    props.setSearchServiceId('')
                                    // dispatchServiceid({ type: 'remove' })
                                }}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faSearch} />
                        )}

                        <input
                            className={classes.searchField}
                            type="number"
                            value={props.searchPatientId}
                            onChange={(e) => props.setSearchPatientId(e.target.value)}
                            placeholder="Search Service by Patient ID#"
                        />
                        {props.searchPatientId.length > 0 ? (
                            <FontAwesomeIcon
                                icon={faXmark}
                                className={classes.xmark}
                                onClick={() => props.setSearchPatientId('')}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faSearch} />
                        )}

                        <input
                            className={classes.searchField}
                            type="text"
                            value={props.searchPatientName}
                            placeholder="Search Service By Patient Name"
                            onChange={(e) => props.setSearchPatientName(e.target.value)}
                        />
                        {props.searchPatientName.length > 0 ? (
                            <FontAwesomeIcon
                                icon={faXmark}
                                className={classes.xmark}
                                onClick={() => props.setSearchPatientName('')}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faSearch} />
                        )}

                        <input
                            className={classes.searchField}
                            type="text"
                            value={props.searchPhone}
                            placeholder="Search Service by Patient Phone"
                            onChange={(e) => props.setSearchPhone(e.target.value)}
                        />
                        {props.searchPhone.length > 0 ? (
                            <FontAwesomeIcon
                                icon={faXmark}
                                className={classes.xmark}
                                onClick={() => props.setSearchPhone('')}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faSearch} />
                        )}

                        <input
                            className={classes.searchField}
                            type="text"
                            value={props.searchAddress}
                            placeholder="Search Service by Address"
                            onChange={(e) => props.setSearchAddress(e.target.value)}
                        />
                        {props.searchAddress.length > 0 ? (
                            <FontAwesomeIcon
                                icon={faXmark}
                                className={classes.xmark}
                                onClick={() => props.setSearchAddress('')}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faSearch} />
                        )}

                        <input
                            className={classes.searchField}
                            type="date"
                            value={props.searchStartDate}
                            onChange={(e) => props.setSearchStartDate(e.target.value)}
                        />
                        {props.searchStartDate.length > 0 ? (
                            <FontAwesomeIcon
                                icon={faXmark}
                                className={classes.xmarkPick}
                                onClick={() => props.setSearchStartDate('')}
                            />
                        ) : (
                            ''
                        )}

                        <select
                            className={classes.select}
                            value={props.searchServiceName}
                            onChange={(e) => props.setSearchServiceName(e.target.value)}>
                            <option value="">Select Service Name</option>
                            <option value="">All</option>
                            <option value="telemedicine">Telemedicine</option>
                            <option value="health_plan">Health Plan</option>
                            <option value="medicine_order">Medicine Order</option>
                            <option value="lab_test">Lab Test</option>
                        </select>
                        {props.searchServiceName.length > 0 ? (
                            <FontAwesomeIcon
                                icon={faXmark}
                                className={classes.xmarkPick}
                                onClick={() => props.setSearchServiceName('')}
                            />
                        ) : (
                            ''
                        )}

                        <select
                            className={classes.select}
                            value={props.searchOrderStatus}
                            onChange={(e) => props.setSearchOrderStatus(e.target.value)}>
                            <option value="">Select Order Status</option>
                            <option value="">All</option>
                            <option value="pending">Pending</option>
                            <option value="processing">Processing</option>
                            <option value="running">Running</option>
                            <option value="done">Done</option>
                            <option value="emergency">Emergency</option>
                            <option value="cancel">Cancel</option>
                        </select>
                        {props.searchOrderStatus.length > 0 ? (
                            <FontAwesomeIcon
                                icon={faXmark}
                                className={classes.xmarkPick}
                                onClick={() => props.setSearchOrderStatus('')}
                            />
                        ) : (
                            ''
                        )}
                    </div>

                    <div className="datepicker">
                        <div>
                            <DatePicker
                                selected={props.startDate}
                                onChange={handleStartDateChange}
                                selectsStart
                                startDate={props.startDate}
                                endDate={props.endDate}
                                placeholderText="Select Start Date"
                                dateFormat="dd.MM.yyyy"
                            />
                        </div>
                        <div>
                            <DatePicker
                                selected={props.endDate}
                                onChange={handleEndDateChange}
                                selectsEnd
                                startDate={props.startDate}
                                endDate={props.endDate}
                                placeholderText="Select End Date"
                                dateFormat="dd.MM.yyyy"
                            />
                        </div>
                        {props.startDate || props.endDate ? (
                            <FontAwesomeIcon
                                icon={faXmark}
                                className={classes.xmarkDate}
                                onClick={() => {
                                    handleStartDateChange(null)
                                    handleEndDateChange(null)
                                }}
                            />
                        ) : (
                            ''
                        )}
                    </div>
                </div>
                <div className={classes.boxBtn}>
                    <button className={classes.button} onClick={() => openClose()}>
                        + Add Service
                    </button>
                </div>
            </div>

            <ListCount title="Service List" results={props.services} />

            <table className={classes.tableMain}>
                <thead>
                    <tr className={classes.tableRow}>
                        <th>Sl</th>
                        <th>ID</th>
                        <th>Customer</th>
                        <th>Phone</th>
                        <th>Address</th>
                        <th>Service Type</th>
                        <th>Service Name</th>
                        <th>Provider</th>
                        <th>Booked By</th>
                        <th>Order Date</th>
                        <th>Total</th>
                        <th>Payable</th>
                        <th>Paid</th>
                        <th>Order Status</th>
                        <th>Call</th>
                        <th>Details</th>
                    </tr>
                </thead>
                <tbody>
                    {props.services[1] &&
                        props.services[1].map((service, index) => (
                            <tr className={classes.tableRow} key={index}>
                                <td>{(serial = serial + 1) + props.pageSkip}</td>
                                <td>HX-S{service?.ServiceOrder?.id + 10000000}</td>
                                <td className={classes.cap}>
                                    <button
                                        onClick={() => {
                                            props.setGetPatientId(service?.User?.id)
                                            setOpenProfile(index)
                                        }}>
                                        {service?.User?.name}
                                    </button>
                                </td>
                                <td>{service?.User?.phone}</td>
                                <td className={classes.cap}>{service?.ServiceOrder?.current_address || '-'}</td>
                                <td className={classes.cap}>
                                    {service?.ServiceOrder?.service_name.replace(/_/g, ' ')}
                                </td>
                                <td className={classes.name}>
                                    {service?.ServiceOrder?.service_name === 'health_plan'
                                        ? service.ServiceOrder?.plan[0]?.name
                                        : '-'}
                                </td>
                                <td>
                                    {service?.ServiceOrder?.service_name === 'medicine_order'
                                        ? 'HEALTHx'
                                        : service?.ServiceOrder?.service_name === 'telemedicine'
                                        ? service.ServiceOrder?.doctor[0]?.id === 1
                                            ? '-'
                                            : service.ServiceOrder?.doctor[0]?.name
                                        : '-'}
                                </td>
                                <td>
                                    {service.ServiceOrder?.issuer[0]?.name || '-'}
                                    {service.ServiceOrder?.service_issuer_type
                                        ? ` (${service.ServiceOrder?.service_issuer_type})`
                                        : ''}
                                </td>
                                <td>{service?.ServiceOrder?.order_placement.slice(0, 10)}</td>
                                <td>{service?.ServiceOrder?.order_value}</td>
                                <td>{service?.ServiceOrder?.payable_amount}</td>
                                <td>{service?.ServiceOrder?.payment_by_customer}</td>
                                <td className={`${classes.status} ${classes.cap}`}>
                                    <span
                                        className={
                                            service?.ServiceOrder?.order_status === 'done'
                                                ? classes.active
                                                : service?.ServiceOrder?.order_status === 'processing'
                                                ? classes.progress
                                                : service?.ServiceOrder?.order_status === 'running'
                                                ? classes.running
                                                : service?.ServiceOrder?.order_status === 'emergency'
                                                ? classes.delete
                                                : service?.ServiceOrder?.order_status === 'cancel'
                                                ? classes.cancel
                                                : classes.waiting
                                        }>
                                        {service?.ServiceOrder?.order_status}
                                    </span>
                                </td>
                                <td>
                                    {service?.ServiceOrder?.service_name === 'telemedicine' ? (
                                        <Link
                                            to={`/meeting/${randomNumber(
                                                9
                                            )}/${service.ServiceOrder?.doctor[0]?.name?.replace(
                                                / /g,
                                                '-'
                                            )}/${service?.User?.name?.replace(/ /g, '-')}`}
                                            target="__blank"
                                            className={classes.link}>
                                            Link
                                        </Link>
                                    ) : (
                                        '-'
                                    )}
                                </td>
                                <td>
                                    <button className={classes.icon}>
                                        <FontAwesomeIcon
                                            icon={faEye}
                                            onClick={() => {
                                                props.setServiceId(service?.ServiceOrder?.id)
                                                setIsOpen(index)
                                            }}
                                        />
                                    </button>
                                    {/* <button className={classes.icon}>
                                        {service?.ServiceOrder?.service_name !== 'telemedicine' ? (
                                            <FontAwesomeIcon
                                                icon={faFileLines}
                                                onClick={() => {
                                                    props.setServiceId(service?.ServiceOrder?.id)
                                                    setPdfOpen(index)
                                                }}
                                            />
                                        ) : (
                                            <FontAwesomeIcon
                                                icon={faFileLines}
                                                style={{ color: 'grey', cursor: 'default' }}
                                            />
                                        )}
                                    </button> */}
                                    <button className={classes.icon}>
                                        <FontAwesomeIcon
                                            icon={faFileLines}
                                            onClick={() => {
                                                props.setServiceId(service?.ServiceOrder?.id)
                                                setPdfOpen(index)
                                            }}
                                        />
                                    </button>
                                </td>
                                {isOpen === index && (
                                    <ListDetail
                                        api={props.api}
                                        token={props.token}
                                        service={service}
                                        index={index}
                                        setIsOpen={setIsOpen}
                                        telemedicines={props.telemedicines}
                                        medicines={props.medicines}
                                        healthPlans={props.healthPlans}
                                        totalALl={props.totalALl}
                                        setSuccess={props.setSuccess}
                                    />
                                )}
                                {pdfOpen === index && (
                                    <ServicePdfPrint
                                        props={[service, props.medicines, props.healthPlans]}
                                        setPdfOpen={setPdfOpen}
                                        index={index}
                                    />
                                )}

                                {oepnProfile === index && (
                                    <PatientProfile index={index} setIsOpen={setOpenProfile} patient={service?.User} />
                                )}
                            </tr>
                        ))}
                </tbody>
            </table>

            <Pagination
                pageSkip={props.pageSkip}
                setPageSkip={props.setPageSkip}
                listItem={props.services[1]}
                pageAll={props.services[0]?.results}
                item="30"
                limit="30"
            />
            {props.loading && <Loading />}
        </div>
    )
}
