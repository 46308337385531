import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import classes from './CorporateList.module.css'

export default function CorporateList({ setListOpen, setFormOpen, corporates }) {
    const [search, setSearch] = useState('')
    let sl = 0

    const openClose = () => {
        setListOpen(false)
        setFormOpen(true)
    }

    return (
        <div className={classes.tableContainer}>
            <div className={classes.wrapper}>
                <form action="">
                    <input
                        className={classes.searchField}
                        type="text"
                        value={search}
                        placeholder="Search Company"
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <button className={classes.searchButton}>
                        <FontAwesomeIcon icon={faSearch} />
                    </button>
                </form>
                <button className={classes.button} onClick={() => openClose()}>
                    + Add Company
                </button>
            </div>
            <table className={classes.tableMain}>
                <thead>
                    <tr className={classes.tableRow}>
                        <th>Sl</th>
                        <th>ID</th>
                        <th>Company Name</th>
                        <th>Type</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Address</th>
                        {/* <th>Details</th> */}
                    </tr>
                </thead>
                <tbody>
                    {corporates &&
                        corporates
                            .filter(
                                (item) =>
                                    item.name.toLowerCase().includes(search) ||
                                    item.phone.toLowerCase().includes(search) ||
                                    item.email.toLowerCase().includes(search)
                            )
                            .map((item, index) => (
                                <tr className={classes.tableRow} key={index}>
                                    <td>{(sl = sl + 1)}</td>
                                    <td>
                                        HX-C
                                        {item.id + 1000}
                                    </td>
                                    <td className={classes.cap}>{item.name}</td>
                                    <td className={classes.cap}>{item.type}</td>
                                    <td>{item.phone}</td>
                                    <td>{item.email}</td>
                                    <td className={classes.cap}>{item.district}</td>
                                    {/* <td>
                                        <button className={classes.icon}>
                                            <FontAwesomeIcon icon={faEye} />
                                        </button>
                                    </td> */}
                                </tr>
                            ))}
                </tbody>
            </table>
        </div>
    )
}
