import { useState, useEffect, useContext } from 'react'
import { Auth, UserInfo } from '../../contexts/allContext'
import { currentDate, firstDay, lastDay, firstDayPrevious, lastDayPrevious } from '../../utils/date'
import classes from './Moderator.module.css'
import ModeratorForm from './ModeratorForm/ModeratorForm'
import ModeratorList from './ModeratorList/ModeratorList'

export default function Moderator() {
    const [listOpen, setListOpen] = useState(true)
    const [formOpen, setFormOpen] = useState(false)
    const [moderators, setModerators] = useState([])
    const [activities, setActivities] = useState([])
    const [results, setResults] = useState([])
    const [employeeId, setEmployeeId] = useState(1)
    const [users, setUsers] = useState({})
    const [roles, setRoles] = useState([])
    const [pageSkip, setPageSkip] = useState(0)
    const [pageSkipPopup, setPageSkipPopup] = useState(0)

    const [statusApi, setStatusApi] = useState('all')
    const [loading, setLoading] = useState(true)

    // services
    const [patientToday, setPatientToday] = useState({})
    const [telemedicineToday, setTelemedicineToday] = useState({})
    const [medicineToday, setMedicineToday] = useState({})
    const [planToday, setPlanToday] = useState({})

    const [patientCurrent, setPatientCurrent] = useState({})
    const [telemedicineCurrent, setTelemedicineCurrent] = useState({})
    const [medicineCurrent, setMedicineCurrent] = useState({})
    const [planCurrent, setPlanCurrent] = useState({})

    const [patientPrevious, setPatientPrevious] = useState({})
    const [telemedicinePrevious, setTelemedicinePrevious] = useState({})
    const [medicinePrevious, setMedicinePrevious] = useState({})
    const [planPrevious, setPlanPrevious] = useState({})

    const api = process.env.REACT_APP_API_URL
    const { stateAuth } = useContext(Auth)
    const { stateUser } = useContext(UserInfo)
    const userInfo = stateUser.info
    const token = stateAuth.token

    // const [posts, setPosts] = useState([])
    // useEffect(() => {
    //     async function loadPosts() {
    //         const response = await fetch('https://blog.healthxbd.com/wp-json/wp/v2/posts', {
    //             mode: 'cors',
    //         })
    //         if (!response.ok) {
    //             console.log('oups! something went wrong')
    //         }
    //         const data = await response.json()

    //         if (response.ok) {
    //             setPosts(data)
    //             console.log('wp', data)
    //         }
    //     }
    //     try {
    //         loadPosts()
    //     } catch {
    //         setPosts([])
    //     }
    // }, [])

    // all employee list
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/admin/employee/${statusApi}?skip=${pageSkip}&limit=10`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()

                if (response.ok) {
                    setModerators(data)
                    setLoading(false)
                }
            } catch {
                setModerators([])
            }
        }
        return fetchData()
    }, [token, api, statusApi, pageSkip])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/roles/`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                if (response.ok) {
                    setRoles(data)
                }
            } catch {
                setRoles([])
            }
        }
        return fetchData()
    }, [token, api])

    // activity log for table
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/admin/activity/log/${employeeId}?skip=${pageSkipPopup}&limit=10`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                setActivities(data)
            } catch {
                setActivities([])
            }
        }
        return fetchData()
    }, [token, api, employeeId, pageSkipPopup])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${api}/admin/activity/log/service/${employeeId}/patient_register?skip=0&limit=1`,
                    {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                const data = await response.json()
                setResults(data)
            } catch {
                setResults([])
            }
        }
        return fetchData()
    }, [token, api, employeeId])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/user/${employeeId}`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                setUsers(data)
            } catch {
                setUsers([])
            }
        }
        return fetchData()
    }, [token, api, employeeId])

    // employee services for today
    useEffect(() => {
        const patients = async () => {
            const response = await fetch(
                `${api}/admin/activity/log/service/${employeeId}/patient_register?start_date=${currentDate}T00%3A00%3A01&end_date=${currentDate}T23%3A59%3A59&skip=0&limit=1`,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            const data = await response.json()
            setPatientToday(data[0])
        }

        const telemedicines = async () => {
            const response = await fetch(
                `${api}/admin/activity/log/service/${employeeId}/telemedicine_order?start_date=${currentDate}T00%3A00%3A01&end_date=${currentDate}T23%3A59%3A59&skip=0&limit=1`,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            const data = await response.json()
            setTelemedicineToday(data[0])
        }

        const medicines = async () => {
            const response = await fetch(
                `${api}/admin/activity/log/service/${employeeId}/medicine_order?start_date=${currentDate}T00%3A00%3A01&end_date=${currentDate}T23%3A59%3A59&skip=0&limit=1`,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            const data = await response.json()
            setMedicineToday(data[0])
        }

        const plans = async () => {
            const response = await fetch(
                `${api}/admin/activity/log/service/${employeeId}/health_plan_subscribe?start_date=${currentDate}T00%3A00%3A01&end_date=${currentDate}T23%3A59%3A59&skip=0&limit=1`,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            const data = await response.json()
            setPlanToday(data[0])
        }

        try {
            patients()
            telemedicines()
            medicines()
            plans()
        } catch {
            setPatientToday({})
            setTelemedicineToday({})
            setMedicineToday({})
            setPlanToday({})
        }
    }, [token, api, employeeId])

    // employee services for current month
    useEffect(() => {
        const patients = async () => {
            const response = await fetch(
                `${api}/admin/activity/log/service/${employeeId}/patient_register?start_date=${firstDay}T00%3A00%3A01&end_date=${lastDay}T23%3A59%3A59&skip=0&limit=1`,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            const data = await response.json()
            setPatientCurrent(data[0])
        }

        const telemedicines = async () => {
            const response = await fetch(
                `${api}/admin/activity/log/service/${employeeId}/telemedicine_order?start_date=${firstDay}T00%3A00%3A01&end_date=${lastDay}T23%3A59%3A59&skip=0&limit=1`,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            const data = await response.json()
            setTelemedicineCurrent(data[0])
        }

        const medicines = async () => {
            const response = await fetch(
                `${api}/admin/activity/log/service/${employeeId}/medicine_order?start_date=${firstDay}T00%3A00%3A01&end_date=${lastDay}T23%3A59%3A59&skip=0&limit=1`,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            const data = await response.json()
            setMedicineCurrent(data[0])
        }

        const plans = async () => {
            const response = await fetch(
                `${api}/admin/activity/log/service/${employeeId}/health_plan_subscribe?start_date=${firstDay}T00%3A00%3A01&end_date=${lastDay}T23%3A59%3A59&skip=0&limit=1`,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            const data = await response.json()
            setPlanCurrent(data[0])
        }

        try {
            patients()
            telemedicines()
            medicines()
            plans()
        } catch {
            setPatientCurrent({})
            setTelemedicineCurrent({})
            setMedicineCurrent({})
            setPlanCurrent({})
        }
    }, [token, api, employeeId])

    // employee services for previous month
    useEffect(() => {
        const patients = async () => {
            const response = await fetch(
                `${api}/admin/activity/log/service/${employeeId}/patient_register?start_date=${firstDayPrevious}T00%3A00%3A01&end_date=${lastDayPrevious}T23%3A59%3A59&skip=0&limit=1`,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            const data = await response.json()
            setPatientPrevious(data[0])
        }

        const telemedicines = async () => {
            const response = await fetch(
                `${api}/admin/activity/log/service/${employeeId}/telemedicine_order?start_date=${firstDayPrevious}T00%3A00%3A01&end_date=${lastDayPrevious}T23%3A59%3A59&skip=0&limit=1`,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            const data = await response.json()
            setTelemedicinePrevious(data[0])
        }

        const medicines = async () => {
            const response = await fetch(
                `${api}/admin/activity/log/service/${employeeId}/medicine_order?start_date=${firstDayPrevious}T00%3A00%3A01&end_date=${lastDayPrevious}T23%3A59%3A59&skip=0&limit=1`,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            const data = await response.json()
            setMedicinePrevious(data[0])
        }

        const plans = async () => {
            const response = await fetch(
                `${api}/admin/activity/log/service/${employeeId}/health_plan_subscribe?start_date=${firstDayPrevious}T00%3A00%3A01&end_date=${lastDayPrevious}T23%3A59%3A59&skip=0&limit=1`,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            const data = await response.json()
            setPlanPrevious(data[0])
        }

        try {
            patients()
            telemedicines()
            medicines()
            plans()
        } catch {
            setPatientPrevious({})
            setTelemedicinePrevious({})
            setMedicinePrevious({})
            setPlanPrevious({})
        }
    }, [token, api, employeeId])

    return (
        <div>
            {userInfo.role_name === 'admin' || userInfo.role_name === 'moderator' ? (
                <div>
                    {formOpen && <ModeratorForm setListOpen={setListOpen} setFormOpen={setFormOpen} roles={roles} />}
                    {listOpen && (
                        <ModeratorList
                            setListOpen={setListOpen}
                            setFormOpen={setFormOpen}
                            moderators={moderators}
                            pageSkip={pageSkip}
                            setPageSkip={setPageSkip}
                            pageSkipPopup={pageSkipPopup}
                            setPageSkipPopup={setPageSkipPopup}
                            activities={activities}
                            results={results}
                            setEmployeeId={setEmployeeId}
                            users={users}
                            roles={roles}
                            token={token}
                            api={api}
                            setStatusApi={setStatusApi}
                            loading={loading}
                            patientToday={patientToday}
                            patientCurrent={patientCurrent}
                            patientPrevious={patientPrevious}
                            telemedicineToday={telemedicineToday}
                            telemedicineCurrent={telemedicineCurrent}
                            telemedicinePrevious={telemedicinePrevious}
                            medicineToday={medicineToday}
                            medicineCurrent={medicineCurrent}
                            medicinePrevious={medicinePrevious}
                            planToday={planToday}
                            planCurrent={planCurrent}
                            planPrevious={planPrevious}
                        />
                    )}
                </div>
            ) : (
                <div className={classes.modWrapper}>
                    <div className={classes.mod}>Employee Can't Access This Page!</div>{' '}
                </div>
            )}
        </div>
    )
}
