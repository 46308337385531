import { useState } from 'react'
import Active from './Active/Active'
import Pending from './Pending/Pending'

export default function ClinicList({ clinics, pendingClinics, pageSkip, setPageSkip, loading }) {
    const [openActive, setOpenActive] = useState(true)
    const [openPending, setOpenPending] = useState(false)

    return (
        <div>
            {openActive && (
                <Active
                    clinics={clinics}
                    pageSkip={pageSkip}
                    setPageSkip={setPageSkip}
                    loading={loading}
                    setOpenActive={setOpenActive}
                    setOpenPending={setOpenPending}
                />
            )}
            {openPending && (
                <Pending
                    pendingClinics={pendingClinics}
                    pageSkip={pageSkip}
                    setPageSkip={setPageSkip}
                    loading={loading}
                    setOpenActive={setOpenActive}
                    setOpenPending={setOpenPending}
                />
            )}
        </div>
    )
}
