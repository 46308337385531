import { faFileLines } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { ListCount, Table } from '../../../../../Resource'
import { Pagination } from '../../../../../index'
import { PdfPrint } from '../Pdf/PdfPrint'
import classes from './Invoice.module.css'

export default function Invoice({ invoices, pharmacy, pageSkip, setPageSkip }) {
    let serial = 0
    const [pdfOpen, setPdfOpen] = useState(false)

    return (
        <div className={classes.invoice}>
            <ListCount title="Invoice List" results={invoices} />

            <Table>
                <thead>
                    <tr>
                        <th>Sl</th>
                        <th>Payment Amount</th>
                        <th>Method</th>
                        <th>Trx</th>
                        <th>Date</th>
                        <th>Payment by</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {invoices[1] &&
                        invoices[1].map((list, index) => (
                            <>
                                <tr className={classes.row} key={index}>
                                    <td>{(serial = serial + 1) + pageSkip}</td>
                                    <td className={classes.cap}>{list.PaymentPayLog?.payment_amount}</td>
                                    <td>{list.PaymentPayLog?.payment_method}</td>
                                    <td>{list.PaymentPayLog?.trx_ref_id || '-'}</td>
                                    <td>{list.PaymentPayLog?.payment_date?.slice(0, 10)}</td>
                                    <td>{list.Issuer?.name}</td>
                                    <td>
                                        <FontAwesomeIcon icon={faFileLines} onClick={() => setPdfOpen(index)} />
                                        {/* <FontAwesomeIcon
                                            icon={faTrash}
                                            style={{ color: 'red', cursor: 'pointer', paddingLeft: 14 }}
                                        /> */}
                                    </td>
                                </tr>
                                {pdfOpen === index && (
                                    <PdfPrint props={{ pharmacy, list }} index={index} setPdfOpen={setPdfOpen} />
                                )}
                            </>
                        ))}
                </tbody>
            </Table>
            <Pagination
                pageSkip={pageSkip}
                setPageSkip={setPageSkip}
                listItem={invoices[1]}
                pageAll={invoices[0]?.results}
            />
        </div>
    )
}
