import { useContext, useEffect, useState } from 'react'
import { Auth } from '../../../../../contexts/allContext'
import { api } from '../../../../../utils/apiToken'
import { currentDate, calenderDate } from '../../../../../utils/date'
import { InnerColor, Popup } from '../../../../Resource'
import classes from './Schedule.module.css'
import ScheduleForm from './ScheduleForm/ScheduleForm'
import ScheduleList from './ScheduleList/ScheduleList'
import ScheduleListDate from './ScheduleListDate/ScheduleListDate'

export default function Schedule({ index, setScheduleOpen, doc, chambers }) {
    const [todaySchedule, setTodaySchedule] = useState([])
    const [changeableSchedule, setChangeableSchedule] = useState([])

    const [scheduleId, setScheduleId] = useState(0)
    const [sOpen, setSOpen] = useState(false)

    const [calender, setCalender] = useState(new Date())
    const changeDate = calenderDate(calender).slice(0, 10)
    const [success, setSuccess] = useState(false)

    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    useEffect(() => {
        let fetchData = async () => {
            let response = await fetch(`${api}/doctor/schedules/public/${doc?.User?.id}?date=${currentDate}`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            let data = await response.json()

            if (response.ok) {
                setTodaySchedule(data)
            }
        }
        try {
            fetchData()
        } catch (e) {
            setTodaySchedule([])
        }
    }, [token, success, doc?.User?.id])

    useEffect(() => {
        let fetchData = async () => {
            let response = await fetch(`${api}/doctor/schedules/public/${doc?.User?.id}?date=${changeDate}`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            let data = await response.json()

            if (response.ok) {
                setChangeableSchedule(data)
            }
        }
        try {
            fetchData()
        } catch (e) {
            setChangeableSchedule([])
        }
    }, [token, success, changeDate, doc?.User?.id])

    // schedule delete
    const deleteSchedule = async (e) => {
        e.preventDefault()

        let deleteFetch = await fetch(`${api}/doctor/schedules/${scheduleId}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })

        if (deleteFetch.ok) {
            setSOpen(false)
            setSuccess(true)
            setTimeout(() => {
                setSuccess(false)
            }, 2000)
        }
    }

    return (
        <Popup
            index={index}
            close={setScheduleOpen}
            btn={setScheduleOpen}
            bg={'var(--red)'}
            title="Close"
            first="Update"
            name={doc?.User?.name}
            second="'s schedules">
            <InnerColor>
                <div className={classes.section}>
                    <ScheduleForm doc={doc} chambers={chambers} setSuccess={setSuccess} />
                    <ScheduleList
                        todaySchedule={todaySchedule}
                        deleteSchedule={deleteSchedule}
                        setScheduleId={setScheduleId}
                        sOpen={sOpen}
                        setSOpen={setSOpen}
                    />
                </div>

                <div>
                    <ScheduleListDate
                        changeableSchedule={changeableSchedule}
                        calender={calender}
                        setCalender={setCalender}
                        deleteSchedule={deleteSchedule}
                        setScheduleId={setScheduleId}
                        sOpen={sOpen}
                        setSOpen={setSOpen}
                    />
                </div>
            </InnerColor>
        </Popup>
    )
}
