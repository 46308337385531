import { useContext, useState } from 'react'
import Doc from '../../../../../assets/doc/doc-df.jpg'
import { Auth } from '../../../../../contexts/allContext'
import { api } from '../../../../../utils/apiToken'
import classes from './Edit.module.css'

export default function Edit({ index, setEditOpen, doc, work, setSuccess }) {
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    const [alert, setAlert] = useState('')
    const [close, setClose] = useState(false)

    const [basic, setBasic] = useState({
        name: doc?.User?.name,
        phone: doc?.User?.phone,
        email: doc?.User?.email,
    })

    const [fees, setFees] = useState({
        dr_title: doc?.Doctor?.dr_title,
        bmdc: doc?.Doctor?.bmdc,
        exp_year: doc?.Doctor?.exp_year,
        online_fees: doc?.Doctor?.online_fees,
        followup_fees: doc?.Doctor?.followup_fees,
    })

    const [createWork, setCreateWork] = useState('')
    const [currentWork, setCurrentWork] = useState('')

    const [image, setImage] = useState()
    const [qualification, setQualification] = useState(doc?.DoctorQualification?.qualification)
    const [speciality, setSpeciality] = useState(doc?.DoctorSpeciality?.speciality)

    const handleBasic = (e) => {
        const { name, value } = e.target
        setBasic((prev) => ({ ...prev, [name]: value }))
    }
    const handleFees = (e) => {
        const { name, value } = e.target
        const updatedValue = value === '' ? '' : value
        setFees((prev) => ({ ...prev, [name]: updatedValue }))
    }

    const submitBasic = async (e) => {
        e.preventDefault()

        let response = await fetch(`${api}/admin/doctor/user/update/${doc?.User?.id}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(basic),
        })

        if (response.ok) {
            setAlert('Basic Info Update Successful')
            setSuccess(true)

            setTimeout(() => {
                setAlert('')
                setSuccess(false)
            }, 3000)
        } else {
            setAlert('Something went wrong!')
            setTimeout(() => {
                setAlert('')
            }, 3000)
        }
    }

    const submitFees = async (e) => {
        e.preventDefault()

        let response = await fetch(`${api}/admin/doctor/update/${doc?.User?.id}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(fees),
        })

        if (response.ok) {
            setAlert('Title & Fee Update Successful')
            setSuccess(true)

            setTimeout(() => {
                setAlert('')
                setSuccess(false)
            }, 3000)
        } else {
            setAlert('Something went wrong!')
            setTimeout(() => {
                setAlert('')
            }, 3000)
        }
    }

    const submitImage = async (e) => {
        e.preventDefault()

        if (image) {
            const imgData = new FormData()
            imgData.append('file', image)

            let response = await fetch(`${api}/admin/s3/profile-pic/${doc?.User?.id}`, {
                headers: {
                    Accept: 'application/json',
                    type: 'image/jpeg',
                    Authorization: `Bearer ${token}`,
                },
                method: 'POST',
                body: imgData,
            })

            if (response.ok) {
                setAlert('Image Update Successful')
                setSuccess(true)

                setTimeout(() => {
                    setAlert('')
                    setSuccess(false)
                }, 3000)
            } else {
                setAlert('Something went wrong!')
                setTimeout(() => {
                    setAlert('')
                }, 3000)
            }
        } else {
            alert('No image selected!')
        }
    }

    const submitQualification = async (e) => {
        e.preventDefault()

        let response = await fetch(`${api}/admin/doctor/qualification/update/${doc?.DoctorQualification?.id}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                qualification,
            }),
        })

        if (response.ok) {
            setAlert('Qualification Update Successful')
            setSuccess(true)

            setTimeout(() => {
                setAlert('')
                setSuccess(false)
            }, 3000)
        } else {
            setAlert('Something went wrong!')
            setTimeout(() => {
                setAlert('')
            }, 3000)
        }
    }

    const submitSpeciality = async (e) => {
        e.preventDefault()

        let response = await fetch(`${api}/admin/doctor/speciality/update/${doc?.DoctorSpeciality?.id}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                speciality,
            }),
        })

        if (response.ok) {
            setAlert('Speciality Update Successful')
            setSuccess(true)

            setTimeout(() => {
                setAlert('')
                setSuccess(false)
            }, 3000)
        } else {
            setAlert('Something went wrong!')
            setTimeout(() => {
                setAlert('')
            }, 3000)
        }
    }

    const submitCreateWork = async (e) => {
        e.preventDefault()

        let response = await fetch(`${api}/admin/doctor/workplace/create`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                institute: createWork,
                top_priority: true,
                user_id: doc?.User?.id,
            }),
        })

        if (response.ok) {
            setAlert('New Work Place Create Successful')
            setSuccess(true)
            setClose(false)

            setTimeout(() => {
                setAlert('')
                setSuccess(false)
            }, 3000)
        } else {
            setAlert('Something went wrong!')
            setTimeout(() => {
                setAlert('')
            }, 3000)
        }
    }

    const submitCurrentWork = async (e) => {
        e.preventDefault()

        let response = await fetch(`${api}/admin/doctor/workplace/update/${work?.id}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                institute: currentWork,
            }),
        })

        if (response.ok) {
            setAlert('Work Place Update Successful')
            setSuccess(true)

            setTimeout(() => {
                setAlert('')
                setSuccess(false)
            }, 3000)
        } else {
            setAlert('Something went wrong!')
            setTimeout(() => {
                setAlert('')
            }, 3000)
        }
    }

    return (
        <div key={() => index} className={classes.containerForm}>
            <div className={classes.overlay} onClick={() => setEditOpen(false)}></div>
            <div className={classes.formWrapper}>
                <div className={classes.close} onClick={() => setEditOpen(false)}>
                    &times;
                </div>

                <p>
                    Update <span>{doc?.User?.name}</span>'s info
                </p>
                <div className={classes.innerWrap}>
                    <div className={classes.container}>
                        <ul>
                            {/* <li>
                                <input type="checkbox" id="listItem1" />
                                <label htmlFor="listItem1" className={classes.labelMain}>
                                    Update Schedule
                                </label>
                                <ul>
                                    <div className={classes.innerContainer}>
                                        <div className={classes.collab}></div>
                                        <button className={classes.buttonSubmit} onClick={(e) => submitBasic(e)}>
                                            Schedule
                                        </button>
                                    </div>
                                </ul>
                            </li> */}

                            <li>
                                <input type="checkbox" id="listItem2" />
                                <label htmlFor="listItem2" className={classes.labelMain}>
                                    Update Basic Info
                                </label>
                                <ul>
                                    <div className={classes.innerContainer}>
                                        <div className={classes.collab}>
                                            <label>
                                                Doctor Name
                                                <input
                                                    type="text"
                                                    name="name"
                                                    value={basic.name}
                                                    onChange={handleBasic}
                                                    required
                                                />
                                            </label>
                                            <div className={classes.formGrid}>
                                                <label>
                                                    Phone
                                                    <input
                                                        type="tel"
                                                        name="phone"
                                                        minLength={11}
                                                        maxLength={11}
                                                        pattern="[0][1][0-9]{9}"
                                                        value={basic.phone}
                                                        onChange={handleBasic}
                                                        required
                                                    />
                                                </label>
                                                <label>
                                                    Email
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        value={basic.email}
                                                        onChange={handleBasic}
                                                        required
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                        <button className={classes.buttonSubmit} onClick={(e) => submitBasic(e)}>
                                            Update Basic Info
                                        </button>
                                    </div>
                                </ul>
                            </li>

                            <li>
                                <input type="checkbox" id="listItem3" />
                                <label htmlFor="listItem3" className={classes.labelMain}>
                                    Update Title & Fee
                                </label>
                                <ul>
                                    <div className={classes.innerContainer}>
                                        <div className={classes.collab}>
                                            <div className={classes.formGrid}>
                                                <label>
                                                    Select Designation Title
                                                    <select
                                                        className={classes.select}
                                                        name="dr_title"
                                                        value={fees.dr_title}
                                                        onChange={handleFees}>
                                                        <option value="">Select</option>
                                                        <option value="Dr.">Dr.</option>
                                                        <option value="Prof. Dr.">Prof. Dr.</option>
                                                        <option value="Assoc. Prof. Dr.">Assoc. Prof. Dr.</option>
                                                        <option value="Asst. Prof. Dr.">Asst. Prof. Dr.</option>
                                                        <option value="">No Title</option>
                                                    </select>
                                                </label>

                                                <div className={`${classes.formGrid} ${classes.formMargin}`}>
                                                    <label>
                                                        BMDC
                                                        <input
                                                            type="number"
                                                            name="bmdc"
                                                            value={fees.bmdc}
                                                            onChange={handleFees}
                                                            required
                                                        />
                                                    </label>

                                                    <label>
                                                        Experience
                                                        <input
                                                            type="number"
                                                            name="exp_year"
                                                            value={fees.exp_year}
                                                            onChange={handleFees}
                                                            required
                                                        />
                                                    </label>
                                                </div>
                                            </div>

                                            <div className={classes.formGrid}>
                                                <label>
                                                    Online Fees
                                                    <input
                                                        type="text"
                                                        name="online_fees"
                                                        value={fees.online_fees}
                                                        onChange={handleFees}
                                                        required
                                                    />
                                                </label>
                                                <label>
                                                    Followup Fees
                                                    <input
                                                        type="number"
                                                        name="followup_fees"
                                                        value={fees.followup_fees}
                                                        onChange={handleFees}
                                                        required
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                        <button className={classes.buttonSubmit} onClick={(e) => submitFees(e)}>
                                            Update Title & Fee
                                        </button>
                                    </div>
                                </ul>
                            </li>

                            <li>
                                <input type="checkbox" id="listItem4" />
                                <label htmlFor="listItem4" className={classes.labelMain}>
                                    Update Profile Image
                                </label>
                                <ul>
                                    <div className={classes.innerContainer}>
                                        <div className={classes.collab}>
                                            <img
                                                src={
                                                    doc?.Doctor?.images.length !== 0
                                                        ? doc?.Doctor?.images[0]?.bucket
                                                            ? doc?.Doctor?.images[0]?.image_url
                                                            : `${api}/images/profile/${doc?.Doctor?.images[0]?.image_string}`
                                                        : Doc
                                                }
                                                className={classes.picture}
                                                alt=""
                                            />
                                            <label>
                                                Select Profile Picture
                                                <input
                                                    type="file"
                                                    accept="image"
                                                    onChange={(e) => setImage(e.target.files[0])}
                                                    required
                                                />
                                            </label>
                                        </div>
                                        <button className={classes.buttonSubmit} onClick={(e) => submitImage(e)}>
                                            Update Image
                                        </button>
                                    </div>
                                </ul>
                            </li>

                            <li>
                                <input type="checkbox" id="listItem5" />
                                <label htmlFor="listItem5" className={classes.labelMain}>
                                    Update Qual. & Spec.
                                </label>
                                <ul>
                                    <div className={classes.innerContainer}>
                                        <div className={classes.collab}>
                                            <label>
                                                Qualification
                                                <textarea
                                                    type="text"
                                                    value={qualification}
                                                    onChange={(e) => setQualification(e.target.value)}
                                                    rows={2}
                                                    required
                                                />
                                            </label>
                                            <button
                                                className={classes.buttonSubmit}
                                                onClick={(e) => submitQualification(e)}>
                                                Update Qualification
                                            </button>

                                            <label>
                                                Speciality
                                                <textarea
                                                    type="text"
                                                    value={speciality}
                                                    onChange={(e) => setSpeciality(e.target.value)}
                                                    rows={2}
                                                    required
                                                />
                                            </label>
                                            <button
                                                className={classes.buttonSubmit}
                                                onClick={(e) => submitSpeciality(e)}>
                                                Update Speciality
                                            </button>
                                        </div>
                                    </div>
                                </ul>
                            </li>

                            <li>
                                <input type="checkbox" id="listItem6" />
                                <label htmlFor="listItem6" className={classes.labelMain}>
                                    Update Current Working
                                </label>
                                <ul>
                                    <div className={classes.innerContainer}>
                                        <div className={classes.collab}>
                                            <label>
                                                Current Workplace
                                                <span className={classes.fetchLabel}>
                                                    {work?.institute} <p>.</p>
                                                </span>
                                            </label>
                                            <div className={classes.buttonContainer}>
                                                <button
                                                    className={classes.buttonAdd}
                                                    onClick={() => setClose((prev) => !prev)}>
                                                    Create Work Place
                                                </button>
                                            </div>

                                            {close && (
                                                <>
                                                    <label>
                                                        Create Working Place
                                                        <textarea
                                                            type="text"
                                                            onChange={(e) => setCreateWork(e.target.value)}
                                                            rows={2}
                                                            required
                                                        />
                                                    </label>

                                                    <button
                                                        className={classes.buttonSubmit}
                                                        onClick={(e) => submitCreateWork(e)}>
                                                        Create Working Place
                                                    </button>
                                                </>
                                            )}

                                            <label>
                                                Update Working Place
                                                <textarea
                                                    type="text"
                                                    onChange={(e) => setCurrentWork(e.target.value)}
                                                    rows={2}
                                                    required
                                                />
                                            </label>
                                        </div>
                                        <button className={classes.buttonSubmit} onClick={(e) => submitCurrentWork(e)}>
                                            Update Working
                                        </button>
                                    </div>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <span className={classes.message}>{alert}</span>
            </div>
        </div>
    )
}
