import { useContext, useState } from 'react'
import { Auth, UserInfo } from '../../../../../../contexts/allContext'
import { useRequest } from '../../../../../../hooks/useRequest'
import { apiV2 } from '../../../../../../utils/apiToken'
import { currentDateTime } from '../../../../../../utils/date'
import { Form } from '../../../../../Resource'

export default function Submit({ pharmacyId, pharmacyUserId, setIsChange, setForm }) {
    const { stateAuth } = useContext(Auth)
    const { stateUser } = useContext(UserInfo)
    const token = stateAuth.token
    const user = stateUser.info

    const { handleSubmit } = useRequest()

    const [payment, setPayment] = useState({
        payment_amount: '',
        payment_method: '',
        payment_date: currentDateTime,
        trx_ref_id: '',
        receiver_type: 'partner',
        remarks: 'paid',
        receiver_id: pharmacyUserId,
        outlet_id: pharmacyId,
        issuer_id: user.id,
    })

    const handleChange = async (e) => {
        const { name, value } = e.target
        const newValue = name === 'payment_amount' ? parseFloat(value) : value
        setPayment({ ...payment, [name]: newValue })
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault()

        const { isSuccess, isErorr } = await handleSubmit(
            `${apiV2}/admin/partner-pharmacy/payment/create`,
            payment,
            token
        )

        if (isSuccess) {
            setIsChange(true)
            setForm(false)
        } else {
            // eslint-disable-next-line no-console
            console.log(isErorr)
        }
    }

    return (
        <div>
            <Form title="Make Payment" onSubmit={handleOnSubmit}>
                <label>
                    Payment Amount <span>*</span>
                    <input
                        type="number"
                        name="payment_amount"
                        value={payment.payment_amount}
                        onChange={handleChange}
                        required
                    />
                </label>

                <label>
                    Payment Method <span>*</span>
                    <select name="payment_method" value={payment.payment_method} onChange={handleChange} required>
                        <option value="">select</option>
                        <option value="bKash">bKash</option>
                        <option value="Nagad">Nagad</option>
                        <option value="Rocket">Rocket</option>
                        <option value="Upay">Upay</option>
                        <option value="Bank">Bank</option>
                    </select>
                </label>

                <label>
                    Transaction ID
                    <input name="trx_ref_id" value={payment.trx_ref_id} onChange={handleChange} />
                </label>
            </Form>
        </div>
    )
}
