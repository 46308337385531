import { useContext, useEffect, useState } from 'react'
import { Auth } from '../../../../../../contexts/allContext'
import { api } from '../../../../../../utils/apiToken'
import classes from './HealthPlanList.module.css'

export default function HealthPlanList(plan) {
    const planId = plan.plan?.health_plan_id
    const [planDetail, setPlanDetail] = useState({})

    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/health-plan/${planId}`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                setPlanDetail(data)
            } catch {
                setPlanDetail({})
            }
        }
        return fetchData()
    }, [token, planId])

    return (
        <>
            {planId !== 0 ? (
                <div className={classes.planWrapper}>
                    <div className={classes.formGrid}>
                        <label>
                            Plan Name
                            <span className={classes.fetchLabel}>
                                {planDetail?.name} <p>.</p>
                            </span>
                        </label>
                        <div className={classes.formGrid}>
                            <label>
                                Plan Duration
                                <span className={classes.fetchLabel}>
                                    {Math.floor(planDetail?.days / 30) === 0 ? 1 : Math.floor(planDetail?.days / 30)}{' '}
                                    {planDetail?.days / 30 > 1
                                        ? 'Months'
                                        : Math.floor(planDetail?.days / 30) === 0
                                        ? 'Day'
                                        : 'Month'}
                                    <p>.</p>
                                </span>
                            </label>
                            <label>
                                Plan Price
                                <span className={classes.fetchLabel}>
                                    {planDetail?.fee} TK <p>.</p>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            ) : (
                ''
            )}
        </>
    )
}
