import { faLeaf, faThumbtack } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useEffect, useState } from 'react'
import districtJson from '../../../config/locations/bd-districts.json'
import divisionJson from '../../../config/locations/bd-divisions.json'
import { Auth } from '../../../contexts/allContext'
import { nameFromDivisionId } from '../../../utils/location'
import classes from './Form.module.css'

export default function Form({ setIsOpen }) {
    const api = process.env.REACT_APP_API_URL
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    const [division, setDivision] = useState('')
    const [divisionName, setDivisionName] = useState('')
    const [file, setFile] = useState('')
    const [detail, setDetail] = useState({
        name: '',
        category: '',
        division: '',
        district: '',
        city: '',
        address: '',
        pathology_discount: '',
        imaging_discount: '',
        ct_mri_discount: '',
        surgery_discount: '',
        bed_ipd_discount: '',
        opd_discount: '',
        icu_discount: '',
        medicine_discount: '',
        emergency_discount: '',
        ambulance_discount: '',
    })

    const upgradeDetails = (e) => {
        const { name, value } = e.target
        setDetail({
            ...detail,
            [name]: value,
        })
    }

    useEffect(() => {
        setDivisionName(nameFromDivisionId(String(division), divisionJson.divisions))
    }, [division])

    useEffect(() => {
        setDetail({
            ...detail,
            division: divisionName,
        })
    }, [divisionName])

    const refreshPage = () => {
        window.location.reload()
    }

    const submitImage = async (Id) => {
        const imgData = new FormData()
        imgData.append('file', file)

        let response = await fetch(`${api}/test-provider/picture/${Id}`, {
            headers: {
                Accept: 'appllication/json',
                type: 'image/jpeg',
                Authorization: `Bearer ${token}`,
            },
            method: 'PATCH',
            body: imgData,
        })

        if (response.ok) {
            console.log('Image upload successful!')
            refreshPage()
        } else {
            alert('Something went wrong!')
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const details = {
            ...detail,
        }
        let postFetch = await fetch(`${api}/test-provider/`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(details),
        })

        let log = await postFetch.json()

        if (postFetch.ok) {
            submitImage(log.id)
        }
    }

    return (
        <div>
            <div className={classes.wrapper}>
                <button className={classes.button} onClick={() => setIsOpen(false)}>
                    Provider List
                </button>
            </div>
            <div className={classes.formWrapper}>
                <FontAwesomeIcon className={classes.icon} icon={faThumbtack} />
                <div className={classes.logo}>ADD PROVIDER</div>

                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className={classes.section}>
                        <FontAwesomeIcon className={classes.leaf} icon={faLeaf} />
                        Details Info
                    </div>
                    <div className={classes.formGrid}>
                        <label>
                            Name <span>*</span>
                            <input type="text" name="name" value={detail.name} onChange={upgradeDetails} required />
                        </label>
                        <label>
                            {' '}
                            Provider Profile Picture <span>*</span>
                            <input
                                type="file"
                                accept="image"
                                onChange={(e) => setFile(e.target.files[0])}
                                title="Select Profile Picture"
                                required
                            />
                        </label>
                    </div>
                    <div className={classes.formGrid}>
                        <label>
                            Category <span>*</span>
                            <select
                                type="text"
                                name="category"
                                value={detail?.category}
                                onChange={upgradeDetails}
                                required>
                                <option value="" selected>
                                    select category
                                </option>
                                <option value="Hospital">Hospital</option>
                                <option value="Lab">Lab</option>
                                <option value="Lab + Clinic">Lab + Clinic</option>
                                <option value="Derma Clinic">Derma Clinic</option>
                                <option value="Physio Clinic">Physio Clinic</option>
                                <option value="Clinic">Clinic</option>
                                <option value="Eye Clinic">Eye Clinic</option>
                                <option value="Psychiatric Hospital">Psychiatric Hospital</option>
                                <option value="Addiction Treatment Center">Addiction Treatment Center</option>
                                <option value="Fertility Clinic">Fertility Clinic</option>
                                <option value="Hospice Care Facility">Hospice Care Facility</option>
                                <option value="Dialysis Facility">Dialysis Facility</option>
                                <option value="Blood Bank">Blood Bank</option>
                            </select>
                        </label>
                        <label>
                            Divison <span>*</span>
                            <select
                                type="text"
                                name="division"
                                value={division}
                                onChange={(e) => setDivision(e.target.value)}
                                required>
                                <option value="">select divison</option>
                                {divisionJson.divisions.map((div) => (
                                    <option key={div.id} value={div.id}>
                                        {div.name}
                                    </option>
                                ))}
                            </select>
                        </label>
                        <label>
                            District <span>*</span>
                            <select
                                type="text"
                                name="district"
                                value={detail?.district}
                                onChange={upgradeDetails}
                                required>
                                <option value="">select district</option>
                                {districtJson.districts
                                    .filter((item) => item.division_id === String(division))
                                    .map((dis) => (
                                        <option key={dis.id} value={dis.name}>
                                            {dis.name}
                                        </option>
                                    ))}
                            </select>
                        </label>

                        <label>
                            City <span>*</span>
                            <input type="text" name="city" value={detail?.city} onChange={upgradeDetails} required />
                        </label>
                    </div>

                    <div className={classes.formGrid}>
                        <label>
                            Address <span>*</span>
                            <textarea
                                type="text"
                                name="address"
                                value={detail?.address}
                                onChange={upgradeDetails}
                                required
                            />
                        </label>
                    </div>

                    <div className={classes.formGrid}>
                        <label>
                            Pathology Discount (%)
                            <input
                                type="text"
                                name="pathology_discount"
                                value={detail?.pathology_discount}
                                onChange={upgradeDetails}
                            />
                        </label>
                        <label>
                            Imaging Discount (%)
                            <input
                                type="text"
                                name="imaging_discount"
                                value={detail?.imaging_discount}
                                onChange={upgradeDetails}
                            />
                        </label>

                        <label>
                            CT MRI Discount (%)
                            <input
                                type="text"
                                name="ct_mri_discount"
                                value={detail?.ct_mri_discount}
                                onChange={upgradeDetails}
                            />
                        </label>
                        <label>
                            Surgery Discount (%)
                            <input
                                type="text"
                                name="surgery_discount"
                                value={detail?.surgery_discount}
                                onChange={upgradeDetails}
                            />
                        </label>
                    </div>

                    <div className={classes.formGrid}>
                        <label>
                            OPD Discount (%)
                            <input
                                type="text"
                                name="opd_discount"
                                value={detail?.opd_discount}
                                onChange={upgradeDetails}
                            />
                        </label>
                        <label>
                            ICU Discount (%)
                            <input
                                type="text"
                                name="icu_discount"
                                value={detail?.icu_discount}
                                onChange={upgradeDetails}
                            />
                        </label>
                        <label>
                            Medicine Discount (%)
                            <input
                                type="text"
                                name="medicine_discount"
                                value={detail?.medicine_discount}
                                onChange={upgradeDetails}
                            />
                        </label>
                        <label>
                            Emergency Discount (%)
                            <input
                                type="text"
                                name="emergency_discount"
                                value={detail?.emergency_discount}
                                onChange={upgradeDetails}
                            />
                        </label>
                    </div>

                    <div className={classes.formGrid}>
                        <label>
                            Bed IPD Discount (%)
                            <input
                                type="text"
                                name="bed_ipd_discount"
                                value={detail?.bed_ipd_discount}
                                onChange={upgradeDetails}
                            />
                        </label>
                        <label>
                            Ambulance Discount (%)
                            <input
                                type="text"
                                name="ambulance_discount"
                                value={detail?.ambulance_discount}
                                onChange={upgradeDetails}
                            />
                        </label>
                    </div>

                    <button className={classes.button} type="submit">
                        SUBMIT
                    </button>
                </form>
            </div>
        </div>
    )
}
