import React, { useContext, useState } from 'react'
import districtJson from '../../../../../../config/locations/bd-districts.json'
import { Auth } from '../../../../../../contexts/allContext'
import { Form } from '../../../../../Resource'
import classes from './ChamberCreate.module.css'

export default function ChamberCreate({ show, setShow, id, setIsLoading }) {
    const api = process.env.REACT_APP_API_URL
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    const [name, setName] = useState('')
    const [chamberDetails, setChamberDetails] = useState('')
    const [chamberDistrict, setChamberDistrict] = useState('')
    const [detailsAddress, setDetailsAddress] = useState('')
    const [fee, setFee] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault()

        const details = {
            name: name,
            detail: chamberDetails,
            district: chamberDistrict,
            detail_address: detailsAddress,
            chamber_fee: fee,
        }
        let postFetch = await fetch(`${api}/doctors/chambercreated_by/medical_affairs/${id}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(details),
        })

        if (postFetch.ok) {
            setShow(false)
            setIsLoading(true)
        }
    }

    return (
        <div className={classes.container}>
            <Form title="Add Chamber" onSubmit={handleSubmit}>
                <label>
                    Chamber Name
                    <input type="text" name="name" value={name} onChange={(e) => setName(e.target.value)} />
                </label>
                <div>
                    <label>
                        Chamber Details
                        <input
                            type="text"
                            name="detail"
                            value={chamberDetails}
                            onChange={(e) => setChamberDetails(e.target.value)}
                        />
                    </label>
                    <label>
                        Chamber District
                        <select
                            name="district"
                            value={chamberDistrict}
                            onChange={(e) => setChamberDistrict(e.target.value)}>
                            <option value="" selected disabled>
                                Select District
                            </option>
                            {districtJson.districts.map((dis) => (
                                <option key={dis.id} value={dis.name}>
                                    {dis.name + ' ' + dis.bn_name}
                                </option>
                            ))}
                        </select>
                    </label>
                </div>
                <div>
                    <label>
                        Details Address
                        <input
                            type="text"
                            name="detail_address"
                            value={detailsAddress}
                            onChange={(e) => setDetailsAddress(e.target.value)}
                        />
                    </label>
                    <label>
                        Chamber Fee
                        <input type="number" name="chamber_fee" value={fee} onChange={(e) => setFee(e.target.value)} />
                    </label>
                </div>
            </Form>
        </div>
    )
}
