import { useState, useEffect, useContext } from 'react'
import { Auth, UserInfo } from '../../contexts/allContext'
import { api } from '../../utils/apiToken'
import { currentDate } from '../../utils/date'
import PrescriptionList from './PrescriptionList/PrescriptionList'
import classes from './Prescriptions.module.css'

export default function Prescription() {
    const [prescriptions, setPrescriptions] = useState([])
    const [pageSkip, setPageSkip] = useState(0)
    const [loading, setLoading] = useState(true)

    const { stateAuth } = useContext(Auth)
    const { stateUser } = useContext(UserInfo)
    const userInfo = stateUser.info
    const token = stateAuth.token

    useEffect(() => {
        let fetchData = async () => {
            try {
                const response = await fetch(
                    `${api}/ep/all/?start_date=2021-01-01&end_date=${currentDate}T23%3A59%3A59&skip=${pageSkip}&limit=10`,
                    {
                        method: 'GET',
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                const data = await response.json()

                if (response.ok) {
                    setPrescriptions(data)
                    setLoading(false)
                }
            } catch (e) {
                setPrescriptions([])
            }
        }
        fetchData()
    }, [token, pageSkip])

    return (
        <div>
            {userInfo.role_name === 'admin' || userInfo.role_name === 'moderator' || userInfo.role_name === 'crm' ? (
                <PrescriptionList
                    prescriptions={prescriptions}
                    pageSkip={pageSkip}
                    setPageSkip={setPageSkip}
                    loading={loading}
                />
            ) : (
                <div className={classes.modWrapper}>
                    <div className={classes.mod}>Employee Can't Access This Tab!</div>
                </div>
            )}
        </div>
    )
}
