import { useState } from 'react'
import classes from './Indicators.module.css'
import { Bp, Pulse, Rbs, Weight } from './index.js'

const Indicators = ({ id }) => {
    const [select, setSelect] = useState(1)

    return (
        <div className={classes.indicators}>
            <p>Select Indicators</p>
            <select onChange={(e) => setSelect(parseInt(e.target.value))}>
                <option value="1">Blood Pressure</option>
                <option value="2">Diabetes</option>
                <option value="3">Weight</option>
                <option value="4">Pulse</option>
            </select>
            <div className={classes.grid}>
                {select === 1 && <Bp id={id} />}
                {select === 2 && <Rbs id={id} />}
                {select === 3 && <Weight id={id} />}
                {select === 4 && <Pulse id={id} />}
            </div>
        </div>
    )
}

export default Indicators
