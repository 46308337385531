import { DefaultStorage, GetStorage, SetStorage } from '../storage/storage'

export const countState = DefaultStorage('count', { bell: 0, total: 0 })

export const countReducer = (state, action) => {
    switch (action.type) {
        case 'get':
            SetStorage('count', { bell: state.bell, total: state.total })
            return GetStorage('count')
        case 'bell':
            SetStorage('count', { bell: action.payload, total: state.total })
            return GetStorage('count')
        case 'total':
            SetStorage('count', { bell: state.bell, total: action.payload })
            return GetStorage('count')
        case 'remove':
            SetStorage('count', { bell: 0, total: state.total })
            return GetStorage('count')
        default:
            return state
    }
}
