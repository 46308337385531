import { faLeaf, faThumbtack } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useEffect, useState } from 'react'
import { Auth } from '../../../contexts/allContext'
import { api } from '../../../utils/apiToken'
import classes from './PlanForm.module.css'

export default function PlanForm({ setListOpen, setFormOpen }) {
    const [type, setType] = useState('')
    const [name, setName] = useState('')
    const [detail, setDetail] = useState()
    const [fee, setFee] = useState()
    const [days, setDays] = useState()
    const [voucher, setVoucher] = useState('')
    const [expire, setExpire] = useState()
    const [date, setDate] = useState(null)
    const [patientNo, setPatientNo] = useState()

    const [hide, setHide] = useState(false)

    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    const openClose = () => {
        setListOpen(true)
        setFormOpen(false)
    }

    useEffect(() => {
        if (expire === 'true') {
            setHide(true)
        } else {
            setHide(false)
        }
    }, [expire])

    const refreshPage = () => {
        window.location.reload()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const details = {
            plan_type: type,
            name: name,
            details: detail,
            voucher_code: voucher,
            total_patients: patientNo,
            expire_status: expire,
            expire_date: date,
            days: days,
            fee: fee,
        }
        let postFetch = await fetch(`${api}/health-plan/`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(details),
        })

        if (postFetch.ok) {
            refreshPage()
        }
    }
    return (
        <div>
            <div className={classes.wrapper}>
                <button className={classes.button} onClick={() => openClose()}>
                    Service List
                </button>
            </div>
            <div className={classes.formWrapper}>
                <FontAwesomeIcon className={classes.icon} icon={faThumbtack} />
                <div className={classes.logo}>ADD SERVICE</div>

                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className={classes.section}>
                        <FontAwesomeIcon className={classes.leaf} icon={faLeaf} />
                        Details Info
                    </div>
                    <div className={classes.innerWrap}>
                        <label>
                            Service Type
                            <select onChange={(e) => setType(e.target.value)} className={classes.select} required>
                                <option value="">Select</option>
                                <option value="health_plan">Health Plan</option>
                                <option value="health_package">Health Package</option>
                            </select>
                        </label>
                        <div className={classes.formGrid}>
                            <label>
                                Name
                                <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
                            </label>
                            <div className={classes.formGrid}>
                                <label>
                                    Voucher Code
                                    <input type="text" value={voucher} onChange={(e) => setVoucher(e.target.value)} />
                                </label>
                                <label>
                                    Duration
                                    <select
                                        required
                                        onChange={(e) => setDays(parseInt(e.target.value))}
                                        className={classes.select}>
                                        <option value="">Select</option>
                                        <option value="1">1 Day</option>
                                        <option value="30">1 Month</option>
                                        <option value="90">3 Months</option>
                                        <option value="180">6 Months</option>
                                        <option value="365">12 Months</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                        <label>
                            Details
                            <textarea
                                type="text"
                                placeholder="Details"
                                rows={2}
                                value={detail}
                                onChange={(e) => setDetail(e.target.value)}
                                required
                            />
                        </label>
                        <div className={classes.formGrid}>
                            <div className={classes.formGrid}>
                                <label>
                                    Number of Patient
                                    <input
                                        type="number"
                                        value={patientNo}
                                        onChange={(e) => setPatientNo(parseInt(e.target.value))}
                                        min={1}
                                        required
                                    />
                                </label>

                                <label>
                                    Plan Price
                                    <input
                                        type="number"
                                        value={fee}
                                        onChange={(e) => setFee(parseInt(e.target.value))}
                                        min={0}
                                        required
                                    />
                                </label>
                            </div>
                            <label>
                                Expire Status
                                <select required className={classes.select} onChange={(e) => setExpire(e.target.value)}>
                                    <option value="">Select</option>
                                    <option value="true">True</option>
                                    <option value="false">False</option>
                                </select>
                            </label>
                        </div>
                        {hide && (
                            <label>
                                Expire Date
                                <input type="date" value={date} onChange={(e) => setDate(e.target.value)} required />
                            </label>
                        )}
                    </div>
                    <button className={classes.button} type="submit">
                        SUBMIT
                    </button>
                </form>
            </div>
        </div>
    )
}
