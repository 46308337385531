import { useContext, useEffect, useState } from 'react'
import { Auth } from '../../contexts/allContext'
import { api } from '../../utils/apiToken'
import MedicineForm from './MedicineForm/MedicineForm'
import MedicineList from './MedicineList/MedicineList'

export default function Medicine() {
    const [manufacturers, setManufacturers] = useState([])
    const [medicines, setMedicines] = useState([])
    const [pendingMedicines, setPendingMedicines] = useState([])

    const [pageSkip, setPageSkip] = useState(0)
    const [loading, setLoading] = useState(true)
    const [success, setSuccess] = useState(false)

    const [search, setSearch] = useState('')
    const [isOpenForm, setIsOpenForm] = useState(false)

    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/medicines/pharma/all?skip=0&limit=250`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                setManufacturers(data)
            } catch {
                setManufacturers([])
            }
        }
        return fetchData()
    }, [token])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${api}/medicines/all?search_medicine=${search}&skip=${pageSkip}&limit=10`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                const data = await response.json()

                if (response.ok) {
                    setMedicines(data)
                    setLoading(false)
                }
            } catch {
                setMedicines([])
            }
        }
        return fetchData()
    }, [token, search, pageSkip, success])

    //********** pending list *********//
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/medicines/pending?skip=${pageSkip}&limit=10`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()

                if (response.ok) {
                    setPendingMedicines(data)
                    setLoading(false)
                }
            } catch {
                setPendingMedicines([])
            }
        }
        return fetchData()
    }, [token, pageSkip, success])

    return (
        <div>
            {isOpenForm && (
                <MedicineForm setIsOpenForm={setIsOpenForm} manufacturers={manufacturers} setSuccess={setSuccess} />
            )}

            <MedicineList
                search={search}
                setSearch={setSearch}
                setIsOpenForm={setIsOpenForm}
                medicines={medicines}
                pendingMedicines={pendingMedicines}
                pageSkip={pageSkip}
                setPageSkip={setPageSkip}
                loading={loading}
                setSuccess={setSuccess}
            />
        </div>
    )
}
