import { useContext, useEffect, useState } from 'react'
import { UserInfo } from '../../../contexts/allContext'
import Form from '../../Resource/Form/Form'
import classes from './From.module.css'

export default function From() {
    const [pharma, setPharma] = useState([])
    const [file, setFile] = useState('')
    const api = process.env.REACT_APP_API_URL_V2
    const { stateUser } = useContext(UserInfo)
    const userInfo = stateUser.info

    const refreshPage = () => {
        window.location.reload()
    }

    const [details, setDetails] = useState({
        product_name: '',
        generic_name: '',
        pharmaceutical_name: '',
        strength: '',
        pack_size: '',
        regular_price: 0,
        discount_offer: 0,
        offer_price: 0,
        invoice_quantity: 0,
        bonus_quantity: 0,
        other_details: '',
        expiry_date: '',
        issuer_id: userInfo.id,
    })
    const upgradeDetail = (e) => {
        const { name, value } = e.target
        setDetails({
            ...details,
            [name]: value,
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg']

        if (allowedTypes.includes(file?.type)) {
            const response = await fetch(`${api}/pharmaceuticals-offer/`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(details),
            })

            let log = await response.json()

            if (response.ok) {
                submitImage(log.id)
            } else {
                alert('error')
            }
        } else {
            alert('"Only JPEG, PNG, and JPG images are allowed."')
        }
    }

    const submitImage = async (docId) => {
        const imgData = new FormData()
        imgData.append('file', file)

        let response = await fetch(`${api}/pharmaceuticals-offer/picture?issuer_id=${userInfo.id}&offer_id=${docId}`, {
            headers: {
                Accept: 'appllication/json',
                type: 'image/jpeg',
            },
            method: 'PATCH',
            body: imgData,
        })

        if (response.ok) {
            console.log('Image upload successful!')
            refreshPage()
        } else {
            alert('Something went wrong!')
        }
    }

    //get pharma name lists
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/pharmaceuticals-offer/pharmaceuticals`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                const data = await response.json()

                if (response.ok) {
                    setPharma(data)
                }
            } catch {
                setPharma([])
            }
        }
        fetchData()
    }, [api])

    return (
        <div className={classes.container}>
            <Form title="Add Offer" onSubmit={handleSubmit}>
                <label>
                    Product Image
                    <input type="file" accept="image" onChange={(e) => setFile(e.target.files[0])} required />
                </label>
                <div>
                    <label>
                        Product Name
                        <input
                            type="text"
                            name="product_name"
                            value={details.product_name}
                            onChange={upgradeDetail}
                            required
                        />
                    </label>
                    <label>
                        Generic Name
                        <input
                            type="text"
                            name="generic_name"
                            value={details.generic_name}
                            onChange={upgradeDetail}
                            required
                        />
                    </label>
                    <label>
                        Pharmaceutical name
                        <select
                            id="type"
                            name="pharmaceutical_name"
                            required
                            value={details.pharmaceutical_name}
                            onChange={upgradeDetail}>
                            <option value="">Select</option>
                            {pharma[1]?.map((par, index) => (
                                <option value={par.name} key={index}>
                                    {par.name}
                                </option>
                            ))}
                        </select>
                    </label>
                    <label>
                        Strength
                        <input type="text" name="strength" onChange={upgradeDetail} value={details.strength} required />
                    </label>
                    <label>
                        Pack Size
                        <input
                            type="text"
                            name="pack_size"
                            onChange={upgradeDetail}
                            value={details.pack_size}
                            required
                        />
                    </label>
                    <label>
                        Regular Price
                        <input
                            type="number"
                            name="regular_price"
                            onChange={upgradeDetail}
                            required
                            value={details.regular_price}
                        />
                    </label>
                    <label>
                        Discount Offer
                        <input
                            type="number"
                            name="discount_offer"
                            onChange={upgradeDetail}
                            required
                            value={details.discount_offer}
                        />
                    </label>
                    <label>
                        Offer Price
                        <input
                            type="number"
                            name="offer_price"
                            onChange={upgradeDetail}
                            value={details.offer_price}
                            required
                        />
                    </label>
                    <label>
                        Invoice Quantity
                        <input
                            type="text"
                            name="invoice_quantity"
                            onChange={upgradeDetail}
                            required
                            value={details.invoice_quantity}
                        />
                    </label>
                    <label>
                        Bonus Quantity
                        <input
                            type="text"
                            name="bonus_quantity"
                            onChange={upgradeDetail}
                            required
                            value={details.bonus_quantity}
                        />
                    </label>
                    <label>
                        Other Details
                        <input
                            type="text"
                            name="other_details"
                            onChange={upgradeDetail}
                            value={details.other_details}
                        />
                    </label>
                    <label>
                        Expiry Date
                        <input
                            type="date"
                            name="expiry_date"
                            onChange={upgradeDetail}
                            value={details.expiry_date}
                            required
                        />
                    </label>
                </div>
            </Form>
        </div>
    )
}
