import { useContext, useEffect, useState } from 'react'
import { Auth, UserInfo } from '../../contexts/allContext'
import classes from './Notice.module.css'
import NoticeForm from './NoticeForm/NoticeForm'
import NoticeList from './NoticeList/NoticeList'

export default function Notice() {
    const [roles, setRoles] = useState([])
    const [notices, setNotices] = useState([])
    const [listOpen, setListOpen] = useState(true)
    const [formOpen, setFormOpen] = useState(false)

    const api = process.env.REACT_APP_API_URL
    const { stateAuth } = useContext(Auth)
    const { stateUser } = useContext(UserInfo)
    const userInfo = stateUser.info
    const token = stateAuth.token

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/notice/?skip=0&limit=10`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                setNotices(data)
            } catch {
                setNotices([])
            }
        }
        return fetchData()
    }, [token, api])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${api}/roles`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                const data = await response.json()
                setRoles(data)
            } catch {
                setRoles([])
            }
        }
        return fetchData()
    }, [token, api])

    return (
        <div>
            {userInfo.role_name === 'admin' || userInfo.role_name === 'moderator' ? (
                <div>
                    {formOpen && <NoticeForm roles={roles} setFormOpen={setFormOpen} setListOpen={setListOpen} />}
                    {listOpen && (
                        <NoticeList
                            setFormOpen={setFormOpen}
                            setListOpen={setListOpen}
                            notices={notices}
                            userInfo={userInfo}
                        />
                    )}
                </div>
            ) : (
                <div className={classes.modWrapper}>
                    <div className={classes.mod}>Employee Can't Access This Page!</div>
                </div>
            )}
        </div>
    )
}
