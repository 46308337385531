import TelemedicineList from './TelemedicineList/TelemedicineList'

export default function TelemedicineShow({ telemedicines, serviceInfo }) {
    let telemedicine = telemedicines[0]
    return (
        <div>
            <TelemedicineList telemedicine={telemedicine} serviceInfo={serviceInfo} />
        </div>
    )
}
