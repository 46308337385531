import {
    faBookMedical,
    faBriefcaseMedical,
    faCirclePlus,
    faCog,
    faHeartPulse,
    faHospital,
    faHospitalUser,
    faMessage,
    faPlus,
    faPrescription,
    faServer,
    faSquarePlus,
    faTachometerAlt,
    faTag,
    faUniversalAccess,
    faUpload,
    faUserGraduate,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext } from 'react'
import { SidebarContext } from '../../contexts/sidebarContext'
import Notifications from '../Notifications/Notifications'
import classes from './Layout.module.css'
import Navbar from './Navbar/Navbar'
import Sidebar from './Sidebar/Sidebar'

export default function Layout({ children }) {
    const { isOpenSidebar } = useContext(SidebarContext)

    const sidebarItems = [
        {
            path: '/',
            icon: <FontAwesomeIcon icon={faTachometerAlt} title="Home" />,
            text: 'Home',
        },
        {
            path: '/patients',
            icon: <FontAwesomeIcon icon={faHeartPulse} title="Patients" />,
            text: 'Patients',
        },
        {
            path: '/services',
            icon: <FontAwesomeIcon icon={faServer} title="Services" />,
            text: 'Services',
        },
        {
            path: '/doctors',
            icon: <FontAwesomeIcon icon={faBriefcaseMedical} title="Doctors" />,
            text: 'Doctors',
        },
        {
            path: '/prescriptions',
            icon: <FontAwesomeIcon icon={faPrescription} title="Prescriptions" />,
            text: 'Prescriptions',
        },
        {
            path: '/employees',
            icon: <FontAwesomeIcon icon={faUserGraduate} title="Employees" />,
            text: 'Employees',
        },
        {
            path: '/corporates',
            icon: <FontAwesomeIcon icon={faUniversalAccess} title="Corporates" />,
            text: 'Corporates',
        },
        {
            path: '/service-list',
            icon: <FontAwesomeIcon icon={faCirclePlus} title="Service List" />,
            text: 'Service List',
        },
        {
            path: '/device-list',
            icon: <FontAwesomeIcon icon={faUpload} title="Device List" />,
            text: 'Device List',
        },
        {
            path: '/lab-provider',
            icon: <FontAwesomeIcon icon={faPlus} title="Service List" />,
            text: 'Provider List',
        },
        {
            path: '/lab-test',
            icon: <FontAwesomeIcon icon={faSquarePlus} title="Service List" />,
            text: 'Lab Test List',
        },
        {
            path: '/partner-pharmacies',
            icon: <FontAwesomeIcon icon={faHospitalUser} title="Pharmacies" />,
            text: 'Pharmacies',
        },
        {
            path: '/partner-pharma-offers',
            icon: <FontAwesomeIcon icon={faTag} title="Pharmacies" />,
            text: 'Pharma Offers',
        },
        {
            path: '/clinics',
            icon: <FontAwesomeIcon icon={faHospital} title="Clinics" />,
            text: 'Clinics',
        },
        // {
        //     path: '/notices',
        //     icon: <FontAwesomeIcon icon={faBullhorn} className={classes.iconNotice} title="Notices" />,
        //     text: 'Notices',
        // },
        {
            path: '/manuals',
            icon: <FontAwesomeIcon icon={faBookMedical} title="Manuals" />,
            text: 'Manuals',
        },
        {
            path: '/activities',
            icon: <FontAwesomeIcon icon={faMessage} title="Activities" />,
            text: 'Activities',
        },
        {
            path: '/settings',
            icon: <FontAwesomeIcon icon={faCog} title="Settings" />,
            text: 'Settings',
        },
        // {
        //     path: '/profile',
        //     icon: <FontAwesomeIcon icon={faUserCircle} title="Profiles" />,
        //     text: 'Profile',
        // },
    ]

    return (
        <>
            <Notifications />
            <div className={!isOpenSidebar ? classes.layout : classes.layoutWhenSidebarIsHidden}>
                <div>
                    <Sidebar sidebarItems={sidebarItems} />
                </div>
                <div className={classes.container}>
                    <Navbar />
                    {children}
                </div>
            </div>
        </>
    )
}
