import { useContext } from 'react'
import { Auth } from '../../../../contexts/allContext'
import { api } from '../../../../utils/apiToken'
import { refreshPage } from '../../../../utils/refreshPage'
import classes from './Popup.module.css'

export default function Popup({ index, setIsOpen, manual }) {
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    const deleted = async () => {
        const response = await fetch(`${api}/admin/s3/manual/${manual.id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })

        if (response.ok) {
            refreshPage()
        }
    }

    return (
        <div key={() => index} className={classes.container}>
            <div className={classes.overlay} onClick={() => setIsOpen(false)}></div>
            <div className={classes.formWrapper}>
                <div className={classes.close} onClick={() => setIsOpen(false)}>
                    &times;
                </div>

                <p>{manual.name}</p>

                <div className={classes.buttonContainer}>
                    <button className={classes.button} onClick={deleted}>
                        Yes
                    </button>

                    <button className={classes.button} onClick={() => setIsOpen(false)}>
                        No
                    </button>
                </div>
            </div>
        </div>
    )
}
