import { faEdit, faEye, faSearch, faTrashArrowUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import Pagination from '../../Pagination/Pagination'
import ListCount from '../../Resource/ListCount/ListCount'
import Table from '../../Resource/Table/Table'
import Detail from './Detail/Detail'
import Edit from './Edit/Edit'
import classes from './List.module.css'
import Popup from './Popup/Popup'

export default function List({ setIsOpen, providers, setSearch, search, setPageSkip, pageSkip }) {
    let sl = 0
    const [popup, setPopup] = useState(false)
    const [edit, setEdit] = useState(false)
    const [detail, setDetail] = useState(false)

    return (
        <div className={classes.list}>
            <div className={classes.wrapper}>
                <div>
                    <input
                        className={classes.searchField}
                        type="text"
                        value={search}
                        placeholder="Search Provider"
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <button className={classes.searchButton}>
                        <FontAwesomeIcon icon={faSearch} />
                    </button>
                </div>
                <button className={classes.button} onClick={() => setIsOpen(true)}>
                    + Add Provider
                </button>
            </div>
            <ListCount title="Provider List" results={providers} />
            <Table>
                <thead>
                    <tr>
                        <th>Sl</th>
                        <th>Image</th>
                        <th>Provider Name</th>
                        <th>Category</th>
                        <th>Division</th>
                        <th>District</th>
                        <th>City</th>
                        <th>Detail Discount</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {providers &&
                        providers[1]?.map((item, index) => (
                            <tr className={classes.row} key={index}>
                                <td>{(sl = sl + 1) + pageSkip}</td>
                                <td>
                                    <img src={item.image_url} alt="" className={classes.picture} />
                                </td>
                                <td>{item.name}</td>
                                <td>{item.category}</td>
                                <td>{item.division}</td>
                                <td>{item.district}</td>
                                <td>{item.city}</td>
                                <td className={classes.singleicon}>
                                    <FontAwesomeIcon icon={faEye} onClick={() => setDetail(index)} />
                                </td>
                                <td
                                // className={classes.icons}
                                >
                                    <FontAwesomeIcon
                                        icon={faEdit}
                                        onClick={() => setEdit(index)}
                                        className={classes.editicon}
                                    />
                                    <FontAwesomeIcon
                                        icon={faTrashArrowUp}
                                        onClick={() => setPopup(index)}
                                        className={classes.dlticon}
                                    />
                                </td>
                                {edit === index && <Edit provider={item} setIsOpen={setEdit} />}
                                {detail === index && <Detail provider={item} setIsOpen={setDetail} />}
                                {popup === index && <Popup provider={item} setIsOpen={setPopup} />}
                            </tr>
                        ))}
                </tbody>
            </Table>

            <Pagination
                pageSkip={pageSkip}
                setPageSkip={setPageSkip}
                listItem={providers[1]}
                pageAll={providers[0]?.results}
                item="30"
                limit="30"
            />
        </div>
    )
}
