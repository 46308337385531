import React from 'react'
import { LabProvider, Layout } from '../components'

export default function LabProviderPage() {
    return (
        <Layout>
            <LabProvider />
        </Layout>
    )
}
