import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import Pagination from '../../Pagination/Pagination'
import { ListCount, Table } from '../../Resource'
import classes from './List.module.css'
import Popup from './Popup/Popup'
import Update from './Update/Update'

export default function List({ offers, pageSkip, setPageSkip, setImgup, setLoader, loader }) {
    let serial = 0
    const [edit, setEdit] = useState(false)
    const [popup, setPopup] = useState(false)

    return (
        <div>
            <ListCount title="Offer List" results={offers} />
            <Table>
                <thead>
                    <tr>
                        <th>Sl</th>
                        <th>Name</th>
                        <th>Image</th>
                        <th>Generic Name</th>
                        <th>Pharma Name</th>
                        <th>Strength</th>
                        <th>Pack Size</th>
                        <th>Regular Price</th>
                        <th>Offer (%)</th>
                        <th>Offer Price</th>
                        <th>Invoice Qty</th>
                        <th>Bonus Qty</th>
                        <th>Expiry Date</th>
                        <th>Other Details</th>
                    </tr>
                </thead>
                <tbody>
                    {offers[1]?.map((offers, index) => (
                        <tr key={index} className={classes.row}>
                            <td>{(serial = serial + 1) + pageSkip}</td>
                            <td>{offers.PharmaOffer?.product_name}</td>
                            <td className={classes.img}>
                                <img src={offers.ImagesLog?.image_url} alt="" />
                            </td>
                            <td>{offers.PharmaOffer?.generic_name}</td>
                            <td>{offers.PharmaOffer?.pharmaceutical_name}</td>
                            <td>{offers.PharmaOffer?.strength}</td>
                            <td>{offers.PharmaOffer?.pack_size}</td>
                            <td>{offers.PharmaOffer?.regular_price}</td>
                            <td>{offers.PharmaOffer?.discount_offer}</td>
                            <td>{offers.PharmaOffer?.offer_price}</td>
                            <td>{offers.PharmaOffer?.invoice_quantity}</td>
                            <td>{offers.PharmaOffer?.bonus_quantity}</td>
                            <td>{offers.PharmaOffer?.expiry_date.split('-').reverse().join('-')}</td>
                            <td>
                                <div className={classes.icons}>
                                    <FontAwesomeIcon icon={faEdit} title="edit" onClick={() => setEdit(index)} />
                                    <FontAwesomeIcon icon={faTrash} title="delete" onClick={() => setPopup(index)} />
                                </div>
                            </td>
                            {edit === index && (
                                <Update
                                    offers={offers}
                                    setIsOpen={setEdit}
                                    setImgup={setImgup}
                                    setLoader={setLoader}
                                    loader={loader}
                                />
                            )}
                            {popup === index && <Popup offers={offers} setIsOpen={setPopup} />}
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Pagination
                pageSkip={pageSkip}
                setPageSkip={setPageSkip}
                listItem={offers[1]}
                pageAll={offers[0]?.results}
            />
        </div>
    )
}
