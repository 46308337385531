import { faLeaf, faThumbtack } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useState } from 'react'
import { Auth, UserInfo } from '../../../contexts/allContext'
import classes from './ModeratorForm.module.css'

export default function ModeratorForm({ setListOpen, setFormOpen, roles }) {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [gender, setGender] = useState('')
    const [role, setRole] = useState('')
    const [password, setPassword] = useState('')
    const [rePassword, setRePassword] = useState('')
    const [alert, setAlert] = useState(false)
    const [alertFetch, setAlertFetch] = useState(false)
    const [fetchInfo, setFetchInfo] = useState({})

    const api = process.env.REACT_APP_API_URL

    const { stateAuth } = useContext(Auth)
    const { stateUser } = useContext(UserInfo)
    const userInfo = stateUser.info
    const token = stateAuth.token

    const openClose = () => {
        setListOpen(true)
        setFormOpen(false)
    }

    const refreshPage = () => {
        window.location.reload()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (password !== rePassword) {
            setAlert(true)
            setTimeout(function () {
                setAlert(false)
            }, 3000)
        } else {
            const details = {
                name,
                email,
                phone,
                role_name: role,
                sex: gender,
                is_active: true,
                password,
            }
            let postFetch = await fetch(`${api}/admin/employee/create`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(details),
            })

            let contextFetch = await postFetch.json()
            if (postFetch.ok) {
                refreshPage()
            } else {
                setAlertFetch(true)
                setFetchInfo(contextFetch)
                setTimeout(function () {
                    setAlertFetch(false)
                }, 3000)
            }
        }
    }

    return (
        <div>
            <div className={classes.wrapper}>
                <button className={classes.button} onClick={() => openClose()}>
                    Employee List
                </button>
            </div>
            <div className={classes.formWrapper}>
                <FontAwesomeIcon className={classes.icon} icon={faThumbtack} />
                <div className={classes.logo}>ADD EMPLOYEE</div>

                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className={classes.section}>
                        <FontAwesomeIcon className={classes.leaf} icon={faLeaf} />
                        Details Info
                    </div>
                    <div className={classes.innerWrap}>
                        <div className={classes.formGrid}>
                            <label>
                                Full Name
                                <input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    minLength={3}
                                    required
                                />
                            </label>
                            <div className={`${classes.formGrid} ${classes.formMargin}`}>
                                <label>
                                    Role
                                    <select
                                        required
                                        className={classes.select}
                                        onChange={(e) => setRole(e.target.value)}>
                                        <option value="">Select</option>
                                        {roles &&
                                            roles.map((role, i) =>
                                                role.name !== 'admin' &&
                                                role.name !== 'doctor' &&
                                                role.name !== 'patient' &&
                                                role.name !== 'pharmacy_admin' &&
                                                role.name !== 'clinic_admin' ? (
                                                    userInfo.role_name !== role.name ? (
                                                        <option key={i} value={role.name}>
                                                            {role.name}
                                                        </option>
                                                    ) : null
                                                ) : null
                                            )}
                                    </select>
                                </label>
                                <label>
                                    Gender
                                    <select
                                        id="type"
                                        required
                                        className={classes.select}
                                        onChange={(e) => setGender(e.target.value)}>
                                        <option value="">Select</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                        <div className={classes.formGrid}>
                            <label>
                                Mobile
                                <input
                                    type="tel"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    minLength={11}
                                    maxLength={11}
                                    pattern="[0][1][0-9]{9}"
                                    required
                                />
                            </label>
                            <label>
                                Email
                                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                            </label>
                        </div>
                        <div className={classes.formGrid}>
                            <label>
                                Password
                                <input
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    minLength={4}
                                    required
                                />
                            </label>
                            <label>
                                Confirm Password
                                <input
                                    type="password"
                                    value={rePassword}
                                    onChange={(e) => setRePassword(e.target.value)}
                                    minLength={4}
                                    required
                                />
                            </label>
                        </div>
                    </div>
                    <button className={classes.button} type="submit">
                        SUBMIT
                    </button>
                    <div> {alert && <p className={classes.alertMessage}>Password not matched!</p>}</div>
                    <div> {alertFetch && <p className={classes.alertMessage}>{fetchInfo.context}</p>}</div>
                </form>
            </div>
        </div>
    )
}
