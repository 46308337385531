import { useContext, useEffect, useState } from 'react'
import Pharmacy from '../../../../../assets/pharmacy.jpg'
import { Auth } from '../../../../../contexts/allContext'
import { useRequest } from '../../../../../hooks/useRequest'
import { apiV2 } from '../../../../../utils/apiToken'
import Invoice from './Invoice/Invoice'
import classes from './Profile.module.css'
import Submit from './Submit/Submit'
import Summary from './Summary/Summary'

export default function Profile({ index, setIsOpen, pharmacyUserId, pharmacyId }) {
    const [pharmacy, setPharmacy] = useState({})
    const [user, setUser] = useState({})
    const [payment, setPayment] = useState({})

    const [telemed, setTelemed] = useState({})
    const [plan, setPlan] = useState({})
    const [patient, setPatient] = useState({})

    const [invoices, setInvoices] = useState([])
    const [pageSkip, setPageSkip] = useState(0)

    const [isChange, setIsChange] = useState(false)
    const [isForm, setForm] = useState(false)

    const { handleFetch } = useRequest()
    const { stateAuth } = useContext(Auth)
    const token = stateAuth.token

    useEffect(() => {
        const fetchData = async () => {
            const { data, isSuccess } = await handleFetch(
                `${apiV2}/admin/partner-pharmacy/single?user_id=${pharmacyUserId}&pharmacy_id=${pharmacyId}`,
                token
            )

            if (isSuccess) {
                setUser(data?.User)
                setPharmacy(data?.Pharmacy)
            } else {
                setUser({})
                setPharmacy({})
            }
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, pharmacyId, pharmacyUserId])

    useEffect(() => {
        const fetchData = async () => {
            const { data, isSuccess } = await handleFetch(
                `${apiV2}/partner-pharmacy/count/payments/logs?pharmacy_id=${pharmacyId}`,
                token
            )

            if (isSuccess) {
                setPayment(data)
            } else {
                setPayment({})
            }
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, pharmacyId, isChange])

    useEffect(() => {
        const fetchData = async () => {
            const tel = await handleFetch(
                `${apiV2}/partner-pharmacy/count/orders/details?pharmacy_id=${pharmacyId}&service_name=telemedicine`,
                token
            )
            const plan = await handleFetch(
                `${apiV2}/partner-pharmacy/count/orders/details?pharmacy_id=${pharmacyId}&service_name=health_plan`,
                token
            )
            const pat = await handleFetch(
                `${apiV2}/partner-pharmacy/count/patients/details?pharmacy_id=${pharmacyId}`,
                token
            )

            setTelemed(tel?.data)
            setPlan(plan?.data)
            setPatient(pat?.data)
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, pharmacyId, isChange])

    useEffect(() => {
        const fetchData = async () => {
            const { data, isSuccess } = await handleFetch(
                `${apiV2}/admin/partner-pharmacy/payment/list?pharmacy_id=${pharmacyId}&skip=${pageSkip}&limit=10`,
                token
            )

            if (isSuccess) {
                setInvoices(data)
            } else {
                setInvoices([])
            }
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, pharmacyId, pageSkip, isChange])

    return (
        <div key={() => index} className={classes.container}>
            <div className={classes.overlay} onClick={() => setIsOpen(false)}></div>
            <div className={classes.formWrapper}>
                <div className={classes.close} onClick={() => setIsOpen(false)}>
                    &times;
                </div>

                <div className={classes.divScroll}>
                    <div className={classes.cardContainer}>
                        <div className={classes.cardWrapper}>
                            <div className={classes.card}>
                                <img src={Pharmacy} alt="" />
                                <p className={classes.title}>{pharmacy.name}</p>
                                <span className={classes.subTitle}>{pharmacy.detail_address}</span>

                                <div className={classes.gridRow}>
                                    <div className={classes.flexColumn}>
                                        <p>Total Revenue</p>
                                        <span className={classes.numbers}>৳{payment.total_volumes || 0}</span>
                                    </div>
                                    <div className={classes.flexColumn}>
                                        <p>Total Commission</p>
                                        <span className={classes.numbers}>৳{payment.total_commissions || 0}</span>
                                    </div>

                                    <div className={classes.flexColumn}>
                                        <p>Total Paid</p>
                                        <span className={classes.numbers}>৳{payment.total_paid || 0}</span>
                                    </div>
                                    <div className={classes.flexColumn}>
                                        <p>Total Due</p>
                                        <span className={classes.numbers}>৳{payment.total_due || 0}</span>
                                    </div>
                                </div>

                                <h4>
                                    Last Payment Date: <span>{payment.last_pay_date?.slice(0, 10)}</span>
                                </h4>

                                <div className={classes.social}>
                                    <p>{user.name}</p>
                                    <p>{user.phone}</p>
                                </div>

                                <div className={classes.profile}>
                                    <button onClick={() => setForm((prev) => !prev)}>Make Payment</button>
                                </div>
                            </div>
                        </div>
                        {isForm ? (
                            <div className={classes.tableContainerPopup}>
                                <Submit
                                    pharmacyId={pharmacyId}
                                    pharmacyUserId={pharmacyUserId}
                                    setIsChange={setIsChange}
                                    setForm={setForm}
                                />
                            </div>
                        ) : (
                            <div className={classes.tableContainerPopup}>
                                <Summary telemed={telemed} plan={plan} patient={patient} />
                                <Invoice
                                    invoices={invoices}
                                    pharmacy={pharmacy}
                                    pageSkip={pageSkip}
                                    setPageSkip={setPageSkip}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
