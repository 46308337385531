import { ListCount, Table } from '../../Resource'
import { Pagination } from '../../index'
import { Loading } from '../../index'
import classes from './ActivityList.module.css'

export default function ActivityList({ activities, pageSkip, setPageSkip, loading }) {
    let serial = 0

    return (
        <div className={classes.list}>
            <ListCount title="Activity List" results={activities} />

            <Table>
                <thead>
                    <tr>
                        <th>Sl</th>
                        <th>Activity Info</th>
                        <th>Activity By</th>
                        <th>Mobile</th>
                        <th>Time</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {activities[1] &&
                        activities[1].map((activity, index) => (
                            <tr className={classes.row} key={index}>
                                <td>{(serial = serial + 1) + pageSkip} </td>
                                <td className={classes.cap}>{activity.service_name.replace(/_/g, ' ')}</td>
                                <td>{activity.user_name}</td>
                                <td>{activity.user_phone}</td>
                                <td>{activity.created_at.slice(11, 16)}</td>
                                <td>{activity.created_at.slice(0, 10)}</td>
                            </tr>
                        ))}
                </tbody>
            </Table>
            <Pagination
                pageSkip={pageSkip}
                setPageSkip={setPageSkip}
                listItem={activities[1]}
                pageAll={activities[0]?.results}
            />

            {loading && <Loading />}
        </div>
    )
}
