import { useState } from 'react'
import FollowUp from './FollowUp/FollowUp'
import HealthPlanShow from './HealthPlan/HealthPlanShow'
import classes from './ListDetail.module.css'
import MedicineShow from './Medicine/MedicineShow'
import TelemedicineShow from './Telemedicine/TelemedicineShow'

export default function ListDetail({
    api,
    token,
    setSuccess,
    index,
    setIsOpen,
    service,
    telemedicines,
    medicines,
    healthPlans,
}) {
    const [serviceInfo, setServiceInfo] = useState(service?.ServiceOrder)
    let [total, setTotal] = useState(serviceInfo?.order_value)
    let [discount, setDiscount] = useState(serviceInfo?.discount_percent)
    let [payable, setPayable] = useState(serviceInfo?.payable_amount)
    let [paid, setPaid] = useState(serviceInfo?.payment_by_customer)
    let [due, setDue] = useState(serviceInfo?.payment_pending)
    let eachTotal = 0

    const [alertFetch, setAlertFetch] = useState(false)
    const [fetchInfo, setFetchInfo] = useState({})

    medicines[1].forEach((item) => (eachTotal = eachTotal + item.total))

    const handlePayment = () => {
        payable = total - total * (discount / 100)
        setPayable(Math.ceil(payable))
        due = Math.ceil(payable) - paid
        setDue(Math.ceil(due))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const details = {
            ...serviceInfo,
            order_value: total,
            discount_percent: discount,
            payable_amount: payable,
            payment_by_customer: paid,
            payment_pending: due,
        }

        let patchFetch = await fetch(`${api}/service/${service?.ServiceOrder?.id}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(details),
        })

        if (patchFetch.ok) {
            alert('Update Successful')
            setSuccess(true)
            setTimeout(() => {
                setSuccess(false)
            }, 3000)
        } else {
            setAlertFetch(true)
            setFetchInfo(patchFetch)
            setTimeout(function () {
                setAlertFetch(false)
            }, 3000)
        }
    }

    return (
        <div key={() => index} className={classes.containerForm}>
            <div className={classes.formWrapper}>
                <div className={classes.close} onClick={() => setIsOpen(false)}>
                    &times;
                </div>

                <div className={classes.innerWrap}>
                    <div className={classes.container}>
                        <ul>
                            <li>
                                <input type="checkbox" id="listItem5" />
                                <label htmlFor="listItem5" className={classes.labelMain}>
                                    Follow-Up
                                </label>
                                <ul>
                                    <div className={classes.innerContainer}>
                                        <FollowUp service={service} />
                                    </div>
                                </ul>
                            </li>

                            <li>
                                <input type="checkbox" id="listItem1" />
                                <label htmlFor="listItem1" className={classes.labelMain}>
                                    Service
                                </label>
                                <ul>
                                    <div className={classes.innerContainer}>
                                        <div className={classes.collab}>
                                            <div className={classes.formGrid}>
                                                <label>
                                                    Patient Name
                                                    <span className={classes.fetchLabel}>
                                                        {service?.User?.name} <p>.</p>
                                                    </span>
                                                </label>
                                                <label className={classes.labelMargin}>
                                                    Patient Mobile
                                                    <span className={classes.fetchLabel}>
                                                        {service?.User?.phone} <p>.</p>
                                                    </span>
                                                </label>
                                            </div>

                                            <div className={classes.formGrid}>
                                                <div className={classes.formGrid}>
                                                    <label>
                                                        Serive Name
                                                        <span className={`${classes.fetchLabel} ${classes.cap}`}>
                                                            {service?.ServiceOrder?.service_name.replace(/_/g, ' ')}
                                                            <p>.</p>
                                                        </span>
                                                    </label>
                                                    <label>
                                                        Order Date
                                                        <span className={classes.fetchLabel}>
                                                            {service?.ServiceOrder?.order_placement !== null ? (
                                                                <>
                                                                    {service?.ServiceOrder?.order_placement.slice(
                                                                        0,
                                                                        10
                                                                    )}{' '}
                                                                    /{' '}
                                                                    {service?.ServiceOrder?.order_placement.slice(
                                                                        11,
                                                                        16
                                                                    )}
                                                                </>
                                                            ) : (
                                                                '--'
                                                            )}
                                                            <p>.</p>
                                                        </span>
                                                    </label>
                                                </div>
                                                <div className={`${classes.formGrid} ${classes.formMargin}`}>
                                                    <label>
                                                        Delivery Date
                                                        <input
                                                            type="datetime-local"
                                                            value={serviceInfo?.order_completion}
                                                            onChange={(e) =>
                                                                setServiceInfo({
                                                                    ...serviceInfo,
                                                                    order_completion: e.target.value,
                                                                })
                                                            }
                                                        />
                                                    </label>
                                                    <label>
                                                        Order Status
                                                        <select
                                                            value={serviceInfo?.order_status}
                                                            onChange={(e) =>
                                                                setServiceInfo({
                                                                    ...serviceInfo,
                                                                    order_status: e.target.value,
                                                                })
                                                            }
                                                            required
                                                            className={classes.select}>
                                                            <option value="">Select</option>
                                                            <option value="pending">Pending</option>
                                                            <option value="processing">Processing</option>
                                                            <option value="running">Running</option>
                                                            <option value="done">Done</option>
                                                            <option value="emergency">Emergency</option>
                                                            <option value="cancel">Cancel</option>
                                                        </select>
                                                    </label>
                                                </div>
                                            </div>
                                            <label>
                                                Service Remarks
                                                <input
                                                    type="text"
                                                    value={serviceInfo?.remarks}
                                                    onChange={(e) =>
                                                        setServiceInfo({
                                                            ...serviceInfo,
                                                            remarks: e.target.value,
                                                        })
                                                    }
                                                    required
                                                />
                                            </label>
                                            <label>
                                                Address
                                                <textarea
                                                    type="text"
                                                    rows={2}
                                                    value={serviceInfo?.current_address}
                                                    onChange={(e) =>
                                                        setServiceInfo({
                                                            ...serviceInfo,
                                                            current_address: e.target.value,
                                                        })
                                                    }
                                                    required
                                                />
                                            </label>
                                        </div>
                                        {medicines[0]?.results !== 0 ? (
                                            <div className={`${classes.innerContainer} ${classes.innerMargin}`}>
                                                <p>Medicine List</p>
                                                <div className={classes.collab}>
                                                    <MedicineShow setSuccess={setSuccess} medicines={medicines} />
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}

                                        {healthPlans.length !== 0 ? (
                                            <div className={`${classes.innerContainer} ${classes.innerMargin}`}>
                                                <p>Health Plan</p>
                                                <div className={classes.collab}>
                                                    <HealthPlanShow healthPlans={healthPlans} />
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}

                                        {telemedicines.length !== 0 ? (
                                            <div className={`${classes.innerContainer} ${classes.innerMargin}`}>
                                                <p>Telemedicine</p>
                                                <div className={classes.collab}>
                                                    <TelemedicineShow
                                                        telemedicines={telemedicines}
                                                        serviceInfo={serviceInfo}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </ul>
                            </li>

                            <li>
                                <input type="checkbox" id="listItem2" />
                                <label htmlFor="listItem2" className={classes.labelMain}>
                                    Payment
                                </label>
                                <ul>
                                    <div className={classes.innerContainer}>
                                        <div className={classes.collab}>
                                            <div className={classes.formGrid}>
                                                <label>
                                                    Payment Method
                                                    <select
                                                        value={serviceInfo?.payment_method}
                                                        onChange={(e) =>
                                                            setServiceInfo({
                                                                ...serviceInfo,
                                                                payment_method: e.target.value,
                                                            })
                                                        }
                                                        className={classes.select}>
                                                        <option value="">Select</option>
                                                        <option value="cash on delivery">Cash On Delivery</option>
                                                        <option value="bkash">Bkash</option>
                                                    </select>
                                                </label>
                                                <div className={`${classes.formGrid} ${classes.formMargin}`}>
                                                    <label>
                                                        Payment Status
                                                        <select
                                                            value={serviceInfo?.payment_status}
                                                            onChange={(e) =>
                                                                setServiceInfo({
                                                                    ...serviceInfo,
                                                                    payment_status: e.target.value,
                                                                })
                                                            }
                                                            required
                                                            className={classes.select}>
                                                            <option value="">Select</option>
                                                            <option value="pending">Pending</option>
                                                            <option value="done">Done</option>
                                                        </select>
                                                    </label>
                                                    <label>
                                                        Payment Date
                                                        <input
                                                            type="datetime-local"
                                                            value={serviceInfo?.last_payment_date}
                                                            onChange={(e) =>
                                                                setServiceInfo({
                                                                    ...serviceInfo,
                                                                    last_payment_date: e.target.value,
                                                                })
                                                            }
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className={classes.formGrid}>
                                                <label>
                                                    Total Amount
                                                    <span className={classes.tk}>
                                                        {isNaN(eachTotal) !== true && eachTotal > 0
                                                            ? ` - ${eachTotal}tk`
                                                            : ''}
                                                    </span>
                                                    <input
                                                        type="number"
                                                        value={total}
                                                        onChange={(e) => setTotal(parseInt(e.target.value))}
                                                        onBlur={(e) => handlePayment(e)}
                                                        min={0}
                                                        required
                                                    />
                                                </label>
                                                <label>
                                                    Discount (%)
                                                    <input
                                                        type="number"
                                                        value={discount}
                                                        onChange={(e) => setDiscount(parseInt(e.target.value))}
                                                        onBlur={(e) => handlePayment(e)}
                                                        min={0}
                                                        max={100}
                                                        required
                                                    />
                                                </label>
                                            </div>

                                            <div className={classes.formGrid}>
                                                <label>
                                                    Payable Amount
                                                    <input
                                                        type="number"
                                                        value={payable}
                                                        onChange={(e) => setPayable(parseInt(e.target.value))}
                                                        onBlur={(e) => handlePayment(e)}
                                                        min={0}
                                                        required
                                                    />
                                                </label>

                                                <div className={`${classes.formGrid} ${classes.formMargin}`}>
                                                    <label>
                                                        Paid Amount
                                                        <input
                                                            type="number"
                                                            value={paid}
                                                            onChange={(e) => setPaid(parseInt(e.target.value))}
                                                            onBlur={(e) => handlePayment(e)}
                                                            min={0}
                                                            required
                                                        />
                                                    </label>
                                                    <label>
                                                        Due Amount
                                                        <input
                                                            type="number"
                                                            value={due}
                                                            onChange={(e) => setDue(parseInt(e.target.value))}
                                                            onBlur={(e) => handlePayment(e)}
                                                            required
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ul>
                            </li>

                            <li>
                                <input type="checkbox" id="listItem3" />
                                <label htmlFor="listItem3" className={classes.labelMain}>
                                    Provider
                                </label>
                                <ul>
                                    <div className={classes.innerContainer}>
                                        <div className={classes.collab}>
                                            <label>
                                                Provider Type
                                                <span className={`${classes.fetchLabel} ${classes.cap}`}>
                                                    {service?.ServiceOrder?.service_provider_type}
                                                    <p>.</p>
                                                </span>
                                            </label>
                                            <div className={classes.formGrid}>
                                                <label>
                                                    Provider Name
                                                    <span className={classes.fetchLabel}>
                                                        {service?.ServiceOrder?.service_name !== 'telemedicine'
                                                            ? 'HEALTHx Pharmacy'
                                                            : '-'}
                                                        <p>.</p>
                                                    </span>
                                                </label>
                                                <label>
                                                    Provider Mobile
                                                    <span className={classes.fetchLabel}>
                                                        {service?.ServiceOrder?.service_name !== 'telemedicine'
                                                            ? '+8801322658481'
                                                            : '-'}
                                                        <p>.</p>
                                                    </span>
                                                </label>
                                            </div>

                                            <div className={classes.formGrid}>
                                                <label>
                                                    Provider Fee
                                                    <input
                                                        type="number"
                                                        value={serviceInfo?.service_provider_fee}
                                                        onChange={(e) =>
                                                            setServiceInfo({
                                                                ...serviceInfo,
                                                                service_provider_fee: parseInt(e.target.value),
                                                            })
                                                        }
                                                    />
                                                </label>
                                                <div className={`${classes.formGrid} ${classes.formMargin}`}>
                                                    <label>
                                                        Paid Amount
                                                        <input
                                                            type="number"
                                                            value={serviceInfo?.service_provider_fee_paid}
                                                            onChange={(e) =>
                                                                setServiceInfo({
                                                                    ...serviceInfo,
                                                                    service_provider_fee_paid: parseInt(e.target.value),
                                                                })
                                                            }
                                                        />
                                                    </label>
                                                    <label>
                                                        Due Amount
                                                        <input
                                                            type="number"
                                                            value={serviceInfo?.service_provider_fee_pending}
                                                            onChange={(e) =>
                                                                setServiceInfo({
                                                                    ...serviceInfo,
                                                                    service_provider_fee_pending: parseInt(
                                                                        e.target.value
                                                                    ),
                                                                })
                                                            }
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className={classes.formGrid}>
                                                <label>
                                                    Payment Status
                                                    <select
                                                        value={serviceInfo?.service_provider_fee_status}
                                                        onChange={(e) =>
                                                            setServiceInfo({
                                                                ...serviceInfo,
                                                                service_provider_fee_status: e.target.value,
                                                            })
                                                        }
                                                        className={classes.select}>
                                                        <option value="">Select</option>
                                                        <option value="pending">Pending</option>
                                                        <option value="done">Done</option>
                                                    </select>
                                                </label>
                                                <label>
                                                    Payment Date
                                                    <input
                                                        type="datetime-local"
                                                        value={serviceInfo?.service_provider_fee_last_update}
                                                        onChange={(e) =>
                                                            setServiceInfo({
                                                                ...serviceInfo,
                                                                service_provider_fee_last_update: e.target.value,
                                                            })
                                                        }
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </ul>
                            </li>

                            <li>
                                <input type="checkbox" id="listItem4" />
                                <label htmlFor="listItem4" className={classes.labelMain}>
                                    Referral
                                </label>
                                <ul>
                                    <div className={classes.innerContainer}>
                                        <div className={classes.collab}>
                                            {service?.ServiceOrder?.referral_id === null ? (
                                                <label>
                                                    Referral
                                                    <span className={classes.fetchLabel}>No</span>
                                                </label>
                                            ) : (
                                                <>
                                                    <label>
                                                        Referral
                                                        <span className={classes.fetchLabel}>Yes</span>
                                                    </label>
                                                    <div className={classes.refMargin}></div>
                                                    <label>
                                                        Referral Type
                                                        <span className={`${classes.fetchLabel} ${classes.cap}`}>
                                                            {service?.ServiceOrder?.referral_type} <p>.</p>
                                                        </span>
                                                    </label>
                                                    <div className={classes.formGrid}>
                                                        <label>
                                                            Referral Name
                                                            <span className={`${classes.fetchLabel} ${classes.cap}`}>
                                                                {service?.ServiceOrder?.referral_id}
                                                                <p>.</p>
                                                            </span>
                                                        </label>
                                                        <label>
                                                            Referral Mobile
                                                            <span className={classes.fetchLabel}>
                                                                <p>.</p>
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className={classes.formGrid}>
                                                        <label>
                                                            Referral Fee
                                                            <span className={classes.fetchLabel}>
                                                                {service?.ServiceOrder?.referral_provider_fee}
                                                                <p>.</p>
                                                            </span>
                                                        </label>
                                                        <div className={`${classes.formGrid} ${classes.formMargin}`}>
                                                            <label>
                                                                Paid Amount
                                                                <span className={classes.fetchLabel}>
                                                                    {service?.ServiceOrder?.referral_provider_fee_paid}
                                                                    <p>.</p>
                                                                </span>
                                                            </label>
                                                            <label>
                                                                Due Amount
                                                                <span className={classes.fetchLabel}>
                                                                    {
                                                                        service?.ServiceOrder
                                                                            ?.referral_provider_fee_pending
                                                                    }
                                                                    <p>.</p>
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className={classes.formGrid}>
                                                        <label>
                                                            Payment Status
                                                            <span className={`${classes.fetchLabel} ${classes.cap}`}>
                                                                {service?.ServiceOrder?.referral_provider_fee_status}
                                                                <p>.</p>
                                                            </span>
                                                        </label>
                                                        <label>
                                                            Update Date
                                                            <span className={classes.fetchLabel}>
                                                                {service?.ServiceOrder
                                                                    ?.referral_provider_fee_last_update !== null ? (
                                                                    <>
                                                                        {service?.ServiceOrder?.referral_provider_fee_last_update.slice(
                                                                            0,
                                                                            10
                                                                        )}
                                                                        --
                                                                        {service?.ServiceOrder?.referral_provider_fee_last_update.slice(
                                                                            11,
                                                                            16
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    '--'
                                                                )}
                                                                <p>.</p>
                                                            </span>
                                                        </label>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </ul>
                            </li>
                        </ul>
                        <div> {alertFetch && <p className={classes.alertMessage}>{fetchInfo.statusText}</p>}</div>
                        <button onClick={(e) => handleSubmit(e)} className={classes.buttonSubmit}>
                            Update
                        </button>
                    </div>
                </div>

                <div className={classes.buttonContainer}>
                    <button className={classes.button} onClick={() => setIsOpen(false)}>
                        Close
                    </button>
                </div>
            </div>
        </div>
    )
}
